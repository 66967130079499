import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { connect } from "react-redux"
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Divider,
  Chip,
  Button,
} from '@mui/material';
import MUIDataTable from "mui-datatables"
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender"
import { getAllFeatures, getAllRolesRequest, getUserByEntityId, getUserById } from "../../store/actions"
import "./ClientManagement.css" // Import CSS file
import { useSelector, useDispatch } from "react-redux"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PulseLoader from './../PulseLoader/PulseLoader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import Icon from '@mdi/react';
import { mdiLock, mdiLockOpenVariant } from '@mdi/js';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Link } from "react-router-dom";
import { Formik, Form } from 'formik';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CloseOutlined } from '@mui/icons-material';

const ClientManagement = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorUsers, selectedUser, roles, loading, modalLoading } = useSelector(state => state?.usersReducer);

  const { role, entityId } = useSelector(state => state?.Login?.user?.userDetails);
  const [editPopupOpen, setEditPopupOpen] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [filterModalView, setFilterModalView] = useState(false);


  const userFeatures = ["SQANIT", "PARSEIT", "CIVIX", "TOD", "WEBIT", "SQALEUP", "MOCK TEST", "CV TEMPLATE"]

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const hasSubFeature = (featureName) => {
    if (selectedUser?.subFeatures) {
      return selectedUser?.subFeatures?.subFeatures?.some(feature => feature.feature.name === featureName);
    }
    return false;
  };

  const formatContactNumber = (value) => {
    let countryCode = '';
    let phoneNumber = value;

    // Check if the number starts with a '+' (international format)
    if (value.startsWith('+')) {
      // Extract country code (assuming it starts with '+')
      countryCode = value.match(/^\+(\d+)/)?.[0] || '';
      // Extract the rest of the number
      phoneNumber = value.replace(countryCode, '').trim();
    } else {
      // Handle case where there is no '+' (assumes the first 2 digits are country code, e.g., "91")
      countryCode = `+${value.slice(0, 2)}`; // Assuming the country code is the first two digits
      phoneNumber = value.slice(2).trim(); // Extract rest of the number
    }

    // Return the formatted number with only a space between country code and the number
    return `${countryCode} ${phoneNumber}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getUserByEntityId(entityId));
      dispatch(getAllRolesRequest());
      dispatch(getAllFeatures());
    };
    fetchData();
  }, [dispatch, entityId])

  const vendorUsersData = vendorUsers?.map((user, index) => {
    const role = roles?.find(role => role._id === user.role);
    return {
      ...user,
      id: index + 1,
      name: user?.firstName + " " + user?.lastName,
      role: role ? role?.name : "Unknown Role",
      doj: formatDate(user?.doj),
      features: role && role.featuresAndPermissions,
      createdOn: formatDate(user?.createdOn) || "N/A",
    };
  });

  console.log("vendor", vendorUsersData)

  const handleViewClick = (dataIndex, e) => {
    e.stopPropagation();
    dispatch(getUserById(vendorUsersData[dataIndex]._id))
    setEditPopupOpen(true);
  }


  const customHeadRender = ({ index, ...columnMeta }) => (
    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
      {columnMeta.label}
    </th>
  );

  const customBodyRender = (value) => (
    <div>
      {value.length > 17 ? (
        <Tooltip title={value} arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#660032',
                color: '#ffffff',
                '& .MuiTooltip-arrow': {
                  color: '#660032',
                }
              }
            }
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '140px',
              display: 'inline-block',  // Changed from block to inline-block
              width: 'fit-content',     // Added to make width fit content
            }}>{value}</span>
        </Tooltip>
      ) : (
        <span
          style={{
            whiteSpace: 'nowrap',
            maxWidth: '120px',
            display: 'inline-block',
            width: 'fit-content',
          }}>{value}</span>
      )}
    </div>
  );

  const setCellProps = (column) => ({
    style: {
      maxWidth: column.maxWidth,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: "10px",
    }
  });
  const setCellHeaderProps = (column) => ({
    style: {
      backgroundColor: "#660032",
      padding: "10px",
      maxWidth: column.maxWidth,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: "10px",
    }
  });


  const columns = [
    {
      name: "_id",
      label: "Id",
      maxWidth: "30px",
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "profilePicture",
      label: "PROFILE",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps,
      },
    },
    {
      name: "firstName",
      options: {
        display: false,
      },
    },
    {
      name: "lastName",
      options: {
        display: false,
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const firstName = tableMeta.rowData[2];
          const lastName = tableMeta.rowData[3];
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {tableMeta?.rowData[1] && (
                (firstName.length + lastName.length) > 17 ? (
                  <Tooltip title={firstName + " " + lastName} arrow placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#660032',
                          color: '#ffffff',
                          '& .MuiTooltip-arrow': {
                            color: '#660032',
                          }
                        }
                      }
                    }}
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '120px',
                        display: 'inline-block',  // Changed from block to inline-block
                        width: 'fit-content',     // Added to make width fit content
                      }}>
                      <div>
                        <img
                          src={tableMeta?.rowData[1]}
                          alt="Profile"
                          className="client-management-profile-img"
                        />
                        {firstName} {lastName}
                      </div>
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      maxWidth: '120px',
                      display: 'inline-block',
                      width: 'fit-content',
                    }}>
                    <div>
                      <img
                        src={tableMeta?.rowData[1]}
                        alt="Profile"
                        className="client-management-profile-img"
                      />
                      {firstName} {lastName}
                    </div>
                  </span>
                )
              )}
            </div>
          );
        },
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
            {columnMeta.label}
          </th>
        ),
        setCellProps,
        setCellHeaderProps,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps,
      },
    },
    {
      name: "contactNumber",
      label: "Contact No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <span
              style={{
                whiteSpace: 'nowrap',
                maxWidth: '120px',
                display: 'inline-block',
                width: 'fit-content',
              }}>
              {formatContactNumber(value)}
            </span>
          );
        },
        customHeadRender,
        setCellProps,
        setCellHeaderProps,
      },
      customHeadRender: ({ index, ...columnMeta }) => (
        <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
          {columnMeta.label}
        </th>
      ),
    },
    {
      name: "address",
      label: "Location",
      options: {
        filter: true,
        sort: true,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps,
      },
    },
    {
      name: "edit",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => (
          <Tooltip title="View User" arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#660032',
                  color: '#ffffff',
                  '& .MuiTooltip-arrow': {
                    color: '#660032',
                  }
                }
              }
            }}
          >
            <IconButton onClick={e => handleViewClick(dataIndex, e)} >
              <VisibilityIcon style={{ color: "#660032" }} />
            </IconButton>
          </Tooltip>
        ),
        customHeadRender,
        setCellProps,
        setCellHeaderProps,
      },
    },
  ]




  const options = {
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    filter: false,
    sort: false
  }

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "User Management", href: "/client-management" },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = vendorUsersData.filter(user =>
      user.name.toLowerCase().includes(value.toLowerCase()) ||
      user.email.toLowerCase().includes(value.toLowerCase()) ||
      user.contactNumber.toLowerCase().includes(value.toLowerCase()) ||
      user.address.toLowerCase().includes(value.toLowerCase()) ||
      user.role.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const handleFilter = (values) => {
    // Update filter values based on the form input
    setFilterValues(values);

    // Filter the vendor users based on the filter values
    const filteredData = vendorUsersData.filter(user => {
      const matchesName = values.name ? user.name.toLowerCase().includes(values.name.toLowerCase()) : true;
      const matchesEmail = values.email ? user.email.toLowerCase().includes(values.email.toLowerCase()) : true;
      const matchesContact = values.contactNumber ? user.contactNumber.includes(values.contactNumber) : true;

      return matchesName && matchesEmail && matchesContact;
    });

    // Set the filtered data
    setFilteredData(filteredData);
  };

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      {loading ? (
        <PulseLoader />
      ) : (
        <>
          <div className="user-management-title-bar">
            <h5>USER MANAGEMENT</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {filterValues && Object.keys(filterValues).map((key) => (
                  filterValues[key] && (
                    <Chip
                      key={key}
                      label={key === 'experience' ? `${filterValues[key]} years` : filterValues[key]}
                      onDelete={() => {
                        const newFilterValues = { ...filterValues };
                        delete newFilterValues[key];
                        setFilterValues(newFilterValues);
                        handleFilter(newFilterValues);
                      }}
                      color="primary"
                    />
                  )
                ))}
              </div>
              <FilterListIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => setFilterModalView(true)} />
              <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
              <Tooltip title="Add User" arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#660032',
                      color: '#ffffff',
                      '& .MuiTooltip-arrow': {
                        color: '#660032',
                      }
                    }
                  }
                }}
              >
                <Link className="user-management-title-icon" to="/super-admin">
                  <PersonAddAlt1Icon className="user-management-title-icon-add" fontSize="small" />
                </Link>
              </Tooltip>
              <Link to="/create-role" style={{ textDecoration: 'none',marginLeft: '10px' }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ 
                    backgroundColor: '#660032', 
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#660032', // Set hover color to match the background
                    }
                  }}
                >
                  Create Role
                </Button>
              </Link>
            </div>
          </div>
          <Row className="mb-3">
            <Col>
              <MUIDataTable
                // className="ClientManagement__table"
                data={filteredData || vendorUsersData}
                columns={columns}
                options={options}
                className="ClientManagement__table"
              />
            </Col>
          </Row>

          <Modal
            isOpen={editPopupOpen}
            toggle={() => setEditPopupOpen(false)}
            size="lg"
            className="custom-modal"
            centered
          >
            <ModalHeader
              className="client-management-modal-header"
              close={<button className="modal-close-button" onClick={() => setEditPopupOpen(false)} style={{ color: 'white', backgroundColor: '#660032', border: "none", textAlign: "right" }}>&times;</button>}
            >
              VIEW USER PROFILE
            </ModalHeader>

            <ModalBody className="client-management-modal-body">
              <Grid container justifyContent="center" >
                <Grid item sm={12} md={5} lg={5} className="client-management-divider">
                  <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                    <Box
                      component="img"
                      alt={selectedUser?.name || "User"}
                      src={selectedUser?.profilePicture || ""}
                      className="client-management-img"
                    />
                    <div className="client-management-name-designation">
                      <Typography variant="h5" className="client-management-modal-name">
                        {selectedUser?.name || "User Name"}
                      </Typography>
                      <Typography variant="subtitle2" className="client-management-modal-text">
                        {selectedUser?.designation || selectedUser?.role || "Designation"}
                      </Typography>
                    </div>

                    <div className="client-management-active-features">
                      <Typography variant="h6" className="client-management-modal-labels" align="left">Active Features</Typography>
                      <div>
                        {userFeatures?.map((feature, index) => (
                          <div key={index} className="client-management-features">
                            {feature}
                            {!hasSubFeature(feature) ? (
                              <Icon path={mdiLock} size={1} className="client-management-lock-icon" />
                            ) : (
                              <Icon path={mdiLockOpenVariant} size={1} className="client-management-lock-icon" />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                  </Box>
                </Grid>

                <Grid item sm={12} md={7} lg={7} padding={1}>
                  <Typography variant="h6" className="client-management-modal-labels">User Details</Typography>
                  <div className="client-management-user-details-box">
                    <div className="client-management-user-details-title">
                      <Typography variant="subtitle2" fontWeight="bold">Email ID:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">Mobile No:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">Location:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">Role:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">Joining Date:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">Created By:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">Created on:</Typography>
                    </div>
                    <div className="client-management-user-details-value">
                      <Typography variant="subtitle2">{selectedUser?.email || "N/A"}</Typography>
                      <Typography variant="subtitle2">
                        {selectedUser?.contactNumber ? formatContactNumber(selectedUser.contactNumber) : 'N/A'}
                      </Typography>
                      <Typography variant="subtitle2">{selectedUser?.address || "N/A"}</Typography>
                      <Typography variant="subtitle2">{selectedUser?.role?.name || "N/A"}</Typography>
                      <Typography variant="subtitle2">{selectedUser?.doj || "N/A"}</Typography>
                      <Typography variant="subtitle2">{selectedUser?.createdBy || "N/A"}</Typography>
                      <Typography variant="subtitle2">{selectedUser?.createdOn || "N/A"}</Typography>
                    </div>
                  </div>

                  <Typography variant="h6" className="client-management-modal-labels">About User</Typography>
                  <div className="client-management-about-user-box">
                    <Typography variant="subtitle2">{selectedUser?.aboutUser || "N/A"}</Typography>
                  </div>

                  <Typography variant="h6" className="client-management-modal-labels">User Links</Typography>
                  <div className="client-management-user-links">
                    {selectedUser?.linkedin && (
                      <a href={selectedUser?.linkedin} target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon className="client-management-user-links-icon" />
                      </a>
                    )}
                    {selectedUser?.facebook && (
                      <a href={selectedUser?.facebook} target="_blank" rel="noopener noreferrer">
                        <FacebookIcon className="client-management-user-links-icon" />
                      </a>
                    )}
                    {selectedUser?.instagram && (
                      <a href={selectedUser?.instagram} target="_blank" rel="noopener noreferrer">
                        <InstagramIcon className="client-management-user-links-icon" />
                      </a>
                    )}
                    {selectedUser?.twitter && (
                      <a href={selectedUser?.twitter} target="_blank" rel="noopener noreferrer">
                        <XIcon className="client-management-user-links-icon" />


                      </a>
                    )}
                    {!selectedUser?.linkedin && !selectedUser?.facebook && !selectedUser?.instagram && !selectedUser?.twitter && (
                      <Typography variant="body2" style={{ color: '#707070' }}>
                        No links provided
                      </Typography>
                    )}

                  </div>
                </Grid>
              </Grid>
            </ModalBody>
          </Modal>


          <Modal isOpen={filterModalView} toggle={() => setFilterModalView(false)} className="filter-modal">
            <div className="filter-modal-container">
              <div className="filter-modal-title">
                <h5>FILTER</h5>
                <CloseOutlined onClick={() => setFilterModalView(false)} />
              </div>

              <Formik
                initialValues={{ name: '' || filterValues?.name, email: '' || filterValues?.email, contactNumber: '' || filterValues?.contactNumber }}
                onSubmit={(values) => {
                  handleFilter(values);
                  setFilterModalView(false);
                }}
              >
                {({ isSubmitting, handleChange, handleBlur, values }) => (
                  <Form>
                    <Grid container spacing={2} className="filter-modal-content">
                      <Grid item xs={12} sm={6} md={4} >
                        <div>
                          <label htmlFor="name" style={{ display: 'block', marginBottom: '5px' }}>Name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter Name"
                            value={values.name}
                            className="filter-modal-input"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} >
                        <div>
                          <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>Email</label>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="filter-modal-input"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} >
                        <div>
                          <label htmlFor="Contact Number" style={{ display: 'block', marginBottom: '5px' }}>Contact Number</label>
                          <input
                            type="text"
                            id="contactNumber"
                            name="contactNumber"
                            placeholder="Enter Contact Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactNumber}
                            className="filter-modal-input"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: 'right', }}>
                        <button type="submit" disabled={isSubmitting} className="filter-modal-button">APPLY</button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>

            </div>
          </Modal>
        </>
      )}
    </>
  )
}

export default connect(null)(ClientManagement)
