import React, { useState } from "react"
import PropTypes from "prop-types"
import { Tabs, Tab, Box, Typography, TextField, Button } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useDropzone } from "react-dropzone"
import img13 from "../../../assets/images/Picture13.png"
import img14 from "../../../assets/images/Picture14.png"
import { Card, CardBody, Col, Row, Form, Label, Input } from "reactstrap"
import { Country, State, City } from "country-state-city"
import "../PaidUnpaid/PaidUnpaid.scss"

const PaidUnpaid = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleSubmit = event => {
    event.preventDefault()
  }
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [cities, setCities] = useState([])

  const handleCountryChange = country => {
    setSelectedCountry(country)
    setSelectedState(null)
    setCities([])
  }
  const handleStateChange = state => {
    setSelectedState(state)
    const stateCities = City.getCitiesOfState(selectedCountry, state)
    setCities(stateCities)
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: [".pdf", ".doc", ".docx"],
  })
  const [query, setQuery] = useState("")

  const handleInputChange = e => {
    setQuery(e.target.value)
  }
  const handleSearch = () => {
    console.log("Searching for:", query)
  }
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            {/* <div className="cv-scanning-form">
             
                <Box
                  className="form-container"
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    maxHeight: "300px",
                    overflowY: "auto",
                    paddingRight: 1,
                    padding: "20px",
                  }}
                >  <div className="tabs gap-2">
                <button className="tab active">PAID</button>
                <button className="tab">UNPAID</button>
              </div>
                  {[
                    "Job Title",
                    "Location",
                    "Experience",
                    "Job Description",
                    "Certifications",
                    
                   
                   
                  ].map(label => (
                    <Box
                      key={label}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderColor: "#660032",
                      }}
                    >
                      <TextField
                        label={label}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{
                          maxWidth: "300px",
                          border: "none",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        }} 
                        InputLabelProps={{
                          sx: { fontSize: 12 }, 
                        }}
                        InputProps={{
                          endAdornment: <SearchIcon sx={{ fontSize: 15 }} />,
                        }}
                      />
                    </Box>
                  ))}
                  <Box
                    sx={{
                      marginTop: 2,
                      gap: "10px",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <div className="search-btn">
                    <button className="search-button">SUBMIT</button>
                    </div>
                  
                  </Box>
                </Box>
              </div> */}

            <div className="cv-scanning-form tod-form">
              <Form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    marginTop: 2,
                    gap: "10px",
                    display: "flex",
                    textAlign: "center",
                    marginLeft: "15px",
                  }}
                >
                  <input type="file" id="upload" hidden />
                  <label
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #56ab2f 0%, #a8e063  51%, #56ab2f  100%)",
                      margin: "0 5px 10px 5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      transition: "0.5s",
                      backgroundSize: "200% auto",
                      color: "#ffffff!important",

                      borderRadius: "10px",
                      display: "block",
                      padding: "10px 30px",
                      height: "auto",
                      width: "auto",
                    }}
                  >
                    Paid
                  </label>

                  <input type="file" hidden />
                  <label
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #56ab2f 0%, #a8e063  51%, #56ab2f  100%)",
                      margin: "0 5px 10px 5px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      transition: "0.5s",
                      backgroundSize: "200% auto",
                      color: "#ffffff!important",
                      borderRadius: "10px",
                      display: "block",
                      padding: "10px 30px",
                      height: "auto",
                      width: "auto",
                    }}
                  >
                    Unpaid
                  </label>
                </Box>

                <Row className="mb-2">
                  <Col md={12}>
                    <Label for="jobTitle">Job Title</Label>
                    <Input
                      type="text"
                      id="jobTitle"
                      placeholder="Enter job title"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={12}>
                    <Label for="workMode">Mandatory Skills</Label>
                    <Input type="select" id="workMode" className="form-control">
                      <option>Mandatory Skills</option>
                      <option>Onsite</option>
                      <option>Remote</option>
                      <option>Hybrid</option>
                    </Input>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md={12}>
                    <Label for="yearsOfExperience">Years of Experience</Label>
                    <Input
                      type="text"
                      id="yearsOfExperience"
                      placeholder="Enter years of experience"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={12}>
                    <Label for="jobTitle">Job Description</Label>
                    <Input
                      type="textarea"
                      id="jobTitle"
                      placeholder="Enter job Description"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={12}>
                    <Label for="country">Location</Label>
                    <Input
                      type="select"
                      id="country"
                      className="form-control"
                      value={selectedCountry}
                      onChange={e => handleCountryChange(e.target.value)}
                    >
                      <option value="">Select Country</option>
                      {Country.getAllCountries().map(country => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <box>
                  <input type="file" hidden />
                  <label
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #56ab2f 0%, #a8e063  51%, #56ab2f  100%)",
                     marginTop:"20px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      transition: "0.5s",
                      backgroundSize: "200% auto",
                      color: "#ffffff!important",
                      borderRadius: "10px",
                      display: "block",
                      padding: "10px 30px",
                      height: "auto",
                      width: "auto",
                    }}
                  >
                    Submit
                  </label>
                </box>
              </Form>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Box
              className="form-container"
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                maxHeight: "300px",
                overflowY: "auto",
                paddingRight: 1,
              }}
            >
              <img src={img13} alt="screenshot" />
            </Box>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Box
              className="form-container"
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                maxHeight: "300px",
                overflowY: "auto",
                paddingRight: 1,
              }}
            >
              <img src={img14} alt="screenshot" />
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaidUnpaid
