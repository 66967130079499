import { GET_ALL_JOB_REQUIREMENT_FAILURE } from "../product/tod/jobRequirements/actionTypes"
import {
  ADD_USER,
  ADD_USER_FAILURE,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_FAILURE,
  GET_ALL_ROLES_SUCCESS,
  GET_ROLE_BY_ID,
  GET_ROLE_BY_ID_FAILURE,
  GET_ROLE_BY_ID_SUCCESS,
  GET_USER_BY_ENTITY_ID,
  GET_USER_BY_ENTITY_ID_SUCCESS,
  GET_USER_BY_ID,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_BY_ENTITY_ID_FAILURE,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  GET_ALL_FEATURES,
  GET_ALL_FEATURES_SUCCESS,
  GET_ALL_FEATURES_FAILURE,
  GET_ALL_COUNTS,
  GET_ALL_COUNTS_SUCCESS,
  GET_ALL_COUNTS_FAILURE,
  GET_USER_BY_CREATED_BY_REQUEST,
  GET_USER_BY_CREATED_BY_SUCCESS,
  GET_USER_BY_CREATED_BY_FAILURE,
} from "./actionTypes"

const initialState = {
  users: [],
  vendorUsers: [],
  selectedUser: null,
  loading: false,
  modalLoading: false,
  error: null,
  role: null,
  roles: [],
  features:[],
  allCounts:null,
  userList: []
}

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER:
    case GET_USERS:
    case GET_USER_BY_ENTITY_ID:
    case GET_USER_BY_CREATED_BY_REQUEST:
    case UPDATE_USER:
    case DELETE_USER:
    case GET_ROLE_BY_ID:
    case GET_ALL_ROLES_REQUEST:
    case GET_ALL_FEATURES:
    case GET_ALL_COUNTS:
      return { ...state, loading: true }

    case GET_USER_BY_ID:
      return { ...state, modalLoading: true, loading: true }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
      }

    case GET_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload }

    case GET_USER_BY_ID_SUCCESS:
      return { ...state, loading: false, selectedUser: action.payload.userDetails, modalLoading: false }

    case GET_USER_BY_ENTITY_ID_SUCCESS:
      return { ...state, loading: false, vendorUsers: action.payload }

      case GET_USER_BY_CREATED_BY_SUCCESS:
        return { ...state, loading: false, userList: action.payload }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => user._id !== action.payload),
      }
    case GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        role: [...state.role, action.payload]
      }

    case GET_ALL_ROLES_SUCCESS:
      return { ...state, loading: false, roles: action.payload }

    case GET_ALL_FEATURES_SUCCESS:
      return { ...state, loading: false, features: action.payload }

    case GET_ALL_COUNTS_SUCCESS:
      return { ...state, loading: false, allCounts: action.payload }

    case ADD_USER_FAILURE:
    case GET_USERS_FAILURE:
    case GET_USER_BY_ID_FAILURE:
    case GET_USER_BY_ENTITY_ID_FAILURE:
    case GET_USER_BY_CREATED_BY_FAILURE:
    case GET_ALL_JOB_REQUIREMENT_FAILURE:
    case UPDATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
    case GET_ROLE_BY_ID_FAILURE:
    case GET_ALL_ROLES_FAILURE:
    case GET_ALL_FEATURES_FAILURE:
    case GET_ALL_COUNTS_FAILURE:
      return { ...state, loading: false, error: action.payload , modalLoading:false}

    default:
      return state
  }
}

export default usersReducer
