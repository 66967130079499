import React, { useState } from "react"
import ProductTabs from "../ProductTabs"
import CustomBreadcrumb from "../../../components/CustomComponents/CustomBreadCrumb";
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';

const newsData = [
  {
    title: 'Top Tech Trends in 2024',
    description: 'Stay ahead with the latest tech trends shaping the industry in 2024.',
    imageUrl: 'https://via.placeholder.com/150',
    link: '#',
  },
  {
    title: 'AI’s Role in Software Development',
    description: 'How artificial intelligence is revolutionizing software engineering.',
    imageUrl: 'https://via.placeholder.com/150',
    link: '#',
  },
  {
    title: 'Top Tech Trends in 2024',
    description: 'Stay ahead with the latest tech trends shaping the industry in 2024.',
    imageUrl: 'https://via.placeholder.com/150',
    link: '#',
  }
];

const blogData = [
  {
    title: 'Improving Your Coding Skills',
    description: 'Tips and tricks to enhance your programming abilities and stay competitive.',
    imageUrl: 'https://via.placeholder.com/150',
    link: '#',
  },
  {
    title: 'Navigating a Career in Tech',
    description: 'A guide to building a successful career in the technology industry.',
    imageUrl: 'https://via.placeholder.com/150',
    link: '#',
  },
  {
    title: 'Improving Your Coding Skills',
    description: 'Tips and tricks to enhance your programming abilities and stay competitive.',
    imageUrl: 'https://via.placeholder.com/150',
    link: '#',
  }
];



const NewsAndBlogs = () => {


    const breadcrumbItems = [
        { label: "Home", href: "/dashboard" },
        { label: "CV Template", href: "/cvtemplate" },
        { label: "Resume Builder", href: "/resumebuilder" },
    ];



    return (
        <div>
            <CustomBreadcrumb items={breadcrumbItems} />
            <ProductTabs tab={7} />

            <Box sx={{ padding: 4 }}>
                {/* Header Section */}
                <Typography variant="h4" gutterBottom>
                    News and Blogs
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Stay updated with the latest news and blogs related to your career.
                </Typography>

                {/* Trending News Section */}
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Trending News
                    </Typography>
                    <Grid container spacing={3}>
                        {newsData.map((news, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={news.imageUrl}
                                        alt={news.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">{news.title}</Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                                            {news.description}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            sx={{ marginTop: 2 }}
                                            href={news.link}
                                        >
                                            Read More
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Relevant Blogs Section */}
                <Box sx={{ marginTop: 6 }}>
                    <Typography variant="h5" gutterBottom>
                        Relevant Blogs
                    </Typography>
                    <Grid container spacing={3}>
                        {blogData.map((blog, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={blog.imageUrl}
                                        alt={blog.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">{blog.title}</Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                                            {blog.description}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            sx={{ marginTop: 2 }}
                                            href={blog.link}
                                        >
                                            Read More
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

        </div>
    )
}

export default NewsAndBlogs
