// import { call, put, takeLatest } from "redux-saga/effects"
// import {
//   FETCH_CV_ACCESS_CANDIDATES_REQUEST,
//   FILTER_CV_ACCESS_CANDIDATES_REQUEST,
// } from "./actionTypes"
// import {
//   fetchCvAccessCandidatesSuccess,
//   fetchCvAccessCandidatesFailure,
//   filterCvAccessCandidatesSuccess,
//   filterCvAccessCandidatesFailure,
// } from "./actions"
// import { fetchCandidatesList, filterCandidateData } from "../../../api"

// function* fetchCvAccessCandidatesSaga() {
//   console.log("fetchCandidatesSaga")
//   try {
//     const response = yield call(fetchCandidatesList)
//     console.log("API call successful, response:----", response)
//     yield put(fetchCvAccessCandidatesSuccess(response.data))
//   } catch (error) {
//     console.log("API call failed, error:", error)
//     yield put(fetchCvAccessCandidatesFailure(error.message))
//   }
// }

// function* filterCvAccessCandidatesSaga(action) {
//   console.log("filterCandidatesSaga")
//   try {
//     const response = yield call(filterCandidateData, action.payload)
//     console.log("API call successful, response:----", response)
//     yield put(filterCvAccessCandidatesSuccess(response.data))
//   } catch (error) {
//     console.log("API call failed, error:", error)
//     yield put(filterCvAccessCandidatesFailure(error.message))
//   }
// }

// function* watchFetchCvAccessCandidatesList() {
//   console.log("watchFetchCandidatesList saga initialized")
//   yield takeLatest(
//     FETCH_CV_ACCESS_CANDIDATES_REQUEST,
//     fetchCvAccessCandidatesSaga,
//   )
// }

// function* watchFilterCvAccessCandidatesList() {
//   console.log("watchFilterCandidatesList saga initialized")
//   yield takeLatest(
//     FILTER_CV_ACCESS_CANDIDATES_REQUEST,
//     filterCvAccessCandidatesSaga,
//   )
// }

// export default function* rootSaga() {
//   console.log("rootSaga initialized")
//   yield all([
//     fork(watchFetchCvAccessCandidatesList),
//     fork(watchFilterCvAccessCandidatesList),
//   ])
// }



import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_CV_ACCESS_CANDIDATES_REQUEST,
  FILTER_CV_ACCESS_CANDIDATES_REQUEST,
} from "./actionTypes";
import {
  fetchCvAccessCandidatesSuccess,
  fetchCvAccessCandidatesFailure,
  filterCvAccessCandidatesSuccess,
  filterCvAccessCandidatesFailure,
} from "./actions";
import { fetchCandidatesList, filterCandidateData } from "../../../api";

function* fetchCvAccessCandidatesSaga() {
  console.log("fetchCandidatesSaga");
  try {
    const response = yield call(fetchCandidatesList);
    console.log("API call successful, response:----", response);
    yield put(fetchCvAccessCandidatesSuccess(response.data));
  } catch (error) {
    console.log("API call failed, error:", error);
    yield put(fetchCvAccessCandidatesFailure(error.message));
  }
}

function* filterCvAccessCandidatesSaga(action) {
  console.log("filterCandidatesSaga");
  try {
    const response = yield call(filterCandidateData, action.payload);
    console.log("API call successful, response:----", response);
    yield put(filterCvAccessCandidatesSuccess(response.data));
  } catch (error) {
    console.log("API call failed, error:", error);
    yield put(filterCvAccessCandidatesFailure(error.message));
  }
}

export function* watchFetchCvAccessCandidatesList() {
  console.log("watchFetchCandidatesList saga initialized");
  yield takeLatest(
    FETCH_CV_ACCESS_CANDIDATES_REQUEST,
    fetchCvAccessCandidatesSaga,
  );
}

export function* watchFilterCvAccessCandidatesList() {
  console.log("watchFilterCandidatesList saga initialized");
  yield takeLatest(
    FILTER_CV_ACCESS_CANDIDATES_REQUEST,
    filterCvAccessCandidatesSaga,
  );
}
