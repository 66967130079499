import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { logoutRequest } from "../../store/actions";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PulseLoader from "./../PulseLoader/PulseLoader"; // Assuming you use PulseLoader for loading
import RegisterHeader from "./Header/RegisterHeader";
import Footer from "./Footer/Footer";
import { Row, Col, Card, CardBody, Input, Button, Form, FormGroup, Label, InputGroupText,InputGroup, } from "reactstrap";
import { FaEnvelope } from 'react-icons/fa';
import { useFormik } from "formik";
import { Link } from "react-router-dom"

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const loading = useSelector((state) => state.auth.loading); // Assuming loading state is managed in auth

// const formik = useFormik({
//   initialValues:{
//     email:""
//   }
// })

  useEffect(() => {
    dispatch(logoutRequest(navigate));
  }, [dispatch, navigate]);

  return (
    <div className="logout-page">
      {/* {loading && <PulseLoader />} */}
      <div className="logout-background">
          <RegisterHeader/>   
            <div className="logout-box">
              <div className="logout-container">         
                  <Row >
                    <Col>
                      <Card>
                        <CardBody className="logout-card">
                          <h1 className="text-center">Logged Out</h1>
                          <h6 className="text-center">Thank You For Using CVIXO</h6>
                          <div className="button-wrapper d-flex justify-content-center">
                            <Button
                              className="w-50 text-center register-btn-custom"
                              type="submit"
                            >
                              <Link
                                to="/login"
                                style={{ textDecoration: 'none', color: 'white' }}
                              >
                                Sign In Again
                              </Link>
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>        
              </div>
            </div>
      </div>  
      <Footer />
    </div>
  );
};

Logout.propTypes = {
  history: PropTypes.object, // No longer needed if using react-router-dom v6
};

export default Logout;
