// reducer.js
import {
    PARSE_PROFILE_REQUEST,
    PARSE_PROFILE_SUCCESS,
    PARSE_PROFILE_FAILURE, UPDATE_CANDIDATE_SUCCESS, UPDATE_CANDIDATE_FAILURE, UPDATE_CANDIDATE_REQUEST,
    SEND_TO_SQANIT_REQUEST, SEND_TO_SQANIT_SUCCESS, SEND_TO_SQANIT_FAILURE,
    SHARE_PROFILE_REQUEST, SHARE_PROFILE_SUCCESS, SHARE_PROFILE_FAILURE,
    DELETE_FAILED_PROFILE_REQUEST,
    DELETE_FAILED_PROFILE_SUCCESS,
    DELETE_FAILED_PROFILE_FAILURE,
    RESET_PARSE_DATA_REQUEST,
    GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST,
    GET_PARSED_PROFILE_DATA_BY_USER_ID_SUCCESS,
    GET_PARSED_PROFILE_DATA_BY_USER_ID_FAILURE,
} from './actionTypes';

const initialState = {
    loading: false,
    parsedProfiles: [],
    failedProfiles: [],
    uploadingProgress: 0,
    error: null,
    reuploadResult: "",
    sqanitData: [],
    profiles:[],
    sharing: false,
    sharingResult: "",
    candidate:{}
};

const parseProfilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARSE_PROFILE_REQUEST:
            if (action.payload.reuploadAll) {
                return {
                    ...state,
                    uploadingProgress: 5,
                    reuploadResult: "",
                    failedProfiles: [],
                    loading: true,
                };
            }
            else {
                return {
                    ...state,
                    parsedProfiles: [],
                    failedProfiles: [],
                    uploadingProgress: 5,
                    reuploadResult: "",
                    loading: true,
                };
            }

        case PARSE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                parsedProfiles: [...state.parsedProfiles, action.payload.uploadedResumes],
                uploadingProgress: action.payload.uploadProgress,
                reuploadResult: action.payload.reupload || action.payload.reuploadAll ? "Done" : ""
            };
        case PARSE_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                failedProfiles: [...state.failedProfiles, action.payload.failedResumes],
                uploadingProgress: action.payload.uploadProgress,
                reuploadResult: action.payload.reupload ? "Failed" : ""
            };

        case UPDATE_CANDIDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                candidate: state.profiles.map(candidate =>
                    candidate.id === action.payload.id ? (console.log("candidate",candidate),action.payload ): candidate
                ),
            };
        case UPDATE_CANDIDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SEND_TO_SQANIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SEND_TO_SQANIT_SUCCESS:
            return {
                ...state,
                loading: false,
                sqanitData: action.payload
            };
        case SEND_TO_SQANIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SHARE_PROFILE_REQUEST:
            return {
                ...state,
                sharing: true,
            };
        case SHARE_PROFILE_SUCCESS:
            return {
                ...state,
                sharing: false,
                sharingResult: action.payload
            };
        case SHARE_PROFILE_FAILURE:
            return {
                ...state,
                sharing: false,
                sharingResult: action.payload
            };

        case DELETE_FAILED_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case DELETE_FAILED_PROFILE_SUCCESS:
            console.log("in delete saga", action.payload);
            return {
                ...state,
                failedProfiles: state.failedProfiles.filter(
                    profile => profile.fileName !== action.payload.fileName
                ),
                loading: false,
            }

        case DELETE_FAILED_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case RESET_PARSE_DATA_REQUEST:
            return {
                initialState: undefined
            }

        case GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case GET_PARSED_PROFILE_DATA_BY_USER_ID_SUCCESS:
            return {
                ...state,
                loading:false,
                profiles: action.payload.data
            }

        case GET_PARSED_PROFILE_DATA_BY_USER_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        default:
            return state;
    }
};

export default parseProfilesReducer;
