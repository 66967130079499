import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
 
// //Import Scrollbar
import SimpleBar from "simplebar-react"
 
// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
 
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SubscriptionIcon from '../../assets/images/icons/subscription.png';
import PaymentIcon from '../../assets/images/icons/payment.png';
import ClientUserIcon from '../../assets/images/icons/client.png';
import ProductsIcon from '../../assets/images/icons/product.png';
import AccountSettingIcon from '../../assets/images/icons/accountsettings.png';
import UserProfileIcon from '../../assets/images/icons/profile-creation.png';
import InvoiceIcon from '../../assets/images/icons/invoice.png';
import usermgt from '../../assets/images/icons/usermgt.png'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import addinvoice from '../../assets/images/icons/addinvoice.png'
import Cvsqanit from '../../assets/images/icons/Cvsqanit.png'
import Cvparseit from '../../assets/images/icons/Cvparseit.png'
import CvAccess from "pages/ProductList/CvAccess"

 
const ClientSidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
 
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
 
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
 
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
 
        const parent3 = parent2.parentElement; // li tag
 
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
 
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
 
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }
 
        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;
 
        if (parent2) {
          parent2.classList.remove("mm-show");
 
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");
 
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };
 
  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);
 
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);
 
  useEffect(() => {
    ref.current.recalculate();
  }, []);
 
  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);
 
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);
 
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const userDetails = useSelector(state => state.Login?.user.userDetails || {})
  const hasPermission = featureName => {
    // Check if the user has 'canView' permission for the given feature
    // console.log("userDetails213456", userDetails)
    return (
      userDetails.permissions?.some(
        p => p.featureName === featureName && p.permissions.canView,
      ) || false
    )
  }


  return (
    <React.Fragment>
     <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
     <div id="sidebar-menu">
        
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/client-management-dashboard" className="waves-effect">
                  <i className="mdi mdi-home"></i>
                  <span>{props.t("HOME")}</span>
                 
                </Link>
              </li>
              <li>
                <Link to="/client-management" className="waves-effect">
                
                <img src={usermgt} />
                  <span> {props.t("USER MANAGEMENT")}</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="has-arrow waves-effect">
                <img src={ClientUserIcon} />
                  <span>{props.t("CLIENT USERS")}</span>
                </Link>
                <ul className="sub-dropdown">
                  <li>
                    <Link to="#">
                    <AccountCircleIcon  style={{ fontSize:'19px', marginRight: '5px' }}/>
                      <span>{props.t("SUPER ADMIN")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <PersonAddIcon  style={{fontSize:'19px', marginRight: '5px' }} />
                      <span>{props.t("ADMIN")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/UserProfileCreation">
                      <img src={UserProfileIcon} />
                      <span>{props.t("USER PROFILE CREATION")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="javascript:void(0)" className="has-arrow waves-effect">
                 <img src={ProductsIcon} />
                  <span>{props.t("PRODUCT")}</span>
                </Link>
                <ul className="sub-dropdown">
                  <li>
                    <Link
                      to={hasPermission("SQANIT") ? "/scan-it" : "#"}
                      className={`waves-effect ${!hasPermission("SQANIT") ? "disabled-link" : ""}`}
                    >
                      <img src={Cvsqanit} className={`custom-img ${!hasPermission("SQANIT") ? "disabled" : ""}`} />
                      <span>{props.t("SQANIT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("PARSEIT") ? "/parsing" : "#"}
                      className={`waves-effect ${!hasPermission("PARSEIT") ? "disabled-link" : ""}`}
                    >
                      <img src={Cvparseit} className={`custom-img ${!hasPermission("PARSEIT") ? "disabled" : ""}`} />
                      <span>{props.t("PARSEIT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("CIVIX") ? "/access" : "#"}
                      className={`waves-effect ${!hasPermission("CIVIX") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-excel ${!hasPermission("CIVIX") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("CIVIX")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("TOD") ? "/todlist" : "#"}
                      className={`waves-effect ${!hasPermission("TOD") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-document ${!hasPermission("TOD") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("TOD")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("WEBIT") ? "/Webscrap" : "#"}
                      className={`waves-effect ${!hasPermission("WEBIT") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-comment-text ${!hasPermission("WEBIT") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("WEBIT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("SQALEUP") ? "/salescrmp" : "#"}
                      className={`waves-effect ${!hasPermission("SQALEUP") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-document ${!hasPermission("SQALEUP") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("SQALEUP")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("MOCK TEST") ? "/mocktestp" : "#"}
                      className={`waves-effect ${!hasPermission("MOCK TEST") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-clipboard-text ${!hasPermission("MOCK TEST") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("MOCK TEST")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("CV TEMPLATES") ? "/cvtemplate" : "#"}
                      className={`waves-effect ${!hasPermission("CV TEMPLATES") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-find ${!hasPermission("CV TEMPLATES") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("CV TEMPLATES")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/" className="waves-effect">
                  <img src={SubscriptionIcon} />
                  <span>{props.t("SUBSCRIPTION")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                 <img src={InvoiceIcon} />
                  <span>{props.t("INVOICE")}</span>
                </Link>
                <ul className="sub-dropdown">
                  <li>
                    <Link to="">
                      <i className="mdi mdi-format-list-bulleted"></i>
                      <span className="menu-text">{props.t("LIST")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src={addinvoice} />
                      <span className="menu-text">{props.t("PREVIEW")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="mdi mdi-account-multiple-plus"></i>
                      <span className="menu-text">{props.t("ADD")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="mdi mdi-pencil-box"></i>
                      <span className="menu-text">{props.t("EDIT")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#" className="waves-effect">
                  <i className="mdi mdi-checkbox-multiple-marked-outline"></i>
                  <span>{props.t("TO DO LIST")}</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="waves-effect">
                  <i className="mdi mdi-message-text"></i>
                  <span>{props.t("EMAIL")}</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="waves-effect">
                  <i className="mdi mdi-alert-octagon"></i>
                  <span>{props.t("ALERTS")}</span>
                </Link>
              </li>
              <li>
                <Link to="/Account" className="waves-effect">
                  <img src={AccountSettingIcon} />
                  <span>{props.t("ACCOUNT SETTING")}</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="waves-effect">
                  <img src={PaymentIcon} />
                  <span>{props.t("PAYMENT SETTING")}</span>
                </Link>
              </li>
            </ul>
      
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

ClientSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter (withTranslation()(ClientSidebarContent));
