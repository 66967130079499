// C:\Users\lenovo\Downloads\Admin\src\pages\TicketStatus\TicketForm.js
import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

const TicketForm = ({ show, onCreate, onCancel }) => {
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [type, setType] = useState("")
  const [attachment, setAttachment] = useState(null)
  const [formError, setFormError] = useState("")

  const [ticketID, setTicketID] = useState(1)

  const handleSubmit = () => {
    if (!name || !title || !description || !type) {
      setFormError("All fields are required.")
      return
    }

    const newTicket = {
      id: ticketID,
      name,
      title,
      description,
      type,
      attachment,
      status: "Unresolved",
      created_at: new Date().toLocaleString(),
    }
    onCreate(newTicket)
    setTicketID(prevID => prevID + 1)
    setName("")
    setTitle("")
    setDescription("")
    setType("")
    setAttachment(null)
    setFormError("")
  }

  const handleFileChange = e => {
    setAttachment(e.target.files[0])
  }

  return (
    <Modal isOpen={show} toggle={onCancel} className="modal-dialog-centered">
      <ModalHeader toggle={onCancel}>Create Ticket</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              type="text"
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="type">Type</Label>
            <Input
              type="select"
              id="type"
              value={type}
              onChange={e => setType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Bug">Bug</option>
              <option value="Suggestion">Suggestion</option>
              <option value="Refund">Refund</option>
              <option value="Activation">Activation</option>
              <option value="Referral">Referral</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="attachment">Attachment</Label>
            <Input type="file" id="attachment" onChange={handleFileChange} />
          </FormGroup>
          {formError && <p style={{ color: "red" }}>{formError}</p>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TicketForm
