import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { resetPasswordRequest } from "../../store/actions"
import { toast } from "react-toastify"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import "../Authentication/Register.css"
import RegisterHeader from "../Authentication/Header/RegisterHeader"
import Footer from "../Authentication/Footer/Footer"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./Register.css"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ResetPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const query = useQuery()
  const id = query.get("id")
  const signature = query.get("signature")

  const toastOptions = {
    autoClose: 500,
    progressClassName: "toast-progress-bar",
    icon: <CheckCircleIcon style={{ color: "#660032" }} />,
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&]).{8,20}$/,
          "Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character.",
        )
        .required("Please enter your password"),
      confirmPassword: Yup.string()
        .required("Please Confirm Your Password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      const data = { id, signature, ...values }
      console.log("data", data)
      dispatch(resetPasswordRequest(data))
    },
  })

  const { resetError, resetSuccessMsg, loading } = useSelector(
    state => state.ForgetPassword,
  )

  const handleChange = e => {
    const { name, value } = e.target
    formik.setFieldValue(name, value)
    formik.setFieldTouched(name, true)
    if (name === "password" || name === "confirmPassword") {
      formik.validateField("confirmPassword")
    }
  }
  useEffect(() => {
    if (resetSuccessMsg) {
      toast.success(resetSuccessMsg, toastOptions)
      navigate("/login")
    }
  }, [resetSuccessMsg, navigate])

  useEffect(() => {
    if (resetError) {
      toast.error(resetError)
    }
  }, [resetError])
  return (
    <React.Fragment>
            <div className="bg-image">
      <RegisterHeader />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4>
                    {/* {resetError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {resetError}
                      </Alert>
                    )} */}
                    <form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        formik.handleSubmit()
                        return false
                      }}
                    >
                      <div className="register-form-group password-group">
                        <Label htmlFor="password">New Password</Label>
                        <div className="input-icon-group">
                          <Input
                            name="password"
                            className={`register-password-input ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                            placeholder="Enter new password"
                            type={visiblePassword ? "text" : "password"}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password || ""}
                            invalid={
                              formik.touched.password &&
                              !!formik.errors.password
                            }
                            required
                          />
                          <span
                            onClick={() => setVisiblePassword(!visiblePassword)}
                            className="eye-icon"
                          >
                            {visiblePassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        {/* {formik.touched.password && formik.errors.password && (
                          <FormFeedback className="invalid-feedback">
                            {formik.errors.password}
                          </FormFeedback>
                        )} */}
                        {formik.touched.password && formik.errors.password && (
                          <div className="password-requirements">
                            Password must be 8-20 characters long and include at
                            least one uppercase letter, one lowercase letter, a
                            number, and a special character.
                          </div>
                        )}
                      </div>

                      <div className="register-form-group password-group">
                        <Label htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        <div className="input-icon-group">
                          <Input
                            name="confirmPassword"
                            className={`register-password-input ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "is-invalid" : ""}`}
                            placeholder="Confirm your new password"
                            type={visibleConfirm ? "text" : "password"}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword || ""}
                            invalid={
                              formik.touched.confirmPassword &&
                              !!formik.errors.confirmPassword
                            }
                            required
                          />
                          <span
                            onClick={() => setVisibleConfirm(!visibleConfirm)}
                            className="eye-icon"
                          >
                            {visibleConfirm ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>

                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <FormFeedback className="invalid-feedback">
                              {formik.errors.confirmPassword}
                            </FormFeedback>
                          )}
                      </div>

                      <Row className="mb-3">
                        <div className="col-12 text-end">
                          <Button
                            className="w-100 register-btn-custom"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Loading..." : "Reset Password"}
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default ResetPassword
