// C:\Users\lenovo\Downloads\Admin\src\pages\TicketStatus\TicketsNavbar.js
import React from "react"
import { ButtonGroup, Button } from "reactstrap"

const TicketsNavbar = ({ setShowTicketForm }) => {
  return (
    <div className="d-flex justify-content-center mb-3">
      <ButtonGroup>
        <Button onClick={() => setShowTicketForm(true)} color="primary">
          Create Ticket
        </Button>
        <Button color="secondary">Table</Button>
        <Button color="secondary">Group</Button>
        <Button color="secondary">Sort</Button>
        <Button color="secondary">Customize</Button>
      </ButtonGroup>
    </div>
  )
}

export default TicketsNavbar
