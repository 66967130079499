import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchJobRequirementsRequest } from "store/actions"
import MUIDataTable from "mui-datatables"
import {
  Col,
  Row,
  Form,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import "../../pages/Client Management Info/ClientManagement.css"
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender"
import { Chip } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"

const RequirementList = () => {
  const dispatch = useDispatch()
  const [selectedJob, setSelectedJob] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const { loading, jobs, error } = useSelector(state => state.JobRequirement)
  console.log("jobs", jobs)
  const { user } = useSelector(state => state.Login)
  const { id } = user?.userDetails
  useEffect(() => {
    dispatch(fetchJobRequirementsRequest(id))
  }, [dispatch])

  const toggleModal = () => setModalOpen(!modalOpen)

  const handleViewClick = job => {
    setSelectedJob(job)
    toggleModal()
  }
  const tableOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    sort: false,
    filter: false,
    viewColumns: false,
    searchAlwaysOpen: true,
    customSearchRender: (searchText, handleSearch) => {
      return (
        <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
      )
    },
  }

  const getRandomColor = () => {
    const colors = [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#FFC300",
      "#DAF7A6",
    ]
    return colors[Math.floor(Math.random() * colors.length)]
  }
  const mainColumns = [
    {
      name: "jobRequirementId",
      label: "Job ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "clientName",
      label: "Client Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "jobTitle",
      label: "Job Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "mandatorySkills",
      label: "Skills",
      options: {
        filter: true,
        sort: false,
        customBodyRender: value => {
          return value.map((skill, index) => (
            <Chip
              key={index}
              label={skill}
              style={{ margin: 2, backgroundColor: "#FFEFD5", color: "#000" }}
            />
          ))
        },
      },
    },
    {
      name: "jobType",
      label: "Job Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "yearsOfExperience",
      label: "Years Of Experience",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "view",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const job = jobs[tableMeta.rowIndex]
          return (
            <VisibilityIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleViewClick(job)}
            />
          )
        },
      },
    },
  ]

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>
  return (
    <>
      <h3>Requirement List</h3>

      <Row className="mb-3">
        <Col>
          <MUIDataTable
            className="ClientManagement__table"
            data={jobs}
            columns={mainColumns}
            options={tableOptions}
          />
        </Col>
      </Row>
      {selectedJob && (
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Job Requirement Details
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <p>
                  <strong>Job ID:</strong> {selectedJob.jobRequirementId}
                </p>
                <p>
                  <strong>Client Name:</strong> {selectedJob.clientName}
                </p>
                <p>
                  <strong>Job Title:</strong> {selectedJob.jobTitle}
                </p>
                <p>
                  <strong>Job Type:</strong> {selectedJob.jobType}
                </p>
                <p>
                  <strong>Years Of Experience:</strong>{" "}
                  {selectedJob.yearsOfExperience}
                </p>
                <p>
                  <strong>Skills:</strong>{" "}
                  {selectedJob.mandatorySkills.join(", ")}
                </p>
                <p>
                  <strong>Country:</strong> {selectedJob.country}
                </p>
                <p>
                  <strong>State:</strong> {selectedJob.state}
                </p>
                <p>
                  <strong>City:</strong> {selectedJob.city}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>Notice Period:</strong> {selectedJob.noticePeriod}
                </p>
                <p>
                  <strong>Certifications:</strong>{" "}
                  {selectedJob.certifications.join(", ")}
                </p>
                <p>
                  <strong>Work Mode:</strong> {selectedJob.workMode}
                </p>
                <p>
                  <strong>Minimum Budget:</strong> {selectedJob.minimumBudget}
                </p>
                <p>
                  <strong>Maximum Budget:</strong> {selectedJob.maximumBudget}
                </p>
                <p>
                  <strong>User Allocation:</strong>{" "}
                  {selectedJob.userAllocation.join(", ")}
                </p>
                <p>
                  <strong>Requirement Received Date:</strong>{" "}
                  {new Date(
                    selectedJob.requirementReceivedDateTime,
                  ).toLocaleDateString()}
                </p>
                <p>
                  <strong>Requirement Allocation Date:</strong>{" "}
                  {new Date(
                    selectedJob.requirementAllocationDateTime,
                  ).toLocaleDateString()}
                </p>
                <p>
                  <strong>Number of Positions:</strong>{" "}
                  {selectedJob.numberOfPositions}
                </p>
                <p>
                  <strong>Deadline:</strong>{" "}
                  {new Date(selectedJob.deadline).toLocaleDateString()}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>Job Description:</strong> {selectedJob.jobDescription}
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default RequirementList
