// src/BarChart.js

import React from "react"
import { Bar } from "react-chartjs-2"
import {
  CategoryScale,
  LinearScale,
  BarController,
  Chart,
  registerables,
} from "chart.js"

Chart.register(CategoryScale, LinearScale, BarController, ...registerables)

const BarChart = ({ data }) => {
  const options = {
    scales: {
      x: {
        type: "category",
        display: true,
        title: {
          display: true,
          text: "Category",
          color: "#660032", // Change x-axis label color
        },
        ticks: {
          color: "#660032", // Change x-axis ticks color
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Value",
          color: "#660032", // Change y-axis label color
        },
        ticks: {
          color: "#660032", // Change y-axis ticks color
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "#660032", // Change legend label color
        },
      },
    },
  }

  // Change color of bars
  if (data.datasets) {
    data.datasets.forEach(dataset => {
      dataset.backgroundColor = "#660032" // Change bar color
      dataset.borderColor = "#660032" // Change bar border color
    })
  }

  return <Bar data={data} options={options} />
}

export default BarChart
