import { createRole, fetchRoles } from "store/api";
import { CREATE_ROLE_REQUEST, FETCH_ROLES_FAILURE, FETCH_ROLES_REQUEST, FETCH_ROLES_SUCCESS } from "./actionTypes";
import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { createRoleFailure, createRoleSuccess } from "./actions";

function* fetchRolesSaga(action) {
    try {
        const roles = yield call(fetchRoles, action.payload.entityId);
        yield put({ type: FETCH_ROLES_SUCCESS, payload: roles });
    } catch (error) {
        yield put({ type: FETCH_ROLES_FAILURE, payload: error.message });
    }
}

function* createRoleSaga(action) {
    try {
        const response = yield call(createRole, action.payload); 
        yield put(createRoleSuccess(response.data.role));  
    } catch (error) {
        yield put(createRoleFailure(error.response?.data?.msg || 'Server error'));  
    }
}

function* watchCreateRole() {
    yield takeLatest(CREATE_ROLE_REQUEST, createRoleSaga);
}



function* FetchRolesSaga() {
    yield takeLatest(FETCH_ROLES_REQUEST, fetchRolesSaga);
}

export default function* roleSaga() {
    yield all([fork(FetchRolesSaga), fork(watchCreateRole)]);
}   