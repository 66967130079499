import { message } from "antd"
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "./actionTypes"

const initialState = {
  loading: false,
  forgetError: null,
  forgetSuccessMsg: null,
  resetError: null,
  resetSuccessMsg: null,
}

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        forgetError: null,
        forgetSuccessMsg: null,
        resetError: null,
        resetSuccessMsg: null,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgetSuccessMsg: action.payload,
      }
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        forgetError: action.payload,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetSuccessMsg: action.payload,
      }
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        resetError: action.payload,
      }
    default:
      return state
  }
}

export default forgotPasswordReducer
