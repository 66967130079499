import {
  PARSE_PROFILE_REQUEST,
  PARSE_PROFILE_SUCCESS,
  PARSE_PROFILE_FAILURE,
  UPDATE_CANDIDATE_REQUEST, UPDATE_CANDIDATE_SUCCESS, UPDATE_CANDIDATE_FAILURE,
  SEND_TO_SQANIT_FAILURE, SEND_TO_SQANIT_REQUEST, SEND_TO_SQANIT_SUCCESS,
  SHARE_PROFILE_REQUEST, SHARE_PROFILE_SUCCESS, SHARE_PROFILE_FAILURE,
  DELETE_FAILED_PROFILE_REQUEST, DELETE_FAILED_PROFILE_SUCCESS, DELETE_FAILED_PROFILE_FAILURE,
  RESET_PARSE_DATA_REQUEST,
  GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST,
  GET_PARSED_PROFILE_DATA_BY_USER_ID_SUCCESS,
  GET_PARSED_PROFILE_DATA_BY_USER_ID_FAILURE,
} from './actionTypes';

export const parseProfileRequest = (data) => ({
  type: PARSE_PROFILE_REQUEST,
  payload: data,
});

export const parseProfileSuccess = (data) => (
  {
    type: PARSE_PROFILE_SUCCESS,
    payload: data,
  });

export const parseProfileFailure = (error) => ({
  type: PARSE_PROFILE_FAILURE,
  payload: error,
});

export const updateCandidateRequest = ({id,updatedData}) => ({
  type: UPDATE_CANDIDATE_REQUEST,
  payload: {id,updatedData},
});

export const updateCandidateSuccess = (candidate) => ({
  type: UPDATE_CANDIDATE_SUCCESS,
  payload: candidate,
});

export const updateCandidateFailure = (error) => ({
  type: UPDATE_CANDIDATE_FAILURE,
  payload: error,
});

export const sendToSqanitRequest = (profiles) => ({
  type: SEND_TO_SQANIT_REQUEST,
  payload: profiles,
});

export const sendToSqanitSuccess = (profiles) => ({
  type: SEND_TO_SQANIT_SUCCESS,
  payload: profiles,
});

export const sendToSqanitFailure = (error) => ({
  type: SEND_TO_SQANIT_FAILURE,
  payload: error,
});

export const shareProfileRequest = (emailData) => ({
  type: SHARE_PROFILE_REQUEST,
  payload: emailData,
});

export const shareProfileSuccess = (result) => ({
  type: SHARE_PROFILE_SUCCESS,
  payload: result
});

export const shareProfileFailure = (result) => ({
  type: SHARE_PROFILE_FAILURE,
  payload: result
});

export const deleteFailedProfileRequest = (profiles) => ({
  type: DELETE_FAILED_PROFILE_REQUEST,
  payload: profiles,
});

export const deleteFailedProfileSuccess = (profiles) => ({
  type: DELETE_FAILED_PROFILE_SUCCESS,
  payload: profiles
});

export const deleteFailedProfileFailure = (result) => ({
  type: DELETE_FAILED_PROFILE_FAILURE,
  payload: result
});

export const resetParseDataRequest = ()=>({
  type: RESET_PARSE_DATA_REQUEST,
})

export const getParsedProfileDataByUserIdRequest = (id) => ({
  type: GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST,
  payload: id,
});

export const getParsedProfileDataByUserIdSuccess = (profiles) => ({
  type: GET_PARSED_PROFILE_DATA_BY_USER_ID_SUCCESS,
  payload: profiles
});

export const getParsedProfileDataByUserIdFailure = (result) => ({
  type: GET_PARSED_PROFILE_DATA_BY_USER_ID_FAILURE,
  payload: result
});



