import React, { useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import "./cvaccessfilter.css"; // Make sure to create and import this CSS file
const CandidateFilterForm = ({ onSubmit }) => {
  const [filters, setFilters] = useState({
    job_title: "",
    experienceMin: "",
    experienceMax: "",
    location: "",
    skills: "",
  });
  const [submittedFilters, setSubmittedFilters] = useState({});


  const handleChange = field => e => {
    const value = e.target.value;
    setFilters(prevFilters => {
      let updatedFilters = { ...prevFilters, [field]: value };

      if (field === "experienceMin" && value) {
        // Reset experienceMax if it is less than or equal to experienceMin
        if (Number(updatedFilters.experienceMax) <= Number(value)) {
          updatedFilters.experienceMax = "";
        }
      } else if (field === "experienceMax" && value) {
        // Ensure experienceMax is greater than experienceMin
        if (Number(value) <= Number(updatedFilters.experienceMin)) {
          return prevFilters; // Do not update if the condition is not met
        }
      }

      return updatedFilters;
    });
  };



  const handleSubmit = e => {
    e.preventDefault();
  
    // Filter out only the fields that have values
    const activeFilters = {};
    Object.keys(filters).forEach(field => {
      if (filters[field]) {
        activeFilters[field] = filters[field];
      }
    });
  
    // Count the number of filled fields
    const filledFieldsCount = Object.keys(activeFilters).length;
  
    if (filledFieldsCount === 0) {
      console.log("No filters applied.");
      return;
    }
  
    // If fields are filled, ensure all must match
    if (filledFieldsCount >= 1 && filledFieldsCount <= 3) {
      const filtersToSubmit = {
        ...activeFilters,
        experienceRange:
          filters.experienceMin && filters.experienceMax
            ? `${filters.experienceMin}-${filters.experienceMax}`
            : "",
      };
  
      console.log("Form submitted with filters: ", filtersToSubmit);
      onSubmit(filtersToSubmit);
      setSubmittedFilters(filtersToSubmit);
    } else {
      console.log("More than three fields are filled; please limit to three for matching.");
    }
  };
  

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   const filtersToSubmit = {
  //     ...filters,
  //     experienceRange:
  //       filters.experienceMin && filters.experienceMax
  //         ? `${filters.experienceMin}-${filters.experienceMax}`
  //         : "",
  //   };
  //   console.log("Form submitted with filters: ", filtersToSubmit);
  //   onSubmit(filtersToSubmit);
  //   setSubmittedFilters(filtersToSubmit);
  // };


  const clearField = field => () => {
    setFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [field]: "",
      };
      if (field === "experience") {
        updatedFilters.experienceMin = "";
        updatedFilters.experienceMax = "";
      }
      const filtersToSubmit = {
        ...updatedFilters,
        experienceRange:
          updatedFilters.experienceMin && updatedFilters.experienceMax
            ? `${updatedFilters.experienceMin}-${updatedFilters.experienceMax}`
            : "",
      };
      onSubmit(filtersToSubmit);
      setSubmittedFilters(filtersToSubmit);
      console.log(`${field} field cleared`, filtersToSubmit);
      return updatedFilters;
    });
  };
  return (
    <Box component="form" onSubmit={handleSubmit} className="filters">
      <div className="button-group">
        <button type="submit" className="cv-access-form-button">
        </button>
      </div>
      <div className="filter-card">
        <input
          type="text"
          className="filter-input"
          value={filters.job_title}
          onChange={handleChange("job_title")}
          placeholder="Enter job title"
        />
        {submittedFilters.job_title && (
          <div className="selected-value">
            <Chip
              label={submittedFilters.job_title}
              onDelete={clearField("job_title")}
              size="small"
              className="cv-access-chip"
            />
          </div>
        )}
      </div>
      <div className="filter-card">
        <div className="experience-inputs">
          <input
            type="number"
            className="filter-input"
            placeholder="Min Experience"
            value={filters.experienceMin}
            onChange={handleChange("experienceMin")}
          />
          {filters.experienceMin && (
            <>
          <span className="cv-access-input-label">to</span>
          <input
            type="number"
            className="filter-input"
            placeholder="Max Experience"
            value={filters.experienceMax}
            onChange={handleChange("experienceMax")}
            min={filters.experienceMin || 0} 
          />
          </>
          )}
        </div>
        {submittedFilters.experienceRange && (
          <div className="selected-value">
            <Chip
              label={`Experience: ${submittedFilters.experienceRange} years`}
              onDelete={clearField("experience")}
              size="small"
              className="cv-access-chip"
            />
          </div>
        )}
      </div>
      <div className="filter-card">
        <input
          type="text"
          className="filter-input"
          value={filters.location}
          onChange={handleChange("location")}
          placeholder="Enter location"
        />
        {submittedFilters.location && (
          <div className="selected-value">
            <Chip
              label={submittedFilters.location}
              onDelete={clearField("location")}
              size="small"
              className="cv-access-chip"
            />
          </div>
        )}
      </div>
      <div className="filter-card">
        <input
          type="text"
          className="filter-input"
          value={filters.skills}
          onChange={handleChange("skills")}
          placeholder="Enter skills"
        />
        {submittedFilters.skills && (
          <div className="selected-value">
            <Chip
              label={submittedFilters.skills}
              onDelete={clearField("skills")}
              size="small"
              className="cv-access-chip"
            />
          </div>
        )}
      </div>
    </Box>
  );
};
export default CandidateFilterForm;