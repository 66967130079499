import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { DELETE_FAILED_PROFILE_REQUEST, GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST, PARSE_PROFILE_REQUEST, RESET_PARSE_DATA_REQUEST, SEND_TO_SQANIT_REQUEST, SEND_TO_SQANIT_SUCCESS, SHARE_PROFILE_REQUEST, UPDATE_CANDIDATE_REQUEST } from './actionTypes';
import { updateCandidateSuccess, updateCandidateFailure, sendToSqanitSuccess, sendToSqanitFailure, shareProfileSuccess, shareProfileFailure, deleteFailedProfileSuccess, deleteFailedProfileFailure, getParsedProfileDataByUserIdSuccess, getParsedProfileDataByUserIdFailure } from './actions';
import { parseProfileFailure, parseProfileSuccess } from './actions';
import { parseAIResponse, saveParsedData, updateCandidateData, uploadProfile, shareProfiles, getParsedProfileDataByUserId } from 'store/api';


function* parseProfileSaga(action) {
  try {
    const { files, reupload, parsedBy } = action.payload;
    let uploadProgress = 0;

    const formDataArray = files.map(file => {
      const formData = new FormData();
      formData.append("file", file);
      return formData;
    });

    for (let i = 0; i < formDataArray.length; i++) {
      let uploadedResumes = null;
      let failedResumes=null;
      uploadProgress = files.length > 0 ? (((i + 1) * 100) / files.length).toFixed(0) : 0;
      try {
        const formData = formDataArray[i];
        let response;

        for (let retry = 0; retry < 2; retry++) {
          try {
            response = yield call(uploadProfile, formData);
            break; 
          } catch (uploadError) {
            if (retry === 1) {
              failedResumes = { fileName: files[i].name, error: uploadError, file: files[i] };
              yield put(parseProfileFailure({ uploadProgress, failedResumes, reupload }));
              throw uploadError; 
            }
           
          }
        }
        if (response && response.data) {
          const { fileUrl, profileText } = response.data;
          // Append additional data to formData for AI scan
          const formData = new FormData();
          formData.append("fileUrl", fileUrl);
          formData.append("textData", profileText);
          // Call parse AI response API
          let parseResponse;
          try {
            parseResponse = yield call(parseAIResponse, formData);
          } catch (parseError) {
            console.error("Parse AI response failed for file:", files[i].name, parseError);
            failedResumes = { fileName: files[i].name, error: parseError, file: files[i] };
            yield put(parseProfileFailure({ uploadProgress, failedResumes, reupload }));

            continue; // Skip to the next file if parsing fails
          }

          const parseData = parseResponse.data.message;
          var savedData;
          // Save parsed data if available
          if (parseData) {
            try {
              savedData = yield call(saveParsedData, {
                content: parseData.content,
                fileUrl,
                parsedBy
              });
              // Store uploaded resume data
              uploadedResumes = savedData.data.data;
            } catch (saveError) {
              failedResumes = { fileName: files[i].name, error: saveError, file: files[i] };
              console.error("Failed to save parsed data for file:", files[i].name, saveError);
              // Handle save error if needed
              yield put(parseProfileFailure({ uploadProgress, failedResumes, reupload }));
            }
          }

          if (uploadedResumes !== null) {
            yield put(parseProfileSuccess({ uploadedResumes, uploadProgress, reupload }));
          }
        }
      } catch (error) {
        const { message } = error;
        failedResumes = { fileName: files[i].name, error: error, file: files[i] };
        yield put(parseProfileFailure({ uploadProgress, failedResumes, reupload }));
      }
    }



  } catch (error) {
    console.log("Error in parsed profile saga-->", error);
  }
}



function* updateCandidateSaga(action) {
  try {
    const response = yield call(updateCandidateData,action.payload);
    yield put(updateCandidateSuccess(response.data));
  } catch (error) {
    yield put(updateCandidateFailure(error.message));
  }
}


function* sendToSqanitSaga(action) {
  try {
    yield put(sendToSqanitSuccess(action.payload));
  } catch (error) {
    yield put(sendToSqanitFailure(error.message));
  }
}

function* shareProfileSaga(action) {
  try {
    const result = yield call(shareProfiles, action.payload);
    console.warn("Result==>", result);
    yield put(shareProfileSuccess(result));
  } catch (error) {
    console.log("error==>", error.message);
    yield put(shareProfileFailure(error.message));
  }
}

function* deleteFailedProfileSaga(action) {
  try {
    yield put(deleteFailedProfileSuccess(action.payload));
  } catch (error) {
    yield put(deleteFailedProfileFailure(error.message));
  }
}

function* resetParseDataRequestSaga() {
  try {
    console.log("Reset parse data successfully..!")    
  } catch (error) {
    console.log("Error while resetting parse data..!")
  }
}

function* getParsedProfileDataByUserIdSaga(action) {
  try {
    const result = yield call(getParsedProfileDataByUserId, action.payload); 
    
    yield put(getParsedProfileDataByUserIdSuccess(result.data));
  } catch (error) {
    yield put(getParsedProfileDataByUserIdFailure(error.message));
    console.log("Error while resetting parse data..!")
  }
}






function* parseProfilesSaga() {
  yield takeLatest(PARSE_PROFILE_REQUEST, parseProfileSaga);
}

function* watchUpdateCandidate() {
  yield takeLatest(UPDATE_CANDIDATE_REQUEST, updateCandidateSaga);
}

function* sendToSqanit() {
  yield takeLatest(SEND_TO_SQANIT_REQUEST, sendToSqanitSaga);
}

function* shareProfile() {
  yield takeLatest(SHARE_PROFILE_REQUEST, shareProfileSaga);
}

function* deleteFailedProfile() {
  yield takeLatest(DELETE_FAILED_PROFILE_REQUEST, deleteFailedProfileSaga);
}

function* resetParseDataRequest() {
  yield takeLatest(RESET_PARSE_DATA_REQUEST, resetParseDataRequestSaga);
}

function* getParsedProfileDataByUserIdRequest() {
  yield takeLatest(GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST, getParsedProfileDataByUserIdSaga);
}

export default function* parseProfileRootSaga() {
  yield all([fork(parseProfilesSaga), fork(watchUpdateCandidate), fork(sendToSqanit), fork(shareProfile), fork(deleteFailedProfile), fork(resetParseDataRequest),fork(getParsedProfileDataByUserIdRequest)]);
}
