import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Linkedin, Instagram } from "react-bootstrap-icons";
import "./footer.css";
import logoLight from "./../../../assets/images/civixo-logo.png";
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-top">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <div className="footer-logo">
            <img src={logoLight} alt="SQANO Logo" className="footer-logo-image" />
          </div>
        </Grid>
        <Grid item xs={6}>
          <ul className="footer-social-icons d-flex gap-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <li>
              <a className="footer-social-link" href="https://www.facebook.com/people/SQANO/61557444795100/" target="_blank" rel="noopener noreferrer">
                <Facebook />
              </a>
            </li>
            <li>
              <a className="footer-social-link" href="https://www.linkedin.com/company/sqano/" target="_blank" rel="noopener noreferrer">
                <Linkedin />
              </a>
            </li>
            <li>
              <a className="footer-social-link" href="javascript:void(0)">
                <Twitter />
              </a>
            </li>
            <li>
              <a className="footer-social-link" href="https://accounts.google.com/" target="_blank" rel="noopener noreferrer">
                <Instagram />
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
      <div className="footer-divider">
      </div>

      <div className="footer-top">
      <Grid container alignItems="flex-start" spacing={10}>
        <Grid item xs={12} sm={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%' }}>
            <p className="footer-description">
              SQANO is a powerful CRM software meticulously tailored for the recruitment industry. As the foremost AI recruitment CRM, it fulfills the global recruiting needs of companies striving to attract top IT talent.
            </p>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <h4 className="footer-title">Company</h4>
          <ul className="footer-link-list" style={{ listStyleType: 'none', padding: 0 }}>
            <li className="footer-link-item"><Link className="footer-links" to="/home">Home</Link></li>
            <li className="footer-link-item"><Link className="footer-links" to="/about">About Us</Link></li>
            <li className="footer-link-item"><Link className="footer-links" to="/contact">Contact Us</Link></li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={2}>
          <h4 className="footer-title">Support</h4>
          <ul className="footer-link-list" style={{ listStyleType: 'none', padding: 0 }}>
            <li className="footer-link-item"><Link className="footer-links" to="/help">Help</Link></li>
            <li className="footer-link-item"><Link className="footer-links" to="/support">Support Request</Link></li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h4 className="footer-title">Subscribe</h4>
          <form className="footer-subscribe-form">
            <input className="footer-input" type="email" placeholder="Enter Your Email" />
            <button className="footer-button" type="submit">Subscribe</button>
          </form>
        </Grid>
      </Grid>
    </div>
      
      <div className="footer-bottom">
        <div className="container d-flex justify-content-between align-items-center footer-bottom-content">
          <p className="footer-rights">All Rights Reserved By SQANO IT Solution Limited.</p>
          <div className="footer-policies">
            <Link className="footer-policy-link" to="/privacy">Privacy Policy</Link>
            <span className="footer-policy-separator">|</span>
            <Link className="footer-policy-link" to="/terms">Terms And Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
