import React, { useState } from "react"
import ProductTabs from "../ProductTabs"
import CustomBreadcrumb from "../../../components/CustomComponents/CustomBreadCrumb";
import { Card, CardContent, CardMedia, Typography, Grid, Button } from '@mui/material';
import { Stepper, Step, StepLabel, TextField, Box } from '@mui/material';

import PostAddIcon from '@mui/icons-material/PostAdd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CreateResume from "../../../assets/images/create_new_resume.webp"
import UploadResume from "../../../assets/images/upload_resume.webp"
import ImportLinkedIn from "../../../assets/images/Import_resume.webp"
import { useNavigate } from "react-router-dom";

const ResumeBuilder = () => {
    const navigate = useNavigate();

    const breadcrumbItems = [
        { label: "Home", href: "/dashboard" },
        { label: "CV Template", href: "/cvtemplate" },
        { label: "Resume Builder", href: "/resumebuilder" },
    ];



    return (
        <div>
            <CustomBreadcrumb items={breadcrumbItems} />
            <ProductTabs tab={7} />

            <div style={{ padding: '10px', marginBottom: "20px" }}>
                <Typography variant="h5" align="center" style={{ color: 'white', fontWeight: 'bold', fontSize: "16px", backgroundColor: "#660032", padding: "10px", borderRadius: "5px", margin: "0px auto", width: "fit-content", outline: "2px solid #660032", outlineOffset: "2px" }}>
                    Resume Builder
                </Typography>
                <Typography variant="body1" align="center" style={{ marginTop: '20px', backgroundColor: "white", padding: "20px", color: "#660032", borderRadius: "5px" }}>
                    Effortlessly create, upload, or import resumes to kickstart your career journey. Choose from professionally designed
                    templates, upload your existing resume, or import your LinkedIn profile for a quick and easy
                    resume-building experience. Our platform provides all the tools you need to craft a standout
                    resume that highlights your skills and accomplishments, helping you land your dream job faster.
                </Typography>
                <Grid container spacing={4} justifyContent="center" marginTop="0px">
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ height: '100%', textAlign: 'center' }}>
                            <CardMedia
                                component="img"
                                style={{ height: "180px", width: "100%", backgroundSize: "cover" }}
                                image={CreateResume}
                                alt="Create New Resume"
                            />
                            <CardContent>
                                <PostAddIcon style={{ fontSize: 50, color: '#660032' }} />
                                <Typography variant="h6">Create New Resume</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Start from scratch with professionally designed templates.
                                </Typography>
                                <Button variant="contained" style={{ marginTop: '10px', backgroundColor: '#660032', color: 'white' }} onClick={()=>{navigate("/create-new-resume")}}>
                                    Get Started
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Upload Resume */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ height: '100%', textAlign: 'center' }}>
                            <CardMedia
                                component="img"
                                style={{ height: "180px", width: "100%", backgroundSize: "cover" }}
                                image={UploadResume}
                                alt="Upload Resume"
                            />
                            <CardContent>
                                <CloudUploadIcon style={{ fontSize: 50, color: '#660032' }} />
                                <Typography variant="h6">Upload Resume</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Upload your existing resumes in .docx or .pdf formats.
                                </Typography>
                                <Button variant="contained" style={{ marginTop: '10px', backgroundColor: '#660032', color: 'white' }}>
                                    Upload
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Import from LinkedIn */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ height: '100%', textAlign: 'center' }}>
                            <CardMedia
                                component="img"
                                style={{ height: "180px", width: "100%", backgroundSize: "cover" }}
                                image={ImportLinkedIn}
                                alt="Import from LinkedIn"
                            />
                            <CardContent>
                                <LinkedInIcon style={{ fontSize: 50, color: '#660032' }} />
                                <Typography variant="h6">Import from LinkedIn</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Easily transfer your LinkedIn profile information.
                                </Typography>
                                <Button variant="contained" style={{ marginTop: '10px', backgroundColor: '#660032', color: 'white' }}>
                                    Import
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>

        </div>
    )
}

export default ResumeBuilder
