import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
// import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider } from '@mui/material/styles';
import store, { persistor, history } from "./store"
import { theme } from "pages/Authentication/Utlits/theme"

const root = ReactDOM.createRoot(document.getElementById("root"))


root.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        {/* <ServiceList /> */}
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)

// serviceWorker.unregister()
