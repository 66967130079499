
import React from 'react';
import { Box, Button } from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import EmailIcon from '@mui/icons-material/Email';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import GeneratePdfHeader from './GeneratePdfHeader';
import GeneratePdfFooter from './GeneratePdfFooter';
import Content from './Content';
import './GeneratePdf.css';

const GeneratePdf = ({ analysis, data }) => {
  const printDocument = () => {
    const input = document.getElementById('divToPrint');
    
    html2canvas(input, {
      scale: 2,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
  
      // Get canvas dimensions
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
  
      // Calculate aspect ratio for canvas to fit within PDF dimensions
      const aspectRatio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
  
      const imgWidth = canvasWidth * aspectRatio;
      const imgHeight = canvasHeight * aspectRatio;
  
      // Center the image on the PDF page
      const marginX = (pdfWidth - imgWidth) / 2;
      const marginY = (pdfHeight - imgHeight) / 2;
  
      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', marginX, marginY, imgWidth, imgHeight);
      pdf.save('CVIXO.pdf');

      
      // pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      // pdf.save('SQANO.pdf');

    });
  };

  return (
    <div>
      <Box id="divToPrint" className="pdf-container">
        <GeneratePdfHeader />
        <Content analysis={analysis} data={data} />
        <GeneratePdfFooter />
      </Box>
      <Box className="pdf-button-container">
        <Button onClick={printDocument} className="GeneratePdf-button">
          <ArrowCircleDownIcon />
        </Button>
        <Button className="GeneratePdf-button">
          <EmailIcon />
        </Button>
      </Box>
    </div>
  );
};

export default GeneratePdf;





// import React from "react";
// import { Box, Button, Rating } from "@mui/material";
// import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
// import EmailIcon from "@mui/icons-material/Email";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import SkillsChart from "./SkillsChart";
// import sqanoIcon from "../../../assets/images/sqano-logo.png";
// import icon from "../../../assets/images/latest-icon.png";

// const GeneratePdf = ({ analysis, data }) => {
//   const loadImageToDataURL = (src) => {
//     return new Promise((resolve, reject) => {
//       const img = new Image();
//       img.crossOrigin = "Anonymous";
//       img.src = src;
//       img.onload = () => resolve(img);
//       img.onerror = (err) => reject(err);
//     });
//   };

//   const printDocument = async () => {
//     const input = document.getElementById("divToPrint");
//     const sqanoLogo = await loadImageToDataURL(sqanoIcon);

//     html2canvas(input, {
//       scale: 2,
//       useCORS: true,
//     }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF({
//         orientation: "p",
//         unit: "mm",
//         format: "a4",
//       });

//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = pdf.internal.pageSize.getHeight();

//       const headerHeight = 20;
//       const footerHeight = 10;
//       const marginBottom = 10;
//       const backgroundColor = "#660032";

//       const adjustedContentHeight =
//         pdfHeight - headerHeight - footerHeight - marginBottom / pdf.internal.scaleFactor;

//       const imgProps = pdf.getImageProperties(imgData);
//       const scaledImgHeight = (imgProps.height * pdfWidth) / imgProps.width;

//       const totalPDFPages = Math.ceil(scaledImgHeight / adjustedContentHeight);

//       for (let i = 0; i < totalPDFPages; i++) {
//         if (i > 0) {
//           pdf.addPage();
//         }

//         pdf.setFillColor(backgroundColor);
//         pdf.rect(0, 0, pdfWidth, headerHeight, "F");

//         pdf.addImage(sqanoLogo, "PNG", pdfWidth / 2 - 20, 2, 40, 15);

//         const startY = -i * adjustedContentHeight;

//         pdf.addImage(
//           imgData,
//           "PNG",
//           0,
//           headerHeight,
//           pdfWidth,
//           adjustedContentHeight,
//           '',
//           'FAST',
//           startY
//         );

//         pdf.setFillColor(backgroundColor);
//         pdf.rect(0, pdfHeight - footerHeight, pdfWidth, footerHeight, "F");

//         pdf.setTextColor(255, 255, 255);
//         const footerText = "FALQON Systems Limited. | All rights reserved.";
//         pdf.setFontSize(12);
//         const textWidth =
//           (pdf.getStringUnitWidth(footerText) * pdf.internal.getFontSize()) /
//           pdf.internal.scaleFactor;
//         const textOffset = (pdfWidth - textWidth) / 2;
//         pdf.text(footerText, textOffset, pdfHeight - footerHeight / 2 + 3);
//       }

//       pdf.save("SQANO.pdf");
//     });
//   };

//   return (
//     <div>
//       <Box>
//         <div
//           id="divToPrint"
//           style={{
//             position: "relative",
//             backgroundColor: "#fff",
//             padding: "20px",
//             borderRadius: "8px",
//             boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//           }}
//         >
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <div style={{ flex: 1, marginRight: "20px" }}>
//               <div style={{ backgroundColor: "#660032", borderRadius: "8px", padding: "10px 15px", color: "#fff", marginBottom: "20px" }}>
//                 <h6 style={{ margin: 0 }}>Profile Synopsis</h6>
//               </div>
//               <div style={{ backgroundColor: "#f4f4f4", padding: "15px", borderRadius: "8px", marginBottom: "20px" }}>
//                 <p>{analysis?.summary}</p>
//               </div>
//             </div>
//             <div style={{ flex: 1 }}>
//               <SkillsChart data={data} />
//             </div>
//           </div>

//           <div style={{ backgroundColor: "#660032", borderRadius: "8px", padding: "10px 15px", color: "#fff", marginBottom: "20px" }}>
//             <h6 style={{ margin: 0 }}>Sqano recommended Q & A</h6>
//           </div>
//           <div style={{ backgroundColor: "#f4f4f4", padding: "15px", borderRadius: "8px" }}>
//             {analysis?.questionnaire?.map((item, index) => (
//               <React.Fragment key={index}>
//                 <h5 style={{ color: "#660032" }}>Q : {item.Q}</h5>
//                 <p><b>Ans :</b> {item.ANS}</p>
//                 <Box display="flex" justifyContent="flex-end">
//                   <Rating
//                     name={`rating-${index}`}
//                     value={item.rating}
//                     size="small"
//                     sx={{
//                       color: '#660032',
//                     }}
//                   />
//                 </Box>
//               </React.Fragment>
//             ))}
//           </div>

//           <img
//             src={`${icon}`}
//             alt="Watermark Logo"
//             style={{
//               position: "absolute",
//               top: "60%",
//               left: "50%",
//               transform: "translate(-50%, -50%) rotate(35deg)",
//               opacity: "0.1",
//               zIndex: "0",
//               width: "300px",
//               height: "400px",
//             }}
//           />
//         </div>
//       </Box>
//       <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
//         <Button style={{ color: "#660032" }} onClick={printDocument}>
//           <ArrowCircleDownIcon />
//         </Button>
//         <Button style={{ color: "#660032" }}>
//           <EmailIcon />
//         </Button>
//       </Box>
//     </div>
//   );
// };

// export default GeneratePdf;

