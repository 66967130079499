
import {
  JOB_CREATION,
  JOB_CREATION_SUCCESS,
  JOB_CREATION_FAILURE,
  GET_ALL_JOB_REQUIREMENTS,
  GET_ALL_JOB_REQUIREMENT_SUCCESSS,
  GET_ALL_JOB_REQUIREMENT_FAILURE,
  FETCH_JOB_REQUIREMENTS_REQUEST,
  FETCH_JOB_REQUIREMENTS_SUCCESS,
  FETCH_JOB_REQUIREMENTS_FAILURE,
  GET_ALL_USERS_BY_ENTITY_ID,
  GET_ALL_USERS_BY_ENTITY_ID_SUCCESS,
  GET_ALL_USERS_BY_ENTITY_ID_FAILURE,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_SUCCESSS,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_FAILURE,
  FETCH_JOB_REQUIREMENT_REQUEST,
  FETCH_JOB_REQUIREMENT_SUCCESS,
  FETCH_JOB_REQUIREMENT_FAILURE,
  POST_JOB_REQUEST, 
  POST_JOB_SUCCESS, 
  POST_JOB_FAILURE,
  UPDATE_JOB_REQUIREMENT,
  UPDATE_JOB_REQUIREMENT_SUCCESS,
  UPDATE_JOB_REQUIREMENT_FAILURE,
  CLEAR_MESSAGE
} from "./actionTypes"


// create job

export const jobCreation = formData => ({
  type: JOB_CREATION,
  payload: formData,
})

export const jobCreationSuccess = formData => ({
  type: JOB_CREATION_SUCCESS,
  payload: formData,
})

export const jobCreationFailure = error => ({
  type: JOB_CREATION_FAILURE,
  payload: error,
})

export const updateJobRequirement = (id,data) => ({
  type: UPDATE_JOB_REQUIREMENT,
  payload: { id, data }
});

export const updateJobRequirementSuccess = (id,data) => ({
  type: UPDATE_JOB_REQUIREMENT_SUCCESS,
  payload: { id, data }
});

export const updateJobRequirementFailure = (id,error) => ({
  type: UPDATE_JOB_REQUIREMENT_FAILURE,
  payload: { id, error }
});

// GET all job requirements

export const getAllJobsRequirements = () => ({
  type: GET_ALL_JOB_REQUIREMENTS,
})

export const getAllJobRequirementsSuccess = allJobs => ({
  type: GET_ALL_JOB_REQUIREMENT_SUCCESSS,
  payload: allJobs,
})

export const getAllJobRequirementsFailure = error => ({
  type: GET_ALL_JOB_REQUIREMENT_FAILURE,
  payload: error,
})

export const getAllJobRequirementsByEntityId = id => ({ type: GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID, payload: id })

export const getAllJobRequirementsByEntityIdSuccess = jobRequirements => ({
  type: GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_SUCCESSS,
  payload: jobRequirements,
})
export const getAllJobRequirementsByEntityIdFailure = error => ({
  type: GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_FAILURE,
  payload: error,
})



export const fetchJobRequirementsRequest = userId => ({
  type: FETCH_JOB_REQUIREMENTS_REQUEST,
  payload: userId,
})

export const fetchJobRequirementsSuccess = jobs => ({
  type: FETCH_JOB_REQUIREMENTS_SUCCESS,
  payload: jobs,
})

export const fetchJobRequirementsFailure = error => ({
  type: FETCH_JOB_REQUIREMENTS_FAILURE,
  payload: error,
})

export const getAllUsersByEntityId = (id) => (
  {
    type: GET_ALL_USERS_BY_ENTITY_ID,
    payload: id
  })

export const getAllUsersByEntityIdSuccess = allUsers => (
  {
    type: GET_ALL_USERS_BY_ENTITY_ID_SUCCESS,
    payload: allUsers,
  })

export const getAllUsersByEntityIdFailure = error => ({
  type: GET_ALL_USERS_BY_ENTITY_ID_FAILURE,
  payload: error,
})


export const fetchJobRequirementRequest = (id) => ({
  type: FETCH_JOB_REQUIREMENT_REQUEST,
  payload: id
});

export const fetchJobRequirementSuccess = (jobRequirement) => ({
  type: FETCH_JOB_REQUIREMENT_SUCCESS,
  payload: jobRequirement
});

export const fetchJobRequirementFailure = (error) => ({
  type: FETCH_JOB_REQUIREMENT_FAILURE,
  payload: error
});

export const postJobRequest = ({jobId, userId}) => ({
  type: POST_JOB_REQUEST,
  payload: { jobId, userId }
});

export const postJobSuccess = (data) => ({
  type: POST_JOB_SUCCESS,
  payload: data
});

export const postJobFailure = (error) => ({
  type: POST_JOB_FAILURE,
  payload: error
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE
});
