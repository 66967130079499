import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard

import SqanoTileDashboard from "pages/Sqano Tile Box/SqanoTileDashboard"
import ClientManagementDashboard from "pages/Client Management Dashboard/index"
import ClientManagementTileBox from "pages/Client Management Tile Box/ClientManagementTileBox"
import UserDashboard from "pages/User Management Dashboard/UserDashboard"


//Profile.page
// import ProfilePage from "pages/User Management Dashboard/ProfilePage"

//ClientManagement
import ClientManagement from "pages/Client Management Info/ClientManagement"

//UserManagement
import UserManagement from "pages/User Management Info/UserManagement"

//Ticket Status
import TicketsStatus from "pages/Ticket Status/TicketsStatus"

//Charts
import ChartsAppex from "../pages/Charts/charts-appex"
import ChartsJs from "../pages/Charts/charts-chartjs"
import ChartsKnob from "../pages/Charts/charts-knob"
import ChartsC3 from "../pages/Charts/charts-c3"
import ChartsSparkLine from "../pages/Charts/charts-sparkline"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"

//Icons
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import Iconion from "../pages/Icons/Iconion"
import IconFontawesome from "../pages/Icons/IconFontawesome"
import IconThemify from "../pages/Icons/IconThemify"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconTypicons from "../pages/Icons/IconTypicons"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import ClientCreation from "pages/Forms/ClientCreation"
import VendorForm from "../pages/Forms/VendorForm"
import JobPostingForm from "../pages/Forms/JobPostingForm"
import UserCreation from "../pages/Forms/UserCreation"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiBadge from "../pages/Ui/UiBadge"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiPagination from "../pages/Ui/UiPagination"
import UiPopoverTooltips from "../pages/Ui/UiPopoverTooltips"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline"
import PagesInvoice from "../pages/Extra Pages/pages-invoice"
import PagesDirectory from "../pages/Extra Pages/pages-directory"
import PagesBlank from "../pages/Extra Pages/pages-blank"
import Pages404 from "../pages/Extra Pages/pages-404"
import Pages500 from "../pages/Extra Pages/pages-500"
import UiUtilities from "pages/Ui/UiUtilities"
import UiColors from "pages/Ui/UiColors"
import UiOffcanvas from "pages/Ui/UiOffcanvas"
import Chat from "pages/Chat/Chat"
import Kanban from "pages/Kanban"
import CvScanning from "pages/ProductList/CvScanning"
import ProductHome from "pages/ProductList/ProductHome"
import CvAccess from "pages/ProductList/CvAccess"
import PaidUnpaid from "components/Common/PaidUnpaid/PaidUnpaid"
import Chatbar from "components/VerticalLayout/ChatBar/Chatbar"
// import UserProfileCreation from "components/Common/UserProfileCreation/UserProfileCreation"
import Invoice from "pages/Invoice/Invoice"
import { CVScan } from "components/VerticalLayout/Products/cvScan"
import { CVPar } from "components/VerticalLayout/Products/cvPar"
import CVAcc from "components/VerticalLayout/Products/cvAcc"
import Todlist from "components/VerticalLayout/Products/todList"
import WebScrap from "components/VerticalLayout/Products/WebScrap"
import SqaleUp from "pages/ProductList/SqaleUp"
import MockTest from "pages/ProductList/MockTest"

import AccountPage from "pages/Authentication/AccountPage"
import ProductTabs from "pages/ProductList/ProductTabs"
// import Help from "@mui/icons-material"
import Setting from "pages/Authentication/Setting/Setting"
import LoggedOutPage from "pages/Authentication/LogoutPage/LogOut"
import DownloadPage from "pages/Authentication/Download/Download"
import Help from "pages/Authentication/Help/Help"

import RequirementList from "pages/Requirement/RequirementList"
import ResetPassword from "pages/Authentication/ResetPwdPage"
import LoginNote from "components/Common/LoginNote/LoginNote"

import SuperAdmin from "pages/SuperAdmin/SuperAdmin"
import PulseLoader from "pages/PulseLoader/PulseLoader"
import CommonDashBoard from "pages/CommonDashBoard/CommonDashBoard"
import ChatBot from "pages/ProductList/ChatBot"
import ViewRequirement from "pages/ProductList/Tod/ViewRequirement"
import RequirementView from 'components/VerticalLayout/Products/viewRequirement'
import EditProfile from "pages/Forms/EditProfile"
import CreateRole from "pages/ProductList/Access/Role/CreateRole"
import GetAllRoles from "pages/ProductList/Access/Role/Roles"
import CommingSoon from "../pages/Extra Pages/pages-blank"
import ScanIT from "pages/ProductList/ScanIT"
import CvParsing from "pages/ProductList/CvParsing"
import Tod from "pages/ProductList/Tod"
import WebIt from "pages/ProductList/WebIt/WebIt"
import CvTemplatep from "pages/ProductList/cvtemplatep"
import ParseIT from "pages/ProductList/ParseIt/ParseIT"
import ResumeBuilder from "pages/ProductList/CvTemplate/ResumeBuilder"
import Analytics from "pages/ProductList/CvTemplate/Analytics"
import NewsAndBlogs from "pages/ProductList/CvTemplate/NewsAndBlogs"
import CreateNewResume from "pages/ProductList/CvTemplate/CreateNewResume"

const userRoutes = [
  { path: "/create-role", component: <CreateRole /> },
  { path: "/roles", component: <GetAllRoles /> },
  { path: "/pulseloader", component: <PulseLoader /> },
  //Dashboard
  { path: "/dashboard", component: <CommonDashBoard /> },
  {
    path: "/sqano-tile-box",
    component: <SqanoTileDashboard />,
  },

  {
    path: "/client-management-dashboard",
    component: <ClientManagementDashboard />,
  },
  {
    path: "/client-management-tile-box",
    component: <ClientManagementTileBox />,
  },

  {
    path: "/user-dashboard",
    component: <UserDashboard />,
  },

  //Profile
  // {
  //   path: "/profile-page",
  //   component: <ProfilePage />,
  // },
  {
    path: "/super-admin",
    component: <SuperAdmin />,
  },

  //ClientManagement
  {
    path: "/client-management",
    component: <ClientManagement />,
  },
  {
    path: "/user-management",
    component: <UserManagement />,
  },

  // { path: "/UserProfileCreation", component: <UserProfileCreation /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  { path: "/chat", component: <Chat /> },
  { path: "/kanbanboard", component: <Kanban /> },


  //Ticket Status
  { path: "/tickets-status", component: <TicketsStatus /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/Account", component: <AccountPage /> },
  { path: "/history", component: <DownloadPage /> },
  { path: "/editProfile", component: <EditProfile /> },

  // //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-compose", component: <EmailCompose /> },

  // //Charts
  { path: "/apex-charts", component: <ChartsAppex /> },
  { path: "/charts-chartjs", component: <ChartsJs /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/charts-c3", component: <ChartsC3 /> },
  { path: "/sparkline-charts", component: <ChartsSparkLine /> },

  // // Icons
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-ion", component: <Iconion /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },
  { path: "/icons-themify", component: <IconThemify /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-typicons", component: <IconTypicons /> },

  // // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  // // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },

  // // Forms
  { path: "/client-creation-form", component: <ClientCreation /> },
  { path: "/vendor-form", component: <VendorForm /> },
  { path: "/Create-job-post", component: <JobPostingForm /> },
  { path: "/user-creation", component: <UserCreation /> },

  // // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-badge", component: <UiBadge /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-pagination", component: <UiPagination /> },
  { path: "/ui-popover-tooltip", component: <UiPopoverTooltips /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-utilities", component: <UiUtilities /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },

  // //Extra Pages
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-invoice", component: <PagesInvoice /> },
  { path: "/pages-directory", component: <PagesDirectory /> },
  { path: "/comming-soon", component: <CommingSoon /> },
  { path: "/ProductHome", component: <ProductHome /> },
  { path: "/requirement-list", component: <RequirementList /> },

  // { path: "/scanning", component: <CvScanning />},
  // { path: "/ProductHome", component: <ProductHome />},
  // { path: "/CvParsing", component: <CvParsing />},
  // { path: "/CvAccess", component: <CvAccess /> },
  // { path: "/Tod", component: <Tod />},
  // { path: "/WebScrapping", component: <WebScrapping />},
  // { path: "/SalesCrm", component: <SalesCrm />},
  // { path: "/MockTest", component: <MockTest />},
  { path: "/PaidUnpaid", component: <PaidUnpaid /> },
  { path: "/Chatbar", component: <Chatbar /> },
  { path: "/Invoice", component: <Invoice /> },
  // { path: "/CvScanning", component: <CvScanning /> },
  // { path: "/parsing", component: <CVPar /> },
  // { path: "/access", component: <CVAcc /> },
  // { path: "/todlist", component: <Todlist /> },
  // { path: "/requirement-view/:id", component: <ViewRequirement /> },
  { path: "/view-requirement/:id", component: <ViewRequirement /> },
  // { path: "/Webscrap", component: <WebScrap /> },
  // { path: "/salescrmp", component: <Salescrmp /> },
  // { path: "/mocktestp", component: <MockTest /> },
  // { path: "/cvtemplate", component: <CvTemp /> },
  { path: "/help", component: <Help /> },
  { path: "Setting", component: <Setting /> },
  {path: "/scan-it", component: <ScanIT />},
  {path:"/parse-it", component: <ParseIT />},
  {path: "/civix", component: <CvAccess />},
  {path: "/tod", component: <Tod />},
  {path: "/web-it", component: <WebIt/>},
  {path: "/sqale-up", component: <SqaleUp/>},
  {path: "/mocktest", component: <MockTest/>},
  {path: "/cvtemplate", component: <CvTemplatep />},
  {path: "/parse", component: <CvParsing />},
  {path: "/resumebuilder", component: <ResumeBuilder />},
  {path: "/analytics", component: <Analytics />},
  {path:"/newsandblogs", component: <NewsAndBlogs />},
  {path:"/create-new-resume", component: <CreateNewResume/>},
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
    { path: "/product-tabs", component: <ProductTabs />}
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/logoutPage", component: <LoggedOutPage /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/loginNote", component: < LoginNote /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/chat-bot", component: <ChatBot /> }
]

export { userRoutes, authRoutes }
