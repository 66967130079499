import React, { useState, useEffect } from "react"
// import "./CvScanning.scss"
import { Box, Button, IconButton, Slide, Typography } from "@mui/material"
import Grid from '@mui/material/Grid';
import {
  Col,
  Row,
  Label,
  Input,
} from "reactstrap"
import { Country, State, City } from "country-state-city"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { getAllJobRequirementsByEntityId, getAllUsersByEntityId, jobCreation, updateJobRequirement, clearMessage } from "../../store/client/product/tod/jobRequirements/actions"
import { fetchJobRequirementsRequest, fetchJobRequirementRequest } from "store/actions";
import Select, { components } from "react-select"
import { v4 as uuidv4 } from "uuid"
import PulseLoader from "pages/PulseLoader/PulseLoader"
import { useNavigate } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Edit, Visibility } from "@mui/icons-material";
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close'; // If using Material-UI
import { toast } from "react-toastify";
import "./tod.css"
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import { format, parseISO } from 'date-fns';
import ProductTabs from "./ProductTabs";
import { useLocation } from "react-router-dom";
import CustomWarn from "components/CustomComponents/CustomWarn";
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender";
import { Modal, Chip } from '@mui/material';
import { Formik, Form } from 'formik';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CloseOutlined } from '@mui/icons-material';


const validationSchema = Yup.object({
  jobRequirementId: Yup.number(),
  jobType: Yup.string().required("Job Type is required"),
  workMode: Yup.string().required("Work Mode is required"),
  minimumBudget: Yup.number().required("Minimum budget is required")
    .min(1, "Minimum budget must be at least 1"),
  maximumBudget: Yup.number()
    .required("Maximum budget is required")
    .test('is-greater', 'Maximum budget must be greater than minimum budget', function (value) {
      const { minimumBudget } = this.parent;
      return !value || value > minimumBudget;
    }),
  numberOfPositions: Yup.number()
    .required("Number of Positions is required")
    .min(1, "At least one position is required"),
  clientName: Yup.string().required("Client Name is required"),
  jobTitle: Yup.string().required("Job Title is required"),
  mandatorySkills: Yup.array().min(1, "Select at least one skill"),
  yearsOfExperience: Yup.number()
    .required("Years of Experience is required")
    .min(2, "Years of experience must be greater than 1")
    .max(24, "Years of experience must be less than 25"),
  noticePeriod: Yup.string().required("Notice period is required"),
  jobDescription: Yup.string().required("Job Description is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  requirementReceivedDateTime: Yup.date().required(
    "Requirement Received Date & Time is required",
  ),
  requirementAllocationDateTime: Yup.date().required(
    "Requirement Allocation Date & Time is required",
  ),
  userAllocation: Yup.array()
    .min(1, "Please select at least one user")
    .required("User allocation is required"),
  deadline: Yup.date().required("Deadline is required"),
})

const CheckboxOption = props => (
  <components.Option {...props}>
    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
    <label style={{ color: props.isSelected ? "black" : "inherit" }}>
      {props.label}
    </label>
  </components.Option>
)

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : "white",
    color: state.isSelected ? "black" : provided.color,
  }),
  valueContainer: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'auto',
    scrollbarWidth: 'none',
    textOverflow: 'ellipsis',
    padding: '0px 5px',
    height: '30px', // Set height to be the same as other fields
  }),
}

const techList = [
  { value: "JavaScript", label: "JavaScript" },
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  { value: "C#", label: "C#" },
  { value: "C++", label: "C++" },
  { value: "Ruby", label: "Ruby" },
  { value: "PHP", label: "PHP" },
  { value: "Swift", label: "Swift" },
  { value: "Kotlin", label: "Kotlin" },
  { value: "Go", label: "Go" },
  { value: "TypeScript", label: "TypeScript" },
  { value: "SQL", label: "SQL" },
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "React", label: "React" },
  { value: "Angular", label: "Angular" },
  { value: "Vue", label: "Vue" },
  { value: "Node.js", label: "Node.js" },
  { value: "Django", label: "Django" },
  { value: "Flask", label: "Flask" },
  { value: "Spring", label: "Spring" },
  { value: "Laravel", label: "Laravel" },
  { value: "Symfony", label: "Symfony" },
  { value: "ASP.NET", label: "ASP.NET" },
  { value: "Ruby on Rails", label: "Ruby on Rails" },
  { value: "Express", label: "Express" },
  { value: "TensorFlow", label: "TensorFlow" },
  { value: "PyTorch", label: "PyTorch" },
  { value: "Keras", label: "Keras" },
  { value: "Docker", label: "Docker" },
  { value: "Kubernetes", label: "Kubernetes" },
  { value: "AWS", label: "AWS" },
  { value: "Azure", label: "Azure" },
  { value: "GCP", label: "GCP" },
  { value: "Linux", label: "Linux" },
  { value: "Unix", label: "Unix" },
  { value: "Windows Server", label: "Windows Server" },
  { value: "MySQL", label: "MySQL" },
  { value: "PostgreSQL", label: "PostgreSQL" },
  { value: "MongoDB", label: "MongoDB" },
  { value: "Oracle", label: "Oracle" },
  { value: "Firebase", label: "Firebase" },
  { value: "GraphQL", label: "GraphQL" },
  { value: "REST", label: "REST" },
  { value: "SOAP", label: "SOAP" },
  { value: "Git", label: "Git" },
  { value: "GitHub", label: "GitHub" },
  { value: "GitLab", label: "GitLab" },
  { value: "Bitbucket", label: "Bitbucket" },
  { value: "Jenkins", label: "Jenkins" },
  { value: "CI/CD", label: "CI/CD" },
  { value: "Terraform", label: "Terraform" },
  { value: "Ansible", label: "Ansible" },
  { value: "Puppet", label: "Puppet" },
  { value: "Chef", label: "Chef" },
  { value: "Elasticsearch", label: "Elasticsearch" },
  { value: "Logstash", label: "Logstash" },
  { value: "Kibana", label: "Kibana" },
  { value: "Splunk", label: "Splunk" },
  { value: "Prometheus", label: "Prometheus" },
  { value: "Grafana", label: "Grafana" },
  { value: "Nagios", label: "Nagios" },
  { value: "Jira", label: "Jira" },
  { value: "Confluence", label: "Confluence" },
  { value: "Trello", label: "Trello" },
  { value: "Slack", label: "Slack" },
  { value: "Zoom", label: "Zoom" },
  { value: "Teams", label: "Teams" },
  { value: "Skype", label: "Skype" },
]

const ModalStepper = ({ currentStep, steps, nextStep, prevStep, handleSubmit, handleUpdate, view }) => (

  <Box sx={{ width: '100%', padding: '10px' }}>
    <Stepper activeStep={currentStep - 1} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            StepIconProps={{
              sx: {
                color: '#660032 !important', // Force icon color to white
                '& .MuiStepIcon-text': {
                  fill: 'white', // Set the step number text color
                },
              },
            }}
            sx={{
              '& .MuiStepLabel-label': {
                color: '#660032', // Ensure label text is always white
              },
              '& .MuiStepLabel-label.Mui-active': {
                color: '#660032 !important', // Ensure label text is white when active
              },
              '& .MuiStepLabel-label.Mui-completed': {
                color: '#660032 !important', // Ensure label text is white when completed
              },
              '& .MuiStepLabel-iconContainer': {
                color: 'red'
              },
            }}
          >
            {step.label}
          </StepLabel>
          <StepContent>
            {step.content}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {currentStep > 1 && (
                  <Button
                    style={{ color: 'white', backgroundColor: '#660032', marginRight: '10px' }}
                    onClick={prevStep}
                    disabled={currentStep === 1}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div>
                {currentStep < steps.length && (
                  <Button
                    style={{ color: 'white', backgroundColor: '#660032', marginRight: '10px' }}
                    onClick={nextStep}
                  >
                    Next
                  </Button>
                )}
                {currentStep === steps.length && (
                  <Button
                    style={{ color: 'white', backgroundColor: '#660032', marginRight: '10px' }}
                    onClick={view === "edit" ? handleUpdate : handleSubmit}
                  >
                    {view === "edit" ? "Update" : "Submit"}
                  </Button>
                )}
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  </Box>
);


const Tod = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedJobRequirement, setSelectedJobRequirement] = useState(null)

  const [creationRequirementAllocationDateTime, setCreationRequirementAllocationDateTime] = useState("");
  const [publishRequirementAllocationDateTime, setPublishRequirementAllocationDateTime] = useState("");

  const userData = useSelector(state => state.usersReducer.entityUsers)
  const { loading, message, allUsers } = useSelector(state => state?.JobRequirement);
  const loggedUser = useSelector(state => state.Login.user?.userDetails);
  const requirement = useSelector(state => state?.JobRequirement?.jobRequirement?.data);
  const { features } = useSelector(state => state.Login?.user?.userDetails?.roleAndFeatures)
  const [tableLoading, setTableLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const entityId = loggedUser?.entityId;
  const userId = loggedUser?.id;
  const userRole = loggedUser?.roleAndFeatures.name;
  const [jobId, setJobId] = useState()
  let jobRequirements = useSelector(state => state.JobRequirement?.allJobRequirements,) || [];
  const [alert, setAlert] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [view, setView] = useState("");
  const [productSubFeatures, setProductSubFeatures] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [filterValues, setFilterValues] = useState({});
  const [filterModalView, setFilterModalView] = useState(false);

  let reversedJobRequirement = jobRequirements.slice().reverse().map((data, index) => ({
    "Sr. No.": index + 1,
    jobRequirementId: data.jobRequirementId,
    clientName: data.clientName,
    jobTitle: data.jobTitle,
    numberOfPositions: data.numberOfPositions,
    deadline: data.deadline,
  }))

  useEffect(() => {
    if (location.state && location.state.openDrawer) {
      toggleDrawer();
    }
  }, []);

  const handleUpdate = () => {
    dispatch(updateJobRequirement(jobId, formik.values))
    setDrawerOpen(false);
  }

  useEffect(() => {
    if (view === "edit") {
      formik.setValues({
        jobRequirementId: requirement?.jobRequirementId || "",
        entityId: entityId,
        jobType: requirement?.jobType || "",
        workMode: requirement?.workMode || "",
        minimumBudget: requirement?.minimumBudget || "",
        maximumBudget: requirement?.maximumBudget || "",
        numberOfPositions: requirement?.numberOfPositions || "",
        clientName: requirement?.clientName || "",
        jobTitle: requirement?.jobTitle || "",
        mandatorySkills: requirement?.mandatorySkills || [],
        yearsOfExperience: requirement?.yearsOfExperience || "",
        noticePeriod: requirement?.noticePeriod || "",
        jobDescription: requirement?.jobDescription || "",
        country: requirement?.country || "",
        state: requirement?.state || "",
        city: requirement?.city || "",
        requirementReceivedDateTime: requirement?.requirementReceivedDateTime || "",
        requirementAllocationDateTime: requirement?.requirementAllocationDateTime || "",
        userAllocation: requirement?.userAllocation || [],
        deadline: requirement?.deadline || "",
      })
    }
  }, [requirement, view])

  useEffect(() => {
    if (features?.some(feature => feature?.feature?.name === "PRODUCT")) {
      const productFeature = features?.find(feature => feature?.feature?.name === "PRODUCT");
      if (productFeature) {
        const subFeatures = productFeature?.subFeatures?.find(subFeature => subFeature?.feature?.name === "TOD");
        if (subFeatures) {
          setProductSubFeatures(subFeatures?.permissions);
        }
      }
    }
  }, [features])

  const hasPermission = (featureName) => {
    return productSubFeatures?.some(subFeature => subFeature?.name === featureName)
  }


  const formik = useFormik({
    initialValues: {
      jobRequirementId: "",
      entityId: entityId,
      jobType: "",
      workMode: "",
      minimumBudget: "",
      maximumBudget: "",
      numberOfPositions: "",
      clientName: "",
      jobTitle: "",
      mandatorySkills: [],
      yearsOfExperience: "",
      noticePeriod: "",
      jobDescription: "",
      country: "",
      state: "",
      city: "",
      requirementReceivedDateTime: "",
      requirementAllocationDateTime: "",
      userAllocation: [],
      deadline: "",
      createdBy: userId
    },
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true,   // Enable validation on blur
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setTableLoading(true);
      if (values.jobRequirementId === "") {
        values.jobRequirementId = `CVIXO-${values.clientName.replace(/[^A-Za-z]/g, "").slice(0, 5)}-${uuidv4()
          .replace(/[^A-Za-z0-9]/g, "")
          .slice(0, 3)}`
      }
      dispatch(jobCreation(values))
      setTableLoading(false);

      setCurrentStep(1);
      setDrawerOpen(false);
      formik.resetForm();
    },
  })
  const handleViewRequirement = (e, tableMeta) => {
    const correctIndex = jobRequirements.length - 1 - tableMeta.rowIndex;
    const jobId = jobRequirements[correctIndex]._id;
    navigate(`/view-requirement/${jobId}`);
  };

  const handleEditRequirement = (e, tableMeta) => {
    setCurrentStep(1);
    setView("edit");
    const correctIndex = jobRequirements.length - 1 - tableMeta.rowIndex;
    const jobId = jobRequirements[correctIndex]._id;
    setJobId(jobId);
    dispatch(getAllUsersByEntityId(entityId));
    setDrawerOpen(true);
    dispatch(fetchJobRequirementRequest(jobId))
  };


  const handleScanRequirement = (e, tableMeta) => {
    const correctIndex = jobRequirements.length - 1 - tableMeta.rowIndex;
    const req = jobRequirements[correctIndex];
    const scanItData = {
      jobRequirementId: req?.jobRequirementId,
      jobTitle: req?.jobTitle,
      experience: req?.yearsOfExperience,
      mandatorySkills: req?.mandatorySkills,
      location: req?.city,
      jobDescription: req?.jobDescription,
    };
    navigate("/scan-it", { state: { requirementData: scanItData } });
  };



  useEffect(() => {
    if (userRole === "Super Admin") {
      dispatch(getAllJobRequirementsByEntityId(entityId));
    } else {
      dispatch(fetchJobRequirementsRequest(userId));
    }
    setTableLoading(false);
  }, [dispatch, message]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage()); // Assuming you have an action to clear the message
    }
  }, [message, dispatch]);

  const userOptions = allUsers?.map(user => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const steps = [
    {
      label: 'JOB DETAILS',
      content: (
        <div className="col-md-12" style={{ padding: '0 10px' }}>
          <Row className="mb-3">
            <Col md={6}>
              <Label for="jobType" className="new-requirement-form-label">Job Type</Label>
              <Input
                type="select"
                id="jobType"
                name="jobType"
                className="form-control common-font"
                {...formik.getFieldProps("jobType")}
                value={formik.values.jobType}
                style={{ color: formik.values.jobType ? "inherit" : "#808080" }}
              >
                <option value="">Job Type</option>
                <option>Full-time</option>
                <option>Contract</option>
                <option>Freelance</option>
              </Input>
              {formik.touched.jobType && formik.errors.jobType ? (
                <div className="error"><span>{formik.errors.jobType}</span></div>
              ) : null}
            </Col>
            <Col md={6}>
              <Label for="workMode" className="new-requirement-form-label">Work Mode</Label>
              <Input
                type="select"
                id="workMode"
                name="workMode"
                className="form-control common-font"
                {...formik.getFieldProps("workMode")}
                style={{ color: formik.values.workMode ? "inherit" : "#808080" }}
              >
                <option value="">Work Mode</option>
                <option>On-site</option>
                <option>Remote</option>
                <option>Hybrid</option>
                <option>Work from home</option>
              </Input>
              {formik.touched.workMode && formik.errors.workMode ? (
                <div className="error"><span>{formik.errors.workMode}</span></div>
              ) : null}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label for="minimumBudget" className="new-requirement-form-label">Minimum Budget</Label>
              <Input
                type="number"
                id="minimumBudget"
                name="minimumBudget"
                placeholder="Minimum budget"
                className="form-control no-spinner common-font"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldTouched('minimumBudget', true, true);
                  formik.validateForm();
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  formik.setFieldTouched('minimumBudget', true, true);
                  formik.validateForm();
                }}
                value={formik.values.minimumBudget}
              />
              {formik.touched.minimumBudget && formik.errors.minimumBudget ? (
                <div className="error"><span>{formik.errors.minimumBudget}</span></div>
              ) : null}
            </Col>
            <Col md={6}>
              <Label for="maximumBudget" className="new-requirement-form-label">Maximum Budget</Label>
              <Input
                type="number"
                id="maximumBudget"
                name="maximumBudget"
                placeholder="Maximum budget"
                className="form-control no-spinner common-font"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldTouched('maximumBudget', true, true);
                  formik.validateForm();
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  formik.setFieldTouched('maximumBudget', true, true);
                  formik.validateForm();
                }}
                value={formik.values.maximumBudget}
              />
              {formik.touched.maximumBudget && formik.errors.maximumBudget ? (
                <div className="error"><span>{formik.errors.maximumBudget}</span></div>
              ) : null}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Label for="numberOfPositions" className="new-requirement-form-label">Number of Positions</Label>
              <Input
                type="number"
                id="numberOfPositions"
                name="numberOfPositions"
                placeholder="Number of positions"
                className="form-control no-spinner common-font"
                {...formik.getFieldProps("numberOfPositions")}
              />
              {formik.touched.numberOfPositions &&
                formik.errors.numberOfPositions ? (
                <div className="error"><span>{formik.errors.numberOfPositions}</span></div>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Label for="clientName" className="new-requirement-form-label">Client Name</Label>
              <Input
                type="text"
                id="clientName"
                name="clientName"
                placeholder="Client name"
                className="form-control common-font"
                {...formik.getFieldProps("clientName")}
              />
              {formik.touched.clientName && formik.errors.clientName ? (
                <div className="error"><span>{formik.errors.clientName}</span></div>
              ) : null}
            </Col>
          </Row>
        </div>
      )
    },



    {
      label: 'ROLES & RESPONSIBILITIES',
      content: (
        <div className="col-md-12" style={{ padding: '0 10px' }}>
          {/* <Row className="mb-3">
          <Col md={12}>
            <Label for="mandatorySkills">Mandatory Skills</Label>
            <Input
              type="text"
              id="mandatorySkills"
              name="mandatorySkills"
              placeholder="Enter mandatory skills"
              className="form-control"
              {...formik.getFieldProps("mandatorySkills")}
            />
            {formik.touched.mandatorySkills &&
            formik.errors.mandatorySkills ? (
              <div className="error">{formik.errors.mandatorySkills}</div>
            ) : null}
          </Col>
        </Row> */}
          <Row className="mb-3">
            <Col md={12}>
              <Label for="jobTitle" className="new-requirement-form-label">Job Title</Label>
              <Input
                type="text"
                id="jobTitle"
                name="jobTitle"
                placeholder="Job title"
                className="form-control common-font"
                {...formik.getFieldProps("jobTitle")}
              />
              {formik.touched.jobTitle && formik.errors.jobTitle ? (
                <div className="error"><span>{formik.errors.jobTitle}</span></div>
              ) : null}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Label for="mandatorySkills" className="new-requirement-form-label">Mandatory Skills</Label>
              <Select
                id="mandatorySkills"
                name="mandatorySkills"
                options={techList}
                placeholder="Mandatory Skills"
                className="common-font"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CheckboxOption }}
                styles={customStyles}
                onChange={selectedOptions => {
                  const values = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : []
                  formik.setFieldValue("mandatorySkills", values)
                }}
                value={techList.filter(option =>
                  formik.values.mandatorySkills.includes(option.value),
                )}
              // formatOptionLabel={option => (
              //   <Tooltip title={techList.map(opt => opt.label).join(', ')} arrow placement="top">
              //     <span>{option.label}</span>
              //   </Tooltip>
              // )}
              />
              {formik.touched.mandatorySkills &&
                formik.errors.mandatorySkills ? (
                <div className="error"><span>{formik.errors.mandatorySkills}</span></div>
              ) : null}
            </Col>

          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label for="yearsOfExperience" className="new-requirement-form-label">Years of Experience</Label>
              <Input
                type="number"
                id="yearsOfExperience"
                name="yearsOfExperience"
                placeholder="Years of experience"
                className="form-control no-spinner common-font"
                {...formik.getFieldProps("yearsOfExperience")}

              />
              {formik.touched.yearsOfExperience &&
                formik.errors.yearsOfExperience ? (
                <div className="error"><span>{formik.errors.yearsOfExperience}</span></div>
              ) : null}
            </Col>
            <Col md={6}>
              <Label for="noticePeriod" className="new-requirement-form-label">Notice Period</Label>
              <Input
                type="select"
                id="noticePeriod"
                name="noticePeriod"
                className="form-control common-font"
                {...formik.getFieldProps("noticePeriod")}
                style={{ color: formik.values.noticePeriod ? "inherit" : "#808080" }}
              >
                <option value="" disabled selected>Notice Period</option>
                <option>Immidiate</option>
                <option value={15}>15 Day's</option>
                <option value={30}>30 Day's</option>
                <option value={60}>60 Day's</option>
                <option value={90}>90 Day's</option>
              </Input>
              {formik.touched.noticePeriod && formik.errors.noticePeriod ? (
                <div className="error"><span>{formik.errors.noticePeriod}</span></div>
              ) : null}
            </Col>

          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <label htmlFor="country" className="new-requirement-form-label">Country</label>
              <Select
                id="country"
                name="country"
                options={Country.getAllCountries().map(country => ({
                  value: country.isoCode,
                  label: country.name,
                }))}
                value={
                  formik.values.country
                    ? {
                      value: formik.values.country,
                      label: Country.getCountryByCode(formik.values.country)
                        ?.name,
                    }
                    : null
                }
                onChange={option => {
                  formik.setFieldValue("country", option ? option.value : "")
                  formik.setFieldValue("state", "")
                  formik.setFieldValue("city", "")
                }}
                onBlur={() => formik.setFieldTouched("country", true)}
                className="form-control p-0 border-0 m-0 common-font"
                placeholder="Country"
              />
              {formik.touched.country && formik.errors.country ? (
                <div className="error"><span>{formik.errors.country}</span></div>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">

            <Col md={6}>
              <label htmlFor="state" className="new-requirement-form-label">State</label>
              <Select
                id="state"
                name="state"
                options={
                  formik.values.country
                    ? State.getStatesOfCountry(formik.values.country).map(
                      state => ({
                        value: state.isoCode,
                        label: state.name,
                      }),
                    )
                    : []
                }
                value={
                  formik.values.state
                    ? {
                      value: formik.values.state,
                      label: State.getStateByCodeAndCountry(
                        formik.values.state,
                        formik.values.country,
                      )?.name,
                    }
                    : null
                }
                onChange={option => {
                  formik.setFieldValue("state", option ? option.value : "")
                  formik.setFieldValue("city", "")
                }}
                onBlur={() => formik.setFieldTouched("state", true)}
                className="form-control p-0 border-0 common-font"
                placeholder="State"
              />
              {formik.touched.state && formik.errors.state ? (
                <div className="error"><span>{formik.errors.state}</span></div>
              ) : null}
            </Col>
            <Col md={6}>
              <label htmlFor="city" className="new-requirement-form-label">City</label>
              <Select
                id="city"
                name="city"
                options={
                  formik.values.state
                    ? City.getCitiesOfState(
                      formik.values.country,
                      formik.values.state,
                    ).map(city => ({
                      value: city.name,
                      label: city.name,
                    }))
                    : []
                }
                value={
                  formik.values.city
                    ? { value: formik.values.city, label: formik.values.city }
                    : null
                }
                onChange={option =>
                  formik.setFieldValue("city", option ? option.value : "")
                }
                onBlur={() => formik.setFieldTouched("city", true)}
                className="form-control p-0 border-0 common-font"
                placeholder="City"
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="error"><span>{formik.errors.city}</span></div>
              ) : null}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Label for="jobDescription" className="new-requirement-form-label">Job Description</Label>
              <Input
                type="textarea"
                id="jobDescription"
                name="jobDescription"
                placeholder="Job description"
                className="form-control common-font"
                {...formik.getFieldProps("jobDescription")}
              />
              {formik.touched.jobDescription &&
                formik.errors.jobDescription ? (
                <div className="error"><span>{formik.errors.jobDescription}</span></div>
              ) : null}
            </Col>
          </Row>

        </div>
      )
    },


    {
      label: 'JOB ALLOCATION',
      content: (
        <div className="col-md-12">
          <Row className="mb-3">
            <Col md={12}>
              <Label for="requirementReceivedDateTime" className="new-requirement-form-label">
                Requirement Received Date & Time
              </Label>
              <Input
                type="datetime-local"
                id="requirementReceivedDateTime"
                name="requirementReceivedDateTime"
                style={{ color: formik.values.requirementReceivedDateTime ? "inherit" : "#808080" }}
                className="form-control common-font"
                {...formik.getFieldProps("requirementReceivedDateTime")}
                value={formik.values.requirementReceivedDateTime ? format(parseISO(formik.values.requirementReceivedDateTime), "yyyy-MM-dd'T'HH:mm") : ''}
                max={new Date().toISOString().slice(0, 16)}
              />
              {formik.touched.requirementReceivedDateTime &&
                formik.errors.requirementReceivedDateTime ? (
                <div className="error">
                  <span>{formik.errors.requirementReceivedDateTime}</span>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Label for="requirementAllocationDateTime" className="new-requirement-form-label">
                Requirement Allocation Date & Time
              </Label>
              <Input
                type="datetime-local"
                id="requirementAllocationDateTime"
                name="requirementAllocationDateTime"
                className="form-control common-font"
                {...formik.getFieldProps("requirementAllocationDateTime")}
                value={formik.values.requirementAllocationDateTime ? format(parseISO(formik.values.requirementAllocationDateTime), "yyyy-MM-dd'T'HH:mm") : ''}
                onChange={(e) => {
                  formik.handleChange(e);
                  setCreationRequirementAllocationDateTime(e.target.value);
                }}
                min={new Date().toISOString().slice(0, 16)}
                style={{ color: formik.values.requirementAllocationDateTime ? "inherit" : "#808080" }}
              />
              {formik.touched.requirementAllocationDateTime &&
                formik.errors.requirementAllocationDateTime ? (
                <div className="error">
                  <span>{formik.errors.requirementAllocationDateTime}</span>
                </div>
              ) : null}
            </Col>
          </Row>


          <Row className="mb-3">

            <Col md={12}>
              <Label for="deadline" className="new-requirement-form-label">Deadline</Label>
              <Input
                type="datetime-local"
                id="deadline"
                name="deadline"
                className="form-control common-font"
                {...formik.getFieldProps("deadline")}
                value={formik.values.deadline ? format(parseISO(formik.values.deadline), "yyyy-MM-dd'T'HH:mm") : ''}
                min={creationRequirementAllocationDateTime ? creationRequirementAllocationDateTime : new Date().toISOString().slice(0, 16)}
                onChange={(e) => {
                  formik.setFieldValue("deadline", e.target.value);
                }}
                style={{ color: formik.values.deadline ? "inherit" : "#808080" }}
              />
              {formik.touched.deadline && formik.errors.deadline ? (
                <div className="error"><span>{formik.errors.deadline}</span></div>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Label for="userAllocation" className="new-requirement-form-label">User Allocation</Label>
              <Select
                id="userAllocation"
                name="userAllocation"
                options={userOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CheckboxOption }}
                styles={customStyles}
                onChange={selectedOptions => {
                  const values = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : []
                  formik.setFieldValue("userAllocation", values)
                }}
                value={userOptions?.filter(option =>
                  formik.values.userAllocation.includes(option.value),
                )}
                placeholder="User Allocation"
                className="common-font"
              />
              {formik.touched.userAllocation &&
                formik.errors.userAllocation ? (
                <div className="error"><span>{formik.errors.userAllocation}</span></div>
              ) : null}
            </Col>
          </Row>
        </div>
      )
    }

  ];

  const nextStep = () => {
    if (currentStep < steps.length) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };


  const customHeadRender = ({ index, ...columnMeta }) => (
    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
      {columnMeta.label}
    </th>
  );
  const customBodyRender = (value) => (
    <div>
      {value.length > 15 ? (
        <Tooltip title={value} arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#660032',
                color: '#ffffff',
                '& .MuiTooltip-arrow': {
                  color: '#660032',
                }
              }
            }
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              display: 'inline-block',  // Changed from block to inline-block
              width: 'fit-content',     // Added to make width fit content
            }}>{value}</span>
        </Tooltip>
      ) : (
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100px',
            display: 'inline-block',
            width: 'fit-content',
          }}>{value}</span>
      )}
    </div>
  );
  const setCellProps = (column) => ({
    style: {
      maxWidth: column.maxWidth, // Adjust the max-width dynamically
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  });

  const setCellHeaderProps = () => ({
    style: {
      maxWidth: '50px', // Adjust the max-width as needed
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: "14px",
      color: "#660032",
    }
  });

  const columns = [
    {
      name: "Sr. No.", label: "Sr. No.",
      maxWidth: '30px',
      options: {
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps
      },
    },
    {
      name: "jobRequirementId", label: "Job ID",
      maxWidth: '50px',
      options: {
        filter: true,
        sort: true,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps
      },
    },
    {
      name: "clientName", label: "Client Name",
      maxWidth: '50px',
      options: {
        filter: true,
        sort: true,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps
      },
    },
    {
      name: "jobTitle", label: "Job Title",
      maxWidth: '70px',
      options: {
        filter: true,
        sort: true,
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps
      },
    },

    {
      name: "deadline", label: "Deadline",
      maxWidth: '50px',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              display: 'inline-block',
              width: 'fit-content',
            }}>{new Date(value).toLocaleDateString('en-GB')}</span>),
        customHeadRender,
        setCellProps,
        setCellHeaderProps
      },
    },
    {
      name: "numberOfPositions", label: "Positions",
      maxWidth: '30px',
      options: {
        customBodyRender,
        customHeadRender,
        setCellProps,
        setCellHeaderProps
      },
    },
    {
      name: "actions",
      label: "Actions",
      maxWidth: '30px',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
              <Tooltip title="View" arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#660032',
                      color: '#ffffff',
                      '& .MuiTooltip-arrow': {
                        color: '#660032',
                      }
                    }
                  }
                }}
              >
                <IconButton onClick={(e) => handleViewRequirement(e, tableMeta)} aria-label="View">
                  <Visibility style={{ color: "#660032" }} />
                </IconButton>
              </Tooltip>
              {hasPermission("UPDATE_REQUIREMENT") && (
                <Tooltip title="Edit" arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#660032',
                        color: '#ffffff',
                        '& .MuiTooltip-arrow': {
                          color: '#660032',
                        }
                      }
                    }
                  }}
                >
                  <IconButton onClick={(e) => handleEditRequirement(e, tableMeta)} aria-label="Edit">
                    <Edit style={{ color: "#660032" }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="ScanIt" arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#660032',
                      color: '#ffffff',
                      '& .MuiTooltip-arrow': {
                        color: '#660032',
                      }
                    }
                  }
                }}
              >
                <IconButton onClick={(e) => handleScanRequirement(e, tableMeta)} aria-label="Scan">
                  <i className="mdi mdi-magnify-scan" style={{ color: "#660032" }}></i>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
        customHeadRender,
        setCellProps: () => ({ style: { maxWidth: '100px' } }),
        setCellHeaderProps: () => ({ style: { maxWidth: '100px' } })
      },
    },
  ];

  const toggleDrawer = () => {
    setView("newRequirement")
    setCurrentStep(1)
    formik.resetForm();
    dispatch(getAllUsersByEntityId(entityId));
    setDrawerOpen(true);
  };



  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No jobs found.",
      },
    },
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = reversedJobRequirement.filter(requirment =>
      requirment.jobRequirementId.toLowerCase().includes(value.toLowerCase()) ||
      requirment.clientName.toLowerCase().includes(value.toLowerCase()) ||
      requirment.jobTitle.toLowerCase().includes(value.toLowerCase()) ||
      requirment.deadline.toLowerCase().includes(value.toLowerCase()) ||
      requirment.numberOfPositions.toString().includes(value)
    );
    setFilteredData(filteredData);
  };

  const handleFilter = (values) => {
    setFilterValues(values);
    const filteredData = reversedJobRequirement?.filter(requirement => {
      return (
        (values.jobTitle ? requirement.jobTitle?.toLowerCase().includes(values.jobTitle.toLowerCase()) : true) &&
          (values.deadlineStartDate && values.deadlineEndDate ? new Date(requirement.deadline) >= new Date(values.deadlineStartDate) && new Date(requirement.deadline) <= new Date(values.deadlineEndDate) : true)
      );
    });
    setFilteredData(filteredData);
  }

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "TOD", href: "/tod" },
  ];

  const handleConfirm = () => {
    setView("");
    setCurrentStep(1);
    formik.resetForm();
    setDrawerOpen(false);
    setOpen(false);
  }

  const warnMessage = "Are you sure you want to close this form?"

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <CustomWarn open={open} message={warnMessage} onConfirm={handleConfirm} onCancel={() => setOpen(false)} />
      <ProductTabs tab={3} />
      <Box className="tod-container">
        <Grid container spacing={2}>
          <Slide direction="right" in={drawerOpen} mountOnEnter unmountOnExit>
            <Grid item xs={12} md={4} sx={{ transition: 'all 0.3s ease-in-out' }}>
              <div className="job-requirement-container" style={{ overflowY: 'auto', position: 'relative', height: '100%' }}>
                <div className="form-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h5 style={{ color: "white" }}>{view === "edit" ? "EDIT JOB REQUIREMENT" : "JOB REQUIREMENT CREATION"}</h5>
                  <IconButton onClick={() => setOpen(true)} >
                    <CloseIcon className="close-icon" />
                  </IconButton>
                </div>
                <ModalStepper
                  currentStep={currentStep}
                  steps={steps}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleSubmit={() => formik.handleSubmit()}
                  handleUpdate={() => handleUpdate()}
                  view={view}
                />
              </div>
            </Grid>
          </Slide>
          <Grid item xs={12} md={drawerOpen ? 8 : 12}>
            <div>
              {loading ? (
                <PulseLoader />
              ) : (
                <>
                  <div className="user-management-title-bar">
                    <h5>JOB REQUIREMENTS</h5>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                      {filterValues && Object.keys(filterValues).map((key) => (
                        filterValues[key] && (
                          key === 'jobTitle' || key === 'deadlineStartDate' ? (
                            <Chip
                              key={key}
                              label={key === 'jobTitle' ? filterValues[key] : `${format(parseISO(filterValues.deadlineStartDate), 'dd-MM-yyyy')} - ${format(parseISO(filterValues.deadlineEndDate), 'dd-MM-yyyy')}`}
                              onDelete={() => {
                                const newFilterValues = { ...filterValues };
                                if (key === 'deadlineStartDate') {
                                  delete newFilterValues['deadlineStartDate'];
                                  delete newFilterValues['deadlineEndDate'];
                                } else {
                                  delete newFilterValues[key];
                                }
                                setFilterValues(newFilterValues);
                                handleFilter(newFilterValues);
                              }}
                              style={{ backgroundColor: 'lightgray' }}
                            />
                          ) : null
                        )
                      ))}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      <FilterListIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => setFilterModalView(true)} />
                      <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
                      {/* <Tooltip title="Add User" placement="top">
                        <Link className="user-management-title-icon" to="/super-admin">
                          <PersonAddAlt1Icon className="user-management-title-icon-add" fontSize="small" />
                        </Link> */}
                      {hasPermission("CREATE_REQUIREMENT") ? (
                        <Tooltip title="Add Job Requirement" arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#660032',
                                color: '#ffffff',
                                '& .MuiTooltip-arrow': {
                                  color: '#660032',
                                }
                              }
                            }
                          }}
                        >
                          <IconButton className={`user-management-title-icon ${drawerOpen ? 'inactive' : ''}`} onClick={toggleDrawer} disabled={drawerOpen} >
                            <AddIcon className="user-management-title-icon-add" />
                          </IconButton>
                        </Tooltip>
                      ) : null
                      }
                      {/* </Tooltip> */}
                    </div>
                  </div>
                  <MUIDataTable
                    data={filteredData ? filteredData : reversedJobRequirement}
                    columns={columns}
                    options={options}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>

        <Modal
          open={filterModalView}
          onClose={() => setFilterModalView(false)}
          className="filter-modal"
        >
          <div className="filter-modal-container">
            <div className="filter-modal-title">
              <h5>FILTER</h5>
              <CloseOutlined onClick={() => setFilterModalView(false)} />
            </div>

            <Formik
              initialValues={{ jobTitle: '' || filterValues?.jobTitle, deadlineStartDate: '' || filterValues?.deadlineStartDate, deadlineEndDate: '' || filterValues?.deadlineEndDate }}
              onSubmit={(values) => {
                handleFilter(values);
                setFilterModalView(false);
              }}
            >
              {({ isSubmitting, handleChange, handleBlur, values }) => (
                <Form>
                  <Grid container spacing={2} className="filter-modal-content">
                    <Grid item xs={12} sm={6} md={4} >
                      <div>
                        <label htmlFor="jobTitle" style={{ display: 'block', marginBottom: '5px' }}>Job Title</label>
                        <input
                          type="text"
                          id="jobTitle"
                          name="jobTitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Job Title"
                          value={values.jobTitle}
                          className="filter-modal-input"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                      <div>
                        <label htmlFor="deadlineStartDate" style={{ display: 'block', marginBottom: '5px' }}>Deadline </label>
                        <span>From : </span>
                        <input
                          type="date"
                          id="deadlineStartDate"
                          name="deadlineStartDate"
                          placeholder="Enter Deadline Start Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.deadlineStartDate}
                          className="filter-modal-input-date"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div>
                        <label htmlFor="deadlineEndDate" style={{ display: 'block', marginBottom: '5px' }}>Deadline</label>
                        <span>To : </span>
                        <input
                          type="date"
                          id="deadlineEndDate"
                          name="deadlineEndDate"
                          placeholder="Enter Deadline End Date"
                          onChange={(e) => {
                            handleChange(e);
                            if (values.deadlineStartDate && !e.target.value) {
                              e.target.setCustomValidity("End date is required if start date is filled");
                            } else {
                              e.target.setCustomValidity("");
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.deadlineEndDate}
                          className="filter-modal-input-date"
                          required={!!values.deadlineStartDate}
                          min={values.deadlineStartDate ? values.deadlineStartDate : ''}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right', }}>
                      <button type="submit" disabled={isSubmitting} className="filter-modal-button">APPLY</button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>

          </div>
        </Modal>
      </Box>

    </>
  )
}

export default Tod;
