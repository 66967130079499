import React from 'react';
import { Box, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NoCandidatesAvailable = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="70%">
      <SearchOffIcon style={{ fontSize: 60, marginBottom: 16 }} />
      <Typography variant="h6">No candidates available for this filter</Typography>
      <Typography>Please adjust your filters and try again.</Typography>
    </Box>
  );
};

export default NoCandidatesAvailable;
