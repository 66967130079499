import { call, put, takeLatest, all, fork } from "redux-saga/effects"
import axios from "axios"
import {
  addUserSuccess,
  addUserFailure,
  getUsersSuccess,
  getUsersFailure,
  getUserByIdSuccess,
  getUserByIdFailure,
  updateUserSuccess,
  updateUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  getUserByEntityIdSuccess,
  getUserByEntityIdFailure,
  getRoleByID,
  getRoleByIDSuccess,
  getRoleByIDFailure,
  getAllRolesSuccess,
  getAllRolesFailure,
  getAllFeaturesSuccess,
  getAllFeaturesFailure,
  getAllCountsSuccess,
  getAllCountsFailure,
  getUserByCreatedBySuccess,
  getUserByCreatedByFailure,
} from "./actions"
import {
  ADD_USER,
  GET_USERS,
  GET_USER_BY_ID,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_BY_ENTITY_ID,
  GET_ROLE_BY_ID,
  GET_ALL_ROLES_REQUEST,
  GET_ALL_FEATURES,
  GET_ALL_COUNTS,
  GET_USER_BY_CREATED_BY_REQUEST,
} from "./actionTypes"
import {
  createVendorUser,
  deleteVendorUserById,
  getAllCounts,
  getAllFeatures,
  getAllRoles,
  getRoleById,
  getUsersByCreatedBy,
  getVendorUserById,
  getVendorUsers,
  getVendorUsersByEntityId,
  updateVendorUser,
} from "store/api"

// Handlers for specific operations
function* handleAddUser(action) {
  try {
    const response = yield call(createVendorUser, action.payload)
    yield put(addUserSuccess(response.data))
  } catch (error) {
    yield put(addUserFailure(error.message))
  }
}

function* handleGetUsers() {
  try {
    const response = yield call(getVendorUsers)
    yield put(getUsersSuccess(response.data))
  } catch (error) {
    yield put(getUsersFailure(error.message))
  }
}

function* handleGetUserById(action) {
  try {
    const response = yield call(getVendorUserById, action.payload)
    yield put(getUserByIdSuccess(response.data))
  } catch (error) {
    yield put(getUserByIdFailure(error.message))
  }
}

function* handleGetUserByEntityId(action) {
  try {
    const response = yield call(getVendorUsersByEntityId, action.payload)
    yield put(getUserByEntityIdSuccess(response.data))
  } catch (error) {
    yield put(getUserByEntityIdFailure(error.message))
  }
}

function* handleGetUserByCreatedBy(action) {
  try {
    const response = yield call(getUsersByCreatedBy, action.payload)
    yield put(getUserByCreatedBySuccess(response.data))
  } catch (error) {
    yield put(getUserByCreatedByFailure(error.message))
  }
}

function* handleUpdateUser(action) {
  try {

    console.log("updateuser",action.payload);
    const response = yield call(updateVendorUser, action.payload)
    yield put(updateUserSuccess(response.data))
  } catch (error) {
    yield put(updateUserFailure(error.message))
  }
}

function* handleDeleteUser(action) {
  try {
    yield call(deleteVendorUserById, action.payload)
    yield put(deleteUserSuccess(action.payload))
  } catch (error) {
    yield put(deleteUserFailure(error.message))
  }
}

function* handleGetRoleById(action) {
  try {
    const response =yield call(getRoleById,action.payload)
    console.log("role",response.data);
    yield put(getRoleByIDSuccess(response.data))
  } catch (error) {
    yield put(getRoleByIDFailure(error.message))
  }
}

function* handleGetAllRoles() {
  try {
    const response = yield call(getAllRoles);
    yield put(getAllRolesSuccess(response.data))
  } catch (error) {
    yield put(getAllRolesFailure(error.message))
  }
}

function* handleGetAllFeatures() {
  try {
    const response = yield call(getAllFeatures);
    yield put(getAllFeaturesSuccess(response.data))
  } catch (error) {
    yield put(getAllFeaturesFailure(error.message))
  }
}

function* handleGetAllCounts(action) {
  try {
    const response = yield call(getAllCounts,action.payload);
    yield put(getAllCountsSuccess(response.data))
  } catch (error) {
    yield put(getAllCountsFailure(error.message))
  }
}

// Watchers
function* watchGetAllUsers() {
  yield takeLatest(GET_USERS, handleGetUsers)
}

function* watchGetAllUsersById() {
  yield takeLatest(GET_USER_BY_ID, handleGetUserById)
}

function* watchGetAllUsersByEntityId() {
  yield takeLatest(GET_USER_BY_ENTITY_ID, handleGetUserByEntityId)
}

function* watchGetAllUserByCreatedBy() {
  yield takeLatest(GET_USER_BY_CREATED_BY_REQUEST, handleGetUserByCreatedBy)
}

function* watchAddUser() {
  yield takeLatest(ADD_USER, handleAddUser)
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, handleUpdateUser)
}

function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, handleDeleteUser)
}

function* watchGetRoleById() {
  yield takeLatest(GET_ROLE_BY_ID, handleGetRoleById)
}

function* watchGetAllRoles() {
  yield takeLatest(GET_ALL_ROLES_REQUEST, handleGetAllRoles)
}

function* watchGetAllFeatures() {
  yield takeLatest(GET_ALL_FEATURES, handleGetAllFeatures)
}

function* watchGetAllCounts() {
  yield takeLatest(GET_ALL_COUNTS, handleGetAllCounts)
}

// Combine all user sagas into a root user saga
export default function* userSaga() {
  yield all([
    fork(watchGetAllUsers),
    fork(watchGetAllUsersById),
    fork(watchGetAllUsersByEntityId),
    fork(watchGetAllUserByCreatedBy),
    fork(watchAddUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
    fork(watchGetRoleById),
    fork(watchGetAllRoles),
    fork(watchGetAllFeatures),
    fork(watchGetAllCounts)
  ])
}
