// OTP Actions
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST"
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE"

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE"

// New action types for SMS OTP
export const SEND_OTP_SMS_REQUEST = "SEND_OTP_SMS_REQUEST"
export const SEND_OTP_SMS_SUCCESS = "SEND_OTP_SMS_SUCCESS"
export const SEND_OTP_SMS_FAILURE = "SEND_OTP_SMS_FAILURE"

export const VERIFY_OTP_SMS_REQUEST = "VERIFY_OTP_SMS_REQUEST"
export const VERIFY_OTP_SMS_SUCCESS = "VERIFY_OTP_SMS_SUCCESS"
export const VERIFY_OTP_SMS_FAILURE = "VERIFY_OTP_SMS_FAILURE"

// Registration Actions
export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAILURE = "REGISTER_FAILURE"

// actionTypes.js
export const RESET_REGISTRATION_STATE = "RESET_REGISTRATION_STATE"
