import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom";
import { Tabs, Tab, Box, Typography, TextField, Button,Grid, CardContent} from "@mui/material"

import { Country, State, City } from "country-state-city"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"
import user1 from "../../assets/images/users/user-1.jpg"
import headerbg from "../../assets/images/image (1).jpeg"
import { editProfile, resetProfileFlag } from "../../store/actions"
import { Menu, MenuItem, IconButton } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import VisibilityIcon from "@mui/icons-material/Visibility"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import CheckIcon from "@mui/icons-material/Check"
import CodeIcon from "@mui/icons-material/Code"
import PublicIcon from "@mui/icons-material/Public"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import PersonIcon from "@mui/icons-material/Person"
import { Switch } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import linkedInIcon from "../../assets/images/linkedin.png"
import facebookIcon from "../../assets/images/facebook.png"
import whatsappIcon from "../../assets/images/whatsapp.png"
import instagramIcon from "../../assets/images/instagram.png"
import youtubeIcon from "../../assets/images/youtube.png"
import "./userProfile.scss"
import { useNavigate } from "react-router-dom"
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";

const initialAccounts = [
  {
    name: "LinkedIn",
    description: "Events and Research",
    iconUrl: linkedInIcon,
    connected: true,
  },
  {
    name: "Facebook",
    description: "Events and Research",
    iconUrl: facebookIcon,
    connected: false,
  },
  {
    name: "Whatsapp",
    description: "Events and Research",
    iconUrl: whatsappIcon,
    connected: false,
  },
  {
    name: "Instagram",
    description: "Events and Research",
    iconUrl: instagramIcon,
    connected: false,
  },
  {
    name: "Youtube",
    description: "Events and Research",
    iconUrl: youtubeIcon,
    connected: false,
  },
]

const UserProfile = () => {
  const { userDetails } = useSelector(state => state.Login.user);
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [cities, setCities] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [accounts, setAccounts] = useState(initialAccounts)
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate=useNavigate();

  const handleToggle = index => {
    const newAccounts = [...accounts]
    newAccounts[index].connected = !newAccounts[index].connected
    setAccounts(newAccounts)
    console.log(
      `Toggled ${newAccounts[index].name} to ${newAccounts[index].connected}`,
    )
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSubmit = event => {
    event.preventDefault()
  }

  const handleCountryChange = country => {
    setSelectedCountry(country)
    setSelectedState(null)
    setCities([])
  }
  const handleStateChange = state => {
    setSelectedState(state)
    const stateCities = City.getCitiesOfState(selectedCountry, state)
    setCities(stateCities)
  }

  

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }
  const editProfile=()=>{
    userDetails.source="Profile"
    navigate("/editProfile",{state: userDetails})
  }
  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "User Profile", href: "/profile" },
  ];

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Box className="user-profile">
            <Box className="profile-header">
              <img src={headerbg} alt="Header" className="header-image" />
              <img src={userDetails.profilePicture} alt="Profile" className="profile-image" />
            </Box>
            <Box className="profile-summary">
              <span>{userDetails.name}</span>
              <span>{userDetails.address}</span>
              <span>{userDetails.roleAndFeatures.name}</span>
            </Box>
          </Box>
        </Grid>
        <Grid>
        <Box className="profile-page-tabs">
          <Tabs value={selectedTab} onChange={handleTabChange} TabIndicatorProps={{ className: 'profile-page-indicator' }} className="profile-page-tabs">
            <Tab label="Updates" className="profile-page-tab" />
            <Tab label="Details" className="profile-page-tab" />
            <Tab label="Gallery" className="profile-page-tab" />
            <Tab label="Media" className="profile-page-tab" />
            <Tab label="Connections" className="profile-page-tab" />
          </Tabs>
        </Box>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={4} direction="column">
            <Grid item>
              <div className="user-profile-buttons">
                <Button variant="contained" className="user-profile-info-btn">Update Bio</Button>
                <Button variant="contained" className="user-profile-info-btn" onClick={editProfile}>Modify Info</Button>
                <Button variant="contained" className="user-profile-info-btn">Add Interests</Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ backgroundColor: '#fff',textAlign: "center"}}>
                <Typography>Gallery</Typography>
              </div>
            </Grid>  
          </Grid>

          <Grid item xs={4}>
            <div style={{ backgroundColor: '#fff'}}>
            <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Recent Activity Feed</h4>
                        <ol className="activity-feed mb-0">
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 25</span>
                                    <span className="activity-text">Responded to need “Volunteer Activities”</span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 24</span>
                                    <span className="activity-text">Added an interest “Volunteer Activities”</span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 23</span>
                                    <span className="activity-text">Joined the group “Boardsmanship Forum”</span>
                                </div>
                            </li>
                            <li className="feed-item">
                                <div className="feed-item-list">
                                    <span className="date">Jun 21</span>
                                    <span className="activity-text">Responded to need “In-Kind Opportunity”</span>
                                </div>
                            </li>
                        </ol>

                        <div className="text-center">
                            <Link to="#" className="btn btn-sm btn-primary">Load More</Link>
                        </div>
                    </CardBody>
                </Card>
            </div>
            </Grid>
          <Grid item xs={4}>
            <div style={{ backgroundColor: '#fff'}}>
           
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default withRouter(UserProfile);
