import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import {
  CREATE_SEARCH_REQUEST,
  DELETE_PROFILE_REQUEST,
  FETCH_CANDIDATES_REQUEST,
  FETCH_SEARCHES_REQUEST,
  FILTER_CANDIDATES_REQUEST,
  UPLOAD_FILE_REQUEST,
} from './actionTypes';
import {
  fetchScanCandidatesSuccess,
  fetchScanCandidatesFailure,
  uploadFileSuccess,
  uploadFileFailure,
  createSearchFailure,
  createSearchSuccess,
  updateCollectedInfo,
  fetchSearchesSuccess,
  fetchSearchesFailure,
  filterCandidatesSuccess,
  filterCandidatesFailure,
  deleteProfileSuccess,
  deleteProfileFailure
} from './actions';
import { createSearchApi, fetchCandidates, getAllSearchRequirement, insertWorkFlow, parseAIResponse, saveParsedData, scanAIResponse, uploadProfile } from 'store/api';


function* fetchFilteredCandidates(action) {
  try {
    const response = yield call(fetchCandidates,
      {
        skills: action.payload.mandatorySkills,
        location: action.payload.location,
        experienceRange: action.payload.experience, 
      },
    );
    if (response && response.data) {
      yield put(filterCandidatesSuccess(response.data.data));
    }
  } catch (error) {
    yield put(filterCandidatesFailure(error.response.data.message));
  }
}


// function* uploadFileSaga(action) {
//   console.log("action=====>", action.payload)
//   const { files, jobRequirementInfo } = action.payload;

//   // const id = localStorage.getItem("userId");
//   const formDataArray = [];
//   const collectedInfo = [];
//   let uploadProgress = 0;

//   for (let i = 0; i < files.length; i++) {
//     const formData = new FormData();
//     formData.append("file", files[i]);
//     formData.append("jobDescription", jobRequirementInfo.jobDescription);
//     formData.append("jobTitle", jobRequirementInfo.jobTitle);
//     formData.append("experience", jobRequirementInfo.experience);
//     formData.append("skillsets", jobRequirementInfo.mandatorySkills);
//     // formData.append("city", userInfo.cityId);  
//     formDataArray.push(formData);
//   }
//   if (files.every(file => file instanceof File)) {
//     try {
//       for (let i = 0; i < formDataArray.length; i++) {
//         const formData = formDataArray[i];
//         let response;

//         for (let retry = 0; retry < 2; retry++) {
//           try {
//             response = yield call(uploadProfile, formData);
//             break;
//           } catch (uploadError) {
//             console.error("Upload failed for file:", files[i].name, uploadError);
//             if (retry === 1) {
//               throw uploadError;
//             }
//           }
//         }

//         if (response && response.data) {
//           const { fileUrl, profileText } = response.data;
//           console.log("fileURL-->", fileUrl);
//           yield put(updateCollectedInfo({ fileUrl }));


//           formData.append("fileUrl", fileUrl);
//           formData.append("textData", profileText);


//           const [scanResponse, parseResponse] = yield all([
//             call(scanAIResponse, formData),
//             call(parseAIResponse, formData)
//           ]);


//           const { content } = scanResponse.data.message;
//           const parseResponseContent = (content) => {
//             const data = JSON.parse(content);
//             console.log("data", data)
//             return {
//               fitmentPercentage: data.fitmentPercentage,
//               analysis: {
//                 questionnaire: data.Questionnaire,
//                 summary: data.Summary,
//               },
//               ...data.personal_information
//             };
//           };
//           const scannedData = parseResponseContent(content);


//           const parseData = parseResponse.data.message;


//           console.log("scan sagas", scannedData, parseData)

//           if(parseData){
//             yield call(saveParsedData, {
//               content: parseData.content,
//               fileUrl,
//             });

//           }

//           if (scannedData) {
//             try {
//               collectedInfo.push({ jobTitle: jobRequirementInfo.jobTitle, experience: jobRequirementInfo.experience, skills: jobRequirementInfo.mandatorySkills, fitmentPercentage: scannedData.fitmentPercentage, analysis: scannedData.analysis, email: scannedData.email, name: scannedData.name, phone: scannedData.phone })
//             } catch (saveError) {
//               console.error("Failed to save parsed data for file:", files[i].name, saveError);
//             }
//           }
//           uploadProgress = files.length > 0 ? ((i + 1) * 100) / files.length : 0;
//         }
//         console.log("collectedInfo", collectedInfo)
//         yield put(uploadFileSuccess(collectedInfo));
//       }

//     } catch (error) {
//       yield put(uploadFileFailure(error.message));
//     }
//   } else {
//     try {
//       for (let i = 0; i < formDataArray.length; i++) {
//         console.log("shdfhdsf=======<", formDataArray[i])
//         const formData = formDataArray[i];


//           formData.append("fileUrl", "");
//           formData.append("textData", `${files[i].experience} ${files[i].professional_experience} ${files[i].skills} ${files[i].name} ${files[i].email} ${files[i].contact} ${files[i].location}`);


//           let scanResponse;
//           try {
//             scanResponse = yield call(scanAIResponse, formData);
//           } catch (scanError) {
//             console.error("AI scan failed for file:", files[i].name, scanError);
//             continue;
//           }
//           const { content } = scanResponse.data.message;
//           const parseResponseContent = (content) => {
//             const data = JSON.parse(content);
//             return {
//               fitmentPercentage: data.fitmentPercentage,
//               analysis: {
//                 questionnaire: data.Questionnaire,
//                 summary: data.Summary,
//               },
//               ...data.personal_information
//             };
//           };
//           const scannedData = parseResponseContent(content);

//           if (scannedData) {
//             try {
//               collectedInfo.push({ profileId: "", jobTitle: jobRequirementInfo.jobTitle, experience: jobRequirementInfo.experience, skills: jobRequirementInfo.mandatorySkills, fitmentPercentage: scannedData.fitmentPercentage, analysis: scannedData.analysis, email: scannedData.email, name: scannedData.name, phone: scannedData.phone })
//             } catch (saveError) {
//               console.error("Failed to save parsed data for file:", files[i].name, saveError);
//             }
//           }
//           uploadProgress = files.length > 0 ? ((i + 1) * 100) / files.length : 0;

//         yield put(uploadFileSuccess(collectedInfo));
//       }

//     } catch (error) {
//       yield put(uploadFileFailure(error.message));
//     }
//   }
// }

function* uploadFileSaga(action) {
  const { files, jobRequirementInfo, parsedBy } = action.payload;
  const formDataArray = [];
  let uploadProgress = 0;
  // Prepare form data for each file
  for (let i = 0; i < files.length; i++) {
    const formData = new FormData();
    formData.append("file", files[i]);
    formData.append("jobDescription", jobRequirementInfo.jobDescription);
    formData.append("jobTitle", jobRequirementInfo.jobTitle);
    formData.append("experience", jobRequirementInfo.experience); 
    formData.append("skillsets", jobRequirementInfo.mandatorySkills);
    formDataArray.push(formData);
  }
  console.log("formDataArray", formDataArray)
  if (files.every(file => file instanceof File)) {
    for (let i = 0; i < formDataArray.length; i++) {
      const collectedInfo = [];
      const failedProfiles = [];
      const formData = formDataArray[i];
      let response;
      try {
        response = yield call(uploadProfile, formData);
      } catch (uploadError) {
        console.error("Upload failed for file:", files[i].name, uploadError);
        failedProfiles.push({ name: files[i].name, file: files[i] });
      }

      if (response && response.data) {
        const { fileUrl, profileText } = response.data;
        console.log("fileURL-->", fileUrl);
        yield put(updateCollectedInfo({ fileUrl }));

        formData.append("fileUrl", fileUrl);
        formData.append("textData", profileText);

        let scanResponse, parseResponse;

        // Parallel API calls for scanning and parsing
        try {
          [scanResponse, parseResponse] = yield all([
            call(scanAIResponse, formData),
            call(parseAIResponse, formData)
          ]);
        } catch (error) {
          console.error("API call failed for file:", files[i].name, error);
        }

        let scannedData, parseData;

        // Process scan response
        if (scanResponse && scanResponse.data) {
          const { content } = scanResponse.data.message;
          const sanitizedContent = sanitizeJSON(content);

          if (sanitizedContent) {
            try {
              scannedData = parseResponseContent(sanitizedContent);
              console.log("Parsed Data:", scannedData);
            } catch (error) {
              yield put(uploadFileFailure({ failedProfiles }));
              console.error("Failed to parse even after sanitization:", error);
            }
          }
        }

        // Collect scanned data if available
        console.log("outerScannedData", scannedData)
        if (scannedData) {
          let copyCollectedInfo = {
            jobTitle: jobRequirementInfo.jobTitle,
            experience: jobRequirementInfo.experience,
            skills: jobRequirementInfo.mandatorySkills,
            fitmentPercentage: scannedData.fitmentPercentage,
            status: "0%",
            reviewDetails: {},
            analysis: scannedData.analysis,
            email: scannedData.email,
            name: scannedData.name,
            phone: scannedData.phone,
            scannedBy: parsedBy 
          }
          console.log("innerColectedInfo", copyCollectedInfo)
          collectedInfo.push(copyCollectedInfo);
          yield put(uploadFileSuccess({ collectedInfo, failedProfiles }));
        }

        if (parseResponse && parseResponse.data) {
          parseData = parseResponse.data.message;
        }

        console.log("scan sagas", scannedData, parseData);

        // Save parsed data if available
        if (parseData) {

          // get response
          try {
            // yield call(saveParsedData, {
            //   content: parseData.content,
            //   fileUrl,
            //   parsedBy
            // });

            yield all([
              call(saveParsedData, {
                content: parseData.content,
                fileUrl,
                parsedBy
              }),
              // call(insertWorkFlow, collectedInfo)
            ]);

            // if save the profile data in candidate collection then only call the workflow API
          } catch (saveError) {
            console.error("Failed to save parsed data for file:", files[i].name, saveError);
          }
        }

        uploadProgress = files.length > 0 ? ((i + 1) * 100) / files.length : 0;
      }else{
        yield put(uploadFileFailure({failedProfiles}));
      }
    }
  } else {
    try {
      for (let i = 0; i < formDataArray.length; i++) {
        const collectedInfo = [];
        const failedProfiles = [];
        const formData = formDataArray[i];
        formData.append("fileUrl", "");
        formData.append("jobDescription", jobRequirementInfo?.jobDescription);
        formData.append("experience", jobRequirementInfo?.experience);
        formData.append("skillsets", jobRequirementInfo?.mandatorySkills);
        formData.append("textData", `${files[i].total_experience} ${files[i].professional_experience} ${files[i].inferred_skills} ${files[i].personal_information.name} ${files[i].personal_information.email} ${files[i].personal_information.phone} ${files[i].personal_information.city}`);
        let scanResponse;
        try {
          scanResponse = yield call(scanAIResponse, formData);
        } catch (scanError) {
          console.error("AI scan failed for file:", files[i].name, scanError);
          continue;
        }
        const { content } = scanResponse.data.message;
        const parseResponseContent = (content) => {
          const data = JSON.parse(content);
          return {
            fitmentPercentage: data.fitmentPercentage,
            analysis: {
              questionnaire: data.Questionnaire,
              summary: data.Summary,
            },
            ...data.personal_information
          };
        };
        const scannedData = parseResponseContent(content);
        if (scannedData) {
          try {
            collectedInfo.push({ 
              profileId: "", 
              jobTitle: jobRequirementInfo.jobTitle, 
              experience: jobRequirementInfo.experience, 
              skills: jobRequirementInfo.mandatorySkills, 
              fitmentPercentage: scannedData.fitmentPercentage, 
              analysis: scannedData.analysis, 
              email: scannedData.email, 
              name: scannedData.name, 
              phone: scannedData.phone,
              status: "0%",
              reviewDetails: {}, 
              scannedBy: parsedBy 
            })
          } catch (saveError) {
            console.error("Failed to save parsed data for file:", files[i].name, saveError);
          }
        }
        uploadProgress = files.length > 0 ? ((i + 1) * 100) / files.length : 0;

        yield put(uploadFileSuccess({collectedInfo}));
      }

    } catch (error) {
      yield put(uploadFileFailure(error.message));
    }
  }
}

function sanitizeJSON(jsonString) {
  try {
    // Remove newlines, tabs, and multiple spaces
    jsonString = jsonString.replace(/(\r\n|\n|\r|\t|\s\s+)/gm, "");

    // Fix unquoted keys
    jsonString = jsonString.replace(/([{,])(\s*)([A-Za-z0-9_\-]+)(\s*):/g, '$1"$3":');

    // Fix trailing commas
    jsonString = jsonString.replace(/,(?=\s*[}\]])/g, '');

    // Balance brackets
    let openBrackets = (jsonString.match(/{/g) || []).length;
    let closeBrackets = (jsonString.match(/}/g) || []).length;
    let openSquareBrackets = (jsonString.match(/\[/g) || []).length;
    let closeSquareBrackets = (jsonString.match(/]/g) || []).length;

    while (openBrackets > closeBrackets) {
      jsonString += '}';
      closeBrackets++;
    }
    while (openSquareBrackets > closeSquareBrackets) {
      jsonString += ']';
      closeSquareBrackets++;
    }

    return jsonString;
  } catch (e) {
    console.error("Error sanitizing JSON:", e);
    return jsonString; // Return original if sanitization fails
  }
}



function parseResponseContent(content) {

  try {
    console.log("inner content", content)
    const data = JSON.parse(content);
    console.log("data inner", data)
    
    return {
      fitmentPercentage: data.fitmentPercentage,
      analysis: {
        questionnaire: data.Questionnaire,
        summary: data.Summary,
      },
      ...data.personal_information
    };
  } catch (error) {
    console.error("Failed to parse scan content:", error);
    return null;
  }
}

function* createSearchSaga(action) {
  try {
    const response = yield call(createSearchApi, action.payload);
    yield put(createSearchSuccess(response.data));
  } catch (error) {
    yield put(createSearchFailure(error.message));
  }
}

function* fetchSearchesSaga(action) {
  try {
    const {id,entityId,role } = action.payload;
    const response = yield call(getAllSearchRequirement,{id,entityId,role});
    yield put(fetchSearchesSuccess(response.data.data));  // Assume response.data.data contains the searches
  } catch (error) {
    yield put(fetchSearchesFailure(error.message));
  }
}

function* deleteProfileSaga(action) {
  try {
    yield put(deleteProfileSuccess(action.payload));
  } catch (error) {
    yield put(deleteProfileFailure(error.message));
  }
}

function* watchFetchSearches() {
  yield takeLatest(FETCH_SEARCHES_REQUEST, fetchSearchesSaga);
}

function* watchCreateSearch() {
  yield takeLatest(CREATE_SEARCH_REQUEST, createSearchSaga);
}

function* filterCandidatesSaga() {
  yield takeLatest(FILTER_CANDIDATES_REQUEST, fetchFilteredCandidates);
}

function* UploadFileSaga() {
  yield takeLatest(UPLOAD_FILE_REQUEST, uploadFileSaga);
}

function* DeleteProfileSaga() {
  yield takeLatest(DELETE_PROFILE_REQUEST, deleteProfileSaga);
}

export default function* scanSaga() {
  yield all([fork(filterCandidatesSaga), fork(UploadFileSaga), fork(watchCreateSearch), fork(watchFetchSearches),fork(DeleteProfileSaga)]);
}
