import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CustomWarn = ({ open, message, onConfirm, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="warning-dialog-title"
      aria-describedby="warning-dialog-description"
      PaperProps={{
        style: {
            marginTop: '-20%', // Adjust this value to move the dialog up slightly
        },
      }}
    >
      <DialogTitle id="warning-dialog-title">Warning</DialogTitle>
      <DialogContent>
        <Typography variant="body1" id="warning-dialog-description">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{ color: '#660032' }}>
          No
        </Button>
        <Button onClick={onConfirm} style={{ color: '#fff', backgroundColor: '#660032' }} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomWarn;
