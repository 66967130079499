import { CREATE_ROLE_FAILURE, CREATE_ROLE_REQUEST, CREATE_ROLE_SUCCESS, FETCH_ROLES_FAILURE, FETCH_ROLES_REQUEST, FETCH_ROLES_SUCCESS } from "./actionTypes";


export const fetchRolesStart = (entityId) => ({
    type: FETCH_ROLES_REQUEST,
    payload: { entityId }
});

export const fetchRolesSuccess = (roles) => ({
    type: FETCH_ROLES_SUCCESS,
    payload: roles
});

export const fetchRolesFailure = (error) => ({
    type: FETCH_ROLES_FAILURE,
    payload: error
});


export const createRoleRequest = (roleData) => ({
    type: CREATE_ROLE_REQUEST,
    payload: roleData,
});

export const createRoleSuccess = (role) => ({
    type: CREATE_ROLE_SUCCESS,
    payload: role,
});

export const createRoleFailure = (error) => ({
    type: CREATE_ROLE_FAILURE,
    payload: error,
});

