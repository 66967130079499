import React from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, IconButton, Typography, Grid } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const CertificationSchema = Yup.object().shape({
  certifications: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Certification name is required'),
      authority: Yup.string().required('Authority is required'),
      startDate: Yup.date().required('Start date is required'),
      endDate: Yup.date().required('End date is required'),
      file: Yup.mixed().required('A PDF file is required')
    })
  )
});

const CertificationForm = ({ onSubmit, info }) => {
  const initialValues = {
    certifications: info?.certification?.map(cert => ({
      name: cert.name || "NA",
      authority: cert.authority || "NA",
      startDate: cert.startDate || "NA",
      endDate: cert.endDate || "NA",
      file: cert.file || null
    }))
      || [{ name: '', authority: '', startDate: '', endDate: '', file: null }]
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CertificationSchema}
      onSubmit={(values) => {
        console.log(values);
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box mb={4}>
            <FieldArray name="certifications">
              {({ push, remove }) => (
                <div>
                  {values.certifications.map((certification, index) => (
                    <Box key={index} mb={2} p={2} border={1} borderRadius={2} borderColor="grey.400" position="relative">
                      <Field
                        name={`certifications[${index}].name`}
                        as={TextField}
                        label="Certification Name"
                        fullWidth
                        margin="normal"
                        value={certification.name}
                        onChange={e => setFieldValue(`certifications[${index}].name`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                          },
                        }}
                      />
                      <Field
                        name={`certifications[${index}].authority`}
                        as={TextField}
                        label="Authority"
                        fullWidth
                        margin="normal"
                        value={certification.authority}
                        onChange={e => setFieldValue(`certifications[${index}].authority`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                          },
                        }}
                      />
                      <Field
                        name={`certifications[${index}].startDate`}
                        as={TextField}
                        type="date"
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                        value={certification.startDate}
                        onChange={e => setFieldValue(`certifications[${index}].startDate`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                          },
                        }}
                      />
                      <Field
                        name={`certifications[${index}].endDate`}
                        as={TextField}
                        type="date"
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                        value={certification.endDate}
                        onChange={e => setFieldValue(`certifications[${index}].endDate`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px',
                          },
                        }}
                      />
                      <input
                        id={`file${index}`}
                        name={`certifications[${index}].file`}
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => {
                          setFieldValue(`certifications[${index}].file`, event.currentTarget.files[0]);
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => remove(index)}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <Button
                    type="button"
                    startIcon={<AddIcon />}
                    style={{color:"#660032"}}
                    onClick={() => push({ name: '', authority: '', startDate: '', endDate: '', file: null })}
                  >
                    Add Certification
                  </Button>
                  <Button type="submit" style={{background:"#660032",color:"white"}}>
                    Save
                  </Button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CertificationForm;
