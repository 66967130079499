
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, IconButton,Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import './CandidateDetailsDialog.css';

const getMostRecentJobTitle = (professionalExperience) => {
  if (!professionalExperience || professionalExperience.length === 0) {
    return null;
  }

  const sortedExperience = professionalExperience.sort((a, b) => {
    const endDateA = a.employment_dates.includes("Present")
      ? new Date()
      : new Date(a.employment_dates.split(" - ")[1] || a.employment_dates.split(" to ")[1]);
    const endDateB = b.employment_dates.includes("Present")
      ? new Date()
      : new Date(b.employment_dates.split(" - ")[1] || b.employment_dates.split(" to ")[1]);
    return endDateB - endDateA;
  });

  return sortedExperience[0].job_title;
};

const CandidateDetailsDialog = ({ open, onClose, candidate }) => {
  if (!candidate) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className='cv-access-dialog'>
      <DialogTitle className="dialog-title" sx={{padding:"10px 15px" }}>
        <h2>PROFILE INFO</h2>
        <IconButton 
          aria-label="close" 
          onClick={onClose}
          className='civix-dialog-close-btn'
          sx={{ position: 'absolute', right: 8, top: 6, color:"white" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box className="resume-dialog-container">
        <DialogContent>
          <div className="resume-profile-header">
            <span>Profile ID : {candidate._id}</span>
            <span>Parsed on : {new Date(candidate.parsedDate).toLocaleDateString()}</span>
          </div>
          <div className='resume-data'>
            <Grid item xs={12} md={6} className="resume-profile-left-column">
              <h5 className='resume-data-underline'>{candidate.personal_information.name}</h5>
              <div className="personal-item">
                {getMostRecentJobTitle(candidate.professional_experience) && (
                  <div><strong>Designation : </strong>{getMostRecentJobTitle(candidate.professional_experience)}</div>
                )}
                {candidate.total_experience && (
                  <div><strong>Total Experience : </strong><span>{candidate.total_experience}</span></div>
                )}
                {candidate.personal_information.email && (
                  <div><strong>Email : </strong> <span>{candidate.personal_information.email}</span></div>
                )}
                {candidate.personal_information.phone && (
                  <div><strong>Contact : </strong><span> {candidate.personal_information.phone}</span></div>
                )}
                {candidate.personal_information.city && (
                  <div><strong>Location : </strong> <span>{candidate.personal_information.city}</span></div>
                )}
              </div>
              <div className="resume-education-details">
                <h5 className='resume-data-underline'>Educational Details :</h5>
                {candidate.education.map((data, index) => (             
                    <li key={index} className="education-item">
                      <strong>{data.degree}</strong>
                      {data.field_of_study && <div><strong>Field : </strong>{data.field_of_study}</div>}
                      {data.graduation_date && <div><strong>Date : </strong>{data.graduation_date}</div>}
                    </li>
                ))}
              </div>
              {candidate.inferred_skills.length > 0 && (
                <div className="resume-skills">
                  <h5 className='resume-data-underline'>Skills : </h5>
                  <div className="resume-skills-list">
                    {candidate.inferred_skills.map((skill, index) => (
                      <div key={index} className="resume-skill-item">{skill}</div>
                    ))}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6} className="resume-profile-right-column">
              <h5 className='resume-data-underline'>Experience:</h5>
              {candidate.professional_experience.map((data, index) => (
                  <li key={index} className="experience-item">
                    <div><strong>Company : </strong> {data.company_name}</div>
                    <div><strong>Designation : </strong> {data.job_title}</div>
                    <div><strong>Period : </strong> {data.employment_dates}</div>
                    {data.experience_description && (
                      <div><strong>Description : </strong> {data.experience_description}</div>
                      
                    )}
                      <Divider
                        style={{
                          borderColor: '#660032',
                          // marginTop: '5px'
                        }}
                      />
                  </li>
              ))}
              
            </Grid>
          </div>
        </DialogContent>
      </Box>
      <DialogActions className="dialog-footer">
          <Button className="dialog-footer-button" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateDetailsDialog;