import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import { Row, Col } from "reactstrap";
// import Miniwidget from "./Miniwidget";
import ProfilesSources from "./ProfilesSources";
import GlobalBusiness from "./GlobalBusiness";
import MonthlyEarnings from "./MonthlyEarnings";
import "./Inbox Cards/FeatureToggle.css";
import "./dashboard.css";
import { getAllCounts, getVendorByIdRequest, setBreadcrumbItems } from "../../store/actions";
import "./UserDashboard.css"
import { initialCustomers, breadcrumbItems } from "./constants"; // Import constants
import { useSelector } from "react-redux";
import { Grid, Card, CardContent, Typography, Box,Paper,Divider,Button,CircularProgress   } from "@mui/material"
import Icon from '@mdi/react';
import GaugeChart from 'react-gauge-chart';
import Miniwidget from "./Miniwidget";
import DownloadIcon from '@mui/icons-material/Download';


import {
  mdiMagnifyScan,
  mdiTextBox,
  mdiFileAccount,
  mdiBriefcaseAccount,
  mdiSearchWeb,
  mdiChartLine
} from '@mdi/js';
import {
  mdiAccount,
  mdiBriefcaseCheck,
  mdiAccountGroup,
  mdiBriefcaseOutline,
  mdiChartBar,
} from '@mdi/js';

import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import PeopleIcon from "@mui/icons-material/People"
import WorkIcon from "@mui/icons-material/Work"
import DoneIcon from "@mui/icons-material/Done"
import AssessmentIcon from "@mui/icons-material/Assessment"
import TimelineIcon from "@mui/icons-material/Timeline"
import { PieChart, Pie, Cell } from "recharts"
import { useDispatch } from "react-redux";
import CustomModal from "components/CustomComponents/CustomModal";
import VendorForm from "pages/Forms/VendorForm";
import PulseLoader from "./../PulseLoader/PulseLoader"
import { Diversity1Outlined, Padding } from "@mui/icons-material";

const Dashboard = (props) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedUserData = useSelector(state => state.Login?.user?.userDetails || null);
  const entityId = loggedUserData?.entityId || null;
  const [reports, setReports] = useState([]);
  const [recruitersContent,setRecruitersContent]=useState([]);
  const userRole=loggedUserData?.roleAndFeatures.name;
  const featuresIndex = userRole === "Lead Recruiter" ? 2 : 1;
  const homePermissions=loggedUserData?.roleAndFeatures.features[0].permissions;
  const subFeatures = loggedUserData?.roleAndFeatures?.features[featuresIndex]?.subFeatures || [];
  const recruitersPermissions=loggedUserData?.roleAndFeatures.features[0].permissions;
  const userId=loggedUserData?.id;
  
  let {allCounts, loading} = useSelector(state=>state.usersReducer);
  const [open, setOpen] = useState(false);  
  // const [loading, setLoading] = useState(true);

  // const { name, DOJ, contactNumber, email, profilePicture } = loggedUserData || {};

// console.log(loggedUserData)
  useEffect(()=>{
    if(entityId){
      dispatch(getAllCounts({id:userId,entityId}))
    }
  },[entityId])

  // useEffect(() => {
  //   if (allCounts || !entityId) {
  //     setLoading(false);
  //   }
  // }, [allCounts, entityId]);

  // console.log("Atharv",subFeatures)

  useEffect(() => {

    const timer = setTimeout(() => {
      {
        userRole==="Guest User" && setOpen(true);
      }
      
    }, 10000); 

    return () => clearTimeout(timer);
  }, []);

  const handleCardClick = (title) => {
    switch (title.toLowerCase()) {
      case 'sqanit':
        navigate('/scan-it');
        break;
      case 'parseit':
        navigate('/parse-it');
        break;
      case 'civix':
        navigate('/civix');
        break;
      case 'tod':
        navigate('/tod');
        break;
      case 'webit':
        navigate('/web-it');
        break;
      case 'sqaleup':
        navigate('/sqale-up');
        break;
      case 'mocktest':
        navigate('/mocktest');
        break;
      default:
        console.warn('No matching route found for:', title);
    }
  };
  
 

  const allReports = [
    {
      title: "CLIENT LIST",
      name:"VIEW_CLIENT_LIST",
      total: "0",
      icon: <Icon path={mdiAccountGroup} size={1} />,
      message:"Total Clients Till Date",
    },
    {
      title: "TOTAL REQUIREMENTS",
      name:"VIEW_REQUIREMENTS_COUNT",
      total: allCounts?.totalRequirements,
      icon: <Icon path={mdiBriefcaseCheck} size={1} />,
      onClick: handleCardClick,
      message:"Total Active Requirement Till Date",
    },
    {
      title: "REVENUE",
      name:"VIEW_REVENUE",
      total: "0",
      icon: <Icon path={mdiChartLine} size={1} />,
      message:"Total Revenue Till Date",
    },
    {
      title: "CLOSURES",
      name:"VIEW_ALL_CLOSURES",
      total: "0",
      icon: <Icon path={mdiBriefcaseOutline} size={1} />,
      message:"Total Closures Till Date",
    },
    {
      title: "PEREFORMANCE MONTH",
      name:"VIEW_PERFORMANCE",
      total: "0",
      icon: <Icon path={mdiChartBar} size={1} />,
      message:"Performance Of The Month List",
    },
    {
      title: "TOTAL USERS",
      
      name: "VIEW_TOTAL_USERS",
      total: "0",
      icon: <Icon path={mdiAccount} size={1} />,
      message:"Total Active Requirement Till Date",
    },
    ];

    useEffect(() => {
      if (!homePermissions || !Array.isArray(homePermissions)) {
        console.error('homePermissions is not properly defined or is not an array.');
        return;
      }
    
      const newReports = homePermissions.map((item) => {
        const match = allReports.find((report) => report.name === item.name);
        console.log("match", match)
        if (match) {
          // Providing default values to avoid undefined
          return {
            ...match,
            total: match.total ?? 'N/A',
            average: match.average ?? 'N/A',
          };
        }
        return null;
      }).filter((report) => report !== null);
    
      setReports(newReports);
    }, [homePermissions,allCounts]);
    

    useEffect(()=>{
      const  filteredData=[];
      const recruiterData = homePermissions?.map(item => {
        const match = data?.map(tab =>{ 
          if(tab.name === item.name)
          {
            filteredData.push(tab)
          }}) // or filter this out later
      })
      
      setRecruitersContent(filteredData);
    },[homePermissions,allCounts])



  

    // useEffect(()=>{
    //   SetHomePermissions();
    // },[loggedUserData]);

    // const SetHomePermissions=()=>{
    //   const {features}=loggedUserData?.roleAndFeatures;
    //   const homePermissions=features.find(feature=>feature.name==="HOME");
        // console.log("per",homePermissions.permissions);
    // }


  // useEffect(() => {
  //   props.setBreadcrumbItems("HOME", breadcrumbItems);
  // }, []);

// Helper function to determine the status based on permissions
// Helper function to determine the status based on permissions
// Helper function to determine the status based on permissions
const getStatus = (featureName) => {
  // Ensure subFeatures is defined and is an array
  if (!Array.isArray(subFeatures)) {
    return "INACTIVE"; // Default to INACTIVE if subFeatures is not available
  }
  // console.log(featureName)
  // Find the matching feature by name
  const isFeatureActive = subFeatures.some(subFeature => subFeature.feature?.name === featureName);
  // console.log("Active Feature",isFeatureActive)
  // Return "ACTIVE" if the feature is found, otherwise "INACTIVE"
  return isFeatureActive ? "ACTIVE" : "INACTIVE";
};


  const data = [
    {
      title: "SQANIT",
      name:"VIEW_PRODUCT_DETAILS",
      count: `${allCounts?.scannedCount}`,
      content: `YOU HAVE ${allCounts?.scannedCount} CV SCANNED`,
      icon: <Icon path={mdiMagnifyScan} size={1} />,
      status: getStatus("SQANIT"),
      // backgroundColor: "#e65a97", 
      message: "NOTE: CONTACT YOUR ADMIN TO ACTIVATE OR DEACTIVATE",
      
    },
    {
      title: "PARSEIT",
      name:"VIEW_PRODUCT_DETAILS",
      count: ` ${allCounts?.parsedCount}`,
      content: "YOU HAVE UNLIMITED CV PARSING ACCESS",
      icon: <Icon path={mdiTextBox} size={1} />,
      status: getStatus("PARSEIT"),
      // backgroundColor: "#46ad68",
     message: "NOTE: CONTACT YOUR ADMIN TO ACTIVATE OR DEACTIVATE",
    },
    {
      title: "CIVIX",
      name:"VIEW_PRODUCT_DETAILS",
      count: `${allCounts?.civixCount}`,
      content: `YOU HAVE BEEN ${allCounts?.civixCount} DATA ACCESS`,
      icon: <Icon path={mdiFileAccount} size={1} />,
      status: getStatus("CIVIX"),
      // backgroundColor: " #cf2345",
     message: "NOTE: CONTACT YOUR ADMIN TO ACTIVATE OR DEACTIVATE",
    },
    {
      title: "TOD",
      name:"VIEW_PRODUCT_DETAILS",
      count: userRole==="Recruiter" ? allCounts?.userRequirementCount : allCounts?.createdRequirementCount,
      content: "REQUIREMENTS AND JOB POSTING",
      icon: <Icon path={mdiBriefcaseAccount} size={1} />,
      status: getStatus("TOD"),
      // backgroundColor: "#f2a536",
      onClick: handleCardClick,
     message: "NOTE: CONTACT YOUR ADMIN TO ACTIVATE OR DEACTIVATE",
      
    },
    {
      title: "WEBIT",
      name:"VIEW_PRODUCT_DETAILS",
      count: "0",
      content: "NO ACCESS TO THIS FEATURE",
      icon: <Icon path={mdiSearchWeb} size={1} />,
      status: getStatus("WEBIT"),
      // backgroundColor: "#43adcf",
     message: "NOTE: CONTACT YOUR ADMIN TO ACTIVATE OR DEACTIVATE",
    },
    {
      title: "SQALEUP",
      name:"VIEW_PRODUCT_DETAILS",
      count: "0",
      content: "NO ACCESS TO THIS FEATURE",
      icon: <Icon path={mdiChartLine} size={1} />,
      status: getStatus("SQALEUP"),
      // backgroundColor: "#a462a2",
      message: "NOTE: CONTACT YOUR ADMIN TO ACTIVATE OR DEACTIVATE",
    },
    // {
    //   title: "Total Requirement",
    //   name:"VIEW_REQUIREMENTS_COUNT",
    //   content: userRole==="Recruiter" ? `Requirements Assigned : ${allCounts?.userRequirementCount}`:  `Requirements Created : ${allCounts?.createdRequirementCount}`,
    //   icon: <FaClipboardList />,
    // },
    // {
    //   title: "Requirement Accepted",
    //   name:"VIEW_REQUIREMENTS_COUNT",
    //   content: `10 Requirements Supported`,
    //   icon: <FaCheckCircle />,
    // },
  ]

  const data1 = [
    { name: 'Requirements Supported', value: 75, color: '#660032' },
    { name: 'Other', value: 25, color: '#cccccc' },
  ];
  
  const data2 = [
    { name: 'Deals Closed', value: 15, color: '#660032' },
    { name: 'Other', value: 85, color: '#cccccc' },
  ];

  const clients = [
    { name: 'INFOSYS', placements: 30, revenue: '30%' },
    { name: 'CAPGEMINI', placements: 60, revenue: '60%' },
    { name: 'WIPRO', placements: 30, revenue: '30%' },
    { name: 'INFOSYS', placements: 10, revenue: '10%' },
    { name: 'CAPGEMINI', placements: 80, revenue: '80%' },
    { name: 'WIPRO', placements: 50, revenue: '50%' },
    { name: 'INFOSYS', placements: 90, revenue: '90%' },
    { name: 'CAPGEMINI', placements: 50, revenue: '50%' },
    { name: 'WIPRO', placements: 50, revenue: '50%' },
  ];

  if (loading) {
    return <PulseLoader />; // Show PulseLoader when loading
  }


  const cardBackgroundClass = (index) => {
    switch (index) {
      case 0:
        return "bg-c-blue";  // First card gets blue
      case 1:
        return "bg-c-green"; // Second card gets green
      case 2:
        return "bg-c-yellow"; // Third card gets yellow
      case 3:
        return "bg-c-pink";  // Fourth card gets pink
      case 4:
        return "bg-c-red";   // Fifth card gets red
      default:
        return "bg-c-violet"; // Any other cards get violet
    }
  };



  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",fontSize:"16px"}}>
        <h4 style={{fontSize:"16px"}}>DASHBOARD</h4>
        <h4 style={{fontSize:"16px"}}>{userRole || "Guest User"}</h4>
      </div>

      {
        (userRole === "Super Admin" || 
          userRole ==="Admin" || 
          userRole === "Guest User") &&
          <div className="superadmin-dashboard-container">
            <Grid container spacing={3}>
            {reports.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div className={`card-block ${cardBackgroundClass(index)}`} onClick={item.onClick}>
                  <div className="circle circle-1"></div>
                    <div className="circle circle-2"></div> 
                      <h3 className="m-b-0 title-icon-container white-text">{item.title}<i class="f-right">{item.icon}</i></h3>
                      <h6 className="text-left white-text">{item.total}</h6>
                      <span className="text-left white-text">{item.message}</span>
                </div>
              </Grid>
              ))}
            </Grid>
            <Grid container spacing={2} className="profile-performance-graph">
              {/* <Miniwidget reports={reports} /> */}
              <Grid item xs={12} md={3}>
                <ProfilesSources />
              </Grid>
              <Grid item xs={12} md={6}>
                <GlobalBusiness />
              </Grid>
              <Grid item xs={12} md={3}>
                <MonthlyEarnings />
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              {/* Permanent Placement Section */}
              <Grid item xs={12} md={4}>
                <div className="profile-perforance-card">
                    <div className="profile-perforance-header">
                      <h3>PERMANANT PLACEMENT</h3>
                      <button className="export-btn">EXPORT</button>
                    </div>
                    <div className="grid-header">
                      <span>CLIENT LIST</span>
                      <span>PLACEMENTS</span>
                      <span>REVENUE</span>
                    </div>
                    {clients.map((client, index) => (
                      <div className="grid-row" key={index}>
                        <span>{client.name}</span>
                        <span>{client.placements}</span>
                        <div className="revenue-bar">
                          <div
                            className="bar"
                            style={{
                              width: client.revenue,
                              backgroundColor: index % 3 === 0 ? '#FF8800' : index % 3 === 1 ? '#00AA00' : '#00AAFF',
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>

              {/* Recruiter Performance Section */}
              <Grid item xs={12} md={4}>
                <div className="profile-perforance-card">
                    <div className="profile-perforance-header">
                      <h3>RECRUITER PERFORMANCE</h3>
                      <button className="export-btn">EXPORT</button>
                    </div>
                    <div className="grid-header">
                      <span>REQCRUITER ID</span>
                      <span>CLOSURES</span>
                      <span>REVENUE</span>
                    </div>
                    {clients.map((client, index) => (
                      <div className="grid-row" key={index}>
                        <span>{client.name}</span>
                        <span>{client.placements}</span>
                        <div className="revenue-bar">
                          <div
                            className="bar"
                            style={{
                              width: client.revenue,
                              backgroundColor: index % 3 === 0 ? '#FF8800' : index % 3 === 1 ? '#00AA00' : '#00AAFF',
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>

              {/* Job Portal or Source of Profiles Section */}
              <Grid item xs={12} md={4}>
                <div className="profile-perforance-card">
                    <div className="profile-perforance-header">
                      <h3>JOB PORTAL OR SOURCE PROFILE</h3>
                      <button className="export-btn">EXPORT</button>
                    </div>
                    <div className="grid-header">
                      <span>PORTAL</span>
                      <span>TOTAL CLOSURES</span>
                      <span>REVENUE</span>
                    </div>
                    {clients.map((client, index) => (
                      <div className="grid-row" key={index}>
                        <span>{client.name}</span>
                        <span>{client.placements}</span>
                        <div className="revenue-bar">
                          <div
                            className="bar"
                            style={{
                              width: client.revenue,
                              backgroundColor: index % 3 === 0 ? '#FF8800' : index % 3 === 1 ? '#00AA00' : '#00AAFF',
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>     
          </div>
      }

      {
        (userRole === "Recruiter"|| 
          userRole === "Lead Recruiter") &&
          <div className="user-dashboard-container">          
          <Grid container spacing={3}>
            {data.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div className={`card-block ${cardBackgroundClass(index)}`} onClick={() => handleCardClick(item.title)}>
                  <div className="circle circle-1"></div>
                    <div className="circle circle-2"></div>
                  {/* <div className="user-dashboard-icon">{item.icon}</div> */}
                        <div className="status-wrapper">
                        <span className={`status-badge ${item.status === 'ACTIVE' ? 'active' : 'inactive'}`}>
                          {item.status}
                        </span>
                        </div>
                        <h3 class="m-b-20 white-text">{item.title}</h3>
                        <h6 class="text-left white-text" >{item.count}</h6>
                        <span class="text-left white-text">{item.content}</span>
                      <div className="divider-horizontal"></div>
                      <h6 class="text-left white-text">{item.message}</h6>
                  </div>
              </Grid>
              ))}
          </Grid>
          <Grid container spacing={2} className="profile-performance-graph">
              <Grid item  xs={12} md={3} direction="column">
                  <Grid item>
                    <div className="profile-performance-card">
                      <h6 className="m-b-20 title-icon-container"  onClick={handleCardClick} >TOTAL REQUIREMENTS<div className="profile-performance-icon bg-color1"><i><WorkIcon/></i></div></h6>
                        <span >100</span>
                          <div>
                            <h6>ACCEPTED</h6>
                            <span>70</span>
                          </div> 
                    </div>
                  </Grid>
                  <Grid item>
                  <div className="profile-performance-card">
                    <h6 className="m-b-20 title-icon-container">PROFILE SUBMITTED<div className="profile-performance-icon bg-color2"><i ><PeopleIcon /></i></div></h6>
                        <span >140</span>
                          <div>
                            <h6>PROFILES REJECTED</h6>
                            <span >80</span>
                          </div>
                  </div>
                  </Grid>   
                  <Grid item>       
                  <div className="profile-performance-card">
                    <h6 className="m-b-20 title-icon-container">AVG. BILLING VALUE<div className="profile-performance-icon bg-color3"><i><AttachMoneyIcon/></i></div></h6>
                      <span >US$ 1000</span>
                        <div>
                          <span >
                            TOTAL REVENUE
                          </span >
                          <span >US$ 1000</span>
                        </div>
                  </div>
                  </Grid>  
                  <Grid item>
                  <div className="profile-performance-card">
                    <h6 className="m-b-20 title-icon-container">DEALS CLOSED<div className="profile-performance-icon bg-color4"><i><DoneIcon/></i></div></h6>
                      <span>20</span>
                        <div>
                          <h6>REQUIREMENTS VS CLOSURES</h6 >
                          <span>Rs 1000</span>
                        </div>
                  </div> 
                  </Grid>             
              </Grid>
              <Grid item xs={12} md={6} className="performance-column">
                <div className="profile-performance-card">
                  <h6 className="m-b-20 title-icon-container">OVERALL PERFORMANCE GRAPH<div className="profile-performance-icon bg-color5"><i><AssessmentIcon/></i></div></h6>
                  <span>Requirements VS Closures</span>
                  <div className="profile-charts">
                    <PieChart width={180} height={180}>
                      <Pie data={data1} dataKey="value" cx={90} cy={90} outerRadius={80}>
                        {data1.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                    <PieChart width={180} height={180}>
                      <Pie data={data2} dataKey="value" cx={90} cy={90} outerRadius={80}>
                        {data2.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </div>
                  <div>
                    <span className="m-b-20 title-icon-container" >Total Revenue <span style={{ color: "green" }}>$ 650,000</span></span>
                    <span className="m-b-20 title-icon-container" >Opportunities <span style={{ color: "green" }}>$ 11,50,000</span></span>
                  </div> 
                  <span>
                    Total Revenue is based on the no. of requirements allocated and budget defined vs Requirements Closed or Revenue generated.
                  </span>
                </div>
              </Grid>
              <Grid item  xs={12} md={3} direction="column">
                <Grid item>
                  <div className="profile-performance-card">
                    <h6 className="m-b-20 title-icon-container">OVERVIEW<div className="profile-performance-icon bg-color6"><i><AssessmentIcon /></i></div></h6>
                      <span >User Performance Report</span>
                      <div className="divider-horizontal"/>
                        <div>
                          <span >Overall Performance</span>
                          <span className="m-b-20 title-icon-container" >Technical Skills <span style={{ color: "green" }}>65%</span></span>
                          <span className="m-b-20 title-icon-container" >Communication Skills<span style={{ color: "green" }}>70%</span></span>
                          <span className="m-b-20 title-icon-container" >Negotiation Skills <span style={{ color: "green" }}>20%</span></span>
                        </div> 
                  </div>
                </Grid> 
                <Grid item>
                  <div className="profile-performance-card">
                    <h6 className="m-b-20 title-icon-container"> QUARTERLY PERFORMANCE<div className="profile-performance-icon bg-color7"><i><TimelineIcon/></i></div></h6>
                      <div className="gauge-chart-container">
                        <GaugeChart
                          id="performance-gauge"
                          nrOfLevels={10}
                          // percent={0.75}
                          colors={["#2A9D8F", "#2A9D8F"]}
                          arcWidth={0.3}
                          arcPadding={0.02}
                          needleColor="#2A9D8F"
                          needleBaseColor="#2A9D8F"
                          textColor="transparent" 
                        />
                      </div>
                      <span>OverAll Performance Is Excellent</span>
                  </div>
                </Grid>
              </Grid>
          </Grid> 
         </div>
      }

      <CustomModal
        open={open}
        modalTitle={"Vendor Creation Form"}
        modalContent={<VendorForm />}
      />


    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Dashboard);
