import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "./actionTypes"

// Action Creators
export const forgotPasswordRequest = email => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload: email,
})

export const forgotPasswordSuccess = message => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: message,
})

export const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
})

// Reset Password Actions
export const resetPasswordRequest = data => ({
  type: RESET_PASSWORD_REQUEST,
  payload: data,
})

export const resetPasswordSuccess = message => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: message,
})

export const resetPasswordFailure = error => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
})
