import PropTypes from "prop-types"
//import React, { Component } from "react"
import React from "react"
import { useLocation } from "react-router-dom"

const NonAuthLayout = ({ children }) => {
  const location = useLocation()

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  // Example useEffect to update the page title based on location
  // React.useEffect(() => {
  //   let currentPage = capitalizeFirstLetter(location.pathname);
  //   document.title = `${currentPage} | Lexa - Responsive Bootstrap 5 Admin Dashboard`;
  // }, [location]);

  return <React.Fragment>{children}</React.Fragment>
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
}

export default NonAuthLayout
// class NonAuthLayout extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//     this.capitalizeFirstLetter.bind(this)
//   }

//   capitalizeFirstLetter = string => {
//     return string.charAt(1).toUpperCase() + string.slice(2)
//   }

//   // componentDidMount() {
//   //   let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

//   //   document.title =
//   //     currentage + " | Lexa - Responsive Bootstrap 5 Admin Dashboard"
//   // }
//   render() {
//     return <React.Fragment>{this.props.children}</React.Fragment>
//   }
// }

// NonAuthLayout.propTypes = {
//   children: PropTypes.any,
//   location: PropTypes.object
// }

// export default withRouter(NonAuthLayout)
