import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types"
import { Box, Typography, } from "@mui/material"
import "./CvScanning.scss"
import { FileText, BarChart2, UserCheck, Newspaper, Clock } from 'lucide-react';
import ProductTabs from "./ProductTabs"
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const CvTemplatep = () => {
  const navigate = useNavigate();
  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "CVTEMPLATE", href: "/cvtemplate" },
  ];

  const FeatureCard = ({ icon, title, description}) => {
    return (
      <div style={{
        background: "white",
        borderRadius: "15px",
        backgroundColor: "transparent",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
      >
        <div style={{  marginBottom: "20px" }}>{icon}</div>
        <h2 style={{ fontSize: "24px", fontWeight: "600", marginBottom: "12px" }}>{title}</h2>
        <p >{description}</p>
      </div>
    );
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transition = "transform 0.3s ease";
    e.currentTarget.style.transform = "scale(1.05)";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transition = "transform 0.3s ease";
    e.currentTarget.style.transform = "scale(1)";
  };

  const featureCardStyle = {
    flex: "1 1 30%",
    margin: "10px",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
  };

  return (
    <div>
      <CustomBreadcrumb items={breadcrumbItems} />
      <ProductTabs tab={7} />


      <div style={{ maxWidth: "1200px", margin: "0 auto",marginBottom:"20px", }}>
        <div style={{ textAlign: "center",padding:"10px", backgroundColor: "#660032",color:"#fff", borderRadius: "10px" }}>
          <h5 style={{ fontWeight: "bold",fontSize:"18px", textAlign: "center", marginBottom: "10px" }}>Welcome to CV Template</h5>
          <h6 style={{ fontSize:"14px", textAlign: "center" }}>
            The ultimate toolkit for crafting resumes, analyzing resumes, optimizing profiles, staying informed and more!
          </h6>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center",padding:"10px 0px" }}>
          <div style={featureCardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <ArrowRightAltIcon style={{ cursor: "pointer" }} onClick={() => navigate("/resumebuilder")} />
            </div>
            <FeatureCard
              icon={<FileText size={40} />}
              title="Resume Builder"
              description="Create professional resumes with our easy-to-use builder."
            />
          </div>
          <div style={featureCardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <ArrowRightAltIcon style={{ cursor: "pointer" }} onClick={() => navigate("/analytics")} />
            </div>
            <FeatureCard
              icon={<BarChart2 size={40} />}
              title="Analytics"
              description="Gain insights into your job search and application process."
            />
          </div>
          <div style={featureCardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <ArrowRightAltIcon style={{ cursor: "pointer" }} onClick={() => navigate("#")} />
            </div>
            <FeatureCard
              icon={<UserCheck size={40} />}
              title="Profile Optimization"
              description="Enhance your professional profile for better job opportunities."
            />
          </div>
          <div style={featureCardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <ArrowRightAltIcon style={{ cursor: "pointer" }} onClick={() => navigate("/newsandblogs")} />
            </div>
            <FeatureCard
              icon={<Newspaper size={40} />}
              title="News and Blogs"
              description="Stay updated with the latest industry news and career advice."
            />
          </div>  
          <div style={featureCardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <ArrowRightAltIcon style={{ cursor: "pointer" }} onClick={() => navigate("#")}/>
            </div>
            <FeatureCard
              icon={<Clock size={40}/>}
              title="History"
              description="Track your progress and review past applications."
            />
          </div>
        </div>
      </div>




    </div>
  )
}

export default CvTemplatep
