import React from "react"
import Grow from "@mui/material/Grow"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import "./table.css"
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const CustomTextField = styled(TextField)({
  width: "200px", // Adjust width as needed to match the filter box
  height: "30px", // Adjust height to match the filter box
  border: "1px solid #660032",
  borderRadius: "25px",
  // margin: "20px",
  "& .MuiInputBase-root": {
    height: "100%",
    borderRadius: "25px", // Rounded corners
    backgroundColor: "#fff", // White background
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important", // Remove border color, even on focus
    padding: "2.5px 12px",
  },
  "& .MuiInputBase-input": {
    color: "#660032", // Custom text colo
    borderColor: "transparent",
  },
  "& .MuiSvgIcon-root": {
    color: "#660032", // Custom icon color
  },
})

const CustomSearchRender = props => {
  const { onSearch, searchText } = props
  const handleTextChange = event => {
    onSearch(event.target.value)
  }

  return (
    <Grow appear in={true} timeout={300}>
      <CustomTextField
        placeholder="Search..."
        size="medium"
        className="customTextField"
        value={searchText || ""}
        onChange={handleTextChange}
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grow>
  )
}

export default CustomSearchRender
