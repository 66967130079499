import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";
import { getFirestore, doc, setDoc } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDCgxtAeZUM5y2ZKAyeC7sF-zpfN8lOtUc",
  authDomain: "cvixo-app.firebaseapp.com",
  projectId: "cvixo-app",
  storageBucket: "cvixo-app.appspot.com",
  messagingSenderId: "983514759709",
  appId: "1:983514759709:web:f0673a507dca2c197b3cf4"
};


const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async (id) => {
    const permission = await Notification.requestPermission();
    console.log("permissions",id);
    if(permission === "granted"){
        const token = await getToken(messaging, {
            vapidKey: "BEitllaIg2VJfC6gw3N7ErP8_wLyVGcp7_bfNhlwzHQ5GChhJu23Z3ewm2vogHriKIdBXHFiuCOKQFghB_nJREw"
        })
        console.log("token", token)
        await saveDeviceTokenToFirestore(id, token);
    }else {
        console.error("Notification permission denied.");
      }
}


const saveDeviceTokenToFirestore = async (id, token) => {
    console.log("id======in utils", id, token)
    const firestore = getFirestore(app); 
    try {
      await setDoc(doc(firestore, "users", id), { deviceToken: token }, { merge: true }); 
      console.log("Device token saved to Firestore successfully.");
    } catch (error) {
      console.error("Error saving device token to Firestore:", error);
    }
};
