import React from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, IconButton, Typography, Grid } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const EducationSchema = Yup.object().shape({
  education: Yup.array().of(
    Yup.object().shape({
      degree: Yup.string().required('Degree is required'),
      field_of_study: Yup.string().required('Field of study is required'),
      graduation_date: Yup.string().required('End date is required')
    })
  )
});

const EditEducation = ({ onSubmit, info }) => {
  const initialValues = {
    education: info?.education.map(edu => ({
      degree: edu.degree || '',
      field_of_study: edu.field_of_study || '',
      graduation_date: edu.graduation_date || ''
    })) || [{ degree: '', field_of_study: '', graduation_date: '' }]
  };



  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EducationSchema}
      onSubmit={(values) => {
        console.log(values);
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box mb={4}>
            <FieldArray name="education">
              {({ push, remove }) => (
                <div>
                  {values.education.map((education, index) => (
                    <Box key={index} mb={2} p={2} border={1} borderRadius={2} borderColor="grey.400" position="relative">
                      <Field
                        name={`education[${index}].degree`}
                        as={TextField}
                        label="Degree"
                        fullWidth
                        margin="normal"
                        value={education.degree}
                        onChange={e => setFieldValue(`education[${index}].degree`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px', // Adjust the padding as needed
                          },
                        }}
                      />
                      <Field
                        name={`education[${index}].field_of_study`}
                        as={TextField}
                        label="Field of Study"
                        fullWidth
                        margin="normal"
                        value={education.field_of_study}
                        onChange={e => setFieldValue(`education[${index}].field_of_study`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px', // Adjust the padding as needed
                          },
                        }}
                      />
                      <Field
                        name={`education[${index}].graduation_date`}
                        as={TextField}
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                        value={education.graduation_date}
                        onChange={e => setFieldValue(`education[${index}].graduation_date`, e.target.value)}
                        InputProps={{
                          style: {
                            paddingLeft: '10px', // Adjust the padding as needed
                          },
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => remove(index)}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <Button
                    type="button"
                    style={{color:"#660032"}}
                    startIcon={<AddIcon />}
                    onClick={() => push({ degree: '', field_of_study: '', graduation_date: '' })}
                  >
                    Add Education
                  </Button>
                  <Button type="submit" style={{background:"#660032",color:"white"}}>
                    Save
                  </Button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditEducation;
