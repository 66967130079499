// C:\Users\lenovo\Downloads\Admin\src\pages\TicketStatus\TicketsTable.js
import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap"

const TicketsTable = ({ tickets, respondToTicket }) => {
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [response, setResponse] = useState("")

  const handleRespond = index => {
    setSelectedTicket(tickets[index])
  }

  const handleResponseSubmit = () => {
    if (!response) {
      alert("Please enter a response.")
      return
    }

    respondToTicket(selectedTicket, response)
    setSelectedTicket(null)
    setResponse("")
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Title</th>
                <th>Description</th>
                <th>Attachment</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket, index) => (
                <tr key={index}>
                  <td>{ticket.id}</td>
                  <td>{ticket.name}</td>
                  <td>{ticket.title}</td>
                  <td>{ticket.description}</td>
                  <td>
                    {ticket.attachment
                      ? ticket.attachment.name
                      : "No Attachment"}
                  </td>
                  <td>{ticket.created_at}</td>
                  <td>{ticket.status}</td>
                  <td>{ticket.type}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => handleRespond(index)}
                    >
                      Respond
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal
        isOpen={!!selectedTicket}
        toggle={() => setSelectedTicket(null)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setSelectedTicket(null)}>
          Respond to Ticket
        </ModalHeader>
        <ModalBody>
          <p>Name: {selectedTicket?.name}</p>
          <p>Title: {selectedTicket?.title}</p>
          <p>Description: {selectedTicket?.description}</p>
          <p>
            Attachment:{" "}
            {selectedTicket?.attachment
              ? selectedTicket.attachment.name
              : "No Attachment"}
          </p>
          <p>Created At: {selectedTicket?.created_at}</p>
          <FormGroup>
            <Label for="response">Response</Label>
            <Input
              type="textarea"
              id="response"
              value={response}
              onChange={e => setResponse(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleResponseSubmit}>
            Submit
          </Button>{" "}
          <Button color="secondary" onClick={() => setSelectedTicket(null)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  )
}

export default TicketsTable
