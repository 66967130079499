import React, { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap"
import { connect, useSelector, useDispatch } from "react-redux"
import { addUserRequest, setBreadcrumbItems, updateSuperAdminRequest, updateUser } from "../../store/actions"
import "./FormWizardSteps/vendor.css"
import PhoneInput from "react-phone-input-2"
import { fetchRoles } from "store/api"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const UserCreation = ({ onClose }) => {

    const breadcrumbItems = [
        { title: "SQANO", link: "#" },
        { title: "Forms", link: "#" },
        { title: "User Profile Creation Form", link: "#" },
    ]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const superAdmin = useSelector((state) => state.Vendor.loggedUser);
    const loggedUser = useSelector(state => state.Login?.user?.userDetails);
    const toastOptions = {
        autoClose: 500,
        progressClassName: "toast-progress-bar",
        icon: <CheckCircleIcon style={{ color: "#660032" }} />,
      };
    // console.log("users", Users)
    const [firstName, lastName] = loggedUser.name.split(" ");
    const [formData, setFormData] = useState({
        firstName: firstName,
        lastName: lastName,
        contactNumber: loggedUser.contactNumber,
        email: loggedUser.email,
        designation: loggedUser.designation || "",
        // password: "",
        // profileImage: "",
        location: loggedUser.location || "",
        aboutUser: loggedUser.aboutUser || "",
        linkedin: loggedUser.linkedin,
        twitter: loggedUser.twitter,
        instagram: loggedUser.instagram,
        facebook: loggedUser.facebook
    })

    const [errors, setErrors] = useState({})
    const [roles, setRoles] = useState([])
    const [visible, setVisible] = useState(false)

    // useEffect(() => {
    //     const getRoles = async () => {
    //         try {
    //             const rolesData = await fetchRoles()
    //             setRoles(rolesData)
    //         } catch (error) {
    //             console.error("Failed to fetch roles", error)
    //         }
    //     }

    //     getRoles()
    // }, [])


    const handleChange = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleFileChange = e => {
        setFormData({ ...formData, profilePicture: e.target.files[0] })
    }
    const handleRoleChange = e => {
        const role = roles.find(role => role._id === e.target.value)
        setFormData({ ...formData, role: role._id })
    }
    const resetForm = () => {
        setFormData({
            name: "",
            contactNumber: "",
            email: "",
            designation: "",
            // password: "",
            // profileImage: "",
            location: "",
            aboutUser: "",
            linkedin: "",
            twitter: "",
            instagram: "",
            facebook: ""
        })
        setErrors({})
    }
    const validateForm = () => {
        let errors = {}

        if (!formData.name) errors.name = "First Name is required";
        if (!formData.contactNumber) errors.contactNumber = "Contact Number is required";
        if (!formData.email) errors.email = "Email is required";
        if (!formData.designation) errors.designation = "Designation is required";
        // if (!formData.password) errors.password = "Password is required";
        // if (!formData.profileImage) errors.profileImage = "Profile Image is required";
        if (!formData.location) errors.location = "Location is required";
        if (!formData.aboutUser) errors.aboutUser = "About User is required";
        if (!formData.linkedin) errors.linkedin = "LinkedIn is required";
        if (!formData.twitter) errors.twitter = "Twitter is required";
        if (!formData.instagram) errors.instagram = "Instagram is required";
        if (!formData.facebook) errors.facebook = "Facebook is required";

        return errors
    }

    // const handleFileChange = e => {
    //   const file = e.target.files[0]
    //   const reader = new FileReader()
    //   reader.onloadend = () => {
    //     setFormData({ ...formData, profilePicture: reader.result })
    //   }
    //   reader.readAsDataURL(file)
    // }

    // const handleSubmit = e => {
    //   e.preventDefault()
    //   dispatch(addUser(formData)) // Dispatching the form data
    // }

    const handleSubmit = async e => {
        e.preventDefault()
        console.warn("1");
        const id = loggedUser._id;
        const validationErrors = validateForm()
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }
        console.log("formdata==");
        const result = await dispatch(updateUser({ id, formData }))
        if (result.type === "UPDATE_SUPERADMIN_REQUEST") {
            toast.success('Super Admin updated successfully..',toastOptions);
            onClose();
            window.location.reload();

        }

    }



    // useEffect(() => {
    //     props.setBreadcrumbItems("User Profile Creation Form", breadcrumbItems)
    // }, [props])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="form-wrapper">
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Label htmlFor="first-name" className="col-md-2 col-form-label">
                                    First Name
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="text"
                                        id="first-name"
                                        name="firstName"
                                        className="form-control"
                                        placeholder="First Name"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        invalid={!!errors.firstName}
                                    />
                                    {errors.firstName && (
                                        <span className="text-danger">{errors.firstName}</span>
                                    )}
                                </Col>
                                <Label htmlFor="last-name" className="col-md-2 col-form-label">
                                    Last Name
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="text"
                                        id="last-name"
                                        name="lastName"
                                        className="form-control"
                                        placeholder="Last Name"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        invalid={!!errors.lastName}
                                    />
                                    {errors.lastName && (
                                        <span className="text-danger">{errors.lastName}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Label htmlFor="contact-number" className="col-md-2 col-form-label">
                                    Contact Number
                                </Label>
                                <Col md={4}>
                                    <PhoneInput
                                        country={"in"}
                                        enableSearch={true}
                                        id="contact-number"
                                        inputProps={{
                                            name: "contactNumber",
                                            required: true,
                                            className: "form-control",
                                        }}
                                        containerStyle={{ width: "100%" }}
                                        inputStyle={{ width: "100%" }}
                                        value={formData.contactNumber}
                                        onChange={value =>
                                            setFormData({ ...formData, contactNumber: value })
                                        }
                                    />
                                </Col>
                                <Label htmlFor="email" className="col-md-2 col-form-label">
                                    Email ID
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Email ID"
                                        value={formData.email}
                                        onChange={handleChange}
                                        invalid={!!errors.email}
                                    />
                                    {errors.email && (
                                        <span className="text-danger">{errors.email}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Label htmlFor="designation" className="col-md-2 col-form-label">
                                    Designation
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="text"
                                        id="designation"
                                        name="designation"
                                        className="form-control"
                                        placeholder="Designation"
                                        value={formData.designation}
                                        onChange={handleChange}
                                        invalid={!!errors.designation}
                                    />
                                    {errors.designation && (
                                        <span className="text-danger">{errors.designation}</span>
                                    )}
                                </Col>
                                <Label htmlFor="location" className="col-md-2 col-form-label">
                                    Location
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="text"
                                        id="location"
                                        name="location"
                                        className="form-control"
                                        placeholder="Location"
                                        value={formData.location}
                                        onChange={handleChange}
                                        invalid={!!errors.location}
                                    />
                                    {errors.location && (
                                        <span className="text-danger">{errors.location}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Label htmlFor="linkedin" className="col-md-2 col-form-label">
                                    Linkedin
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="url"
                                        id="linkedin"
                                        name="linkedin"
                                        className="form-control"
                                        placeholder="Linkedin"
                                        value={formData.linkedin}
                                        onChange={handleChange}
                                        invalid={!!errors.linkedin}
                                    />
                                    {errors.linkedin && (
                                        <span className="text-danger">{errors.linkedin}</span>
                                    )}
                                </Col>
                                <Label htmlFor="twitter" className="col-md-2 col-form-label">
                                    Twitter
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="url"
                                        id="twitter"
                                        name="twitter"
                                        className="form-control"
                                        placeholder="Twitter"
                                        value={formData.twitter}
                                        onChange={handleChange}
                                        invalid={!!errors.twitter}
                                    />
                                    {errors.twitter && (
                                        <span className="text-danger">{errors.twitter}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Label htmlFor="instagram" className="col-md-2 col-form-label">
                                    Instagram
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="url"
                                        id="instagram"
                                        name="instagram"
                                        className="form-control"
                                        placeholder="Instagram"
                                        value={formData.instagram}
                                        onChange={handleChange}
                                        invalid={!!errors.instagram}
                                    />
                                    {errors.instagram && (
                                        <span className="text-danger">{errors.instagram}</span>
                                    )}
                                </Col>
                                <Label htmlFor="facebook" className="col-md-2 col-form-label">
                                    Facebook
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="url"
                                        id="facebook"
                                        name="facebook"
                                        className="form-control"
                                        placeholder="Facebook"
                                        value={formData.facebook}
                                        onChange={handleChange}
                                        invalid={!!errors.facebook}
                                    />
                                    {errors.facebook && (
                                        <span className="text-danger">{errors.facebook}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Label htmlFor="aboutUser" className="col-md-2 col-form-label">
                                    About User
                                </Label>
                                <Col md={4}>
                                    <Input
                                        type="textarea"
                                        id="aboutUser"
                                        name="aboutUser"
                                        className="form-control"
                                        placeholder="About User"
                                        value={formData.aboutUser}
                                        onChange={handleChange}
                                        invalid={!!errors.aboutUser}
                                    />
                                    {errors.aboutUser && (
                                        <span className="text-danger">{errors.aboutUser}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <Button type="submit" className="custom-button w-25">
                                    Submit
                                </Button>
                            </Row>

                        </Form>

                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(UserCreation)
