import React, { useState, useEffect } from "react"
import {
  Box,
  Grid,
  Paper,
  Avatar,
  Button,
  Typography,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material"

import AddIcon from "@mui/icons-material/Add"
import SettingsForm from "./SettingsForm"
import CollapsibleTable from "./UserListTable"
import AssignLiceneTable from "./AssignLiceneTable"
import FilterBtn from "./FilterBtn"
import "./superAdmin.scss"
import UserList from "../../assets/images/user-list.png"
import img55 from "../../assets/images/product-Licen.png"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import TwitterIcon from "@mui/icons-material/Twitter"
import GitHubIcon from "@mui/icons-material/GitHub"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import { color } from "echarts"
import CloseIcon from "@mui/icons-material/Close"
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { addUserRequest, getUserByCreatedByRequest } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import EditSuperAdmin from "pages/Forms/EditSuperAdmin"
import UserCreation from "pages/Forms/UserCreation"
import CustomModal from "components/CustomComponents/CustomModal"
import PulseLoader from "./../PulseLoader/PulseLoader"
import { useNavigate } from "react-router-dom"
import userImg from "../../assets/images/user_shadow.jpg"
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb"

const SuperAdmin = () => {
  const [tabValue, setTabValue] = React.useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const loggedUser = useSelector(state => state.Login?.user?.userDetails);
  let { userList, loading } = useSelector(state => state.usersReducer)
  const userRole = loggedUser?.roleAndFeatures.name;

  console.warn("superAdmin", userRole)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  const formatContactNumber = (value) => {
    let countryCode = '';
    let phoneNumber = value;
  
    // Check if the number starts with a '+' (international format)
    if (value.startsWith('+')) {
      // Extract country code (assuming it starts with '+')
      countryCode = value.match(/^\+(\d+)/)?.[0] || '';
      // Extract the rest of the number
      phoneNumber = value.replace(countryCode, '').trim();
    } else {
      // Handle case where there is no '+' (assumes the first 2 digits are country code, e.g., "91")
      countryCode = `+${value.slice(0, 2)}`; // Assuming the country code is the first two digits
      phoneNumber = value.slice(2).trim(); // Extract rest of the number
    }
  
    // Return the formatted number with only a space between country code and the number
    return `${countryCode} ${phoneNumber}`;
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClickOpen = () => {
    navigate("/editProfile", { state: loggedUser })
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    const fetchUserList = async () => {
      setIsFetching(true);
      await dispatch(getUserByCreatedByRequest(loggedUser?.id));
      setIsFetching(false);
    };

    if (tabValue === 1) {
      fetchUserList();
    }
  }, [dispatch, tabValue, loggedUser?.id]);


  const handleSubmit = async (event, formData) => {
    event.preventDefault();
    // const validationErrors = validateForm()
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors)
    //   return
    // }
    // Create a FormData object to handle file upload
    setIsSubmitting(true);
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }
    console.log("data", data)
    await dispatch(addUserRequest(data))
    setTimeout(() => {
      setTabValue(1);
      setIsSubmitting(false);
    }, 5000);
  }

  useEffect(() => {
    if (tabValue === 1) {
      dispatch(getUserByCreatedByRequest(loggedUser?.id));
    }
  }, [dispatch, tabValue]);

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "User Management", href: "/client-management" },
    { label: "Super Admin", href: "/super-admin" },
  ];

  console.log("loggedUser", loggedUser)
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <Box className="profile-container">

        <Grid container className="custom-grid">
          {loggedUser === null ? (
            <Grid item xs={12} md={3} className="mr">
              <h5>No SuperAdmin Found</h5>
            </Grid>
          ) : (
            <Grid item xs={12} md={3} className="mr section-two">
              <Paper className="profile-info">
                <Box className="profile-header">
                  <Button className="profile-edit-button" onClick={handleClickOpen} >
                    EDIT
                  </Button>
                </Box>
                <Box className="profile-picture">
                  <img src={loggedUser?.profilePicture} alt={userImg} className="profile-picture-img" />
                </Box>
                <Box className="profile-content">
                  <Typography className="profile-name">
                    {loggedUser?.name}
                  </Typography>
                  <Typography className="profile-role">
                    {loggedUser?.designation}
                  </Typography>
                </Box>
                <Box className="profile-details">
                  <Grid container spacing={2}>
                    <Grid item xs={12} container>
                      <Grid item xs={4}>
                        <Typography className="profile-info-label">Email ID:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className="profile-info-value">{loggedUser?.email}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container style={{ paddingTop: "0px", margin: "0px" }}>
                      <Grid item xs={4}>
                        <Typography className="profile-info-label">Mobile No:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className="profile-info-value">{formatContactNumber(loggedUser?.contactNumber)}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} container style={{ paddingTop: "0px", margin: "0px" }}>
                      <Grid item xs={4}>
                        <Typography className="profile-info-label">Address:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className="profile-info-value">{loggedUser?.address}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container style={{ paddingTop: "0px", margin: "0px" }}>
                      <Grid item xs={4}>
                        <Typography className="profile-info-label">Role:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className="profile-info-value">{userRole}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container className="profile-url-icons-container">
                  <Grid item xs={3} md={3}>
                    <a href={loggedUser?.facebook} target="_blank" rel="noopener noreferrer"><FacebookIcon className="profile-url-icons" /></a>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <a href={loggedUser?.instagram} target="_blank" rel="noopener noreferrer"><InstagramIcon className="profile-url-icons" /></a>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <a href={loggedUser?.twitter} target="_blank" rel="noopener noreferrer"><TwitterIcon className="profile-url-icons" /></a>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <a href={loggedUser?.linkedin} target="_blank" rel="noopener noreferrer"><LinkedInIcon className="profile-url-icons" /></a>
                  </Grid>
                </Grid>
                {/* <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #e3e3e3",
            padding: "4px 24px",
          }}
        >
          <Box>Edit Profile</Box>
          <Box>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <EditSuperAdmin onClose={handleCloseDialog} />
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
                <CustomModal
                  open={openDialog}
                  onClose={() => handleCloseDialog()}
                  modalTitle={"Edit Profile"}
                  modalContent={<EditSuperAdmin />}
                />
              </Paper>
              {/* <Paper sx={{ padding: 3, marginTop: 3 }}>
            <Typography variant="h6">Messages</Typography>
            
          </Paper> */}
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            <Paper sx={{ padding: 3 }} className="section-two">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="profile tabs"
                className="custom-tabs"
                TabIndicatorProps={{ style: { backgroundColor: '#660032' } }}
                sx={{
                  '& .Mui-selected': {
                    color: '#660032 !important',
                  },
                }}
              >
                <Tab label="Add User" className="tab-color" color="#b55786" />
                <Tab label="Users List" className="tab-color" color="#b55786" />
                <Tab label="Assign License" className="tab-color" color="#b55786" />
              </Tabs>
              {isFetching || isSubmitting ? (
                <PulseLoader />
              ) : (
                <>
                  {tabValue === 0 && (
                    <Box sx={{ marginTop: 3 }}>
                      <UserCreation handleSubmit={handleSubmit} />
                    </Box>
                  )}
                  {tabValue === 1 && (
                    <Box sx={{ marginTop: 3 }} className="img-width profile-buttons">
                      {/* <Button variant="contained" color="error" className="btn-color">
                  Add Filters
                </Button> */}
                      <FilterBtn />
                      <CollapsibleTable users={userList} loading={loading} />
                    </Box>
                  )}
                  {tabValue === 2 && (
                    <Box sx={{ marginTop: 3 }} className="img-width">
                      <FilterBtn />
                      <AssignLiceneTable />
                    </Box>
                  )}
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>

  )
}

export default SuperAdmin
