import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

import Miniwidget from "./Miniwidget"
import ProfilesSources from "./ProfilesSources"
import GlobalBusiness from "./GlobalBusiness"
import MonthlyEarnings from "./MonthlyEarnings"
import SubscriptionValues from "./SubscriptionValues"
import EditDropdown from "./Inbox Cards/EditDropdown"
import user1 from "../../assets/images/users/user-1.jpg"
import user2 from "../../assets/images/users/user-2.jpg"
import "./Inbox Cards/FeatureToggle.css"
import "pages/Sqano Dashboard/dashboard.css"
import { setBreadcrumbItems } from "../../store/actions"
import "pages/Sqano Dashboard/table.css"
import MUIDataTable from "mui-datatables"
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender"
import CustomToolbar from "pages/Sqano Dashboard/CustomToolbar"
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import ClientManagementTileBox from "pages/Client Management Tile Box/ClientManagementTileBox"



const ClientManagementDashboard = props => {

  const [sortValue, setSortValue] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [customers, setCustomers] = useState([
    {
      id: 1,
      clientLogo:
        "https://st2.depositphotos.com/6105304/10689/i/450/depositphotos_106896086-stock-photo-vector-abstract-logo-design-element.jpg",
      clientImage: user1,
      clientName: "Misty",
      startDate: "2001-06-24",
      founderName: "John Doe",
      contactNumber: "123-456-7890",
      email: "misty@example.com",
      location: "New York, USA",
      subscriptionStatus: true,
      currency: "USD",
      subscriptionValue: "52,345",
      badge: "PREMIUM",
      expiryDate: "01-01-2000",
      features: [
        { id: 1, name: "SQANIT", Limit: "Unlimited", enabled: true },
        { id: 2, name: "PARSEIT", Limit: "Unlimited", enabled: false },
        { id: 3, name: "CIVIX", Limit: "Unlimited", enabled: true },
        { id: 4, name: "ToD", Limit: "Unlimited", enabled: false },
        { id: 5, name: "ATS", Limit: "Unlimited", enabled: true },
        { id: 6, name: "Interview", Limit: "Unlimited", enabled: false },
      ],
      recentActivity: [
        { date: "2024-05-01", activity: "Logged in" },
        { date: "2024-05-02", activity: "Updated profile" },
      ],
    },
    {
      id: 2,
      clientLogo:
        "https://st3.depositphotos.com/4073397/14210/i/450/depositphotos_142102102-stock-photo-gold-star-logo.jpg",
      clientImage: user2,
      clientName: "Melissa",
      startDate: "2002-10-04",
      founderName: "Jane Doe",
      contactNumber: "987-654-3210",
      email: "melissa@example.com",
      location: "Los Angeles, USA",
      subscriptionStatus: false,
      currency: "USD",
      subscriptionValue: "32,345",
      badge: "SILVER",
      expiryDate: "01-01-2000",
      features: [
        { id: 1, name: "SQANIT", enabled: false },
        { id: 2, name: "PARSEIT", enabled: true },
        { id: 3, name: "CIVIX", enabled: false },
        { id: 4, name: "TOD", enabled: true },
        { id: 5, name: "ATS", enabled: false },
        { id: 6, name: "Interview", enabled: true },
      ],
      recentActivity: [{ date: "2024-05-03", activity: "Viewed profile" }],
    },
  ])
  const [selectedCustomer, setSelectedCustomer] = useState(customers[0])
  const [expandedCustomer, setExpandedCustomer] = useState(null)
  const [dropdownPosition, setDropdownPosition] = useState(null)
  const [isTileBoxVisible, setIsTileBoxVisible] = useState(false)

  const handleSortAndFilter = (data, sortValue, startDate, endDate) => {
    let sortedData = [...data]

    if (startDate && endDate) {
      sortedData = sortedData.filter(customer => {
        const date = new Date(customer.startDate)
        return date >= startDate && date <= endDate
      })
    }

    switch (sortValue) {
      case "paid":
        sortedData = sortedData.filter(
          customer => customer.subscriptionStatus === true,
        )
        break
      case "unpaid":
        sortedData = sortedData.filter(
          customer => customer.subscriptionStatus === false,
        )
        break
      case "old":
        sortedData.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
        break
      case "new":
        sortedData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
        break
      case "":
      default:
        break
    }

    return sortedData
  }

  const sortedAndFilteredCustomers = handleSortAndFilter(
    customers,
    sortValue,
    startDate,
    endDate,
  )

  const columns = [
    { name: "id", label: "ID", options: { filter: true, sort: true } },
    {
      name: "clientName",
      label: "NAME",
      options: { filter: true, sort: true },
    },
    {
      name: "clientLogo",
      label: "LOGO",
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (
          <img
            src={value}
            alt="Client Logo"
            style={{ width: 40, height: 40, borderRadius: "50%" }}
          />
        ),
      },
    },
    // {
    //   name: "clientImage",
    //   label: "CLIENT IMAGE",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: value => (
    //       <img
    //         src={value}
    //         alt="Client Image"
    //         style={{ width: 40, height: 40, borderRadius: "50%" }}
    //       />
    //     ),
    //   },
    // },

    {
      name: "startDate",
      label: "DATE",
      options: { filter: true, sort: true },
    },
    {
      name: "founderName",
      label: "FOUNDER NAME",
      options: { filter: true, sort: true },
    },
    {
      name: "contactNumber",
      label: "CONTACT NUMBER",
      options: { filter: true, sort: true },
    },
    { name: "email", label: "EMAIL ID", options: { filter: true, sort: true } },
    {
      name: "location",
      label: "LOCATION",
      options: { filter: true, sort: true },
    },
    {
      name: "subscriptionStatus",
      label: "STATUS",
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? "Paid" : "Unpaid"),
      },
    },
    {
      name: "currency",
      label: "CURRENCY",
      options: { filter: true, sort: true },
    },
    {
      name: "edit",
      label: <DriveFileRenameOutlineIcon style={{ color: "#660032" }} />,
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => (
          <button
            style={{
              backgroundColor: "#660032",
              color: "white",
              padding: "5px 10px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={e => handleEditClick(dataIndex, e)}
          >
            <DriveFileRenameOutlineIcon style={{ color: "white" }} />
          </button>
        ),
      },
    },
  ]

  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "HOME", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("HOME", breadcrumbItems)
  }, [props, breadcrumbItems])

  const reports = [
    {
      title: "Revenue",
      iconClass: "buffer",
      total: "$46,782",
      average: "-29%",
      badgecolor: "danger",
    },
    {
      title: "No Of Requirements",
      iconClass: "cube-outline",
      total: "1,587",
      average: "+11%",
      badgecolor: "info",
    },
    {
      title: "Pre Placement revenue , Contract Placement Revenue",
      iconClass: "tag-text-outline",
      total: "$15.9",
      average: "0%",
      badgecolor: "warning",
    },
    {
      title: "Growth percentage ",
      iconClass: "briefcase-check",
      total: "1890",
      average: "+89%",
      badgecolor: "info",
    },
  ]

  const handleEditClick = (dataIndex, e) => {
    const customerId = customers[dataIndex].id
    setExpandedCustomer(prev => (prev === customerId ? null : customerId))
    setSelectedCustomer(customers[dataIndex])

    const rect = e.target.getBoundingClientRect()
    const dropdownHeight = 500 // height of the dropdown
    const dropdownWidth = 400 // width of the dropdown
    const footerHeight = 60 // assuming a footer height

    let dropdownTop = rect.bottom + window.scrollY
    let dropdownLeft = rect.left + window.scrollX

    // Check if dropdown fits below the element
    const fitsBelow =
      dropdownTop + dropdownHeight + footerHeight <= window.innerHeight

    // Adjust top position if it doesn't fit below
    if (!fitsBelow) {
      dropdownTop = rect.top + window.scrollY - dropdownHeight
    }

    // Adjust left position to fit within viewport
    const fitsRight = dropdownLeft + dropdownWidth <= window.innerWidth
    if (!fitsRight) {
      dropdownLeft = window.innerWidth - dropdownWidth - 20 // Adjust with some padding
    }

    setDropdownPosition({ top: dropdownTop, left: dropdownLeft })
  }

  const handleFeatureToggle = featureId => {
    const newCustomers = customers.map(customer => {
      if (customer.id === expandedCustomer) {
        return {
          ...customer,
          features: customer.features.map(f => ({
            ...f,
            enabled: f.id === featureId ? !f.enabled : f.enabled,
          })),
        }
      }
      return customer
    })
    setCustomers(newCustomers)
  }

  const handleCloseDropdown = () => {
    setExpandedCustomer(null)
  }

  const tableOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    sort: false,
    filter: false,
    viewColumns: false,
    searchAlwaysOpen: true,
    customSearchRender: (searchText, handleSearch) => (
      <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
    ),
    customToolbar: () => (
      <CustomToolbar
        sortValue={sortValue}
        setSortValue={setSortValue}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    ),
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h4 style={{ margin: "0", fontSize: "18px" }}>Vendor Dashboard</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ margin: "0 10px 0 0", fontSize: "18px" }}>
            {isTileBoxVisible ? "Tile Box View" : "Dashboard View"}
          </h4>
          <label className="sqano-switch">
            <input
              type="checkbox"
              className="sqano-tile-switch"
              checked={isTileBoxVisible}
              onChange={() => setIsTileBoxVisible(!isTileBoxVisible)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      {isTileBoxVisible ? (
        <ClientManagementTileBox />
      ) : (
        <>
          <Miniwidget reports={reports} />
          <Row>
            <Col xl="3">
              <ProfilesSources />
            </Col>
            <Col xl="6">
              <GlobalBusiness />
            </Col>
            <Col xl="3">
              <MonthlyEarnings />
            </Col>
          </Row>
          <Row>
            <Col xl="9">
                <MUIDataTable
                  className="dashboard-table"
                  data={sortedAndFilteredCustomers}
                  columns={columns}
                  options={tableOptions}
                  onRowClick={(rowData, rowMeta) => {
                    const clickedCustomerId = rowData[0] // Assuming the first column is customer ID
                    const clickedCustomer = customers.find(
                      customer => customer.id === clickedCustomerId,
                    )
                    setExpandedCustomer(clickedCustomerId)
                  }}
                />

            </Col>
            <Col xl="3">
              {selectedCustomer && (
                <SubscriptionValues
                  subScriptionValue={selectedCustomer.subscriptionValue}
                  badge={selectedCustomer.badge}
                  clientImage={selectedCustomer.clientImage}
                  expiryDate={selectedCustomer.expiryDate}
                />
              )}
            </Col>
          </Row>
          {expandedCustomer && (
            <div
              style={{
                position: "absolute",
                top: dropdownPosition.top,
                left: dropdownPosition.left - 380,
                zIndex: 1000,
              }}
            >
              <EditDropdown
                features={
                  customers.find(customer => customer.id === expandedCustomer)
                    .features
                }
                handleFeatureToggle={handleFeatureToggle}
                handleClose={handleCloseDropdown}
                isPaidSubscriber={
                  customers.find(customer => customer.id === expandedCustomer)
                    .subscriptionStatus
                }
              />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ClientManagementDashboard)
