import React, { useState } from "react"
import ProductTabs from "../ProductTabs"
import CustomBreadcrumb from "../../../components/CustomComponents/CustomBreadCrumb";
import { Box, Typography, Card, CardContent, LinearProgress, Grid } from '@mui/material';


const Analytics = () => {

    const profileScore = 85;
  const profileViews = 150;
  const matchedRequirements = 12;
  const sectionsCompletion = {
    experience: 90,
    education: 80,
    skills: 70,
  };

    const breadcrumbItems = [
        { label: "Home", href: "/dashboard" },
        { label: "CV Template", href: "/cvtemplate" },
        { label: "Analytics", href: "/analytics" },
    ];



    return (
        <div>
            <CustomBreadcrumb items={breadcrumbItems} />
            <ProductTabs tab={7} />
            <Box sx={{ padding: 4 }}>
      {/* Header Section */}
      <Typography variant="h4" gutterBottom>
        Analytics
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Gain insights into your profile's performance and engagement.
      </Typography>

      {/* Overview Cards Section */}
      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        {/* Profile Score Card */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Profile Score</Typography>
              <Typography variant="h4" color="primary" sx={{ marginTop: 1 }}>
                {profileScore}%
              </Typography>
              <LinearProgress variant="determinate" value={profileScore} sx={{ marginTop: 2 }} />
            </CardContent>
          </Card>
        </Grid>

        {/* Profile Views Card */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Profile Views</Typography>
              <Typography variant="h4" color="secondary" sx={{ marginTop: 1 }}>
                {profileViews}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                Compared to last month: +15%
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Matched Requirements Card */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Matched Requirements</Typography>
              <Typography variant="h4" color="success.main" sx={{ marginTop: 1 }}>
                {matchedRequirements}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                Matches found for your profile
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Detailed Analytics Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Detailed Analytics
        </Typography>

        {/* Profile Score Breakdown */}
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Profile Score Breakdown</Typography>
          {Object.keys(sectionsCompletion).map((section) => (
            <Box key={section} sx={{ marginTop: 1 }}>
              <Typography variant="body1">
                {section.charAt(0).toUpperCase() + section.slice(1)}: {sectionsCompletion[section]}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={sectionsCompletion[section]}
                sx={{ marginTop: 0.5 }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
        </div>
    )
}

export default Analytics
