import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import "./superAdmin.scss"
import { useDispatch } from "react-redux"
import { getUsers } from "store/actions"
import { useSelector } from "react-redux"

const subscriptionModels = ['Basic', 'Regular', 'Standard', 'Enterprise'];
const limitations = ['100 / Month', 'Unlimited', '50 / Month', 'Disabled'];
const licenceStatuses = ['Activate', 'Block'];

const rows = [
  {
    ProductList: 'SQANIT',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Activate',
      },
    ],
  },
  {
    ProductList: 'PARSEIT',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Activate',
      },
    ],
  },
  {
    ProductList: 'CIVIX',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Free',
      },
    ],
  },
  {
    ProductList: 'TOD',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Free',
      },
    ],
  },
  {
    ProductList: 'WEBIT',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Free',
      },
    ],
  },
  {
    ProductList: 'SQALEUP',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Free',
      },
    ],
  },
  {
    ProductList: 'ASSIGNMENTS',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Free',
      },
    ],
  },
  {
    ProductList: 'TEMPLATES',
    SubscriptionModel: 'Basic',
    Limitations: '100 / Month',
    history: [
      {
        Startdate: '2020-01-05',
        EndDate: '2024-08-15',
        subscription: 'Free',
      },
    ],
  },
];

function Row(props) {
  const { row, isSelected, onClick } = props;
  const [open, setOpen] = React.useState(false);
  const [subscriptionModel, setSubscriptionModel] = React.useState(row.SubscriptionModel);
  const [limitation, setLimitation] = React.useState(row.Limitations);
  const [licenceStatus, setLicenceStatus] = React.useState(row.history[0].subscription);

  const handleSubscriptionModelChange = (event) => {
    setSubscriptionModel(event.target.value);
  };

  const handleLimitationChange = (event) => {
    setLimitation(event.target.value);
  };
  const handleLicenceStatusChange = (event) => {
    setLicenceStatus(event.target.value);
  };
  const cellStyle = {
    backgroundColor: isSelected ? "#660032" : "inherit",
    color: isSelected ? "#ffffff" : "inherit",
    textAlign: "left",
    fontSize: "12px",
    height: "5px",
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} onClick={onClick}>
        <TableCell style={cellStyle}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={e => {
              e.stopPropagation()
              setOpen(!open)
            }}
            sx={{ color: isSelected ? "#ffffff" : "inherit" }}
          >
            {open ? (
              <KeyboardArrowUpIcon
                sx={{ color: isSelected ? "#ffffff" : "inherit" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ color: isSelected ? "#ffffff" : "inherit" }}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={cellStyle}> {row.ProductList}</TableCell>
        <TableCell style={cellStyle}>
          <Select className='custom-dropdown'
            value={subscriptionModel}
            onChange={handleSubscriptionModelChange}
            sx={{ 
              color: isSelected ? '#ffffff' : 'inherit',
              '.MuiSelect-icon': { color: isSelected ? '#ffffff' : 'inherit' },
             
            }}
          >
            {subscriptionModels.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell style={cellStyle}>
          <Select
            value={limitation}
            onChange={handleLimitationChange}
            sx={{ 
              color: isSelected ? '#ffffff' : 'inherit',
              '.MuiSelect-icon': { color: isSelected ? '#ffffff' : 'inherit' }
            }}
          >
            {limitations.map((limit) => (
              <MenuItem key={limit} value={limit}>
                {limit}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell style={cellStyle}>{row.EmailId}</TableCell>
        <TableCell style={cellStyle}>{row.Location}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className="table-wrapper-text"
              >
                Subscription Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={cellStyle}>Start Date</TableCell>
                    <TableCell sx={cellStyle}>End Date</TableCell>
                    <TableCell sx={cellStyle}>Assign License</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map(historyRow => (
                    <TableRow key={historyRow.Startdate}>
                      <TableCell component="th" scope="row">
                        {historyRow.Startdate}
                      </TableCell>
                      <TableCell>{historyRow.EndDate}</TableCell>
                      <TableCell>
                        <Select
                          value={licenceStatus}
                          onChange={handleLicenceStatusChange}
                          // sx={{ 
                          //   color: isSelected ? '#ffffff' : 'inherit',
                          //   '.MuiSelect-icon': { color: isSelected ? '#ffffff' : 'inherit' }
                          // }}
                        >
                          {licenceStatuses.map((status) => (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    ProductList: PropTypes.string.isRequired,
    SubscriptionModel: PropTypes.string.isRequired,
    Limitations: PropTypes.string.isRequired,
    EmailId: PropTypes.string.isRequired,
    Location: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        Startdate: PropTypes.string.isRequired,
        EndDate: PropTypes.string.isRequired,
        subscription: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function AssignLicenseTable() {
  const [selectedRow, setSelectedRow] = React.useState(null);

  return (
    <TableContainer component={Paper} className="mt-4">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="custom-table-cell">Products List</TableCell>
            <TableCell className="custom-table-cell">Subscription Model</TableCell>
            <TableCell className="custom-table-cell">Limitations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <Row
              key={row.ProductList}
              row={row}
              isSelected={selectedRow === row.ProductList}
              onClick={() => setSelectedRow(row.ProductList)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
