
import {
  FILTER_CANDIDATES_REQUEST,
  FILTER_CANDIDATES_SUCCESS,
  FILTER_CANDIDATES_FAILURE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  CREATE_SEARCH_REQUEST,
  CREATE_SEARCH_SUCCESS,
  CREATE_SEARCH_FAILURE,
  FETCH_SEARCHES_REQUEST,
  FETCH_SEARCHES_SUCCESS,
  FETCH_SEARCHES_FAILURE,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAILURE,
  CLEAR_CANDIDATE_DATA
} from "./actionTypes";



const initialState = {
  loading: false,
  error: null,
  uploadProgress: 0,
  uploadedResumes: [],
  filterCandidates: [],
  collectedInfo: [],
  searchRequirement: {},
  searches: [],
  failedProfiles: []
};

const profileScanReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_CANDIDATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FILTER_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        filterCandidates: action.payload,
        error: null
      };
    case FILTER_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
        filterCandidates: [],
        error: action.payload
      };
    case UPLOAD_FILE_REQUEST:
      if (action.payload.reupload) {
        return { ...state, loading: true, failedProfiles: [], error: null };
      } else {
        return { ...state, loading: true, collectedInfo: [], failedProfiles: [], error: null };
      }
    case UPLOAD_FILE_SUCCESS:
      console.log("action.payload", action.payload)
      return {
        ...state,
        loading: false,
        collectedInfo: [...state.collectedInfo, ...action.payload.collectedInfo],
        failedProfiles: action.payload.failedProfiles ? [...state.failedProfiles, ...action.payload.failedProfiles] : []
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        failedProfiles: [...state.failedProfiles, ...action.payload.failedProfiles]
      };
    case CREATE_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchRequirement: action.payload
      };
    case CREATE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_SEARCHES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SEARCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        searches: action.payload
      };
    case FETCH_SEARCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case DELETE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        failedProfiles: state.failedProfiles.filter(profile =>  profile.name !== action.payload.file.name),
        loading: false,
      }

    case DELETE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_CANDIDATE_DATA:
      return {
        ...state,
        filterCandidates:[]
      }

    default:
      return state;
  }
};

export default profileScanReducer;




