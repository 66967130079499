import React from "react"
import "pages/Sqano Tile Box/SqanoTileDashboard.css"

const DashboardCards = ({ title, value, detail, icon }) => {
  return (
    <div className="sqano-Tile-dashboard-card">
      <i className={`fas ${icon} sqano-Tile-dashboard-card-icon`}></i>
      <div className="dashboard-card-content">
        <h3 className="sqano-Tile-dashboard-card-title">{title}</h3>
        <h2 className="sqano-Tile-dashboard-card-value">{value}</h2>
        <p className="sqano-Tile-dashboard-card-detail">{detail}</p>
      </div>
    </div>
  )
}

export default DashboardCards
