// saga.js
import { call, put, takeLatest, all, fork } from "redux-saga/effects"
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
} from "./actions"
import { LOGIN_REQUEST, LOGOUT_REQUEST } from "./actionTypes"
import { loginApi, logoutApi } from "../../api"
import { toast } from "react-toastify"

function* loginSaga(action) {
  try {
    const res = yield call(loginApi, action.payload)
    yield put(loginSuccess(res.data))
    
  } catch (error) {
    yield put(loginFailure(error.response.data.message))
  }
}

function* LoginSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSaga)
}

function* logoutSaga() {
  try {
    yield call(logoutApi)
    yield put(logoutSuccess())
  } catch (error) {
    yield put(logoutFailure(error.message))
  }
}

function* LogoutSaga() {
  yield takeLatest(LOGOUT_REQUEST, logoutSaga)
}

export default function* authSaga() {
  yield all([fork(LoginSaga), fork(LogoutSaga)])
}
