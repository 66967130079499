import React, { useState } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Paper from "@mui/material/Paper"
import Draggable from "react-draggable"
import TextField from "@mui/material/TextField"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./Chatbar.scss"
import user1 from "../../../assets/images/users/user-1.jpg"
import user2 from "../../../assets/images/users/user-2.jpg"
import user3 from "../../../assets/images/users/user-3.jpg"
import user4 from "../../../assets/images/users/user-4.jpg"
import user5 from "../../../assets/images/users/user-5.jpg"
import user6 from "../../../assets/images/users/user-6.jpg"
import user7 from "../../../assets/images/users/user-7.jpg"
import user8 from "../../../assets/images/users/user-8.jpg"
import user9 from "../../../assets/images/users/user-9.jpg"
import user10 from "../../../assets/images/users/user-10.jpg"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const Chatbar = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div className="fixed-sidebar right">
        <div className="chat-friendz">
          <ul className="chat-users">
            {friends.map((friend, index) => (
              <li key={index} onClick={handleClickOpen}>
                <div className="author-thmb">
                  <img src={friend.img} alt="" />
                  <span className={`status ${friend.status}`}></span>
                </div>
              </li>
            ))}
          </ul>

          <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="chat-dialog"
            sx={{ textAlign: "right", padding: "0px" }}
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              <div className="chat-head">
                <span className="status f-online"></span>
                <h6>komal</h6>
                <div className="more">
                  <div className="dropdown">
                    <div
                      className="btn btn2  dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="ti-more-alt"></i>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          block chat
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          unblock chat
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          conversation
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span className="close-mesage" onClick={handleClose}>
                    <i className="ti-close"></i>
                  </span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent className="chat-content">
              <div className="chat-list">
                <ul>
                  {messages.map((message, index) => (
                    <li key={index} className={message.type}>
                      <div className="chat-thumb">
                        <img src={message.img} alt="" />
                      </div>
                      <div className="notification-event">
                        <span className="chat-message-item">
                          {message.text}
                        </span>
                        <span className="notification-date">
                          <time
                            dateTime={message.time}
                            className="entry-date updated"
                          >
                            {message.displayTime}
                          </time>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </DialogContent>
            <DialogActions className="chat-actions">
              <form className="text-box">
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Post enter to post..."
                  InputProps={{
                    endAdornment: (
                      <span
                        title="add icon"
                        className="em em-expressionless"
                      ></span>
                    ),
                  }}
                />
              </form>
            </DialogActions>
          </Dialog>

          {/* {activeChat !== null && (
          <div className="chat-box">
            <div className="chat-head">
              <span className="status f-online"></span>
              <h6>{friends[activeChat].name}</h6>
              <div className="more">
                <div className="more-optns">
                  <i className="ti-more-alt"></i>
                  <ul>
                    <li>block chat</li>
                    <li>unblock chat</li>
                    <li>conversation</li>
                  </ul>
                </div>
                <span className="close-mesage" onClick={handleCloseChat}>
                  <i className="ti-close"></i>
                </span>
              </div>
            </div>
            <div className="chat-list">
              <ul>
                {messages.map((message, index) => (
                  <li key={index} className={message.type}>
                    <div className="chat-thumb">
                      <img src={message.img} alt="" />
                    </div>
                    <div className="notification-event">
                      <span className="chat-message-item">{message.text}</span>
                      <span className="notification-date">
                        <time dateTime={message.time} className="entry-date updated">
                          {message.displayTime}
                        </time>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              <form className="text-box">
                <textarea placeholder="Post enter to post..."></textarea>
                <div className="add-smiles">
                  <span title="add icon" className="em em-expressionless"></span>
                  <div className="smiles-bunch">
                    {smiles.map((smile, index) => (
                      <i key={index} className={`em em-${smile}`}></i>
                    ))}
                  </div>
                </div>
                <button type="submit"></button>
              </form>
            </div>
          </div>
        )} */}
        </div>
      </div>
    </React.Fragment>
  )
}

const friends = [
  { img: user1, status: "f-online", name: "User 1" },
  { img: user2, status: "f-online", name: "User 2" },
  { img: user3, status: "f-offline", name: "User 3" },
  { img: user4, status: "f-online", name: "User 4" },
  { img: user5, status: "f-online", name: "User 5" },
  { img: user6, status: "f-offline", name: "User 6" },
  { img: user7, status: "f-online", name: "User 7" },
  { img: user8, status: "f-away", name: "User 8" },
  { img: user9, status: "f-away", name: "User 9" },
  { img: user10, status: "f-online", name: "User 10" },
  { img: user5, status: "f-online", name: "User 5" },
  { img: user1, status: "f-online", name: "User 1" },
  { img: user4, status: "f-online", name: "User 4" },
]

const messages = [
  {
    type: "me",
    img: user1,
    text: "HI, Jack i have faced a problem with your software. are you available now, when i install this i have to received an error.",
    time: "2004-07-24T18:18",
    displayTime: "Today at 2:12pm",
  },
  {
    type: "you",
    img: user2,
    text: "Hi tina, Please let me know your purchase code, and show me the screnshot of error.",
    time: "2004-07-24T18:18",
    displayTime: "Today at 2:14pm",
  },
  {
    type: "me",
    img: user3,
    text: "Yes, sure please wait a while, i ll show you the complete error list. Thank you.",
    time: "2004-07-24T18:18",
    displayTime: "Today at 2:15pm",
  },
]

const smiles = [
  "-1",
  "smiley",
  "anguished",
  "laughing",
  "angry",
  "astonished",
  "blush",
  "disappointed",
  "worried",
  "kissing_heart",
  "rage",
  "stuck_out_tongue",
]

export default Chatbar
