import React, { useState } from "react";
import { Box, Typography, Grid, IconButton, Tooltip, Link  } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import "./CandidateCard.css"

const getMostRecentJobTitle = (professionalExperience) => {
  if (!professionalExperience || professionalExperience.length === 0) {
    return "No job title available";
  }

  const sortedExperience = professionalExperience.sort((a, b) => {
    const endDateA = a.employment_dates.includes("Present")
      ? new Date()
      : new Date(a.employment_dates.split(" - ")[1] || a.employment_dates.split(" to ")[1]);
    const endDateB = b.employment_dates.includes("Present")
      ? new Date()
      : new Date(b.employment_dates.split(" - ")[1] || b.employment_dates.split(" to ")[1]);
    return endDateB - endDateA;
  });

  return sortedExperience[0].job_title;
  
};

const CandidateCard = ({ candidate, onPreviewClick }) => {
  const [showAllSkills, setShowAllSkills] = useState(null);

  const toggleSkillsExpansion = (candidateId) => {
    setShowAllSkills((prevId) => (prevId === candidateId ? null : candidateId));
  };

  return (
    <Grid item xs={12} sm={6} md={3} key={candidate._id}>
      <div className="candidate-card">
        <div className="card-header">
          <div className="avatar">
            {candidate.personal_information && candidate.personal_information.name
              ? candidate.personal_information.name[0]
              : "N/A"}
          </div>
          <Tooltip title={candidate.personal_information.name || ""} arrow>
            <Typography className="candidate-name">
              {candidate.personal_information.name}
            </Typography>
          </Tooltip>
          <IconButton onClick={() => onPreviewClick(candidate)}>
            <VisibilityIcon className="icon-button" />
          </IconButton>
          <IconButton>
            <DownloadIcon className="icon-button" />
          </IconButton>
        </div>
        <div className="card-content">
          
          <div className="card-contain-container">
            <span className="job-title-label">Email:</span>
              <Tooltip title={candidate.personal_information.email || ""} arrow>
                <span className="job-title-value">{candidate.personal_information.email}</span>
              </Tooltip>
          </div>

          <div className="card-contain-container">
            <span className="job-title-label">Experience:</span>
                <span className="job-title-value">{candidate.total_experience}</span>
          </div>

          <div className="card-contain-container">
            <span className="job-title-label">Job Title:</span>
              <Tooltip title={getMostRecentJobTitle(candidate.professional_experience)} arrow>
                <span className="job-title-value">{getMostRecentJobTitle(candidate.professional_experience)}</span>
              </Tooltip>
          </div>      
              {/* <Grid item xs={6}>
                <p class="f-right">{candidate.total_experience}</p>
              </Grid> */}
          <div className="card-contain-container">
            <span className="job-title-label">Location:</span>          
                <span className="job-title-value">{candidate.personal_information.city}</span>
          </div>

          <div className="card-contain-container">
            <span className="job-title-label">Skills: </span>
            {candidate.inferred_skills?.length > 0 && (
              <Tooltip title={candidate.inferred_skills.join(', ')} arrow>
                {candidate.inferred_skills.slice(0, 1).map((skill, index) => (
                  <span key={index} className="job-title-value-skills">{skill}</span>
                ))}
                {candidate.inferred_skills.length > 1 && (
                  <span
                    className="see--more"
                    // onClick={() => toggleSkillsExpansion(candidate._id)}
                  >
                    {/* {showAllSkills === candidate._id ? "See Less" : "See More"} */}
                    See More
                  </span>
                )}
              </Tooltip>
            )}
          </div>       
        </div>
      </div>
    </Grid>
  );
};

export default CandidateCard;