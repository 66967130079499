import React, { useState } from "react";
import { Box, Grid, Divider} from "@mui/material";
import CvParsing from "./CvParsing";
import CvAccess from "./CvAccess";
import Tod from "./Tod";
import SalesCrm from "./SqaleUp";
import MockTest from "./MockTest";
import CvTemplatep from "./cvtemplatep";
import "./CvScanning.scss"; // Assuming this is where your styles are located
import ScanIT from "./ScanIT";
import ViewRequirement from "pages/ProductList/Tod/ViewRequirement";
import WebScrapping from "./WebScrapping";
import Icon from "@mdi/react";
import { useNavigate } from 'react-router-dom';

import {
  mdiMagnifyScan,
  mdiTextBox,
  mdiFileAccount,
  mdiBriefcaseAccount,
  mdiSearchWeb,
  mdiChartLine,
  mdiClipboardText,
  mdiFileFind,
} from "@mdi/js";
import CommingSoon from "pages/Extra Pages/pages-blank";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const tabConfigs = [
  { label: "SQANIT", component: <ScanIT />, icon: mdiMagnifyScan },
  { label: "PARSEIT", component: <CvParsing />, icon: mdiTextBox },
  { label: "CIVIX", component: <CvAccess />, icon: mdiFileAccount },
  { label: "TOD", component: <Tod />, icon: mdiBriefcaseAccount },
  { label: "WEBIT", component: <CommingSoon />, icon: mdiSearchWeb },
  { label: "SQALEUP", component: <CommingSoon />, icon: mdiChartLine },
  { label: "Mock Test", component: <CommingSoon />, icon: mdiClipboardText },
  { label: "Cv Template", component: <CommingSoon />, icon: mdiFileFind },
];

const MainLayout = ({ initialTab }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(initialTab || 0);

  const handleChange = (index) => {
    setValue(index);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <div className="product-tabs">
            {tabConfigs.map((tab, index) => (
              <React.Fragment key={index}>
                <button
                  onClick={() => handleChange(index)}
                  className={`product-tabs-buttons ${value === index ? "active" : ""}`}
                  {...a11yProps(index)}
                >
                  <Icon
                    path={tab.icon}
                    size={0.5}
                    className={`product-tab-icon ${value === index ? "active" : ""}`}
                  />
                  {tab.label}
                </button>
                {index < tabConfigs.length - 1 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    className="tab-divider"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ p: 1 }}>
            {tabConfigs[value]?.component}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div className="green-bg">300</div>
          <div className="yellow-bg">300</div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainLayout;
