// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { StepContent } from '@mui/material';

// const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

// export default function HorizontalLinearStepper() {
//   const [activeStep, setActiveStep] = React.useState(0);

//   const handleNext = () => {

//     setActiveStep((prevActiveStep) => prevActiveStep + 1);

//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };


//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Stepper activeStep={activeStep}>
//         {steps.map((label, index) => {
//           const stepProps = {};
//           const labelProps = {};

//           return (
//             <Step key={label} {...stepProps}>
//               <StepLabel {...labelProps}>{label}</StepLabel>
//             </Step>
//           );
//         })}
//       </Stepper>
//       {activeStep === steps.length ? (
//         <React.Fragment>
//           <Typography sx={{ mt: 2, mb: 1 }}>
//             All steps completed - you&apos;re finished
//           </Typography>
//           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
//             <Box sx={{ flex: '1 1 auto' }} />
//             <Button onClick={handleReset}>Reset</Button>
//           </Box>
//         </React.Fragment>
//       ) : (
//         <React.Fragment>
//           <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
//           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
//             <Button
//               color="inherit"
//               disabled={activeStep === 0}
//               onClick={handleBack}
//               sx={{ mr: 1 }}
//             >
//               Back
//             </Button>
//             <Box sx={{ flex: '1 1 auto' }} />


//             <Button onClick={handleNext}>
//               {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//             </Button>
//           </Box>
//         </React.Fragment>
//       )}
//     </Box>
//   );
// }


import React, { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './CustomComponent.css';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#660032',
        },
    },
});

const style = {
    scroller: {
        padding: '20px',
        maxHeight: "65vh",
        maxWidth: "100%",
        margin: "auto",
        overflow: 'auto',
        border: "1px solid",
        scrollbarWidth: "none",
        scrollbarColor: '#c0c0c0 transparent',
        '&::-webkit-scrollbar': {
            width: '1px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c0c0c0',
            borderRadius: '1px',
        },

    },
    button: {
        fontSize: '0.8rem',
        textTransform: 'none',
        padding: '3px',
        mr: "6px"
    }
}

export default function HorizontalLinearStepper({ steps, getStepContent, activeStep, handleNext, handleStep, handleBack }) {


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', p: 0, mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stepper activeStep={activeStep} sx={{ justifyContent: 'flex-start' }}>
                            {steps?.map((step, index) => (
                                <Step key={index}>
                                    <StepLabel
                                        onClick={() => handleStep(index)}
                                    >
                                        {step}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ p: 1 }}>
                            {getStepContent(activeStep)}
                            {
                                activeStep === 0 ?
                                    <Grid container>
                                        <Grid item lg={7}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                                                <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" sx={style.button}>
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    color="primary"
                                                    sx={style.button}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid> :
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt:2 }}>
                                        <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" sx={style.button}>
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            color="primary"
                                            sx={style.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}
