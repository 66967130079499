import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import MUIDataTable from "mui-datatables"
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender"
import CustomToolbar from "pages/Sqano Dashboard/CustomToolbar"
import EditDropdown from "pages/Sqano Dashboard/Inbox Cards/EditDropdown"
import "./UserManagement.css"

const columns = [
  {
    name: "id",
    options: {
      display: false,
    },
  },
  {
    name: "name",
    label: "Name",
  },
  {
    name: "phone",
    label: "Phone",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "location",
    label: "Location",
  },
  {
    name: "userType",
    label: "User Type",
  },
  {
    name: "subscriptionStatus",
    label: "Subscription Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: value => (value ? "Paid" : "Unpaid"),
    },
  },
]

const options = {
  selectableRows: "none",
  print: false,
  download: false,
  sort: false,
  filter: false,
  viewColumns: false,
  searchAlwaysOpen: true,
}

const UserManagement = props => {

  const [selectedUser, setSelectedUser] = useState(null)

  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "User Management", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("User Management", breadcrumbItems)
  }, [props, breadcrumbItems])



  return (
    <React.Fragment>
      <Grid container spacing={2} className="UserManagement__container">
        <Grid item xs={12}>
          <MUIDataTable
            className="UserManagement__table"
            title={"User Management"}
            columns={columns}
            options={{
              ...options,
              customSearchRender: (searchText, handleSearch) => (
                <CustomSearchRender
                  searchText={searchText}
                  onSearch={handleSearch}
                />
              )
            }}
          />
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserManagement)
