// src/UserDashboard.js

import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import {
  FaSearch,
  FaFileAlt,
  FaLock,
  FaTasks,
  FaCalendarAlt,
  FaPlus,
  FaClipboardList,
  FaCheckCircle,
  FaDollarSign,
  FaHandshake,
  FaChartLine,
} from "react-icons/fa"
import BarChart from "./BarChart"
import "./UserDashboard.css"

const UserDashboard = ({ setBreadcrumbItems }) => {
  useEffect(() => {
    const breadcrumbItems = [
      { title: "SQANO", link: "#" },
      { title: "User Dashboard", link: "#" },
    ]
    setBreadcrumbItems("User Dashboard", breadcrumbItems)
  }, [setBreadcrumbItems])

  const data = [
    {
      title: "SQANIT",
      content: "You have 10 CV Scanning's per Day",
      icon: <FaSearch />,
    },
    {
      title: "PARSEIT",
      content: "You have unlimited CV Parsing per day",
      icon: <FaFileAlt />,
    },
    {
      title: "CIVIX",
      content: "You have no access to this feature",
      icon: <FaLock />,
    },
    {
      title: "TOD",
      content: "You have job posting per day",
      icon: <FaTasks />,
    },
    {
      title: "Interview Scheduling",
      content: "You have no access to this feature",
      icon: <FaCalendarAlt />,
    },
    {
      title: "Extra Button",
      content: "You have no access to this feature",
      icon: <FaPlus />,
    },
    {
      title: "Total Requirement",
      content: "100 Requirements Allotted Till Dated",
      icon: <FaClipboardList />,
    },
    {
      title: "Requirement Accepted",
      content: "70 Requirements Supported",
      icon: <FaCheckCircle />,
    },
    {
      title: "Avg. Billing Value",
      content: "US$ 1000",
      icon: <FaDollarSign />,
    },
    { title: "Deals Closed", content: "20", icon: <FaHandshake /> },
    {
      title: "Overview",
      content:
        "Till Date Performance report: $650K, 25% vs Last Quarter\nOverall Performance\nTechnical Skills: 65%, 5%\nComm Skills: 70%, 5%\nNegotiation Skills: 60%, 5%",
      icon: <FaChartLine />,
    },
  ]

  const barChartData = {
    labels: ["Technical Skills", "Comm Skills", "Negotiation Skills"],
    datasets: [
      {
        label: "Skills",
        data: [65, 70, 60],
        backgroundColor: "#660032",
      },
    ],
  }

  return (
    <div className="user-dashboard-container">
      {data.map((item, index) => (
        <div className="user-dashboard-card" key={index}>
          <div className="user-dashboard-icon">{item.icon}</div>
          <h2 className="user-dashboard-title">{item.title}</h2>
          <p className="user-dashboard-content">{item.content}</p>
        </div>
      ))}
      <div className="user-dashboard-performance-graph">
        <h2 className="user-dashboard-title">Overall Performance Graph</h2>
        <BarChart data={barChartData} />
      </div>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(UserDashboard)
