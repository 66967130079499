import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  FormFeedback,
  Card,
  CardBody,
  InputGroupText,
  InputGroup,
} from "reactstrap"
import {
  Facebook,
  Twitter,
  Linkedin,
  Youtube,
  Whatsapp,
} from "react-bootstrap-icons"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import { MDBContainer, MDBCollapse, MDBNavbar, MDBNavbarToggler, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { loginRequest, loginFailure, getAllCounts } from "../../store/actions"
// import RegisterHeader from "./Header/RegisterHeader"
// import RegisterPageIcon from "../../assets/images/RegisterPageIcon.png"
import Footer from "./Footer/Footer"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import "./Login.css"
import loginbackground from "../../assets/images/login-background.png"
import loginimg from "../../assets/images/Login-img.png"
import PulseLoader from "pages/PulseLoader/PulseLoader"
import logoLight from "./../../assets/images/logo-light.png";
import { FaTh } from 'react-icons/fa';
import { List } from "react-bootstrap-icons"
import Drawer from "./Drawer/Drawer"
import RegisterHeader from "./Header/RegisterHeader"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FaEnvelope } from 'react-icons/fa';
import axios from "axios";


const Login = () => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, loading, error } = useSelector(state => state.Login)
  // let user = useSelector(state => state.Login?.user)

  // const [isSidebarActive, setIsSidebarActive] = useState(false)
  // const [topDrawerOpen, setTopDrawerOpen] = useState(false);

  const [ipAddress, setIpAddress] = useState(null);
  const [location, setLocation] = useState(null);

  
  

  const fetchIpAddressAndLocation = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      setIpAddress(response.data.ip);
      setLocation(`${response.data.city}, ${response.data.region}, ${response.data.country}`);
    } catch (error) {
      console.error("Failed to get IP address and location", error);
    }
  };

  useEffect(() => {
    // Fetch IP and location when component mounts
    fetchIpAddressAndLocation();
  }, []); 

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
        .required("Please enter your email"),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&]).{8,20}$/,
          "Password must have at least 8 characters long and include at least one uppercase and lowercase letter, a number, and a special character.",
        )
        .required("Please enter your password"),
    }),
    onSubmit: values => {
      dispatch(loginRequest(values))
  
    },
  })

  // useEffect(() => {

  //   if (user) {
  //     switch (user?.userDetails?.role) {
  //       case "Sqano Superadmin":
  //         navigate("/dashboard")
  //         break
  //       case "Sqano Admin":
  //         navigate("/dashboard")
  //         break
  //       case "Superadmin":
  //         navigate("/client-management-dashboard")
  //         break
  //       case "Admin":
  //         navigate("/client-management-dashboard")
  //         break
  //       case "Recruiter":
  //       case "Guest User":
  //         navigate("/dashboard")
  //         break
  //       default:
  //         navigate("/login")
  //     }
  //   }
  // }, [user, navigate])

  useEffect(() => {
    if (user?.userDetails?.id ) {
      // dispatch(getAllCounts({ id: user.userDetails.id, entityId: user.userDetails.entityId }))
      navigate('/dashboard');
      console.log("IP Address:", ipAddress);
      console.log("Location:", location);
      toast.success(`Login successful! \n IP Address: ${ipAddress} \n Location: ${location}`, {
        // position: "top-center",
        autoClose: 3000,
        progressClassName: 'toast-progress-bar',
        icon: <CheckCircleIcon style={{ color: '#660032' }} />,
      })
      
    }
    if (error) {
      toast.error(error)
    }
  }, [error, user])

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    const newValue = type === "checkbox" ? checked : value
    formik.setFieldValue(name, newValue)
    formik.setFieldTouched(name, true, false)
  }

  // const toggleDrawer = () => {
  //   setIsSidebarActive(!isSidebarActive)
  // }

  // const toggleTopDrawer = (open) => () => {
  //   setTopDrawerOpen(open);
  // };

  return (
    <div className="login-page">     
      {loading && <PulseLoader/>}   
        {/* <RegisterHeader /> */} 
        <div className="login-background">
          <RegisterHeader/>   
            <div className="login-box">
              <div className="login-container">         
                  <Row >
                    <Col xs={12} sm={6} md={6} lg={6}
                      className="d-flex flex-column justify-content-center align-items-center text-center login-wrap"
                    >
                      <img className="login-image" src={loginimg} />
                      <p className="login-p">
                        If you don't have an account, you can{" "}
                        <Link className="register-login" to="/register">
                          Signup here
                        </Link>
                        .
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Card>
                        <CardBody className="login-card">
                          <Form onSubmit={formik.handleSubmit}>
                            {/* {error && <Alert color="danger">{error}</Alert>} */}
                            <div className="text-center">
                              {/* <h4 className="text-muted font-size-18 mb-1 text-center">
                            Welcome!
                          </h4> */}
                        <h4 className="text-muted text-center">Sign in</h4>
                      </div>

                            <div className="login-form-group">
                              <Label htmlFor="email">Email</Label>
                              <InputGroup className={`login-input-group ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}>
                                <Input
                                  id="email"
                                  name="email"
                                  type="email"
                                  placeholder="Enter Your Email"
                                  onChange={handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  className="login-input"
                                  
                                />
                                <InputGroupText
                                  className="email-icon"
                                  style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    position: 'absolute',
                                    right: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer', // Optional, for a pointer cursor on hover
                                    zIndex: '10', // Ensures the icon stays above the input
                                    pointerEvents: 'none', // Makes the icon unclickable to avoid input focus loss
                                  }}
                                >
                                  <FaEnvelope style={{ color: '#660032' }} />
                                </InputGroupText>
                              </InputGroup>
                              {formik.touched.email && formik.errors.email && (
                                <FormFeedback className="invalid-feedback">
                                  {formik.errors.email}
                                </FormFeedback>
                              )}
                            </div>

                            <div className="login-form-group login-password-group">
                            <Label htmlFor="password">Password</Label>
                              <div className="login-input-icon-group">
                                <Input
                                  id="password"
                                  name="password"
                                  className={`login-password-input ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                  type={visible ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.password}
                                />
                                <span
                                  onClick={() => setVisible(!visible)}
                                  className="login-eye-icon"
                                >
                                  {visible ? <FaEyeSlash /> : <FaEye />}
                                </span>
                                </div>
                              {/* {formik.touched.password && formik.errors.password && (
                            <FormFeedback className="invalid-feedback">
                              {formik.errors.password}
                            </FormFeedback>
                          )} */}
                        {formik.touched.password &&
                          formik.errors.password && (
                            <div className="login-password-requirements">
                              Password must be 8-20 characters long and
                              include at least one uppercase letter, one
                              lowercase letter, a number, and a special
                              character.
                            </div>
                          )}
                      </div>
                      <FormGroup check className="mt-4">
                        <Input
                          type="checkbox"
                          id="rememberMe"
                          name="rememberMe"
                          className="register-checkbox-input"
                          onChange={handleChange}
                          checked={formik.values.rememberMe}
                        />
                        <Label htmlFor="rememberMe" check style={{fontFamily: 'Roboto', fontSize: "12px"}}>
                          Remember Me
                        </Label>
                      </FormGroup>

                            <div className="mt-2">
                              <Button
                                type="submit"
                                className="w-100  register-btn-custom"
                              >
                                Log In
                              </Button>
                            </div>
                          </Form>
                          <div className="text-center mt-4">
                            <Link
                              to="/forgot-password"
                              className="register-login"
                            >
                              Forgot Password ?
                            </Link>
                          </div>
                          <div className="text-center my-3 or-separator-container">
                            <span className="or-separator-line"></span>
                            <span className="or-separator-text">or</span>
                            <span className="or-separator-line"></span>
                          </div>
                          <div className="row text-center">
                            <ul className="footer-social-icon d-flex gap-3 justify-content-center">
                              <li>
                                <a
                                  className="footer-link"
                                  href="https://www.facebook.com/people/SQANO/61557444795100/"
                                  target="blank"
                                >
                                  <Facebook />
                                </a>
                              </li>
                              <li>
                                <a
                                  className="footer-link"
                                  href="https://www.linkedin.com/company/sqano/"
                                  target="blank"
                                >
                                  <Linkedin />
                                </a>
                              </li>
                              <li>
                                <a className="footer-link" href="javascript:void(0)">
                                  <Twitter />
                                </a>
                              </li>
                              <li>
                                <a
                                  className="footer-link"
                                  href="https://accounts.google.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fab fa-google or-google-icon"></i>
                                </a>  
                              </li>
                            </ul>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>        
              </div>
            </div>
        </div>  
          <Footer />
    </div>  
  )
}

export default Login
{/* <Col md={4} sm={12}>
              <div className="img-overlay img-width">
                
              </div>
            </Col> */}