import { all, fork } from "redux-saga/effects"
import AuthSaga from "./auth/login/saga"

// import AccountSaga from "./auth/register/saga"

import ForgtSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import RegisterSaga from "./auth/register/saga"

// import jobCreationsSaga from "./client/product/tod/"
// import watchSaveParsedData from "./client/product/profileScan/saga"
// import GetAllUsersSaga from "./client/users/saga"
import jobRequirementSaga from "./client/product/tod/jobRequirements/saga"
import userSaga from "./client/users/saga"
import scanSaga from "./client/product/profileScan/saga"
import {
  watchFetchCvAccessCandidatesList,
  watchFilterCvAccessCandidatesList,
} from "./client/product/Civix/saga"

import watchSaveParsedData from "./client/product/profileParse/saga"

import parseProfileRootSaga from "./client/product/profileParse/saga"
import authSaga from "./auth/login/saga"
import workFlowSaga from "./client/product/workFlow/saga"
import watchVendorActions from "./client/vendorForm/saga"
import roleSaga from "./client/product/Roles/saga"

export default function* rootSaga() {
  yield all([
    fork(RegisterSaga),
    // fork(AccountSaga),
    fork(authSaga),

    // fork(calendarSaga),
    // fork(chatSaga),
    // fork(tasksSaga),
    // fork(tasksSaga),
    // fork(jobCreationsSaga),
    // fork(watchFetchCandidates),

    // fork(watchFetchCandidates),

    // fork(watchSaveParsedData),
    fork(userSaga),
    fork(jobRequirementSaga),
    // fork(userSaga),
    fork(scanSaga),
    fork(ProfileSaga),
    fork(ForgtSaga),

    fork(LayoutSaga),
    // fork(GetAllUsersSaga),
    fork(watchFetchCvAccessCandidatesList),
    fork(watchFilterCvAccessCandidatesList),
    fork(parseProfileRootSaga),
    fork(workFlowSaga),
    fork(watchVendorActions),
    fork(roleSaga)
  ])
}
