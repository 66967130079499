import React, { useState, useEffect, useRef } from "react"
import { Avatar } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import sqanologo from "../../../assets/images/Sqano-white.png"
import logoutimg from "../../../assets/images/logout-image.png"
import { Container, Row, Col } from "reactstrap"
import loginimg from "../../../assets/images/loginimg.png"
import "./LoginNote.scss"

const LoginNote = () => {
  const [text, setText] = useState("")
  const [isVisible, setIsVisible] = useState(true)
  const words = ["Hello Kiran.", "Hello Kiran.", "Hello Kiran."]
  const colors = ["#ffffff", "#00C000", "#ffffff"]
  const [colorIndex, setColorIndex] = useState(0)
  const [wordIndex, setWordIndex] = useState(0)
  const [letterIndex, setLetterIndex] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      updateText()
    }, 120)

    return () => clearInterval(interval)
  }, [letterIndex, isDeleting])

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setIsVisible(visible => !visible)
    }, 400)

    return () => clearInterval(blinkInterval)
  }, [])
  const updateText = () => {
    const currentWord = words[wordIndex]
    const currentColor = colors[colorIndex]

    if (isDeleting) {
      if (letterIndex > 0) {
        setText(currentWord.substring(0, letterIndex - 1))
        setLetterIndex(letterIndex - 1)
      } else {
        setIsDeleting(false)
        setColorIndex((colorIndex + 1) % colors.length)
        setWordIndex((wordIndex + 1) % words.length)
      }
    } else {
      if (letterIndex < currentWord.length) {
        setText(currentWord.substring(0, letterIndex + 1))
        setLetterIndex(letterIndex + 1)
      } else {
        setTimeout(() => setIsDeleting(true), 1000)
      }
    }
  }

  return (
    <>
      <div className="welcome-box">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="login-text">
              <div className="text-style">
                <span style={{ color: colors[colorIndex] }}>{text}</span>
              </div>

              <div className="wrapper">
                <h1>Welcome Back To SQANO</h1>
              </div>
              <div className="ptext-style">Let's Have An Amazing Day Together...!</div>
              {/* <p>Let's Have An Amazing Day Together...!</p> */}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="login-img">
              <img src={loginimg} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginNote
