// C:\Users\lenovo\Downloads\Admin\src\pages\TicketStatus\TicketsStatus.js
import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import TicketsTable from "./TicketsTable"
import TicketForm from "./TicketForm"
import TicketsNavbar from "./TicketsNavbar" // Import the new Navbar component
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const TicketsStatus = ({ setBreadcrumbItems }) => {
  useEffect(() => {

    const breadcrumbItems = [
      { title: "SQANO", link: "#" },
      { title: "HOME", link: "#" },
    ]

    setBreadcrumbItems("HOME", breadcrumbItems)
  }, [setBreadcrumbItems])

  const [showTicketForm, setShowTicketForm] = useState(false)
  const [tickets, setTickets] = useState([])
  const [currentTicketIndex, setCurrentTicketIndex] = useState(null)
  const [response, setResponse] = useState("")

  const handleCreateTicket = ticket => {
    setTickets([...tickets, { ...ticket, status: "Unresolved" }])
    setShowTicketForm(false)
  }

  const respondToTicket = (selectedTicket, response) => {
    const updatedTickets = tickets.map(ticket => {
      if (ticket === selectedTicket) {
        return { ...ticket, status: "Resolved" }
      }
      return ticket
    })

    setTickets(updatedTickets)
    setCurrentTicketIndex(null)
  }

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <h2>Tickets</h2>
        </Col>
      </Row>

      <TicketsNavbar setShowTicketForm={setShowTicketForm} />

      <TicketForm
        show={showTicketForm}
        onCreate={handleCreateTicket}
        onCancel={() => setShowTicketForm(false)}
      />

      <TicketsTable tickets={tickets} respondToTicket={respondToTicket} />
    </Container>
  )
}

export default connect(null, { setBreadcrumbItems })(TicketsStatus)
