import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
 
// //Import Scrollbar
import SimpleBar from "simplebar-react"
 
// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
 
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import DraftsIcon from "@mui/icons-material/Drafts"
import ContactMailIcon from "@mui/icons-material/ContactMail"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import ProductsIcon from '../../assets/images/icons/product.png';
import Cvsqanit from '../../assets/images/icons/Cvsqanit.png'
import Cvparseit from '../../assets/images/icons/Cvparseit.png'

const SqanoSidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
 
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
 
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
 
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
 
        const parent3 = parent2.parentElement; // li tag
 
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
 
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
 
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }
 
        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;
 
        if (parent2) {
          parent2.classList.remove("mm-show");
 
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");
 
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };
 
  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);
 
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);
 
  useEffect(() => {
    ref.current.recalculate();
  }, []);
 
  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);
 
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);
 
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const userDetails = useSelector(state => state.Login?.user.userDetails || {})
  const hasPermission = featureName => {
    // Check if the user has 'canView' permission for the given feature
    // console.log("userDetails213456", userDetails)
    return (
      userDetails.permissions?.some(
        p => p.featureName === featureName && p.permissions.canView,
      ) || false
    )
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
         
            <ul className="metismenu list-unstyled first-menu" id="side-menu">
              <li className="hover-color">
                <Link to="/dashboard" className="waves-effect">
                  <i className="mdi mdi-home"></i>
                  <span>{props.t("HOME")}</span>
                </Link>
              </li>

              <li className="hover-color">
                <Link to="/client-management" className="waves-effect">
                  <i className="mdi mdi-account"></i>
                  <span> {props.t("CLIENT MANAGEMENT")}</span>
                </Link>
              </li>
            

              <li className="hover-color">
                <Link to="#" className="has-arrow waves-effect">
                  <i className="mdi mdi-receipt"></i>
                  <span> {props.t("SQANO USERS")}</span>
                </Link>
                <ul className="sub-dropdown">
                  <li>
                    <Link to="#">
                      <i className="mdi mdi-format-list-bulleted"></i>
                      <span>{props.t("SQANO SUPER ADMIN")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="mdi mdi-plus-box"></i>
                      <span>{props.t("SQANO ADMIN")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="mdi mdi-pencil-box"></i>
                      <span>{props.t("SQANO USERS")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="hover-color">
                <Link to="javascript:void(0)" className="has-arrow waves-effect">
                 <img src={ProductsIcon} />
                  <span>{props.t("PRODUCT")}</span>
                </Link>
                <ul className="sub-dropdown ">
                  <li>
                    <Link
                      to={hasPermission("SQANIT") ? "/scan-it" : "#"}
                      className={`waves-effect ${!hasPermission("SQANIT") ? "disabled-link" : ""}`}
                    >
                      <img src={Cvsqanit} className={`custom-img ${!hasPermission("SQANIT") ? "disabled" : ""}`} />
                      <span>{props.t("SQANIT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("PARSEIT") ? "/parsing" : "#"}
                      className={`waves-effect ${!hasPermission("PARSEIT") ? "disabled-link" : ""}`}
                    >
                      <img src={Cvparseit} className={`custom-img ${!hasPermission("PARSEIT") ? "disabled" : ""}`} />
                      <span>{props.t("PARSEIT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("CIVIX") ? "/access" : "#"}
                      className={`waves-effect ${!hasPermission("CIVIX") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-excel ${!hasPermission("CIVIX") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("CIVIX")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("TOD") ? "/todlist" : "#"}
                      className={`waves-effect ${!hasPermission("TOD") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-document ${!hasPermission("TOD") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("TOD")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("WEBIT") ? "/Webscrap" : "#"}
                      className={`waves-effect ${!hasPermission("WEBIT") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-comment-text ${!hasPermission("WEBIT") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("WEBIT")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("SQALEUP") ? "/salescrmp" : "#"}
                      className={`waves-effect ${!hasPermission("SQALEUP") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-document ${!hasPermission("SQALEUP") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("SQALEUP")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("MOCK TEST") ? "/mocktestp" : "#"}
                      className={`waves-effect ${!hasPermission("MOCK TEST") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-clipboard-text ${!hasPermission("MOCK TEST") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("MOCK TEST")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("CV TEMPLATES") ? "/cvtemplate" : "#"}
                      className={`waves-effect ${!hasPermission("CV TEMPLATES") ? "disabled-link" : ""}`}
                    >
                      <i className={`mdi mdi-file-find ${!hasPermission("CV TEMPLATES") ? "disabled" : ""}`}></i>
                      <span className="menu-text">{props.t("CV TEMPLATES")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/PaidUnpaid" className="waves-effect">
                  <i className="mdi mdi-account-multiple"></i>
                  <span>{props.t("PAID/UNPAID SUBSCRIBERS")}</span>
                </Link>
              </li>
              <li>
                <Link to="/Invoice" className="waves-effect">
                  <i className="mdi mdi-receipt"></i>
                  <span>{props.t("INVOICE MANAGEMENT")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-checkbox-marked-outline"></i>
                  <span>{props.t("TO DO LISTING")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-message-text"></i>
                  <span>{props.t("EMAIL")}</span>
                </Link>
                <ul className="sub-dropdown">
                  <li>
                    <Link to="/email-inbox">
                      <DraftsIcon
                        style={{ width: "10px", marginRight: "3px" }}
                      />
                      {props.t("Inbox")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/email-read">
                      <ForwardToInboxIcon
                        style={{ width: "10px", marginRight: "3px" }}
                      />
                      {props.t("Email Read")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/email-compose">
                      <ContactMailIcon
                        style={{ width: "10px", marginRight: "3px" }}
                      />
                      {props.t("Email Compose")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-alert-circle"></i>
                  <span>{props.t("ALERTS")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-clipboard-outline"></i>
                  <span>{props.t("Form Creation")}</span>
                </Link>
                <ul className="sub-dropdown">
                  <li>
                    <Link to="/client-creation-form">
                      <i className="mdi mdi-account"></i>
                      {props.t("Client Creation")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/vendor-form">
                      <i className="mdi mdi-account-plus"></i>
                      {props.t("Vendor Form")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-creation">
                      <i className="mdi mdi-account-multiple-plus"></i>
                      {props.t("User Creation")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Create-job-post">
                      <i className="mdi mdi-briefcase-plus"></i>
                      {props.t("New Job Post")}
                    </Link>
                  </li>
                </ul>
              </li>

              {/* list */}

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-cog"></i>
                  <span>{props.t("ACCOUNT SETTING")}</span>
                </Link>
              </li>

              {/* list */}

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-cash"></i>
                  <span>{props.t("PAYMENT SETTING")}</span>
                </Link>
              </li>

             
            </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SqanoSidebarContent.propTypes = {
  router: PropTypes.object, 
  t: PropTypes.func.isRequired,
}

export default withRouter(withTranslation()(SqanoSidebarContent))
