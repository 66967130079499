import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import Icon from "@mdi/react"
import { mdiAccount } from "@mdi/js"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import "./SidebarContent.scss"

const UserSidebarContent = props => {
  const ref = useRef()
  const userDetails = useSelector(state => state.Login?.user.userDetails || {})
  console.log("userDetails", userDetails)

  useEffect(() => {
    new MetisMenu("#side-menu")
    ref.current?.recalculate()
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  const hasPermission = featureName => {
    // Check if the user has 'canView' permission for the given feature
    // console.log("userDetails213456", userDetails)
    return (
      userDetails.permissions?.some(
        p => p.featureName === featureName && p.permissions.canView,
      ) || false
    )
  }

  // const renderLink = (featureName, path, icon, text) => {
  //   // Render link only if user has 'canView' permission
  //   if (hasPermission(featureName)) {
  //     return (
  //       <li>
  //         <Link to={path}>
  //           <i className={`mdi ${icon}`}></i>
  //           {props.t(text)}
  //         </Link>
  //       </li>
  //     )
  //   }
  //   return null
  // }

  // const renderLink = (featureName, path, icon, text) => {
  //   const enabled = hasPermission(featureName)
  //   return (
  //     <li>
  //       <Link
  //         to={enabled ? path : "#"}
  //         className={`waves-effect ${!enabled && "disabled-link"}`}
  //       >
  //         <i className={`mdi ${icon} ${!enabled && "disabled"}`}>
  //           {props.t(text)}
  //         </i>
  //       </Link>
  //     </li>
  //   )
  // }

  // const activateParentDropdown = useCallback(item => {
  //   item.classList.add("active")
  //   const parent = item.parentElement
  //   const parent2El = parent.childNodes[1]

  //   if (parent2El && parent2El.id !== "side-menu") {
  //     parent2El.classList.add("mm-show")
  //   }

  //   if (parent) {
  //     parent.classList.add("mm-active")
  //     const parent2 = parent.parentElement

  //     if (parent2) {
  //       parent2.classList.add("mm-show")

  //       const parent3 = parent2.parentElement

  //       if (parent3) {
  //         parent3.classList.add("mm-active") // li
  //         parent3.childNodes[0].classList.add("mm-active")
  //         const parent4 = parent3.parentElement
  //         if (parent4) {
  //           parent4.classList.add("mm-show")
  //           const parent5 = parent4.parentElement
  //           if (parent5) {
  //             parent5.classList.add("mm-show") // li
  //             parent5.childNodes[0].classList.add("mm-active")
  //           }
  //         }
  //       }
  //     }
  //     scrollElement(item)
  //     return false
  //   }
  //   scrollElement(item)
  //   return false
  // }, [])

  // const removeActivation = items => {
  //   for (let i = 0; i < items.length; ++i) {
  //     const item = items[i]
  //     const parent = items[i].parentElement

  //     if (item.classList.contains("active")) {
  //       item.classList.remove("active")
  //     }
  //     if (parent) {
  //       const parent2El =
  //         parent.childNodes.length && parent.childNodes[1]
  //           ? parent.childNodes[1]
  //           : null
  //       if (parent2El && parent2El.id !== "side-menu") {
  //         parent2El.classList.remove("mm-show")
  //       }

  //       parent.classList.remove("mm-active")
  //       const parent2 = parent.parentElement

  //       if (parent2) {
  //         parent2.classList.remove("mm-show")

  //         const parent3 = parent2.parentElement
  //         if (parent3) {
  //           parent3.classList.remove("mm-active") // li
  //           parent3.childNodes[0].classList.remove("mm-active")

  //           const parent4 = parent3.parentElement // ul
  //           if (parent4) {
  //             parent4.classList.remove("mm-show") // ul
  //             const parent5 = parent4.parentElement
  //             if (parent5) {
  //               parent5.classList.remove("mm-show") // li
  //               parent5.childNodes[0].classList.remove("mm-active") // a tag
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // const activeMenu = useCallback(() => {
  //   const pathName = process.env.PUBLIC_URL + props.router.location.pathname
  //   let matchingMenuItem = null
  //   const ul = document.getElementById("side-menu")
  //   const items = ul.getElementsByTagName("a")
  //   removeActivation(items)

  //   for (let i = 0; i < items.length; ++i) {
  //     if (pathName === items[i].pathname) {
  //       matchingMenuItem = items[i]
  //       break
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     activateParentDropdown(matchingMenuItem)
  //   }
  // }, [props.router.location.pathname, activateParentDropdown])

  // useEffect(() => {
  //   ref.current.recalculate()
  // }, [])

  // useEffect(() => {
  //   new MetisMenu("#side-menu")
  // }, [])

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" })
  //   activeMenu()
  // }, [activeMenu])

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <div className="top-side-menu">
            <ul className="metismenu list-unstyled first-menu" id="side-menu">
              <li>
                <Link to="/user-dashboard" className="waves-effect">
                  <i className="mdi mdi-home"></i>
                  <span>{props.t("HOME")}</span>
                </Link>
              </li>

              {/* list */}
              <li>
                <Link to="/requirement-list" className="waves-effect">
                  <i className="mdi mdi-checkbox-multiple-marked-outline"></i>
                  <span>REQUIREMENT LIST</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-cube-outline"></i>
                  <span>PRODUCT</span>
                </Link>
                <ul className="sub-dropdown">
                  {/* {renderLink(
                    "CV-Scanning",
                    "/scan-it",
                    "mdi-file-outline",
                    "SQANIT",
                  )}
                  {renderLink(
                    "CV-Parsing",
                    "/parsing",
                    "mdi-file-cloud",
                    "PARSEIT",
                  )}
                  {renderLink(
                    "CV-Access",
                    "/access",
                    "mdi-file-excel",
                    "CIVIX",
                  )}
                  {renderLink("TOD", "/todlist", "mdi-file-document", "TOD")}
                  {renderLink(
                    "WebScraping",
                    "/Webscrap",
                    "mdi-comment-text",
                    "WEBIT",
                  )}
                  {renderLink(
                    "SalesCRM",
                    "/salescrmp",
                    "mdi-file-document",
                    "SQALEUP",
                  )}
                  {renderLink(
                    "MockTests",
                    "/mocktestp",
                    "mdi-clipboard-text",
                    "MOCK TEST",
                  )}
                  {renderLink(
                    "CVTemplates",
                    "/cvtemplate",
                    "mdi-file-find",
                    "CV TEMPLATES",
                  )} */}
                  <li>
                    <Link
                      to={hasPermission("SQANIT") ? "/scan-it" : "#"}
                      className={`waves-effect ${!hasPermission("SQANIT") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-file-outline ${!hasPermission("SQANIT") ? "disabled" : ""}`}
                      ></i>
                      {props.t("SQANIT")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("PARSEIT") ? "/parsing" : "#"}
                      className={`waves-effect ${!hasPermission("PARSEIT") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-file-cloud ${!hasPermission("PARSEIT") ? "disabled" : ""}`}
                      ></i>
                      {props.t("PARSEIT")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("CIVIX") ? "/access" : "#"}
                      className={`waves-effect ${!hasPermission("CIVIX") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-file-excel ${!hasPermission("CIVIX") ? "disabled" : ""}`}
                      ></i>
                      {props.t("CIVIX")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("TOD") ? "/todlist" : "#"}
                      className={`waves-effect ${!hasPermission("TOD") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-file-document ${!hasPermission("TOD") ? "disabled" : ""}`}
                      ></i>
                      {props.t("TOD")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("WEBIT") ? "/Webscrap" : "#"}
                      className={`waves-effect ${!hasPermission("WEBIT") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-comment-text ${!hasPermission("WEBIT") ? "disabled" : ""}`}
                      ></i>
                      {props.t("WEBIT")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("SQALEUP") ? "/salescrmp" : "#"}
                      className={`waves-effect ${!hasPermission("SQALEUP") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-file-document ${!hasPermission("SQALEUP") ? "disabled" : ""}`}
                      ></i>
                      {props.t("SQALEUP")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("MOCK TEST") ? "/mocktestp" : "#"}
                      className={`waves-effect ${!hasPermission("MOCK TEST") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-clipboard-text ${!hasPermission("MOCK TEST") ? "disabled" : ""}`}
                      ></i>
                      {props.t("MOCK TEST")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={hasPermission("CV TEMPLATES") ? "/cvtemplate" : "#"}
                      className={`waves-effect ${!hasPermission("CV TEMPLATES") ? "disabled-link" : ""}`}
                    >
                      <i
                        className={`mdi mdi-file-find ${!hasPermission("CV TEMPLATES") ? "disabled" : ""}`}
                      ></i>
                      {props.t("CV TEMPLATES")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-alert-octagon"></i>
                  <span>ALERTS</span>
                </Link>
              </li>

              {/* list */}

              {/* <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi  mdi-cog"></i>
                  <span>ACCOUNT SETTING</span>
                </Link>
              </li>

            

              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-cash"></i>
                  <span>PAYMENT SETTING</span>
                </Link>
              </li> */}

              <div className="line"> </div>
            </ul>
          </div>

          {/* list */}
          <div className="bottom-side-menu">
            <ul className="metismenu list-unstyled second-menu" id="side-menu">
              <li>
                <Link to="" className="waves-effect">
                  <Icon path={mdiAccount} size={1} />
                  <span>{props.t("TERMS & CONDITION")}</span>
                </Link>
              </li>
              <li>
                <Link to="" className="waves-effect">
                  <Icon path={mdiAccount} size={1} />
                  <span>{props.t("PRIVACY POLICY")}</span>
                </Link>
              </li>
              <li>
                <Link to="" className="waves-effect">
                  <Icon path={mdiAccount} size={1} />
                  <span>{props.t("USER POLICY")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

UserSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UserSidebarContent))
