import React, { useState, useEffect } from "react"
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper, Divider, Chip, List, ListItem, ListItemText,
  IconButton,
  Snackbar,
  Skeleton,
  Menu, MenuItem, Checkbox
} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useDropzone } from "react-dropzone"
import { useDispatch } from "react-redux"
import { deleteFailedProfileRequest, getAllUsersByEntityId, parseProfileRequest, sendToSqanitRequest, shareProfileRequest, updateCandidateRequest, updateUser } from "store/actions"
import VisibilityIcon from "@mui/icons-material/Visibility"
import ProductTabs from "./ProductTabs"
import ReplayIcon from "@mui/icons-material/Replay"
import * as XLSX from "xlsx"
import { useSelector } from "react-redux"
import CustomModal from "components/CustomComponents/CustomModal"
import xlsIcon from "../../assets/images/icons-xls-export.png"
import CustomProgressBar from "components/CustomComponents/CustomProgressBar"
import CustomSideModal from "components/CustomComponents/CutomSideModal"
import EditCertificationForm from "../Forms/EditCertificationForm";
import EditPersonalInformationForm from "../Forms/EditPersonalInformationForm";
import EditEducationForm from "pages/Forms/EditEducationForm"
import "./CvParsing.css"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CustomList from "../../components/CustomComponents/CustomList";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from "react-router-dom"
import cvDetailsIcon from "../../assets/images/details-img.png";
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";

const users = [
  { id: 1, name: 'Abhijeet', email: "abhijeet@ample.be" },
  { id: 2, name: 'Atharv', email: "atharv@ample.be" },
  { id: 3, name: 'Kiran Sir', email: "kiran@ample.be" },
];

const CvParsing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([])
  const { userDetails } = useSelector(state => state.Login?.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rows, setRows] = useState([])
  const [information, setInformation] = useState(rows[0]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [removeSelectedData, setRemoveSelectedData] = useState(false);
  const loader = useSelector(state => state.parseProfilesReducer.loading);
  const usersList=useSelector(state=>state.JobRequirement?.allUsers);
  const [emailOptions,setEmailOptions]=useState();
  const { failedProfiles, reuploadResult, parsedProfiles } = useSelector(state => state.parseProfilesReducer)
  const [reuploadFlag, setReuploadFlag] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [reuploadingFile, setReuploadingFile] = useState({})
  let progress = useSelector(state => state.parseProfilesReducer.uploadingProgress)
  const [showFinalModal, setShowFinalModal] = useState()
  const [activeButton, setActiveButton] = useState("parsed")

  const [candidateInfo, setCandidateInfo] = useState()
  const [viewModal, setViewModal] = useState(false)

  const [failedRows, setFailedRows] = useState([])
  const [viewEditModal, setViewEditModal] = useState(false)

  const [updatedInfo, setUpdatedInfo] = useState();
  const [personalInfo, setPersonalInfo] = useState();
  const [certifications, setCertifications] = useState();
  const [education, setEducation] = useState();

  const [skeletonRows, setSkeletonRows] = useState([]);
  const [previousFilesCount,setPreviousFilesCount]=useState(0)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf, .doc, .docx",
    onDrop: acceptedFiles => {

      setFiles(prevFiles => [...prevFiles, ...acceptedFiles])
    },
  })

  useEffect(()=>{
    const updatedUsers=usersList?.filter(user=>user._id !== userDetails?.id )
    setEmailOptions(updatedUsers)
  },[usersList])

  useEffect(() => {
    if (parsedProfiles?.length > 0) {
      !information && setInformation(parsedProfiles[0]);

      const newRows = parsedProfiles?.map((info, index) => {
        const parsedDate = getFormattedDate(info.parsedDate)
        const fields = {
          ProfileId: info._id || "NA",
          CandidateName: info.personal_information.name || <Skeleton variant="text" width={50} />,
          EmailId: info.personal_information.email || "NA",
          Contact:
            info.personal_information.phone &&
              info.personal_information.phone !== "Not provided"
              ? info.personal_information.phone
              : "NA",
          Location:
            info.personal_information.city &&
              info.personal_information.city !== "Not provided"
              ? info.personal_information.city
              : "NA",
          Experience: info.total_experience || "NA",
          ProfessionalExperience: info.professional_experience || "NA",
          Education: info.education || "NA",
          JobTitle: "NA",
          Skills: info.inferred_skills.join(", "),
          ParsedOn: info.parsedDate ? parsedDate : "NA",
          Action: (
            <div>
              <IconButton
                sx={{ fontSize: "0.1rem" }}
                onClick={() => handleVisibility(info)}
              >
                <VisibilityIcon />
              </IconButton>
              {/* <IconButton size="small" onClick={() => handelEditClick(info)}>
                <EditIcon />
              </IconButton> */}
            </div>
          ),
        }

        const totalFields = Object.keys(fields).length
        const filledFields = Object.values(fields).filter(
          value => value !== "NA",
        ).length
        const fulfillmentRatio = `${((filledFields / totalFields) * 100).toFixed(0)}`

        // return {
        //   ...fields,
        //   DataFulfillment: fulfillmentRatio,
        // }
        return fields
      })

      setRows(prevRows => [
        ...newRows,
        ...prevRows.filter(row => row.isLoading),
      ])
    }
  }, [parsedProfiles, parsedProfiles?.length, viewEditModal])

  const onReupload = filedata => {
    setReuploadingFile(filedata.fileName)
    setReuploadFlag(true)
    let files = []
    files.push(filedata.file)
    dispatch(parseProfileRequest({ files, reupload: true, reuploadAll: false, parsedBy: userDetails?.id }))
  }

  const reuploadAllFiles = () => {
    setPreviousFilesCount(rows.length)
    setReuploadFlag(true)
    setActiveButton("parsed")
    setFailedRows([])
    let files = failedRows?.map((file) => file.file)
    setFiles(files)
    dispatch(parseProfileRequest({ files, reupload: true, reuploadAll: true, parsedBy: userDetails?.id }))
  }

  useEffect(() => {
    let skeletonCount;
    if(reuploadFlag){
      skeletonCount = files.length-(failedRows.length+rows.length-previousFilesCount)
    }else{
      skeletonCount = files.length - (rows?.length + failedRows?.length);
    }

    const myArray = Array.from({ length: skeletonCount }, (_, index) => index);
    
    setSkeletonRows(myArray);

  }, [files, rows, failedRows, uploadFlag]);

  useEffect(() => {
    if (failedProfiles?.length > 0 || reuploadFlag) {
      const existingFilesMap = new Map()
      failedRows?.forEach((row, index) => {
        existingFilesMap.set(row.fileName, { ...row, ProfileId: index + 1 })
      })

      const newKey = "result"


      failedProfiles.forEach(file => {
        const existingFile = existingFilesMap.get(file.fileName)
        let index = existingFile
          ? existingFile.ProfileId - 1
          : existingFilesMap.size


        if (reuploadingFile === file.fileName) {
          file[newKey] = reuploadResult !== "" ? reuploadResult : null

          if (reuploadResult === "") {
            existingFilesMap.set(file.fileName, {
              ProfileId: index + 1,
              fileName: file.fileName,
              file: file.file,
              Action: (
                <IconButton size="small" onClick={() => onReupload(file)}>
                  <ReplayIcon
                    style={{
                      transition: "transform 5s",
                      transform: loader ? "rotate(720deg)" : "rotate(0deg)",
                    }}
                  />
                </IconButton>
              ),
            })
          } else {
            existingFilesMap.set(file.fileName, {
              ProfileId: index + 1,
              fileName: file.fileName,
              file: file.file,
              Action: (
                <h5
                  style={{
                    textAlign: "center",
                    color:
                      file[newKey] === "Done"
                        ? "green"
                        : "red",
                  }}
                >
                  {file[newKey]}
                </h5>
              ),
            })
          }
        } else {
          existingFilesMap.set(file.fileName, {
            ProfileId: index + 1,
            fileName: file.fileName,
            file: file.file,
            Action:
              file.result && file.result !== null ? (
                <h5
                  style={{
                    textAlign: "center",
                    color:
                      file[newKey] === "Done"
                        ? "green"
                        : "red",
                  }}
                >
                  {file[newKey]}
                </h5>
              ) : (
                <IconButton size="small" onClick={() => onReupload(file)}>
                  <ReplayIcon />
                </IconButton>
              ),
          })
        }


      })

      const updatedFailedRows = Array.from(existingFilesMap.values()).filter(
        row => row.Action.props.children !== "Done"
      );
      setFailedRows(updatedFailedRows)
    }

  }, [failedProfiles,failedProfiles?.length, loader,])

  function getFormattedDate(dateString) {
    const date = new Date(dateString)
    const day = String(date.getUTCDate()).padStart(2, "0")
    const month = String(date.getUTCMonth() + 1).padStart(2, "0")
    const year = date.getUTCFullYear()

    return `${day}/${month}/${year}`
  }

  const uploadFiles = () => {
    setUploadFlag(true)
    setReuploadFlag(false)
    setSelectedUsers([]);
    dispatch(getAllUsersByEntityId(userDetails?.entityId))
    dispatch(parseProfileRequest({ files, reupload: false, reuploadAll: false, parsedBy: userDetails?.id }))

  }

 




  const handleExport = () => {
    const dt = new Date()
    const date = dt.toLocaleDateString()
    const time = dt.toLocaleTimeString()


    const filteredRows = rows.map(row => {
      const { ProfileId, ProfessionalExperience, Education, Action, ...rest } = row;
      return rest;
    });

    const ws = XLSX.utils.json_to_sheet(filteredRows)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
    XLSX.writeFile(wb, `parsed_data_${date}_${time}.xlsx`)
  }

  const sendProfilesToSqanit = () => {
    dispatch(sendToSqanitRequest(selectedProfiles));
    navigate('/scan-it');
  }

  const handleDelete = () => {

    if (selectedData.length > 0) {
      setSelectedData([]);
      setSelectedProfiles([]);
      setRemoveSelectedData(true);
      const updatedFailedRows = [...failedRows];
      selectedProfiles.forEach(selectedItem => {
        const index = updatedFailedRows.findIndex(row => row.ProfileId === selectedItem.ProfileId);
        if (index > -1) {
          dispatch(deleteFailedProfileRequest(selectedItem))
          updatedFailedRows.splice(index, 1);
        }
      });
      setFailedRows(updatedFailedRows);
      setSelectedProfiles(updatedFailedRows);
    } else {
      console.warn("no selected data");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleUser = (user) => {
    const currentIndex = selectedUsers.indexOf(user);
    const newSelectedUsers = [...selectedUsers];

    if (currentIndex === -1) {
      newSelectedUsers.push(user);
    } else {
      newSelectedUsers.splice(currentIndex, 1);
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handleShare = () => {
    const receivers = [];

    selectedUsers.map(val => {
      receivers.push(val.email);
    })
    dispatch(shareProfileRequest({ selectedProfiles, receivers }))
    setSelectedUsers([]);
    handleClose();
  };


  const handleSelectedData = (selected, selectedProfiles) => {
    setRemoveSelectedData(false);
    setSelectedData(selected);
    setSelectedProfiles(selectedProfiles)
  };

  const handleVisibility = info => {
    setInformation(info);
  }

  const handleVisibilityClick = info => {
    const modalView = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <span>Profile ID : {info._id}</span>
          <span>Parsed on : {getFormattedDate(info.parsedDate)}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div
            style={{
              flex: "1",
              marginRight: "10px",
              borderRight: "solid black 1px",
              paddingRight: "20px",
            }}
          >
            <div style={{ marginBottom: 50 }}>
              <h1>{info.personal_information.name}</h1>
              <h4>
                <strong>Email:</strong>{" "}
                {info.personal_information.email || "NA"}
              </h4>
              <h4>
                <strong>Contact:</strong>{" "}
                {info.personal_information.phone || "NA"}
              </h4>
              <h4>
                <strong>Location:</strong>{" "}
                {info.personal_information.city || "NA"}
              </h4>
            </div>

            <div>
              <h4>Educational Details :</h4>
              {info.education.map((data, index) => (
                <>
                  <ul
                    key={index}
                    style={{
                      backgroundColor: "#e0e0e0",
                      listStyleType: "none",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <strong>{data.degree}</strong>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <strong>Field : </strong>{" "}
                        </div>
                        <div>{data.field_of_study}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <strong>Date : </strong>
                        </div>
                        <div>{data.graduation_date}</div>
                      </div>
                    </li>
                  </ul>
                </>
              ))}
            </div>
            <div>
              <h4>Skills:</h4>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {info.inferred_skills.length > 0
                  ? info.inferred_skills.map((skill, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "8px",
                        background: "#e0e0e0",
                        borderRadius: "20px",
                        fontSize: "0.8rem",
                      }}
                    >
                      {skill}
                    </div>
                  ))
                  : "NA"}
              </div>
            </div>
          </div>
          <div style={{ flex: "1", marginLeft: "10px" }}>
            <h4>Experience:</h4>
            {info.professional_experience.map((data, index) => (
              <>
                <ul
                  key={index}
                  style={{
                    backgroundColor: "#e0e0e0",
                    listStyleType: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <li>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <strong>Company:</strong>
                      </div>
                      <div style={{ flex: 3 }}>{data.company_name}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <strong>Designation:</strong>{" "}
                      </div>
                      <div style={{ flex: 3 }}>{data.job_title}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <strong>Period:</strong>
                      </div>
                      <div style={{ flex: 3 }}>{data.employment_dates}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <strong>Description</strong>
                      </div>
                      <div style={{ flex: 3 }}>
                        {data.experience_description}
                      </div>
                    </div>
                  </li>
                </ul>
              </>
            ))}
          </div>
        </div>
      </>
    )
    setCandidateInfo(modalView)
    setViewModal(true)
  }



  const handelEditClick = info => {

    setUpdatedInfo(info);

    const handelPersonalInfo = (values) => {
      setPersonalInfo(values);
    }

    const handelCertifications = (values) => {
      setCertifications(values.certifications)
    }

    const handelEducationalDetails = (values) => {
      setEducation(values.education)
    }

    const editView = (
      <Box sx={{ color: "black" }}>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h6">Profile ID: {info._id}</Typography>
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <EditPersonalInformationForm info={info} onSubmit={handelPersonalInfo} />
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <EditCertificationForm info={info} onSubmit={handelCertifications} />
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <EditEducationForm info={info} onSubmit={handelEducationalDetails} />
        </Box>
      </Box>
    );


    setCandidateInfo(editView)
    setViewEditModal(true)
  }

  const handelUpdate = () => {

    updatedInfo.personal_information.name = personalInfo.name;
    updatedInfo.personal_information.email = personalInfo.email;
    updatedInfo.personal_information.phone = personalInfo.contact;
    updatedInfo.personal_information.city = personalInfo.location;
    updatedInfo.job_title = personalInfo.jobTitle;
    updatedInfo.total_experience = personalInfo.totalExperience;
    updatedInfo.preferred_location = personalInfo.preferredLocation;
    updatedInfo.education = education;
    updatedInfo.certifications = certifications;

    dispatch(updateCandidateRequest(updatedInfo));
    setViewEditModal(false)
  }


  useEffect(() => {
    if (progress == 100) {
      progress = 0
      setFiles([])
      setShowFinalModal(true)
    }
    if (progress > 5) {
    }
  }, [progress])

  const handleParsedClick = () => {
    setSelectedData([]);
    setSelectedProfiles([]);
    setRemoveSelectedData(true);
    setActiveButton("parsed")
  }

  const handleFailedClick = () => {
    setSelectedData([]);
    setSelectedProfiles([]);
    setRemoveSelectedData(true);
    setActiveButton("failed");
  }

  const clearData = () => {
    setUploadFlag(false)
    setRows([])
    setFailedRows([])
    setInformation(undefined)
    setActiveButton("parsed")
  }

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "PARSEIT", href: "/parse-it" },
  ];

  return (
    <>
      <Snackbar
        open={showFinalModal}
        autoHideDuration={2000}
        onClose={() => setShowFinalModal(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {/* {failedProfiles.length > 0 ? <h5>{failedProfiles.length} files failed to upload</h5> : <h5>All files uploaded successfully</h5>} */}
      </Snackbar>
      <CustomBreadcrumb items={breadcrumbItems} />
      <ProductTabs tab={1}/>
      <Grid 
        container
        className="gridContainer"
        spacing={1.5}
      >
        <Grid item className="gridItem" xs={2.5}>
          <Box className="uploadBox">
            <div className="uploadBoxTitle">
              Parse Profiles Here
            </div>
            {files.length === 0 ? (
              <Box
                {...getRootProps({ className: "dropzone" })}
              >
                <CloudUploadIcon
                  fontSize="large"
                  sx={{ color: "#660032" }}
                />
                <input {...getInputProps()} />
                <h5 className="textColor">Upload Profiles</h5>
                <p>Click or drag files to upload</p>
                <p>[.pdf, .doc, .docx]</p>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#660032",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#660032",
                    },
                    textTransform: "none",
                  }}
                  onClick={clearData}
                >
                  Select Files
                </Button>
              </Box>
            ) : (
              <Box className="dropzone">
                <IconButton
                  sx={{
                    padding: 0,
                    marginBottom: 1,
                    color: "#660032",
                    display: "flex",
                    justifyContent: "left",
                  }}
                  onClick={() => setFiles([])}

                >
                  <ArrowBackIcon />
                </IconButton>
                <h4 style={{ color: "#660032" }}>[{files.length}]</h4>
                <h4 style={{ color: "#660032" }}>Files Selected</h4>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: 2,
                    backgroundColor: "#660032",
                    "&:hover": {
                      backgroundColor: "#660032",
                    },
                  }}
                  onClick={uploadFiles}
                >
                  Upload
                </Button>
              </Box>
            )}
            <div className="infoField">
              <h6>Fields Extracted by PARSEIT</h6>
              <ul>
                <li>Personal Information</li>
                <li>Educational Details</li>
                <li>Skills</li>
                <li>Professional Experience</li>
              </ul>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          xs={4.5}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            {progress > 0 && progress < 100 ? (
              <CustomProgressBar prog={progress} />
            ) : (
              <div>
                <Button
                  onClick={handleParsedClick}
                  style={{
                    fontSize: "12px",
                    padding: "8.5px 5px",
                    minWidth: "auto",
                    color: activeButton === "parsed" ? "#fff" : "#660032",
                    backgroundColor:
                      activeButton === "parsed" ? "#660032" : "transparent",
                    border: "#660032 solid 1px",
                    marginLeft: 5,
                  }}
                >
                  Parsed [{rows ? rows.length : 0}]
                </Button>
                <Button
                  onClick={handleFailedClick}
                  style={{
                    fontSize: "12px",
                    padding: "8.5px 5px",
                    minWidth: "auto",
                    color: activeButton === "failed" ? "#fff" : "#660032",
                    backgroundColor:
                      activeButton === "failed" ? "#660032" : "transparent",
                    border: "#660032 solid 1px",
                    marginLeft: 5,
                  }}
                >
                  Failed [{failedRows ? failedRows.length : 0}]
                </Button>
              </div>
            )}
            <div style={{ display: "flex" }}>

              {
                activeButton === "failed" ?
                  <Button
                    onClick={handleDelete}
                    disabled={selectedData.length === 0 || !failedRows}
                    style={{
                      padding: "2px 4px",
                      minWidth: "auto",
                      color: selectedData.length > 0 ? "#660032" : "#dc95b7",
                      border: selectedData.length > 0 ? "#660032 solid 1px" : "#dc95b7 solid 1px",
                      marginLeft: 5,
                    }}
                  >
                    <DeleteForeverIcon />
                  </Button> :
                  <>
                    <div style = {{ minWidth: 'auto'}}
                    className={selectedData.length !== 0 ? "customButtonOne" : "customButtonTwo"} onClick={sendProfilesToSqanit}>
                      SqanIt
                    </div>

                    <div>
                      <Button
                        onClick={handleClick}
                        disabled={selectedData.length === 0 || !rows}
                        style={{
                          padding: '2px 4px',
                          minWidth: 'auto',
                          color: selectedData.length > 0 ? "#660032" : "#dc95b7",
                          border: selectedData.length > 0 ? "#660032 solid 1px" : "#dc95b7 solid 1px",
                          marginLeft: 5,
                        }}
                      >
                        <MailOutlineIcon />
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}

                      >
                        {emailOptions?.map((user) => (
                          <MenuItem key={user.id} onClick={() => handleToggleUser(user)} className="dropdownMenuItem">
                            <Checkbox checked={selectedUsers.includes(user)} />
                            <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                          </MenuItem>
                        ))}
                        <MenuItem className="dropdownMenuItem">
                          <Button onClick={handleShare} disabled={selectedUsers.length === 0} className="dropdownButton">
                            Share
                          </Button>
                        </MenuItem>
                      </Menu>
                    </div>


                  </>
              }
              {
                activeButton === "parsed" &&
                <Button
                  onClick={handleExport}
                  disabled={rows.length === 0 || !rows}
                  style={{
                    padding: "2px 4px",
                    minWidth: "auto",
                    color: "#660032",
                    border: "#660032 solid 1px",
                    marginLeft: 5,
                  }}
                >
                  {" "}
                  <img
                    src={xlsIcon}
                    className="imgSize"
                    alt="XLS"
                  />
                </Button>
              }
            </div>
          </div>

          <CustomList
            skeletonRows={uploadFlag ? skeletonRows : null}
            parsedData={rows}
            failedData={failedRows}
            activeButton={activeButton}
            onSelectionChange={handleSelectedData}
            removeSelectedData={removeSelectedData}
            selectedData={selectedData}
            reuploadAllFiles={reuploadAllFiles}
          />

        </Grid>

        {information !== undefined ?
          (
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={{ p: 2, overflow: "scroll", scrollbarWidth: "none" }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Personal Information</Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Email:
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Contact:
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Location:
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                      Experience:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Divider orientation="vertical" flexItem />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {information?.personal_information.email || "NA"}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {information?.personal_information.phone || "NA"}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {information?.personal_information.city || "NA"}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {`${information?.total_experience} Years`}
                    </Typography>
                  </Grid>
                  {/* Education Background */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Education</Typography>
                    <List dense>
                      {information?.education?.map((edu) => (
                        <ListItem key={edu._id}>
                          <ListItemText
                            primary={edu.degree}
                            secondary={`${edu.field_of_study}, Graduation: ${edu.graduation_date}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Professional Experience */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Professional Experience</Typography>
                    {information?.professional_experience?.map((exp) => (
                      <div key={exp._id}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{exp.job_title} at {exp.company_name}</Typography>
                        <Typography variant="body2">{exp.experience_description}</Typography>
                        <Typography variant="body2">Duration: {exp.employment_dates}</Typography>
                        <Divider sx={{ my: 1 }} />
                      </div>
                    ))}
                  </Grid>

                  {/* Skills List */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Skills</Typography>
                    <Grid container spacing={1}>
                      {information?.inferred_skills?.map((skill, index) => (
                        <Grid item key={index}>
                          <Chip label={skill} variant="outlined" />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) :
          (
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={{ p: 2, overflow: "scroll", scrollbarWidth: "none" }}>
                <img
                  src={cvDetailsIcon}
                  object-fit="cover"
                  height="100%"
                  width="100%"
                />
              </Paper>
            </Grid>
          )
        }





      </Grid>
      <CustomModal
        open={viewModal}
        onClose={() => setViewModal(false)}
        modalContent={candidateInfo}
      />

      <CustomSideModal
        open={viewEditModal}
        onClose={() => setViewEditModal(false)}
        onUpdate={handelUpdate}
        content={candidateInfo}
      />
    </>
  )
}
export default CvParsing
