import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Roboto', 
    },
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    height: '40px !important', 
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    // border: '1px solid #660032 !important',
                },
            },
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    padding: '0px 8px !important',
                    fontSize: '12px !important',
                },
            },
        },
        MuiTableCell: { // Target the TableCell component
            styleOverrides: {
                root: { // Override styles for the root class
                    padding: 0, // Set padding to 0 to remove it
                    borderTop: '1px solid #660032 !important', // Change 'blue' to whatever color you need
                    borderBottom: '1px solid #660032 !important'
                }
            }
        },
        MuiTablePagination: {  // Target the TablePagination component
            styleOverrides: {
              selectLabel: {  // This targets the select label specifically
                marginBottom: '0 !important',  // Set margin-bottom to 0
              },
              displayedRows: { // This targets the specific part that shows '1-6 of 50'
                marginBottom: '0 !important', // Set margin-bottom to 0 to remove it
              }
            }
          },
          MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '40px !important'
                }
            }
          }
    },
});