import React from "react"
import { Avatar } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { Row, Col,  Card, CardBody, } from "reactstrap"
import "../logout.css"
import Footer from "../Footer/Footer"
import RegisterHeader from "../Header/RegisterHeader"

const LoggedOutPage = () => {
  return (
    <div className="logout-page">
      {/* {loading && <PulseLoader />} */}
      <div className="logout-background">
          <RegisterHeader/>   
            <div className="logout-box">
              <div className="logout-container">         
                  <Row >
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Card>
                        <CardBody className="logout-card">
                          {/* */}

                        </CardBody>
                      </Card>
                    </Col>
                  </Row>        
              </div>
            </div>
        </div>  
      <Footer />
    </div>
  );
};

export default LoggedOutPage
