import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PulseLoader from "pages/PulseLoader/PulseLoader"
import {
  fetchJobRequirementRequest,
  postJobRequest,
  clearMessage,
} from "store/actions"
import {
  Card,
  Typography,
  Chip,
  Box,
  Button,
  Grid,
  Container,
  Paper,
  Tooltip,
} from "@mui/material"
import ApartmentIcon from "@mui/icons-material/Apartment"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight"
import WorkIcon from "@mui/icons-material/Work"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb"
import { toast } from "react-toastify"
import ProductTabs from "pages/ProductList/ProductTabs"
import CustomWarn from "components/CustomComponents/CustomWarn"
import jobRequirementImage from "assets/images/job-application.jpg"
import "./ViewRequirement.css"

const ViewRequirement = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const requirement = useSelector(
    state => state?.JobRequirement?.jobRequirement?.data,
  )
  const { userDetails } = useSelector(state => state.Login?.user)
  const { loading, message } = useSelector(state => state?.JobRequirement)
  const [jobRequirement, setJobRequirement] = useState()
  const [open, setOpen] = useState(false)

  const handleConfirm = () => {
    setOpen(false)
    dispatch(postJobRequest({ jobId: id, userId: userDetails?.id }))
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const warnMessage = "Are you sure you want to post this job requirement?"

  const formatDate = dateString => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const spreadSkills = skills => {
    return skills?.map((skill, index) => (
      <Chip
        size="small"
        key={index}
        label={skill}
        variant="outlined"
        className="view-requirement-info-skills-chip"
      />
    ))
  }

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "Tod", href: "/tod" },
    { label: "View Requirement", href: `/todlist/view-requirement/${id}` },
  ]

  useEffect(() => {
    setJobRequirement(requirement)
  }, [requirement])

  useEffect(() => {
    dispatch(fetchJobRequirementRequest(id))
  }, [dispatch, id])

  useEffect(() => {
    if (message) {
      toast.success(message)
      dispatch(clearMessage())
      navigate("/tod")
    }
  }, [message, dispatch])

  return (
    <>
      <CustomWarn
        open={open}
        message={warnMessage}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      {loading ? (
        <PulseLoader />
      ) : (
        <div>
          <CustomBreadcrumb items={breadcrumbItems} />
          <ProductTabs tab={3} />
          <div className="view-requirement-info-container">
            <div className="view-requirement-info">
              <h5 className="view-requirement-info-title">{jobRequirement?.jobTitle}</h5>
              <div className="view-requirement-info-box">
                <Box className="view-requirement-info-box-col">
                  <ApartmentIcon />
                  <Typography className="view-requirement-info-box-col-title">{jobRequirement?.clientName}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <LocationOnOutlinedIcon />
                  <Typography className="view-requirement-info-box-col-title">{jobRequirement?.city}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <WorkIcon />    
                  <Typography className="view-requirement-info-box-col-title">{jobRequirement?.workMode}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <CurrencyRupeeIcon />
                  <Typography className="view-requirement-info-box-col-title">{jobRequirement?.minimumBudget} - {jobRequirement?.maximumBudget} Lakhs</Typography>
                </Box>
              </div>
              <div className="view-requirement-info-skills">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>Required Skills :</Typography>
                <Box>
                  {spreadSkills(jobRequirement?.mandatorySkills)}
                </Box>
              </div>
            </div>
            <div>
              <Tooltip
                title={
                  jobRequirement?.posted ? "This job is already posted!" : ""
                }
                placement="top"
              >
                <Button
                  onClick={() => setOpen(true)}
                  variant="contained"
                  disabled={jobRequirement?.posted}
                  sx={{
                    backgroundColor: "white",
                    color: "#660032",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                    "&:disabled": {
                      backgroundColor: "#cccccc",
                      color: "#666666",
                    },
                  }}
                  endIcon={<ArrowCircleRightIcon />}
                >
                  Post Requirement
                </Button>
              </Tooltip>
            </div>
          </div>
          <Grid container className="view-requirement-job-details-container">
            <Grid item xs={12} sm={7} md={7} className="view-requirement-job-details-container1">
              <h6 className="view-requirement-info-title">Job Details</h6>
              <div className="view-requirement-job-details">
                <Box className="view-requirement-info-box-col">
                  <Typography className="view-requirement-job-details-label">Job ID</Typography>
                  <Typography className="view-requirement-job-details-value">{jobRequirement?.jobRequirementId}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <Typography className="view-requirement-job-details-label">Job Type</Typography>
                  <Typography className="view-requirement-job-details-value">{jobRequirement?.jobType}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <Typography className="view-requirement-job-details-label">Notice Period</Typography>
                  <Typography className="view-requirement-job-details-value">{jobRequirement?.noticePeriod}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <Typography className="view-requirement-job-details-label">Experience Required</Typography>
                  <Typography className="view-requirement-job-details-value">{jobRequirement?.yearsOfExperience} years</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <Typography className="view-requirement-job-details-label">Openings</Typography>
                  <Typography className="view-requirement-job-details-value">{jobRequirement?.numberOfPositions}</Typography>
                </Box>
                <Box className="view-requirement-info-box-col">
                  <Typography className="view-requirement-job-details-label">Deadline</Typography>
                  <Typography className="view-requirement-job-details-value">{jobRequirement?.deadline ? formatDate(jobRequirement.deadline) : "N/A"}</Typography>
                </Box>

              </div>
              <Box className="view-requirement-job-details-description" >
                <Typography className="view-requirement-job-details-label">Job Description</Typography>
                <Typography className="view-requirement-job-details-description-box">{jobRequirement?.jobDescription}</Typography>
              </Box>

            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <img src={jobRequirementImage} alt="Job Requirement" className="view-requirement-job-details-image" />
            </Grid>
          </Grid>
          {/* <Card
            sx={{
              backgroundColor: "#660032",
              color: "white",
              padding: 2,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}>

              <Typography variant="h6" component="div">
                {jobRequirement?.jobTitle}
              </Typography>
              <Tooltip
                title={
                  jobRequirement?.posted ? "This job is already posted!" : ""
                }
                placement="top"
              >
                <span>
                  <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    disabled={jobRequirement?.posted}
                    sx={{
                      backgroundColor: "white",
                      color: "#660032",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                      "&:disabled": {
                        backgroundColor: "#cccccc",
                        color: "#666666",
                      },
                    }}
                    endIcon={<ArrowCircleRightIcon />}
                  >
                    Post A job
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ApartmentIcon sx={{ marginRight: 1 }} />
                  <Typography>{jobRequirement?.clientName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LocationOnOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography>{jobRequirement?.city}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <WorkIcon sx={{ marginRight: 1 }} />
                  <Typography>{jobRequirement?.workMode}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CurrencyRupeeIcon sx={{ marginRight: 1 }} />
                  <Typography>
                    {jobRequirement?.minimumBudget} -{" "}
                    {jobRequirement?.maximumBudget} Lakhs
                  </Typography>
                </Box>
              </Grid>
            </Grid> 

            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6" gutterBottom>
                Required Skills:
                <Box sx={{ paddingLeft: 2, display: "inline" }}>
                  {spreadSkills(jobRequirement?.mandatorySkills)}
                </Box>
              </Typography>
            </Box>
          </Card>

          

          <Grid container spacing={4} sx={{ marginTop: 1, marginBottom: 3 }}>
            <Grid item xs={12} md={6} sx={{ pb: { xs: 1, md: 0 } }}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#660032", fontWeight: "bold", mb: 2 }}
                >
                  Job Details
                </Typography>
                <Grid container spacing={2}>
                  {[
                    {
                      label: "Job ID",
                      value: jobRequirement?.jobRequirementId,
                    },
                    {
                      label: "Experience Required",
                      value: `${jobRequirement?.yearsOfExperience} years`,
                    },
                    { label: "Job Type", value: jobRequirement?.jobType },
                    {
                      label: "Openings",
                      value: jobRequirement?.numberOfPositions,
                    },
                    {
                      label: "Notice Period",
                      value: jobRequirement?.noticePeriod,
                    },
                    {
                      label: "Deadline",
                      value: jobRequirement?.deadline
                        ? formatDate(jobRequirement.deadline)
                        : "N/A",
                    },
                  ].map((item, index) => (
                    <Grid item xs={6} key={index}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontWeight: "medium" }}
                      >
                        {item.label} :
                      </Typography>
                      <Typography variant="body1">{item.value}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#660032", fontWeight: "bold", mb: 2 }}
                >
                  Job Description
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, overflowY: "auto" }}>
                  {jobRequirement?.jobDescription}
                </Typography>
              </Paper>
            </Grid>
          </Grid> */}
        </div>
      )}
    </>
  )
}

export default ViewRequirement
