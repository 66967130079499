const DashboardData = [
  {
    id: 1,
    title: "TOTAL REVENUE",
    value: "$65,841",
    detail: "Jan - Apr 2019",
    icon: "fa-dollar-sign",
    color: "#660032",
  },
  {
    id: 2,
    title: "Paid Subscribers",
    value: "26,521",
    detail: "Jan - Apr 2019",
    icon: "fa-users",
    color: "#660032",
  },
  {
    id: 3,
    title: "Unpaid Subscribers",
    value: "7,842",
    detail: "Jan - Apr 2019",
    icon: "fa-user-times",
    color: "#660032",
  },
  {
    id: 4,
    title: "Growth Percentage",
    value: "2.07%",
    detail: "Jan - Apr 2019",
    icon: "fa-chart-line",
    color: "#660032",
  },
  {
    id: 5,
    title: "Invoice",
    value: "3652",
    detail: "User Today",
    icon: "fa-file-invoice-dollar",
    color: "#660032",
  },
  {
    id: 6,
    title: "Payments",
    value: "7854",
    detail: "Statistics",
    icon: "fa-credit-card",
    color: "#660032",
  },
  {
    id: 7,
    title: "Client/User Creation",
    value: "6521",
    detail: "New Downloads",
    icon: "fa-industry",
    color: "#660032",
  },
  {
    id: 8,
    title: "Tickets",
    value: "1250",
    detail: "User This Month",
    icon: "fa-ticket-alt",
    color: "#660032",
  },
  {
    id: 9,
    title: "SQANIT",
    value: "2,562",
    detail: "Product Sold",
    icon: "fa-search",
    color: "#660032",
  },
  {
    id: 10,
    title: "PARSEIT",
    value: "8,542",
    detail: "User This Month",
    icon: "fa-code",
    color: "#660032",
  },
  {
    id: 11,
    title: "CIVIX",
    value: "6,254",
    detail: "Request Per Minute",
    icon: "fa-lock-open",
    color: "#660032",
  },
  {
    id: 12,
    title: "TOD",
    value: "N/A",
    detail: "No Data Available",
    icon: "fa-calendar",
    color: "#660032",
  },
  {
    id: 13,
    title: "Web Scraping",
    value: "N/A",
    detail: "No Data Available",
    icon: "fa-globe",
    color: "#660032",
  },
  {
    id: 14,
    title: "Sales CRM",
    value: "N/A",
    detail: "No Data Available",
    icon: "fa-chart-bar",
    color: "#660032",
  },
  {
    id: 15,
    title: "Mock Tests",
    value: "10",
    detail: "Detail Mock",
    icon: "fa-file-alt",
    color: "#660032",
  },
  {
    id: 16,
    title: "CV Template",
    value: "100",
    detail: "Unlimited Template",
    icon: "fa-file",
    color: "#660032",
  },
]

export default DashboardData
