import { 
INSERT_WORKFLOWS_REQUEST, 
INSERT_WORKFLOWS_SUCCESS, 
INSERT_WORKFLOWS_FAILURE, 
FETCH_WORKFLOWS_REQUEST, 
FETCH_WORKFLOWS_SUCCESS, 
FETCH_WORKFLOWS_FAILURE, 
FETCH_WORKFLOWS_BY_ID_REQUEST, 
FETCH_WORKFLOWS_BY_ID_SUCCESS, 
FETCH_WORKFLOWS_BY_ID_FAILURE, 
UPDATE_WORKFLOW_REQUEST, 
UPDATE_WORKFLOW_SUCCESS, 
UPDATE_WORKFLOW_FAILURE 
} from './actionTypes';

export const insertWorkflowsRequest = workflows => ({
    type: INSERT_WORKFLOWS_REQUEST,
    payload: workflows
});

export const insertWorkflowsSuccess = data => ({
    type: INSERT_WORKFLOWS_SUCCESS,
    payload: data
});

export const insertWorkflowsFailure = error => ({
    type: INSERT_WORKFLOWS_FAILURE,
    payload: error
});


export const fetchWorkflowsRequest = () => ({
    type: FETCH_WORKFLOWS_REQUEST
});

export const fetchWorkflowsSuccess = data => ({
    type: FETCH_WORKFLOWS_SUCCESS,
    payload: data
});

export const fetchWorkflowsFailure = error => ({
    type: FETCH_WORKFLOWS_FAILURE,
    payload: error
});

export const fetchWorkflowsByIdRequest = (id) => ({
    type: FETCH_WORKFLOWS_BY_ID_REQUEST,
    payload: id
});

export const fetchWorkflowsByIdSuccess = data => ({
    type: FETCH_WORKFLOWS_BY_ID_SUCCESS,
    payload: data
}); 

export const fetchWorkflowsByIdFailure = error => ({
    type: FETCH_WORKFLOWS_BY_ID_FAILURE,
    payload: error
});

export const updateWorkflowRequest = (id, details) => ({
    type: UPDATE_WORKFLOW_REQUEST,
    payload: { id, details }
});

export const updateWorkflowSuccess = workflow => ({
    type: UPDATE_WORKFLOW_SUCCESS,
    payload: workflow
});

export const updateWorkflowFailure = error => ({
    type: UPDATE_WORKFLOW_FAILURE,
    payload: error
});

