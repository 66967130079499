import { Facebook, Linkedin, Whatsapp } from "react-bootstrap-icons"

export const socialIcons = [
  {
    id: 1,
    icon: <Facebook />,
    url: "https://www.facebook.com/people/SQANO/61557444795100/",
  },
  {
    id: 2,
    icon: <Linkedin />,
    url: "https://www.linkedin.com/company/sqano/",
  },
  {
    id: 3,
    icon: <Whatsapp />,
    url: "https://wa.me/8600088499",
  },
]
