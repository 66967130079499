import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
} from "reactstrap"
import { connect } from "react-redux"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { logoutRequest, setBreadcrumbItems } from "../../store/actions"
import { createVendorRequest } from "../../store/client/vendorForm/actions"
import "./FormWizardSteps/vendor.css"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { validate } from "uuid"
import { Stepper, Step, StepLabel, StepContent, Button as MUIButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



const ClientCreation = props => {
  const dispatch = useDispatch()
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Company Information",
    "Founder Information",
    "Super Admin Information",
  ];
  const toastOptions = {
    autoClose: 500,
    progressClassName: "toast-progress-bar",
    icon: <CheckCircleIcon style={{ color: "#660032" }} />,
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "Forms", link: "#" },
    { title: "Vendor Management Form", link: "#" },
  ]

  const vendorState = useSelector(state => state.Vendor?.vendor)
  console.log("vendorState", vendorState)
  const [superAdmins, setSuperAdmins] = useState([
    {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      // doj: "",
      designation: "",
      password: "",
      profilePicture: "",
      address: "",
      aboutUser: "",
      linkedin: "",
      twitter: "",
      instagram: "",
      facebook: ""
    },
  ])

  const [vendor, setVendor] = useState({
    companyName: "",
    companyType: "",
    incorporationNumber: "",
    incorporationCertificate: "",
    gstVatNumber: "",
    gstVatCertificate: "",
    companyLogo: "",
    companySize: "",
    industry: "",
    ownerName: "",
    ownerContactNumber: "",
    ownerEmail: "",
    isPaidSubscriber: true,
    subscription: {
      subscriptionType: "premium",
      subscriptionDate: new Date(),
    },
    onFreeTrial: false,
    isPremium: true,
    payment: {
      paidOn: new Date(),
      paymentId: "",
      paidAmount: 0,
      currency: "INR",
    },
  })

  const validateForm = () => {
    let errors = {};

    // Validate vendor fields
    if (!vendor.companyName) errors.companyName = "Company Name is required";
    if (!vendor.companyType) errors.companyType = "Company Type is required";
    if (!vendor.incorporationNumber) errors.incorporationNumber = "Incorporation Number is required";
    if (!vendor.incorporationCertificate) errors.incorporationCertificate = "Incorporation Certificate is required";
    if (!vendor.gstVatNumber) errors.gstVatNumber = "GST/VAT Number is required";
    if (!vendor.gstVatCertificate) errors.gstVatCertificate = "GST/VAT Certificate is required";
    if (!vendor.companyLogo) errors.companyLogo = "Company Logo is required";
    if (!vendor.companySize) errors.companySize = "Company Size is required";
    if (!vendor.industry) errors.industry = "Industry is required";
    if (!vendor.ownerName) errors.ownerName = "Owner Name is required";
    if (!vendor.ownerEmail) errors.ownerEmail = "Owner Email is required";
    if (!vendor.ownerContactNumber) errors.ownerContactNumber = "Owner Contact Number is required";

    // Validate super admin fields
    superAdmins.forEach((admin, index) => {
      if (!admin.firstName) errors[`superAdmin-${index}-firstName`] = "First Name is required";
      if (!admin.middleName) errors[`superAdmin-${index}-middleName`] = "Middle Name is required";
      if (!admin.lastName) errors[`superAdmin-${index}-lastName`] = "Last Name is required";
      if (!admin.contactNumber) errors[`superAdmin-${index}-contactNumber`] = "Contact Number is required";
      if (!admin.email) errors[`superAdmin-${index}-email`] = "Email is required";
      if (!admin.designation) errors[`superAdmin-${index}-designation`] = "Designation is required";
      if (!admin.password) errors[`superAdmin-${index}-password`] = "Password is required";
      if (!admin.profilePicture) errors[`superAdmin-${index}-profilePicture`] = "Profile Picture is required";
      if (!admin.address) errors[`superAdmin-${index}-address`] = "Address is required";
      if (!admin.aboutUser) errors[`superAdmin-${index}-aboutUser`] = "About User is required";
    });

    return errors;
  };

  // Added validateField function to validate individual fields
  const validateField = (name, value, index = null) => {
    let error = "";

    const namePattern = /^[A-Za-z\s]+$/;
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&]).{8,20}$/;
    const phonePattern = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,6}[-\s\.]?[0-9]{1,10}$/;
    const incorporationNumberPattern = /^(U|L)(\d{5})([A-Z]{2})(\d{4})(\w{3})(\d{6})$/;
    const gstNumberPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
    const facebookPattern = /^(https?:\/\/)?(www\.)?facebook.com\/[A-Za-z0-9._%+-]+\/?$/;
    const instagramPattern = /^(https?:\/\/)?(www\.)?instagram.com\/[A-Za-z0-9._%+-]+\/?$/;
    const twitterPattern = /^(https?:\/\/)?(www\.)?twitter.com\/[A-Za-z0-9._%+-]+\/?$/;
    const linkedinPattern = /^(https?:\/\/)?(www\.)?linkedin.com\/in\/[A-Za-z0-9._%+-]+\/?$/;


    if (["firstName", "middleName", "lastName", "companyName", "ownerName"].includes(name)) {
      if (!namePattern.test(value)) {
        error = "This field cannot contain numbers or special characters.";
      }
    }


    if (name === "ownerEmail" || (index !== null && name === "email")) {
      if (!emailPattern.test(value)) {
        error = "Invalid email address format";
      }
    }

    if (name === "password") {
      if (!passwordPattern.test(value)) {
        error = "Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character.";
      }
    }

    if (name === "ownerContactNumber" || (index !== null && name === "contactNumber")) {
      if (!phonePattern.test(value)) {
        error = "Invalid phone number format";
      }
    }

    if (name === "incorporationNumber") {
      if (!incorporationNumberPattern.test(value)) {
        error = "Invalid incorporation number format";
      }
    }

    if (name === "gstVatNumber") {
      if (!gstNumberPattern.test(value)) {
        error = "Invalid GST/VAT number format";
      }
    }

    if (name === "facebook" && !facebookPattern.test(value)) {
      error = "Invalid Facebook URL format";
    }

    if (name === "instagram" && !instagramPattern.test(value)) {
      error = "Invalid Instagram URL format";
    }

    if (name === "twitter" && !twitterPattern.test(value)) {
      error = "Invalid Twitter URL format";
    }

    if (name === "linkedin" && !linkedinPattern.test(value)) {
      error = "Invalid LinkedIn URL format";
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [index !== null ? `superAdmin-${index}-${name}` : name]: error,
    }));
  };


  console.log("vendor", vendor)

  const handleVendorChange = e => {
    if (e.target.files) {
      const { name, files } = e.target;
      console.log("files", files[0]);
      setVendor(prevState => ({
        ...prevState,
        [name]: files[0],
      }));
      validateField(name, files[0]);
    } else {
      const { name, value } = e.target;
      setVendor(prevState => ({
        ...prevState,
        [name]: value,
      }));
      validateField(name, value);
    }
  };

  const handleSuperAdminChange = (index, e) => {
    if (e.target.files) {
      const { name, files } = e.target;
      const updatedSuperAdmins = [...superAdmins]
      updatedSuperAdmins[index][name] = files[0]
      setSuperAdmins(updatedSuperAdmins)
      validateField(name, files[0], index);
    } else {
      const { name, value } = e.target
      const updatedSuperAdmins = [...superAdmins]
      updatedSuperAdmins[index][name] = value
      setSuperAdmins(updatedSuperAdmins)
      validateField(name, value, index);
    }

  }

  const handleSuperAdminPhoneChange = (index, value) => {
    const updatedSuperAdmins = [...superAdmins]
    updatedSuperAdmins[index].contactNumber = value
    setSuperAdmins(updatedSuperAdmins)
    validateField("contactNumber", value, index);
  }

  // const handleVendorChange = e => {

  //   if (e.target.files) {
  //     const { name, files } = e.target;
  //     console.log("files", files[0])
  //     setVendor({ ...vendor, [name]: files[0] });
  //   } else {
  //     const { name, value } = e.target
  //     setVendor({ ...vendor, [name]: value })
  //   }


  // }

  // const handleSuperAdminChange = (index, e) => {
  //   if (e.target.files) {
  //     const { name, files } = e.target;
  //     const updatedSuperAdmins = [...superAdmins]
  //     updatedSuperAdmins[index][name] = files[0]
  //     setSuperAdmins(updatedSuperAdmins)
  //   } else {
  //     const { name, value } = e.target
  //     const updatedSuperAdmins = [...superAdmins]
  //     updatedSuperAdmins[index][name] = value
  //     setSuperAdmins(updatedSuperAdmins)
  //   }

  // }

  // const handleSuperAdminPhoneChange = (index, value) => {
  //   const updatedSuperAdmins = [...superAdmins]
  //   updatedSuperAdmins[index].contactNumber = value
  //   setSuperAdmins(updatedSuperAdmins)
  // }



  const addSuperAdmin = () => {
    setSuperAdmins([
      ...superAdmins,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        contactNumber: "",
        email: "",
        designation: "",
        password: "",
        profilePicture: "",
        address: "",
        aboutUser: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        facebook: ""
      },
    ])
  }

  const removeSuperAdmin = index => {
    setSuperAdmins(superAdmins.filter((_, i) => i !== index))
  }

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const { incorporationCertificate, gstVatCertificate, companyLogo } = vendor;

      const pdfFiles = [];
      pdfFiles.push(incorporationCertificate);
      pdfFiles.push(gstVatCertificate);
      pdfFiles.push(companyLogo);

      superAdmins.map(file => {
        const { profilePicture } = file;
        pdfFiles.push(profilePicture);
      });
      console.log(pdfFiles);

      const formData = new FormData();
      for (const file of pdfFiles) {
        formData.append("files", file);
      }
      formData.append("vendor", JSON.stringify(vendor));
      formData.append("superAdmins", JSON.stringify(superAdmins));

      const response = await dispatch(createVendorRequest(formData));
      console.log("Response", response.type);

      if (response.type === 'CREATE_VENDOR_REQUEST') {
        toast.success('Vendor registered successfully.', toastOptions);
        dispatch(logoutRequest());
        navigate("/login");
      }
    } else {
      setErrors(formErrors);
    }
  };


  // const handleSubmit = async e => {
  //   e.preventDefault()
  //   const { incorporationCertificate, gstVatCertificate, companyLogo } = vendor;

  //   const pdfFiles = [];
  //   pdfFiles.push(incorporationCertificate)
  //   pdfFiles.push(gstVatCertificate)
  //   pdfFiles.push(companyLogo)

  //   superAdmins.map(file=>{
  //     const {profilePicture}=file;
  //     pdfFiles.push(profilePicture);
  //   })
  //   console.log(pdfFiles);
  //   const formData = new FormData();
  //   // formData.append("files", pdfFiles);
  //   for (const file of pdfFiles) {
  //     formData.append("files", file);
  //   }
  //   formData.append("vendor",JSON.stringify(vendor));
  //   formData.append("superAdmins",JSON.stringify(superAdmins));

  //   const response=await dispatch(createVendorRequest(formData));
  //   console.log("Respo",response.type);

  //   if (response.type === 'CREATE_VENDOR_REQUEST') {
  //     toast.success('Vendor registered successfully..');
  //     dispatch(logoutRequest())
  //     navigate("/login");
  //   }

  // }


  useEffect(() => {
    props.setBreadcrumbItems("Vendor Management Form", breadcrumbItems)
  }, [props, breadcrumbItems])

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col md={12}>
          <Card>
            <CardBody>
              {/* <h4 className="card-title">Vendor Management Form</h4> */}
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <Form onSubmit={handleSubmit}>
                        {index === 0 && (
                          <div>
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="companyName">
                                    Entity Name / Company Name<span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    value={vendor.companyName}
                                    onChange={handleVendorChange}
                                    placeholder="Enter company name"
                                  />
                                  {errors.companyName && <span className="text-danger">{errors.companyName}</span>}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="companyType">Company Type<span style={{ color: "red" }}>*</span></Label>
                                  <Input
                                    type="select"
                                    id="companyType"
                                    name="companyType"
                                    value={vendor.companyType}
                                    onChange={handleVendorChange}
                                  >
                                    <option value="">Select company type</option>
                                    <option value="private_ltd">Private Ltd.</option>
                                    <option value="llp">LLP</option>
                                    <option value="llc">LLC</option>
                                    <option value="inc">INC.</option>
                                    <option value="public_limited">Public Limited</option>
                                    <option value="others">Others</option>
                                  </Input>
                                  {errors.companyType && <span className="text-danger">{errors.companyType}</span>}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="incorporationNumber">
                                    Incorporation Number<span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="incorporationNumber"
                                    name="incorporationNumber"
                                    value={vendor.incorporationNumber}
                                    onChange={handleVendorChange}
                                    placeholder="Enter incorporation number"
                                  />
                                  {errors.incorporationNumber && <span className="text-danger">{errors.incorporationNumber}</span>}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="incorporationCertificate">
                                    Incorporation Certificate<span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    id="incorporationCertificate"
                                    name="incorporationCertificate"
                                    onChange={handleVendorChange}
                                  />
                                  {errors.incorporationCertificate && <span className="text-danger">{errors.incorporationCertificate}</span>}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="gstVatNumber">GST/VAT Number<span style={{ color: "red" }}>*</span></Label>
                                  <Input
                                    type="text"
                                    id="gstVatNumber"
                                    name="gstVatNumber"
                                    value={vendor.gstVatNumber}
                                    onChange={handleVendorChange}
                                    placeholder="Enter GST/VAT number"
                                  />
                                  {errors.gstVatNumber && <span className="text-danger">{errors.gstVatNumber}</span>}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="gstVatCertificate">
                                    GST/VAT Certificate<span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    id="gstVatCertificate"
                                    name="gstVatCertificate"
                                    onChange={handleVendorChange}
                                  />
                                  {errors.gstVatCertificate && <span className="text-danger">{errors.gstVatCertificate}</span>}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="companySize">Company Size</Label><span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="select"
                                    id="companySize"
                                    name="companySize"
                                    value={vendor.companySize}
                                    onChange={handleVendorChange}
                                  >
                                    <option value="">Select company size</option>
                                    <option value="1-10 employees">1-10 employees</option>
                                    <option value="11-50 employees">11-50 employees</option>
                                    <option value="51-200 employees">
                                      51-200 employees
                                    </option>
                                    <option value="201-500 employees">
                                      201-500 employees
                                    </option>
                                    <option value="501-1000 employees">
                                      501-1000 employees
                                    </option>
                                    <option value="1001-5000 employees">
                                      1001-5000 employees
                                    </option>
                                    <option value="5001-10000 employees">
                                      5001-10000 employees
                                    </option>
                                    <option value="10001+ employees">
                                      10001+ employees
                                    </option>
                                  </Input>
                                  {errors.companySize && <span className="text-danger">{errors.companySize}</span>}
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="industry">Industry</Label><span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="select"
                                    id="industry"
                                    name="industry"
                                    value={vendor.industry}
                                    onChange={handleVendorChange}
                                  >
                                    <option value="">Select industry</option>
                                    <option value="Technology, Information and Media">
                                      Technology, Information and Media
                                    </option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Professional Services">
                                      Professional Services
                                    </option>
                                    <option value="Technology, Information and Internet">
                                      Technology, Information and Internet
                                    </option>
                                    <option value="Financial Services">
                                      Financial Services
                                    </option>
                                  </Input>
                                  {errors.industry && <span className="text-danger">{errors.industry}</span>}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="companyLogo">Upload Company Logo<span style={{ color: "red" }}>*</span></Label>
                                  <Input
                                    type="file"
                                    id="companyLogo"
                                    name="companyLogo"
                                    onChange={handleVendorChange}
                                  />
                                  {errors.companyLogo && <span className="text-danger">{errors.companyLogo}</span>}
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        )}

                        {index === 1 && (
                          <div>
                            {/* <h5 className="mt-3">Founder Information</h5> */}
                            <Row form>
                              <Col md={4}>
                                <FormGroup>
                                  <Label htmlFor="ownerName">Owner / Founder Name</Label><span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="text"
                                    id="ownerName"
                                    name="ownerName"
                                    value={vendor.ownerName}
                                    onChange={handleVendorChange}
                                    placeholder="Enter owner/founder name"
                                  />
                                  {errors.ownerName && <span className="text-danger">{errors.ownerName}</span>}

                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label htmlFor="ownerEmail">Owner Email ID</Label><span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="email"
                                    id="ownerEmail"
                                    name="ownerEmail"
                                    value={vendor.ownerEmail}
                                    onChange={handleVendorChange}
                                    placeholder="Enter email ID"
                                  />
                                  {errors.ownerEmail && <span className="text-danger">{errors.ownerEmail}</span>}
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label htmlFor="ownerContactNumber">
                                    Owner Contact Number<span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    value={vendor.ownerContactNumber}
                                    onChange={value =>
                                      setVendor({ ...vendor, ownerContactNumber: value })
                                    }
                                    containerStyle={{ width: "100%" }}
                                    inputStyle={{ width: "100%" }}
                                  />
                                  {errors.ownerContactNumber && <span className="text-danger">{errors.ownerContactNumber}</span>}
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        )}

                        {index === 2 && (
                          <div>
                            {superAdmins.map((admin, index) => (
                              <div key={index} className="mt-3">
                                <Row>
                                  <Col>
                                    {/* <h5>Super Admin {index + 1} Information</h5> */}
                                  </Col>
                                  <Col className="d-flex justify-content-end">
                                    {index > 0 && (
                                      <Button
                                        onClick={() => removeSuperAdmin(index)}
                                        className="btn custom-button mr-2"
                                      >
                                        <i className="fa fa-minus"></i>
                                      </Button>
                                    )}
                                    <Button
                                      onClick={addSuperAdmin}
                                      className="btn custom-button"
                                    >
                                      <i className="fa fa-plus"></i>
                                    </Button>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-firstName`}>
                                        First Name<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        id={`superAdmin-${index}-firstName`}
                                        name="firstName"
                                        value={admin.firstName}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter first name"
                                      />
                                      {errors[`superAdmin-${index}-firstName`] && <span className="text-danger">{errors[`superAdmin-${index}-firstName`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-middleName`}>
                                        Middle Name<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        id={`superAdmin-${index}-middleName`}
                                        name="middleName"
                                        value={admin.middleName}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter middle name"
                                      />
                                      {errors[`superAdmin-${index}-middleName`] && <span className="text-danger">{errors[`superAdmin-${index}-middleName`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-lastName`}>
                                        Last Name<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        id={`superAdmin-${index}-lastName`}
                                        name="lastName"
                                        value={admin.lastName}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter last name"
                                      />
                                      {errors[`superAdmin-${index}-lastName`] && <span className="text-danger">{errors[`superAdmin-${index}-lastName`]}</span>}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-contactNumber`}>
                                        Contact Number<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <PhoneInput
                                        country={"in"}
                                        enableSearch={true}
                                        value={admin.contactNumber}
                                        onChange={value =>
                                          handleSuperAdminPhoneChange(index, value)
                                        }
                                        containerStyle={{ width: "100%" }}
                                        inputStyle={{ width: "100%" }}
                                      />
                                      {errors[`superAdmin-${index}-contactNumber`] && <span className="text-danger">{errors[`superAdmin-${index}-contactNumber`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-email`}>
                                        Email ID<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="email"
                                        id={`superAdmin-${index}-email`}
                                        name="email"
                                        value={admin.email}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter email ID"
                                      />
                                      {errors[`superAdmin-${index}-email`] && <span className="text-danger">{errors[`superAdmin-${index}-email`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-designation`}>
                                        Job Title<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        id={`superAdmin-${index}-designation`}
                                        name="designation"
                                        value={admin.designation}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter designation"
                                      />
                                      {errors[`superAdmin-${index}-designation`] && <span className="text-danger">{errors[`superAdmin-${index}-designation`]}</span>}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-profilePicture`}>
                                        Upload Image<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="file"
                                        id={`superAdmin-${index}-profilePicture`}
                                        name="profilePicture"
                                        onChange={e => handleSuperAdminChange(index, e)}
                                      />
                                      {errors[`superAdmin-${index}-profilePicture`] && <span className="text-danger">{errors[`superAdmin-${index}-profilePicture`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-password`}>
                                        Password<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <div style={{ position: 'relative' }}>
                                        <Input
                                          type={showPassword ? "text" : "password"} // Toggle between text and password
                                          id={`superAdmin-${index}-password`}
                                          name="password"
                                          value={admin.password}
                                          onChange={e => handleSuperAdminChange(index, e)}
                                          placeholder="Enter password"
                                        />
                                        <span
                                          onClick={togglePasswordVisibility}
                                          style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer'
                                          }}
                                        >
                                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                      </div>
                                      {errors[`superAdmin-${index}-password`] && (
                                        <div className="password-requirements">
                                          Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character.
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-address`}>
                                        Location<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="text"
                                        id={`superAdmin-${index}-address`}
                                        name="address"
                                        value={admin.address}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter Location"
                                      />
                                      {errors[`superAdmin-${index}-address`] && <span className="text-danger">{errors[`superAdmin-${index}-address`]}</span>}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-linkedin`}>
                                        Linkedin
                                      </Label>
                                      <Input
                                        type="url"
                                        id={`superAdmin-${index}-linkedin`}
                                        name="linkedin"
                                        value={admin.linkedin}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Linkedin URL"
                                      />
                                      {errors[`superAdmin-${index}-linkedin`] && <span className="text-danger">{errors[`superAdmin-${index}-linkedin`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-twitter`}>
                                        Twitter
                                      </Label>
                                      <Input
                                        type="url"
                                        id={`superAdmin-${index}-twitter`}
                                        name="twitter"
                                        value={admin.twitter}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Twitter URL"
                                      />
                                      {errors[`superAdmin-${index}-twitter`] && <span className="text-danger">{errors[`superAdmin-${index}-twitter`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-instagram`}>
                                        Instagram
                                      </Label>
                                      <Input
                                        type="url"
                                        id={`superAdmin-${index}-instagram`}
                                        name="instagram"
                                        value={admin.instagram}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Instagram URL"
                                      />
                                      {errors[`superAdmin-${index}-instagram`] && <span className="text-danger">{errors[`superAdmin-${index}-instagram`]}</span>}
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-facebook`}>
                                        Facebook
                                      </Label>
                                      <Input
                                        type="url"
                                        id={`superAdmin-${index}-facebook`}
                                        name="facebook"
                                        value={admin.facebook}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Facebook URL"
                                      />
                                      {errors[`superAdmin-${index}-facebook`] && <span className="text-danger">{errors[`superAdmin-${index}-facebook`]}</span>}</FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  {/* <Col md={4}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-doj`}>
                                        DOJ<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="date"
                                        id={`superAdmin-${index}-doj`}
                                        name="doj"
                                        max={getTodayDate()}
                                        value={admin.doj}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="Enter Joined Date"
                                      />
                                      {errors[`superAdmin-${index}-doj`] && <span className="text-danger">{errors[`superAdmin-${index}-doj`]}</span>}
                                    </FormGroup>
                                  </Col> */}
                                  <Col md={12}>
                                    <FormGroup>
                                      <Label htmlFor={`superAdmin-${index}-aboutUser`}>
                                        About user<span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Input
                                        type="textArea"
                                        id={`superAdmin-${index}-aboutUser`}
                                        name="aboutUser"
                                        value={admin.aboutUser}
                                        onChange={e => handleSuperAdminChange(index, e)}
                                        placeholder="About user"
                                      />

                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        )}

                        <div style={{ marginTop: 16, textAlign: 'right' }} >
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            style={{ color: 'white', backgroundColor: '#660032', marginRight: 8 }}
                          >
                            Back
                          </Button>
                          <Button
                            style={{ color: 'white', backgroundColor: '#660032' }}
                            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                          >
                            {activeStep === steps.length - 1 ? "Submit" : "Next"}
                          </Button>
                        </div>
                      </Form>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>

              {activeStep === steps.length && (
                <div>
                  <MUIButton onClick={handleReset}>Reset</MUIButton>
                </div>
              )}
              {/* </Form> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  vendor: state.vendor,
})

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  createVendorRequest,
})(ClientCreation)
