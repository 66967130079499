import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Link
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import visa from "../../assets/images/visa.png";
import mastercard from "../../assets/images/mastercard.png";
import americanexpress from "../../assets/images/american-express.png";
import LockIcon from "@mui/icons-material/Lock";
import { Col, Row } from "reactstrap";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./AccountPage.scss";

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState("subscription");
  const [open, setOpen] = useState(false);
  const [modalKey, setModalKey] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [reason, setReason] = useState("");
  const [resubscribe, setResubscribe] = useState("");
  const [zoom, setZoom] = useState(1);
  const [isMaximized, setIsMaximized] = useState(false);

  const handleCardNumberChange = (event) => setCardNumber(event.target.value);
  const handleExpiryMonthChange = (event) => setExpiryMonth(event.target.value);
  const handleExpiryYearChange = (event) => setExpiryYear(event.target.value);
  const handleSecurityCodeChange = (event) => setSecurityCode(event.target.value);

  const handleClickOpen = (e, value) => {
    setOpen(true);
    setModalKey(value);
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Selected reason:", reason);
    console.log("Willing to resubscribe:", resubscribe);
    // Add form submission logic here
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // const renderSubscriptionDetails = () => (
  //   <React.Fragment>
  //     <Grid item xs={12} md={6}>
  //       <Box>
  //         <Typography variant="h6" component="h2">
  //           Current Plan
  //         </Typography>
  //         <Paper className="card">
  //           <Typography variant="subtitle1" component="h3" className="title">
  //             Yearly
  //           </Typography>
  //           <Typography variant="body2" className="subtitle">
  //             Subscription renewal date
  //           </Typography>
  //           <Typography variant="body2">
  //             December 8, 2024 (in 185 days)
  //           </Typography>
  //           <Typography variant="body2" className="subtitle">
  //             What you'll be charged
  //           </Typography>
  //           <Typography variant="body2">USD $198.00 (excluding tax)</Typography>
  //           <Button
  //             variant="outlined"
  //             onClick={(e) => handleClickOpen(e, "isSubscription")}
  //             color="error"
  //             className="button"
  //           >
  //             Cancel Subscription
  //           </Button>
  //         </Paper>
  //       </Box>

  //       <Box>
  //         <Paper className="card team-upgrade">
  //           <Typography variant="subtitle1" component="h3" className="title">
  //             Envato Elements for teams
  //           </Typography>
  //           <Typography variant="body2" className="subtitle">
  //             Add multiple people to this account.
  //           </Typography>
  //           <Box className="actions">
  //             <Button
  //               variant="contained"
  //               sx={{ backgroundColor: "#660032", color: "#ffffff" }}
  //               onClick={(e) => handleClickOpen(e, "isUpgrade")}
  //               className="button"
  //             >
  //               Upgrade Account
  //             </Button>
  //             <Button
  //               variant="outlined"
  //               sx={{ border: "1px solid #660032", color: "#660032" }}
  //               className="button"
  //             >
  //               Learn More
  //             </Button>
  //           </Box>
  //         </Paper>
  //       </Box>
  //     </Grid>

  //     <Grid item xs={12} md={6}>
  //       <Typography variant="h6" component="h2">
  //         Payment Details
  //       </Typography>
  //       <Paper className="card">
  //         <Typography variant="body2" className="subtitle">
  //           Payment method
  //         </Typography>
  //         <Typography variant="body2" className="subtitle">
  //           Credit card
  //         </Typography>
  //         <Typography variant="body2" className="subtitle">
  //           <img
  //             src="https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg"
  //             alt="Visa"
  //             style={{ height: "20px", marginRight: "10px" }}
  //           />
  //           **** **** **** 9817
  //         </Typography>
  //         <Button
  //           variant="outlined"
  //           sx={{ border: "1px solid #660032", color: "#660032" }}
  //           onClick={(e) => handleClickOpen(e, "isPayment")}
  //           className="button"
  //         >
  //           Update Payment Method
  //         </Button>

  //         <Dialog
  //           sx={{
  //             borderRadius: "10px !important",
  //             width: isMaximized ? "100%" : "auto",
  //             height: isMaximized ? "100%" : "auto",
  //             padding: "10px",
  //           }}
  //           open={open}
  //           onClose={handleClose}
  //           aria-labelledby="alert-dialog-title"
  //           aria-describedby="alert-dialog-description"
  //           maxWidth={isMaximized ? "xl" : "sm"}
  //           fullWidth={isMaximized}
  //         >
  //           <DialogTitle
  //             id="alert-dialog-title"
  //             sx={{
  //               display: "flex",
  //               justifyContent: "space-between",
  //               alignItems: "center",
  //               borderBottom: "1px solid #e3e3e3",
  //               padding: "4px 24px",
  //             }}
  //           >
  //             <Box>
  //               <Typography
  //                 variant="h5"
  //                 component="h5"
  //                 sx={{ color: "#660032", fontSize: "16px", fontWeight: "600" }}
  //                 className="payment-popup-header"
  //               >
  //                 {modalKey === "isPayment"
  //                   ? "My Account"
  //                   : modalKey === "isSubscription"
  //                     ? "Cancel Subscription"
  //                     : "Upgrade Account"}
  //               </Typography>
  //             </Box>
  //             <Box>
  //               {/* <IconButton onClick={toggleMaximize}>
  //                 {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
  //               </IconButton> */}
  //               <IconButton onClick={handleClose}>
  //                 <CloseIcon />
  //               </IconButton>
  //             </Box>
  //           </DialogTitle>
  //           <DialogContent>
  //             {modalKey === "isPayment" ? (
  //               <DialogContentText id="alert-dialog-description">
  //                 <Box className="update-payment-method-page">
  //                   <Typography
  //                     variant="h5"
  //                     component="h5"
  //                     sx={{
  //                       fontSize: "16px",
  //                       fontWeight: "600",
  //                       marginBottom: "10px",
  //                     }}
  //                     className="subheader"
  //                   >
  //                     Update payment method
  //                   </Typography>

  //                   <Alert severity="warning" className="alert">
  //                     PayPal is not available in your country. Please enter your
  //                     credit card information for payment instead.
  //                   </Alert>

  //                   <Paper className="payment-form">
  //                     <Grid container spacing={2}>
  //                       <Grid item xs={12}>
  //                         <Box className="card-icons">
  //                           <img
  //                             src="https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg"
  //                             alt="Visa"
  //                             className="card-icon"
  //                           />
  //                           <img
  //                             src={mastercard}
  //                             alt="Mastercard"
  //                             className="card-icon"
  //                           />
  //                           <img
  //                             src={americanexpress}
  //                             alt="American Express"
  //                             className="card-icon"
  //                           />
  //                         </Box>
  //                       </Grid>
  //                       <Grid item xs={12}>
  //                         <TextField
  //                           label="Card Number"
  //                           variant="outlined"
  //                           fullWidth
  //                           required
  //                           value={cardNumber}
  //                           onChange={handleCardNumberChange}
  //                           InputProps={{
  //                             endAdornment: (
  //                               <LockIcon
  //                                 sx={{ fontSize: "20", color: "#666666" }}
  //                               />
  //                             ),
  //                           }}
  //                         />
  //                       </Grid>
  //                       <Grid item xs={6} sm={4}>
  //                         <TextField
  //                           label="Expiry Month"
  //                           variant="outlined"
  //                           fullWidth
  //                           required
  //                           value={expiryMonth}
  //                           onChange={handleExpiryMonthChange}
  //                         />
  //                       </Grid>
  //                       <Grid item xs={6} sm={4}>
  //                         <TextField
  //                           label="Expiry Year"
  //                           variant="outlined"
  //                           fullWidth
  //                           required
  //                           value={expiryYear}
  //                           onChange={handleExpiryYearChange}
  //                         />
  //                       </Grid>
  //                       <Grid item xs={12} sm={4}>
  //                         <TextField
  //                           label="Security Code"
  //                           variant="outlined"
  //                           fullWidth
  //                           required
  //                           value={securityCode}
  //                           onChange={handleSecurityCodeChange}
  //                         />
  //                       </Grid>
  //                     </Grid>

  //                     <Typography variant="body2" className="note">
  //                       Your new payment method will take effect at the end of
  //                       your current billing cycle. Your subscription and
  //                       payment cycle will remain the same.
  //                     </Typography>

  //                     <Box className="form-actions">
  //                       <Button
  //                         variant="contained"
  //                         sx={{ backgroundColor: "#660032", color: "#fff" }}
  //                       >
  //                         Confirm
  //                       </Button>
  //                       <Button
  //                         variant="outlined"
  //                         className="cancel-button"
  //                         sx={{ border: "1px solid #660032", color: "#660032" }}
  //                       >
  //                         Cancel
  //                       </Button>
  //                     </Box>
  //                   </Paper>
  //                 </Box>
  //               </DialogContentText>
  //             ) : modalKey === "isSubscription" ? (
  //               <DialogContentText id="alert-dialog-description">
  //                 <Box className="update-payment-method-page">
  //                   <h5
  //                     sx={{
  //                       fontSize: "16px",
  //                       fontWeight: "600",
  //                       marginBottom: "10px",
  //                     }}
  //                     className="subheader"
  //                   >
  //                     Your Current Subscription
  //                   </h5>
  //                   <p>
  //                     If you do not cancel, your current subscription will
  //                     automatically renew on Dec 8, 2024 and you will be charged
  //                     USD $198.00 (excluding tax).
  //                   </p>

  //                   <div className="subscription-wrap">
  //                     <h5
  //                       sx={{
  //                         fontSize: "16px",
  //                         fontWeight: "600",
  //                         marginBottom: "10px",
  //                       }}
  //                       className="subheader"
  //                     >
  //                       What will happen after I cancel?
  //                     </h5>
  //                     <p>Between now and Dec 7, 2024:</p>
  //                     <ul>
  //                       <li>your current subscription will still be valid</li>
  //                       <li>
  //                         you can continue downloading and creating new licenses
  //                       </li>
  //                       <li>there will not be any refunds</li>
  //                     </ul>
  //                     <p>After Dec 7, 2024:</p>
  //                     <ul>
  //                       <li>
  //                         you will be able to see a list of items you have
  //                         previously licensed and continue using them for the
  //                         projects they are licensed for
  //                       </li>
  //                       <li>
  //                         your payment method will no longer be charged unless
  //                         you resubscribe
  //                       </li>
  //                       <li>
  //                         you will not be able to download any item, including
  //                         previously licensed items
  //                       </li>
  //                       <li>
  //                         you will not be able to create new licenses for any
  //                         item, including previously licensed items
  //                       </li>
  //                       <li>
  //                         if you resubscribe, you will be charged a new
  //                         subscription price (whatever the latest is at the time
  //                       </li>
  //                     </ul>

  //                     <form onSubmit={handleSubmit}>
  //                       <h2>Help us improve!</h2>
  //                       <p>
  //                         Your answers may directly lead to improvements for
  //                         subscribers and will enable us to continually improve
  //                         the experience.
  //                       </p>

  //                       <label>
  //                         What is the main reason you have decided to cancel
  //                         your subscription?
  //                       </label>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="difficultSearch"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           Searching for digital asset was too difficult
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="notEnoughAssets"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           There's not enough of the specific digital assets I
  //                           need
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="noBudget"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           I no longer have the budget for the subscription
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="foundBetter"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           I found another source of digital assets that better
  //                           suits my needs
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="projectComplete"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           I subscribed for a specific project and it is now
  //                           complete
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="notUsingEnough"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           I'm not using it enough to justify the cost
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="poorExperience"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>
  //                           I had a poor experience/s on Envato Elements
  //                         </label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="reason"
  //                           value="other"
  //                           onChange={(e) => setReason(e.target.value)}
  //                         />
  //                         <label>Other (please specify)</label>
  //                       </div>

  //                       <label>
  //                         Do you think you might resubscribe to Envato Elements
  //                         in the future?
  //                       </label>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="resubscribe"
  //                           value="unsure"
  //                           onChange={(e) => setResubscribe(e.target.value)}
  //                         />
  //                         <label>Unsure at this stage</label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="resubscribe"
  //                           value="yes"
  //                           onChange={(e) => setResubscribe(e.target.value)}
  //                         />
  //                         <label>Yes</label>
  //                       </div>
  //                       <div>
  //                         <input
  //                           type="radio"
  //                           name="resubscribe"
  //                           value="no"
  //                           onChange={(e) => setResubscribe(e.target.value)}
  //                         />
  //                         <label>No</label>
  //                       </div>
  //                       <p>
  //                         If you have any suggestions or feedback we'd love to
  //                         know how we can improve! (We're not able to respond to
  //                         enquiries submitted through this survey. Visit our
  //                         Help Center for assistance)
  //                       </p>
  //                       <Row className="mb-2">
  //                         <Col md={12}>
  //                           <textarea placeholder="Enter your feedback here" />
  //                         </Col>
  //                       </Row>
  //                     </form>
  //                   </div>

  //                   <Box className="form-actions">
  //                     <Button
  //                       variant="contained"
  //                       sx={{ backgroundColor: "#660032", color: "#fff" }}
  //                     >
  //                       Cancel My Subscription
  //                     </Button>
  //                     <Button
  //                       variant="outlined"
  //                       className="cancel-button"
  //                       sx={{ border: "1px solid #660032", color: "#660032" }}
  //                     >
  //                       Keep My Subscription
  //                     </Button>
  //                   </Box>
  //                 </Box>
  //               </DialogContentText>
  //             ) : (
  //               <DialogContentText id="alert-dialog-description">
  //                 <Box className="update-payment-method-page upgrade-page">
  //                   <Stepper activeStep={activeStep} alternativeLabel>
  //                     <Step style={{color:"#660032"}} onClick={() => handleStepChange(0)}>
  //                       <StepLabel>Upgrade Account</StepLabel>
  //                     </Step>
  //                     <Step onClick={() => handleStepChange(1)}>
  //                       <StepLabel>Payment Information</StepLabel>
  //                     </Step>
  //                     <Step onClick={() => handleStepChange(2)}>
  //                       <StepLabel>Payment History</StepLabel>
  //                     </Step>
  //                   </Stepper>
  //                   {activeStep === 0 && (
  //                    <Grid container spacing={2} className="mt-4">
  //                    <Grid item xs={12} sm={6} md={6}>
  //                      <Paper className="card upgrade-ac">
  //                        <h4>Starter</h4>
  //                        <h2 className="hcolor">$40/ <span className="ac-wrap mb-2">Per month</span></h2>
  //                        <Button variant="contained" className="upgrade-ac-btn mb-3">Sign up Now</Button>
  //                        <ul>
  //                          <li>Free SQANIT</li>
  //                          <li>Free PARSEIT</li>
  //                          <li>free CIVIX</li>
  //                          <li>Free TOD</li>
  //                        </ul>
  //                      </Paper>
  //                    </Grid>
  //                    <Grid item xs={12} sm={6} md={6}>
  //                      <Paper className="card upgrade-ac">
  //                        <h4>Basic</h4>
  //                        <h2 className="hcolor">$60/ <span className="ac-wrap mb-2">Per month</span></h2>
  //                        <Button variant="contained" className="upgrade-ac-btn mb-3">Sign up Now</Button>
  //                        <ul>
  //                          <li>Free SQANIT</li>
  //                          <li>Free PARSEIT</li>
  //                          <li>free CIVIX 20 Downloads</li>
  //                          <li>TOD Integration - New Requirement, Job Postings</li>
  //                          <li>WEBIT</li>
  //                        </ul>
  //                      </Paper>
  //                    </Grid>
  //                    <Grid item xs={12} sm={6} md={6}>
  //                      <Paper className="card upgrade-ac">
  //                        <h4>Standard</h4>
  //                        <h2 className="hcolor">$80/ <span className="ac-wrap mb-2">Per month</span></h2>
  //                        <Button variant="contained" className="upgrade-ac-btn mb-3">Sign up Now</Button>
  //                        <ul>
  //                          <li>Free SQANIT</li>
  //                          <li>Free PARSEIT</li>
  //                          <li>free CIVIX 20 Downloads</li>
  //                          <li>TOD Integration - New Requirement, Job Postings</li>
  //                          <li>WEBIT</li>
  //                        </ul>
  //                      </Paper>
  //                    </Grid>
  //                    <Grid item xs={12} sm={6} md={6}>
  //                      <Paper className="card upgrade-ac">
  //                        <h4>Enterprice</h4>
  //                        <h2 className="hcolor">$100/ <span className="ac-wrap mb-2">Per month</span></h2>
  //                        <Button variant="contained" className="upgrade-ac-btn mb-3">Sign up Now</Button>
  //                        <ul>
  //                          <li>Free SQANIT</li>
  //                          <li>Free PARSEIT</li>
  //                          <li>free CIVIX 50 Downloads</li>
  //                          <li>TOD Integration</li>
  //                          <li>WEBIT</li>
  //                          <li>Sales CRM - YTC</li>
  //                        </ul>
  //                      </Paper>
  //                    </Grid>
  //                  </Grid>
                   
                    
  //                   )}
  //                   {activeStep === 1 && (
  //                     <Box className="payment-info mt-4">
  //                       <div className="payment-info-img">
  //                       <img src={visa} alt="Visa"/> 
  //                       <span>Your current payment method is Visa ending with 2819</span>
  //                       </div>

  //                       <fieldset role="group" aria-labelledby="label-type" className="m-0 form-group mt-4">
  //                         <div className="form-row">
  //                           <label for="payment_cc" id="label-type" 
  //                           className="col-md-3 col-form-label form-label">Payment type</label>
  //                           <div role="group" aria-labelledby="label-type" className="col-md-9">
  //                             <div role="group" className="btn-group btn-group-toggle" data-toggle="buttons" 
  //                             style={{color:"#660032"}}>
  //                               <label className="btn btn-outline-secondary active">
  //                                 <input type="radio" id="payment_cc" name="payment_type" value="cc" checked
  //                                 aria-checked="true"/> Debit or credit card
  //                               </label>
  //                               <label className="btn btn-outline-secondary">
  //                               <input type="radio" id="payment_pp" name="payment_type" value="pp" checked
  //                                 aria-checked="true"/>  PayPal       
  //                               </label>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </fieldset>
                        
                        
  //                       <TextField
  //                         label="Card Number"
  //                         variant="outlined"
  //                         fullWidth
  //                         required
  //                         margin="normal"
  //                         maxlength="4" size="3"
  //                       />
  //                       <TextField
  //                         label="Security Code (CVV)"
  //                         variant="outlined"
  //                         fullWidth
  //                         required
  //                         margin="normal"
  //                         maxlength="4" size="3"
  //                         // style={{ width: '300px', height: '30px' }}
  //                       />
  //                         <fieldset role="group" aria-labelledby="label-type" className="m-0 form-group mb-2 mt-4">
  //                         <div className="form-row">
  //                           <label for="payment_cc" id="label-type" 
  //                           className="col-md-3 col-form-label form-label date-1">Expiration Date</label>
  //                           <div role="group" aria-labelledby="label-type" className="col-md-9 "> 
  //                               <input type="month" id="start" name="start" min="2018-03" value="2018-05" 
  //                               className="form-control singledate"/>
  //                           </div>
  //                         </div>
  //                       </fieldset>



  //                       {/* <Box sx={{ display: 'flex', marginBottom: 2 }}>
  //                         <FormControl>
  //                           <InputLabel>Expiration Month</InputLabel>
  //                           <Select>
  //                             <MenuItem value="january">January</MenuItem>
  //                             <MenuItem value="february">February</MenuItem>
  //                             <MenuItem value="march">March</MenuItem>
                           
  //                           </Select>
  //                         </FormControl>
  //                         <FormControl>
  //                           <InputLabel>Expiration Year</InputLabel>
  //                           <Select>
  //                             <MenuItem value="2024">2024</MenuItem>
  //                             <MenuItem value="2025">2025</MenuItem>
  //                             <MenuItem value="2026">2026</MenuItem>
                           
  //                           </Select>
  //                         </FormControl>
  //                       </Box> */}
  //                       <Button variant="contained" style={{backgroundColor:"#660032"}} className="mt-4">
  //                         Save Changes
  //                       </Button>
  //                     </Box>
  //                   )}
  //                   {activeStep === 2 && (
  //                     <Box sx={{ marginTop: 2 }}>
  //                       <div className="Appinfo">
  //                         <div className="payment-box">
  //                           <p>
  //                             Please pay your amount due <span className="amount">$25.00</span> with invoice <a href="#" className="invoice-link">#8331</a>
  //                           </p>
  //                           <button className="pay-now-btn">Pay Now</button>
  //                         </div>
  //                       </div>
  //                       <Typography>Your current payment method is Visa ending with 2819</Typography>
  //                       <TableContainer component={Paper}>
  //                         <Table aria-label="payment history table">
  //                           <TableHead>
  //                             <TableRow>
  //                               <TableCell>DOCUMENT</TableCell>
  //                               <TableCell>AMOUNT</TableCell>
  //                               <TableCell>STATUS</TableCell>
  //                               <TableCell>DATE</TableCell>
  //                             </TableRow>
  //                           </TableHead>
  //                           <TableBody>
  //                             <TableRow>
  //                               <TableCell>
  //                                 <Link href="#">INVOICE #12199</Link>
  //                               </TableCell>
  //                               <TableCell>$25 USD</TableCell>
  //                               <TableCell>
  //                                 <Typography color="green">PAID</Typography>
  //                               </TableCell>
  //                               <TableCell>12 FEB 2016</TableCell>
  //                             </TableRow>
  //                             <TableRow>
  //                               <TableCell>
  //                                 <Link href="#">INVOICE #8331</Link>
  //                               </TableCell>
  //                               <TableCell>$25 USD</TableCell>
  //                               <TableCell>
  //                                 <Typography color="red">UNPAID</Typography>
  //                               </TableCell>
  //                               <TableCell>12 JAN 2016</TableCell>
  //                             </TableRow>
  //                             <TableRow>
  //                               <TableCell>
  //                                 <Link href="#">INVOICE #2421</Link>
  //                               </TableCell>
  //                               <TableCell>$25 USD</TableCell>
  //                               <TableCell>
  //                                 <Typography color="green">PAID</Typography>
  //                               </TableCell>
  //                               <TableCell>12 DEC 2016</TableCell>
  //                             </TableRow>
  //                           </TableBody>
  //                         </Table>
  //                       </TableContainer>
  //                       <Box display="flex" justifyContent="center" sx={{ marginY: 2 }}>
  //                         <Pagination count={2} page={1} color="primary" />
  //                       </Box>
  //                       <Button variant="contained" color="success" sx={{ float: "right" }}>
  //                         Pay Now
  //                       </Button>
  //                     </Box>
  //                   )}
  //                 </Box>
  //               </DialogContentText>
  //             )}
  //           </DialogContent>
  //           <DialogActions></DialogActions>
  //         </Dialog>

  //         <div className="divider"></div>

  //         <Typography variant="body2" className="subtitle">
  //           Billing Information
  //         </Typography>
  //         <Typography variant="body2" className="billing-info">
  //           <div className="info-item">Kiran Kambhatla</div>
  //           <div className="info-item">
  //             Ample Enterprise Technologies Private Limited
  //           </div>
  //           <div className="info-item">
  //             902, B2 Wing, Mandke Advantage Society
  //           </div>
  //           <div className="info-item">Lulla Nagar, Near Sapna Hotel</div>
  //           <div className="info-item">Pune, Maharashtra 411040, India</div>
  //           <div className="info-item">GSTIN 29AAKCA3588N1ZU</div>
  //         </Typography>
  //         <Button
  //           variant="outlined"
  //           sx={{ border: "1px solid #660032", color: "#660032" }}
  //           className="button"
  //         >
  //           Update Billing Information
  //         </Button>
  //       </Paper>
  //     </Grid>
  //   </React.Fragment>
  // );

  // const renderAccountDetails = () => (
  //   <Grid item xs={12} md={6}>
  //     <Typography variant="h6" component="h2">
  //       Account Details
  //     </Typography>
  //     <Paper className="card">
  //       <Typography variant="body2" className="subtitle">
  //         Username
  //       </Typography>
  //       <Typography variant="body2" className="billing-info">
  //         kiranample
  //       </Typography>
  //       <Typography variant="body2" className="subtitle">
  //         Name
  //       </Typography>
  //       <Typography variant="body2" className="billing-info">
  //         Kiran Kumar Kambhatla
  //       </Typography>
  //       <Typography variant="body2" className="subtitle">
  //         Email
  //       </Typography>
  //       <Typography variant="body2" className="billing-info">
  //         kiran@ample.be
  //       </Typography>
  //       <Button
  //         variant="outlined"
  //         sx={{ border: "1px solid #660032", color: "#660032" }}
  //         className="button"
  //       >
  //         Update account details
  //       </Button>
  //     </Paper>
  //   </Grid>
  // );

  return (
    // <Box className="account-page">
    //   <Typography variant="h6" component="h1" className="header">
    //     My Account
    //   </Typography>

    //   <Grid container spacing={3}>
    //     <Grid item xs={12}>
    //     <Box className="tabs">
    //   <Button
    //     className={activeTab === "subscription" ? "active" : ""}
    //     variant="text"
    //     onClick={() => setActiveTab("subscription")}
    //   >
    //     Subscription Details
    //   </Button>
    //   <Button
    //     className={activeTab === "account" ? "active" : ""}
    //     variant="text"
    //     onClick={() => setActiveTab("account")}
    //   >
    //     Account Details
    //   </Button>
    // </Box>
    //     </Grid>

    //     {activeTab === "subscription" && renderSubscriptionDetails()}
    //     {activeTab === "account" && renderAccountDetails()}
    //   </Grid>
    // </Box>
    <div>      
    <div className="row">
      <div className="text-center" >
       <h2 style={{fontSize:"40px", color:"#660032", fontWeight:"bold", marginTop:"60px"}}>Coming Soon...</h2>
       </div>
      </div>     
</div>
  );
};

export default AccountPage;
