import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { useSelector } from 'react-redux';

const DonutChart2 = () => {
    const { parsedCount = 0, civixCount = 0, scannedCount = 0 } = useSelector(state => state.usersReducer?.allCounts || {});
    const data = {
        labels: ['TOD', 'PARSEIT', 'SQANIT'],
        datasets: [
            {
                label: 'Profiles Source',
                data: [parsedCount || 0, civixCount || 0, scannedCount || 0], 
                backgroundColor: [
                    "#0000FF","#FF5800","#C3F550"
                ],
                hoverOffset: 4,
                borderWidth: 1,
            },
        ],
    };

    const size = {
        height: 300,
      }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
            },
        },
        cutout: '70%', // This creates the donut shape
    };

    return (
        <div style={{ height: '250px', width: '250px' }}>
                <Doughnut data={data} options={options} />
            </div>
    );
};

export default DonutChart2;
