import React, { useEffect, useState, useRef } from 'react'
import MUIDataTable from 'mui-datatables';
import PulseLoader from 'pages/PulseLoader/PulseLoader';
import { useDispatch } from 'react-redux';
import { getParsedProfileDataByUserIdRequest, updateCandidateRequest } from 'store/actions';
import { useSelector } from 'react-redux';
import {
    Tooltip, TextField, Paper, Stepper, Stack, Chip, InputAdornment,Modal,
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, IconButton, Grid, Step, StepLabel, StepContent
} from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { ProgressBar } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CustomSideModal from "../../../components/CustomComponents/CutomSideModal"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';
import '../../../components/CustomComponents/CustomComponent.css';
import FilterButton from './FilterButton';
import CustomSearchRender from 'pages/Sqano Dashboard/CustomSearchRender';
// import './CandidateDetailsDialog.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CloseOutlined } from '@mui/icons-material';
import "./Download.scss";
import "./parseitHistory.css";
import { Label } from 'reactstrap';


//stepper theme(color)
const theme = createTheme({
    palette: {
        primary: {
            main: '#660032',
        },
    },
});


function ParseItHistory() {
    const dispatch = useDispatch();
    const { profiles, loading, candidate } = useSelector(state => state?.parseProfilesReducer);
    const { id } = useSelector(state => state.Login?.user?.userDetails);
    const [updatedProfiles, setUpdatedProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState();
    const [viewProfile, setViewProfile] = useState(false);
    const [editView, setEditView] = useState(false);
    const [step, setStep] = useState();
    const [personalDetails, setPersonalDetails] = useState();
    const [educationalDetails, setEducationalDetails] = useState([]);
    const [skills, setSkills] = useState([]);
    const [newSkill, setNewSkill] = useState('');
    const [certificationDetails, setCertificationDetails] = useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [inputField, setInputField] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState();
    const stepsData = [];
    const [filterValues, setFilterValues] = useState({});
    const [filterModalView, setFilterModalView] = useState(false);


    useEffect(() => {
        setPersonalDetails({
            name: selectedProfile?.name || "NA",
            email: selectedProfile?.email || "NA",
            contact: selectedProfile?.contact || "NA",
            location: selectedProfile?.location || "NA",
            preferredLocation: selectedProfile?.preferredLocation || "NA",
            jobTitle: selectedProfile?.jobTitle || "NA",
            totalExperience: selectedProfile?.experience || "NA",
        })
        setEducationalDetails(educationData)
        setCertificationDetails(certificationsData)
        setSkills(selectedProfile?.inferredSkills)
    }, [selectedProfile])


    const customBodyRender = (value) => (
        <div>
            {value?.length > 15 ? (
                <Tooltip title={value} arrow 
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#660032',
                                color: '#ffffff',
                                '& .MuiTooltip-arrow': {
                                    color: '#660032',
                                }
                            }
                        }
                    }}
                >
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100px',
                            display: 'inline-block',  // Changed from block to inline-block
                            width: 'fit-content',     // Added to make width fit content
                        }}>{value}</span>
                </Tooltip>
            ) : (
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100px',
                        display: 'inline-block',
                        width: 'fit-content',
                    }}>{value}</span>
            )}
        </div>
    );

    const columns = [
        {
            name: "_id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
                print: false,
                viewColumns: false,
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
            },
        },
        {
            name: "contact",
            label: "Phone",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "preferredLocation",
            label: "Preferred Location",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "jobTitle",
            label: "Job Title",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "experience",
            label: "Experience (Yrs)",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "dataFulfillment",
            label: "Data Fulfillment",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{
                        color: "#660032", paddingLeft: "14px", whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                    }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender: (value) => {
                    return (
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100px',
                        }}>
                            {/* <Chip sx={{ fontSize: "9px" }} size="small" label={label} color={color} /> */}
                            <ProgressBar
                                variant={value > 60 ? "success" : "danger"}
                                now={value}
                                label={
                                    <span
                                        style={{ color: "black" }}
                                    >{`${value}%`}</span>
                                }
                                style={{
                                    border: value > 60 ? "1px solid #22bb33" : "1px solid #bb2124",
                                    margin: 12,
                                    height: '13px', // Increased height
                                }}
                            />
                        </div>
                    );
                },
            },
        },
        {
            name: "analysis",
            label: "Action",
            options: {
                display: "excluded",
                filter: true,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender: function viewCandidateSynopsisAndQuestionnaire(
                    value,
                    tableMeta
                ) {
                    return (
                        <>
                            <Tooltip title="Fitment percentage is below 65%">
                                <span>
                                    <IconButton disabled>
                                        <VisibilitySharpIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    );
                },
            },
        },
        {
            name: "parsedDate",
            label: "Active Since",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "actions",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender: (value, tableMeta) => {
                    const handleClick = (event) => {
                        event.stopPropagation(); // Prevents the click from triggering onRowClick
                        handelEditClick(tableMeta.rowData[0]);
                    };
                    return (
                        <div onClick={handleClick}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100px',
                            }}>
                            <Tooltip title="Edit Profile" arrow 
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: '#660032',
                                            color: '#ffffff',
                                            '& .MuiTooltip-arrow': {
                                                color: '#660032',
                                            }
                                        }
                                    }
                                }}
                            >

                                <DriveFileRenameOutlineIcon />

                            </Tooltip>
                        </div>
                    );
                },
            },
        },
    ];

    const handleRowClick = (rowData) => {
        const profileData = profiles?.find(profile => profile._id === rowData[0]);
        if (profileData) {
            setSelectedProfile(profileData);
            setViewProfile(true)
        }
    }

    const handelEditClick = (rowData) => {
        console.log("click", rowData)
        const profileData = profiles?.find(profile => profile._id === rowData);
        if (profileData) {
            setSelectedProfile(profileData);
            setEditView(true)
        }
    }

    const handleModalClose = () => {
        setInputField(false)
        setActiveStep(0)
        setEditView(false)
    }

    const options = {
        // filterType: "dropdown",
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        searchAlwaysOpen: false,
        setTableProps: () => ({
            size: "small",
        }),
        // customSearchRender: (searchText, handleSearch) => {
        //     return (
        //         <div className="cutomsearch-filter" style={{ display: "flex", flexDirection: "row", padding: "10px", margin: "10px", gap: "10px" }}>
        //             <CustomSearchRender
        //                 searchText={searchText}
        //                 onSearch={handleSearch}
        //             />
        //             <FilterButton />
        //         </div>
        //     );
        // },
        rowsPerPageOptions: [5, 10, 15, 20],
        rowsPerPage: 5,
        search: false,
        onRowClick: handleRowClick,
        setRowProps: (row, dataIndex, rowIndex) => {
            return {
                style: { cursor: 'pointer' },
            };
        },
    };


    useEffect(() => {
        dispatch(getParsedProfileDataByUserIdRequest(id))
    }, [id, dispatch, candidate])

    useEffect(() => {
        if (profiles) {
            const newProfiles = profiles?.map((data) => {

                const totalFields = Object?.keys(data).length
                const filledFields = Object?.values(data).filter(
                    value => value !== "NA",
                ).length
                const fulfillmentRatio = `${((filledFields / totalFields) * 100).toFixed(0)}`
                return {
                    ...data,
                    dataFulfillment: fulfillmentRatio
                }
            })
            .sort((a, b) => {
                // Convert "DD/MM/YYYY" to "YYYY-MM-DD" for correct date comparison
                const [dayA, monthA, yearA] = a.parsedDate.split('/');
                const [dayB, monthB, yearB] = b.parsedDate.split('/');
                const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
                const dateB = new Date(`${yearB}-${monthB}-${dayB}`);

                return dateB - dateA; // Sort in descending order
            });
            setUpdatedProfiles(newProfiles);
        }
    }, [dispatch, profiles]);

    useEffect(() => {
        // if (step === 0) {
        //     updatedData.append("personal_information",JSON.stringify(personalDetails))
        // } else if (step === 1) {
        //     updatedData.append("education",JSON.stringify(educationalDetails));
        // } else if (step === 2) {
        //     console.log("certification", JSON.stringify(certificationDetails));
        //     updatedData.append("certifications",JSON.stringify(certificationDetails));
        // } else
        if (step === 3) {
            setActiveStep(0)
            setEditView(false)
            const updatedData = new FormData();
            updatedData.append("personal_information", JSON.stringify(personalDetails));
            updatedData.append("education", JSON.stringify(educationalDetails));
            updatedData.append("certifications", JSON.stringify(certificationDetails));

            const certificates = certificationDetails.map(cert => cert.file);
            certificates.forEach(file => {
                updatedData.append("files", file);
            });

            updatedData.append("skills", skills);
            dispatch(updateCandidateRequest({ id: selectedProfile._id, updatedData }));
        }
    }, [step]);



    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalDetails({
            ...personalDetails,
            [name]: value,
        });
    };

    const EditPersonalInfo = (
        <>
            <Box  p={1}  borderRadius={2} style={{backgroundColor: "#fff", borderRadius: "8px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Name</Label>
                        <TextField
                            name="name"
                            // label="Name"
                            value={selectedProfile?.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                            placeholder='Enter a Name'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Email</Label>
                        <TextField
                            name="email"
                            // label="Email"
                            type="email"
                            value={personalDetails?.email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                            placeholder='Enter E-Mail'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Contact</Label>
                        <TextField
                            name="contact"
                            // label="Contact"
                            value={personalDetails?.contact}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                            placeholder='Enter Contact Number'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Location</Label>
                        <TextField
                            name="location"
                            // label="Location"
                            value={personalDetails?.location}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                            placeholder='Enter a Location'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Preffered Location</Label>
                        <TextField
                            name="preferredLocation"
                            // label="Preferred Location"
                            value={personalDetails?.preferredLocation}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                            placeholder='Enter Preferred Location'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Job Title</Label>
                        <TextField
                            name="jobTitle"
                            // label="Job Title"
                            value={personalDetails?.jobTitle}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                            placeholder='Job Title'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Label className='parse-it-history-label'>Total Experience</Label>
                        <TextField
                            name="totalExperience"
                            // label="Total Experience (years)"
                            type="number"
                            value={personalDetails?.totalExperience}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputProps={{ style: { paddingLeft: '10px' } }}
                            className='parse-it-history-textfeild'
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );


    const EditEducationInfo = () => {
        const initialValues = {
            education: selectedProfile?.education.map(edu => ({
                degree: edu.degree || '',
                field_of_study: edu.field_of_study || '',
                graduation_date: edu.graduation_date || ''
            })) || [{ degree: '', field_of_study: '', graduation_date: '' }]
        };
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => console.log("submit2", values)}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box mb={4}>
                                <FieldArray name="education">
                                    {({ push, remove }) => (
                                        <div>
                                            {values.education.map((education, index) => (
                                                <Box key={index} mb={2} p={2}  borderRadius={2}  position="relative">
                                                    <Field
                                                        name={`education[${index}].degree`}
                                                        as={TextField}
                                                        label="Degree"
                                                        fullWidth
                                                        margin="normal"
                                                        value={education.degree}
                                                        onChange={e => setFieldValue(`education[${index}].degree`, e.target.value)}
                                                        InputProps={{
                                                            style: {
                                                                paddingLeft: '10px', // Adjust the padding as needed
                                                            },
                                                        }}
                                                    />
                                                    <Field
                                                        name={`education[${index}].field_of_study`}
                                                        as={TextField}
                                                        label="Field of Study"
                                                        fullWidth
                                                        margin="normal"
                                                        value={education.field_of_study}
                                                        onChange={e => setFieldValue(`education[${index}].field_of_study`, e.target.value)}
                                                        InputProps={{
                                                            style: {
                                                                paddingLeft: '10px', // Adjust the padding as needed
                                                            },
                                                        }}
                                                    />
                                                    <Field
                                                        name={`education[${index}].graduation_date`}
                                                        as={TextField}
                                                        label="End Date"
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        margin="normal"
                                                        value={education.graduation_date}
                                                        onChange={e => setFieldValue(`education[${index}].graduation_date`, e.target.value)}
                                                        InputProps={{
                                                            style: {
                                                                paddingLeft: '10px', // Adjust the padding as needed
                                                            },
                                                        }}
                                                    />
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => remove(index)}
                                                        style={{ position: 'absolute', top: 0, right: 0 }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Button
                                                    type="button"
                                                    style={{ color: "#660032" }}
                                                    startIcon={<AddIcon />}
                                                    onClick={() => push({ degree: '', field_of_study: '', graduation_date: '' })}
                                                >
                                                    Add Education
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </FieldArray>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }


    const educationData = selectedProfile?.education?.map(edu => ({
        degree: edu.degree || '',
        field_of_study: edu.field_of_study || '',
        graduation_date: edu.graduation_date || ''
    })) || [{ degree: '', field_of_study: '', graduation_date: '' }]



    const certificationsData = selectedProfile?.certifications?.map(cert => ({
        name: cert.name || "NA",
        authority: cert.authority || "NA",
        startDate: cert.startDate || "NA",
        endDate: cert.endDate || "NA",
        file: cert.file || null
    })) || [{ name: '', authority: '', startDate: '', endDate: '', file: null }]


    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedEducations = [...educationalDetails];
        updatedEducations[index][name] = value;
        setEducationalDetails(updatedEducations);
    };

    const handleAddEducation = () => {
        setEducationalDetails([...educationalDetails, { degree: '', field_of_study: '', graduation_date: '' }]);
    };

    const handleRemoveEducation = (index) => {
        const updatedEducations = educationalDetails.filter((_, i) => i !== index);
        setEducationalDetails(updatedEducations);
    };


    const EditEducationData = (
        <>
            {educationalDetails.map((education, index) => (
                <Box key={index} mb={1} p={1}  borderRadius={2}  position="relative" style={{backgroundColor: "#fff", borderRadius: "8px"}}>
                    <IconButton
                        type="button"
                        onClick={() => handleRemoveEducation(index)}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <Grid container spacing={2} style={{marginTop: '5px'}}>
                    <Grid item xs={6}>
                    <Label className='parse-it-history-label'>Graduation</Label>
                    <TextField
                        name="degree"
                        // label="Degree"
                        fullWidth
                        margin="normal"
                        value={education.degree}
                        placeholder='Enter Graduation'
                        onChange={(event) => handleInputChange(index, event)}
                        InputProps={{
                            style: {
                                paddingLeft: '10px',
                            },
                        }}
                         className='parse-it-history-textfeild'
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <Label className='parse-it-history-label'>Field of Study</Label>
                    <TextField
                        name="field_of_study"
                        // label="Field of Study"
                        fullWidth
                        margin="dense"
                        placeholder='Enter Field Of Study'
                        value={education.field_of_study}
                        onChange={(event) => handleInputChange(index, event)}
                        InputProps={{
                            style: {
                                paddingLeft: '10px',
                            },
                        }}
                        className='parse-it-history-textfeild'  
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <Label className='parse-it-history-label'>End Date</Label>
                    <TextField
                        name="graduation_date"
                        // label="End Date"
                        fullWidth
                        margin="dense"
                        placeholder='Enter Completion Year'
                        value={education.graduation_date}
                        onChange={(event) => handleInputChange(index, event)}
                        InputProps={{
                            style: {
                                paddingLeft: '10px',
                            },
                        }}
                        className='parse-it-history-textfeild'
                    />
                    </Grid>
                    </Grid>
                </Box>
            ))}
            <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
                type="button"
                onClick={handleAddEducation}
                startIcon={<AddIcon />}
            >
                Add More
            </Button>
            </div>
        </>
    )

    const handleCertificationChange = (index, event) => {
        const { name, value, files } = event.target;
        const updatedCertications = [...certificationDetails];
        if (files) {
            updatedCertications[index][name] = files[0];
        } else {
            updatedCertications[index][name] = value;
        }

        setCertificationDetails(updatedCertications);
    };

    const handleAddCertification = () => {
        setCertificationDetails([...certificationDetails, { name: '', authority: '', startDate: '', endDate: '', file: null }]);
    };

    const handleRemoveCertification = (index) => {
        const updatedCertications = certificationDetails.filter((_, i) => i !== index);
        setCertificationDetails(updatedCertications);
    };



    const CertificationData = (
        <div style={{
            width: "100%",
            minWidth: "100%" // Ensure it stretches to fill the container
        }}>
            {
                certificationDetails?.length === 0 ? (
                    <Box mb={1} p={1}  borderRadius={2}  style={{backgroundColor: "#fff", borderRadius: "8px", width: "100%",minWidth: "100%"}}>
                    <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', marginBottom: '10px' }}>
                        No certifications found You Can Add Certification's.
                    </Typography>
                    </Box>
                ) : (
                    certificationDetails?.map((certification, index) => (
                        <Box key={index} mb={1} p={2}  borderRadius={2}  position="relative" style={{backgroundColor: "#fff", borderRadius: "8px", width: "100%",minWidth: "100%"}}>
                            <IconButton
                                type="button"
                                onClick={() => handleRemoveCertification(index)}
                                style={{ position: 'absolute', top: 0, right: 0 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <Grid  container spacing={2} style={{marginTop: '5px'}}>
                            <Grid item xs={6}>
                            <Label className='parse-it-history-label'>Name</Label>
                            <TextField
                                name="name"
                                // label="Name"
                                fullWidth
                                margin="normal"
                                value={certification?.name}
                                placeholder='Enter Certification Name'
                                onChange={(event) => handleCertificationChange(index, event)}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                    },
                                }}
                                className='parse-it-history-textfeild'
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <Label className='parse-it-history-label'>Authority</Label>
                            <TextField
                                name="authority"
                                // label="Authority"
                                fullWidth
                                margin="dense"
                                placeholder='Enter Authority Name'
                                value={certification.authority}
                                onChange={(event) => handleCertificationChange(index, event)}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                    },
                                }}
                                className='parse-it-history-textfeild'
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <Label className='parse-it-history-label'>Start Date</Label>
                            <TextField
                                name="startDate"
                                type="date"
                                fullWidth
                                margin="dense"
                                value={certification?.startDate ? format(parseISO(certification?.startDate), 'yyyy-MM-dd') : ''}
                                onChange={(event) => handleCertificationChange(index, event)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                    },
                                }}
                                className='parse-it-history-textfeild'
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <Label className='parse-it-history-label'>End Date</Label>
                            <TextField
                                name="endDate"
                                type="date"
                                fullWidth
                                margin="dense"
                                value={certification?.endDate ? format(parseISO(certification?.endDate), 'yyyy-MM-dd') : ''}
                                onChange={(event) => handleCertificationChange(index, event)}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                    },
                                }}
                                className='parse-it-history-textfeild'
                            />
                            </Grid>
                            <Grid item xs={12}>
                            {certification?.file && !inputField ? (
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <a href={certification?.file} target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px', color: "#660032" }}>
                                        Show Certificate
                                    </a>
                                    <button style={{ border: "1px solid #660032", borderRadius: "5px", padding: "2px 5px", color: "#660032" }} onClick={() => setInputField(true)}>Update</button>
                                </div>) :
                                (
                                    <input
                                        name="file"
                                        type="file"
                                        accept="application/pdf"
                                        style={{fontSize: '12px', fontFamily: 'Roboto'}}
                                        onChange={(event) => handleCertificationChange(index, event)}
                                    />
                                )}
                            </Grid>
                            </Grid>
                        </Box>
            ))
        )}
            <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
                type="button"
                onClick={handleAddCertification}
                startIcon={<AddIcon />}
                style={{ marginTop: '10px' }}
            >
                Add
            </Button>
            </div>
        </div>
    )

    const handleDelete = (skillToDelete) => {
        setSkills((prevSkills) => prevSkills.filter((skill) => skill !== skillToDelete));
    };

    const handleAddSkill = () => {
        if (newSkill && !skills.includes(newSkill)) {
            setSkills(prevSkills => {
                const skillsArray = Array.isArray(prevSkills) ? prevSkills : [];
                const newSkills = newSkill.split(',').map(skill => skill.trim());
                return [...new Set([...skillsArray, ...newSkills])];
            });
            setNewSkill('');
        }
    };

    const handleSkillsChange = (event) => {
        setNewSkill(event.target.value);
    };

    const SkillsData = (
        <div style={{backgroundColor: "#fff", borderRadius: "8px",padding: "10px"}}>
            <Stack direction="row" spacing={1} flexWrap="wrap">
                {skills?.map((skill) => (
                    <Chip
                        style={{ margin: "2px" }}
                        key={skill}
                        label={skill}
                        onDelete={() => handleDelete(skill)}
                        deleteIcon={<CancelIcon />}
                    />
                ))}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                {/* <Label className='parse-it-history-label'>Skill's</Label> */}
                <TextField
                    value={newSkill}
                    onChange={handleSkillsChange}
                    placeholder="Add a new skill"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button onClick={handleAddSkill} variant="contained" color="primary" size="small" >
                                    <AddIcon />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    style={{marginTop: "10px"}}
                />
            </Stack>
        </div>
    )
    const EditCertificationInfo = () => {
        const initialValues = {
            certifications: selectedProfile?.certifications?.map(cert => ({
                name: cert.name || "NA",
                authority: cert.authority || "NA",
                startDate: cert.startDate || "NA",
                endDate: cert.endDate || "NA",
                file: cert.file || null
            }))
                || []
        };
        return (
            <Formik
                initialValues={initialValues}

                onSubmit={(values) => console.log("submit2", values)}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        +                        <Box mb={4}>
                            <FieldArray name="certifications">
                                {({ push, remove }) => (
                                    <div>
                                        {values.certifications.map((certification, index) => (
                                            <Box key={index} mb={2} p={2}  borderRadius={2}  position="relative" style={{backgroundColor: "#fff", borderRadius: "8px"}}>
                                                <Field
                                                    name={`certifications[${index}].name`}
                                                    as={TextField}
                                                    label="Certification Name"
                                                    fullWidth
                                                    margin="normal"
                                                    value={certification.name}
                                                    onChange={e => setFieldValue(`certifications[${index}].name`, e.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            paddingLeft: '10px',
                                                        },
                                                    }}
                                                />
                                                <Field
                                                    name={`certifications[${index}].authority`}
                                                    as={TextField}
                                                    label="Authority"
                                                    fullWidth
                                                    margin="normal"
                                                    value={certification.authority}
                                                    onChange={e => setFieldValue(`certifications[${index}].authority`, e.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            paddingLeft: '10px',
                                                        },
                                                    }}
                                                />
                                                <Field
                                                    name={`certifications[${index}].startDate`}
                                                    as={TextField}
                                                    type="date"
                                                    label="Start Date"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    margin="normal"
                                                    value={certification.startDate}
                                                    onChange={e => setFieldValue(`certifications[${index}].startDate`, e.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            paddingLeft: '10px',
                                                        },
                                                    }}
                                                />
                                                <Field
                                                    name={`certifications[${index}].endDate`}
                                                    as={TextField}
                                                    type="date"
                                                    label="End Date"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    margin="normal"
                                                    value={certification.endDate}
                                                    onChange={e => setFieldValue(`certifications[${index}].endDate`, e.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            paddingLeft: '10px',
                                                        },
                                                    }}
                                                />
                                                <input
                                                    id={`file${index}`}
                                                    name={`certifications[${index}].file`}
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={(event) => {
                                                        setFieldValue(`certifications[${index}].file`, event.currentTarget.files[0]);
                                                    }}
                                                />
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => remove(index)}
                                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ))}
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Button
                                                type="button"
                                                startIcon={<AddIcon />}
                                                style={{ color: "#660032" }}
                                                onClick={() => push({ name: '', authority: '', startDate: '', endDate: '', file: null })}
                                            >
                                                Add Certification
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        </Box>
                    </Form>
                )}
            </Formik>
        );
    }

    const steps = 4; // Number of steps
    const stepLabel = ['Personal Information', 'Educational Details', 'Certifications', 'Skills']; // Labels for each step
    const stepContent = [
        EditPersonalInfo,
        EditEducationData,
        CertificationData,
        SkillsData
    ];

    const currentStep = (step) => {
        setStep(step);
    }

    stepLabel?.map((label, index) => {
        const object = { label: label, description: stepContent[index] }
        stepsData.push(object);
    })

    const handleNext = (index) => {
        currentStep(index)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        currentStep()
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (stepIndex) => {
        setActiveStep(stepIndex);
    }


    const stepperView = (
        <ThemeProvider theme={theme}>
            <Box sx={{ maxHeight: '100%', overflowY: 'auto', marginY: "", scrollbarWidth: "none" }} >
                <Stepper activeStep={activeStep} orientation="vertical" >
                    {stepsData?.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === steps - 1 ? (
                                        <Typography variant="caption">Last step</Typography>
                                    ) : null
                                }
                            >
                                <span style={{ fontWeight: "bold", color: "#660032", cursor: "pointer" }} onClick={() => handleStep(index)}>{step.label}</span>
                            </StepLabel>
                            <StepContent>
                                {/* <Typography class='bar' style={{ overflowY: 'auto', maxHeight: '55vh' }}> */}
                                {step.description}
                                {/* </Typography> */}
                                
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 2, fontSize: "0.8rem", textTransform: "none", padding: "3px" }}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleNext(index)}
                                            sx={{ mt: 1, fontSize: "0.8rem", textTransform: "none", padding: "3px" }}
                                        >
                                            {index === steps - 1 ? 'Update' : 'Next'}
                                        </Button>
                                    </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed</Typography>
                    </Paper>
                )}
            </Box>
        </ThemeProvider>
    );

    const handleSearch = (value) => {
        setSearchText(value);
        const filteredData = updatedProfiles.filter(candidate =>
            candidate.name?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.email?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.contact?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.jobTitle?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.dataFulfillment?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.location?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.experience?.toString().includes(value) ||
            candidate.preferredLocation?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filteredData);
    };

    const handleFilter = (values) => {
        setFilterValues(values);
        const filteredData = updatedProfiles.filter(candidate => {
            const isExperienceInRange = (candidateExp, range) => {
                const [min, max] = range.split('-').map(Number);
                const exp = parseFloat(candidateExp);
                return exp >= min && (max ? exp <= max : true);
            };
            return (
                (values.jobTitle ? candidate.jobTitle?.toLowerCase().includes(values.jobTitle.toLowerCase()) : true) &&
                (values.experience ? 
                    (values.experience === '10+' ? 
                        parseFloat(candidate.experience) >= 10 :
                        isExperienceInRange(candidate.experience, values.experience)
                    ) : true
                )  &&
                (values.location ? candidate.location?.toLowerCase().includes(values.location.toLowerCase()) : true)
            );
        });
        setFilteredData(filteredData);
    }

    return (
        <div>
            <div className="history-parse-it-table-topbar">
                <div>
                    <span ><b>{updatedProfiles?.length}</b> Candidates recommended for you</span>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {filterValues && Object.keys(filterValues).map((key) => (
                        filterValues[key] && (
                            <Chip
                                key={key}
                                label={key === 'experience' ? `${filterValues[key]} years` : filterValues[key]}
                                onDelete={() => {
                                    const newFilterValues = { ...filterValues };
                                    delete newFilterValues[key];
                                    setFilterValues(newFilterValues);
                                    handleFilter(newFilterValues);
                                }}
                                color="primary"
                            />
                        )
                    ))}
                </div>
                <div>
                    <FilterListIcon style={{ marginRight: '10px',cursor:'pointer' }} onClick={() => setFilterModalView(true)} />
                <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
                </div>
            </div>
            {
                loading ?
                    <PulseLoader /> :
                    <div style={{marginBottom: "10px"}}>
                    <MUIDataTable data={filteredData || updatedProfiles} 
                    columns={columns} options={options} />
                    </div>
            }
            <Dialog open={viewProfile} maxWidth="md" fullWidth>
                <DialogTitle className="dialog-title">
                    Profile Info

                    <IconButton
                        aria-label="close"
                        onClick={() => setViewProfile(false)}
                        className='civix-dialog-close-btn'
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Box className="resume-dialog-container">
                    <DialogContent dividers>
                        <div className="resume-profile-header">
                            <span>Profile ID : {selectedProfile?._id}</span>
                            <span>Parsed on : {selectedProfile?.parsedDate}</span>
                        </div>
                        <div className='resume-data'>
                            <Grid item xs={12} md={6} className="resume-profile-left-column">
                            <h5 className='resume-data-underline'>{selectedProfile?.name}</h5>
                                <div className="personal-item">

                                    <div><strong>Designation : {selectedProfile?.jobTitle}</strong> </div>

                                    <div><strong>Total Experience : {selectedProfile?.experience}</strong></div>

                                    <div><strong>Email : </strong> {selectedProfile?.email}</div>

                                    <div><strong>Contact : </strong> {selectedProfile?.contact} </div>

                                    <div><strong>Location : </strong> {selectedProfile?.location}</div>

                                </div>
                                <div className="resume-education-details">
                                <h5 className='resume-data-underline'>Educational Details :</h5>
                                    {selectedProfile?.education.map((data, index) => (
                                        <ul key={index} className="education-item">
                                            <li>
                                                <strong>{data.degree}</strong>
                                                {data.field_of_study && <div><strong>Field : </strong>{data.field_of_study}</div>}
                                                {data.graduation_date && <div><strong>Date : </strong>{data.graduation_date}</div>}
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                                <div className="resume-skills">
                                <h5 className='resume-data-underline'>Skills : </h5>
                                    <div className="resume-skills-list">
                                        {selectedProfile?.inferredSkills?.map((skill, index) => (
                                            <div key={index} className="resume-skill-item">{skill}</div>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className="resume-profile-right-column">
                            <h5 className='resume-data-underline'>Experience:</h5>
                                {selectedProfile?.professional_experience.map((data, index) => (    
                                        <li key={index} className="experience-item">
                                            <div><strong>Company : </strong> {data.company_name}</div>
                                            <div><strong>Designation : </strong> {data.job_title}</div>
                                            <div><strong>Period : </strong> {data.employment_dates}</div>
                                            {data.experience_description && (
                                                <div><strong>Description : </strong> {data.experience_description}</div>
                                            )}
                                        </li>
                                ))}
                            </Grid>
                        </div>
                    </DialogContent>
                </Box>
                <DialogActions className="dialog-footer">
                    <Button className="dialog-footer-button"
                        onClick={() => setViewProfile(false)}
                    >Close</Button>
                </DialogActions>
            </Dialog>

            <CustomSideModal open={editView} onClose={handleModalClose} content={stepperView} />

                            
            <Modal
                open={filterModalView}
                onClose={() => setFilterModalView(false)}
                className="filter-modal"
            >
                <div className="filter-modal-container">
                    <div className="filter-modal-title">
                        <h5>FILTER</h5>
                        <CloseOutlined onClick={() => setFilterModalView(false)}/>
                    </div>

                    <Formik
                        initialValues={{ jobTitle: '' || filterValues?.jobTitle, location: '' || filterValues?.location, experience: '' || filterValues?.experience }}
                        onSubmit={(values) => {
                            handleFilter(values);
                            setFilterModalView(false);
                        }}
                    >
                        {({ isSubmitting, handleChange, handleBlur, values }) => (
                            <Form>
                                <Grid container spacing={2} className="filter-modal-content">
                                    <Grid item xs={12} sm={6} md={4} >
                                        <div>
                                            <label htmlFor="jobTitle" style={{ display: 'block', marginBottom: '5px' }}>Job Title</label>
                                            <input
                                                type="text"
                                                id="jobTitle"
                                                name="jobTitle"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter Job Title"
                                                value={values.jobTitle}
                                                className="filter-modal-input"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <div>
                                            <label htmlFor="location" style={{ display: 'block', marginBottom: '5px' }}>Location</label>
                                            <input
                                                type="text"
                                                id="location"
                                                name="location"
                                                placeholder="Enter Location"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.location}
                                                className="filter-modal-input"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <div>
                                            <label htmlFor="experience" style={{ display: 'block', marginBottom: '5px' }}>Experience</label>
                                            <select
                                                id="experience"
                                                name="experience"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.experience}
                                                className="filter-modal-select"
                                            >
                                                <option value="">Select Experience</option>
                                                <option value="0-2">0 - 2</option>
                                                <option value="3-5">3 - 5</option>
                                                <option value="6-10">6 - 10</option>
                                                <option value="10+">10+</option>
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'right',}}>
                                        <button type="submit" disabled={isSubmitting} className="filter-modal-button">APPLY</button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>

                </div>
            </Modal>

        </div>
    )
}

export default ParseItHistory
