
import React, { useEffect, useState } from "react"
import './Invoice.scss';
import MUIDataTable from "mui-datatables"
import { Row, Col } from "reactstrap"
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender"
import CustomToolbar from "pages/Sqano Dashboard/CustomToolbar"
import user1 from "../../assets/images/users/user-1.jpg"
import user2 from "../../assets/images/users/user-2.jpg"
import { Grid } from '@mui/material';
import DashboardCard from './DashboardCard';
import Icon1 from '@mui/icons-material/Description';
import Icon2 from '@mui/icons-material/CheckCircle';
import Icon3 from '@mui/icons-material/AccessTime';
import Icon4 from '@mui/icons-material/Cancel';


const Invoice = () => {
    const [sortValue, setSortValue] = useState("")
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const columns = [
     
        {
          name: "clientImage",
          label: "CLIENT IMAGE",
          options: {
            filter: true,
            sort: true,
            customBodyRender: value => (
              <img
                src={value}
                alt="Client Image"
                style={{ width: 40, height: 40, borderRadius: "50%" }}/>
            ),
          },
          
        },
        {
          name: "clientName",
          label: "CLIENT NAME",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "startDate",
          label: "DATE",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "email",
          label: "EMAIL ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        
        {
          name: "subscriptionStatus",
          label: "STATUS",
          options: {
            filter: true,
            sort: true,
            customBodyRender: value => (
      <div className={value ? 'badge-paid' : 'badge-unpaid'}>
        {value ? "Paid" : "Unpaid"}
      </div>
    ),
          },
        },
        {
          name: "billed",
          label: "Billed",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "edit",
          label: "Edit",
          options: {
            filter: false,
            sort: false,
            customBodyRenderLite: dataIndex => (
              <button
                style={{
                  backgroundColor: "#660032",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "4px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={e => handleEditClick(dataIndex, e)}
              >
                Edit
              </button>
            ),
          },
        },
      ]
    const handleSortAndFilter = (data, sortValue, startDate, endDate) => {
        let sortedData = [...data]
    
        if (startDate && endDate) {
          sortedData = sortedData.filter(customer => {
            const date = new Date(customer.startDate)
            return date >= startDate && date <= endDate
          })
        }
    
        switch (sortValue) {
          case "paid":
            sortedData = sortedData.filter(
              customer => customer.subscriptionStatus === true,
            )
            break
          case "unpaid":
            sortedData = sortedData.filter(
              customer => customer.subscriptionStatus === false,
            )
            break
          case "old":
            sortedData.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            break
          case "new":
            sortedData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            break
          case "":
          default:
            break
        }
    
        return sortedData
      }
    const [customers, setCustomers] = useState([
        {
          id: 1,
          clientImage: user1,
          clientName: "Misty",
          startDate: "2001-06-24",
          email: "misty@example.com",
          subscriptionStatus: true,
          billed: "550.00",
          subscriptionValue: "52,345",
          badge: "PREMIUM",
          features: [
            { id: 1, name: "SQANIT", Limit: "Unlimited", enabled: true },
            { id: 2, name: "PARSEIT", Limit: "Unlimited", enabled: false },
            { id: 3, name: "CIVIX", Limit: "Unlimited", enabled: true },
            { id: 4, name: "TOD", Limit: "Unlimited", enabled: false },
            { id: 5, name: "ATS", Limit: "Unlimited", enabled: true },
            { id: 6, name: "Interview", Limit: "Unlimited", enabled: false },
          ],
          recentActivity: [
            { date: "2024-05-01", activity: "Logged in" },
            { date: "2024-05-02", activity: "Updated profile" },
          ],
        },
        {
          id: 2,
         clientImage: user2,
          clientName: "Melissa",
          startDate: "2002-10-04",
          email: "melissa@example.com",
          location: "Los Angeles, USA",
          subscriptionStatus: false,
          billed: "820.00",
          subscriptionValue: "32,345",
          badge: "SILVER",
          features: [
            { id: 1, name: "SQANIT", enabled: false },
            { id: 2, name: "PARSEIT", enabled: true },
            { id: 3, name: "CIVIX", enabled: false },
            { id: 4, name: "TOD", enabled: true },
            { id: 5, name: "ATS", enabled: false },
            { id: 6, name: "Interview", enabled: true },
          ],
          recentActivity: [{ date: "2024-05-03", activity: "Viewed profile" }],
        },
        {
          id: 3,
         clientImage: user2,
          clientName: "Melissa",
          startDate: "2002-10-04",
          email: "melissa@example.com",
          location: "Los Angeles, USA",
          subscriptionStatus: false,
          billed: "820.00",
          subscriptionValue: "32,345",
          badge: "SILVER",
          features: [
            { id: 1, name: "SQANIT", enabled: false },
            { id: 2, name: "PARSEIT", enabled: true },
            { id: 3, name: "CIVIX", enabled: false },
            { id: 4, name: "TOD", enabled: true },
            { id: 5, name: "ATS", enabled: false },
            { id: 6, name: "Interview", enabled: true },
          ],
          recentActivity: [{ date: "2024-05-03", activity: "Viewed profile" }],
        },
        {
          id: 4,
         clientImage: user2,
          clientName: "Melissa",
          startDate: "2002-10-04",
          email: "melissa@example.com",
          location: "Los Angeles, USA",
          subscriptionStatus: false,
          billed: "820.00",
          subscriptionValue: "32,345",
          badge: "SILVER",
          features: [
            { id: 1, name: "CIVIX", enabled: false },
            { id: 2, name: "PARSEIT", enabled: true },
            { id: 3, name: "CIVIX", enabled: false },
            { id: 4, name: "TOD", enabled: true },
            { id: 5, name: "ATS", enabled: false },
            { id: 6, name: "Interview", enabled: true },
          ],
          recentActivity: [{ date: "2024-05-03", activity: "Viewed profile" }],
        },
      ])
    
      const sortedAndFilteredCustomers = handleSortAndFilter(
        customers,
        sortValue,
        startDate,
        endDate,
      )
    
      const [selectedCustomer, setSelectedCustomer] = useState(customers[0])
      const [expandedCustomer, setExpandedCustomer] = useState(null)
      const [dropdownPosition, setDropdownPosition] = useState(null)
    
      const handleEditClick = (dataIndex, e) => {
        const customerId = customers[dataIndex].id
        setExpandedCustomer(prev => (prev === customerId ? null : customerId))
        setSelectedCustomer(customers[dataIndex])
    
        const rect = e.target.getBoundingClientRect()
        const dropdownTop = rect.bottom + window.scrollY
        const dropdownLeft = rect.left + window.scrollX
    
        setDropdownPosition({ top: dropdownTop, left: dropdownLeft })
      }
    
      const handleFeatureToggle = featureId => {
        const newCustomers = customers.map(customer => {
          if (customer.id === expandedCustomer) {
            return {
              ...customer,
              features: customer.features.map(f => ({
                ...f,
                enabled: f.id === featureId ? !f.enabled : f.enabled,
              })),
            }
          }
          return customer
        })
        setCustomers(newCustomers)
      }
  return (

    <div>


<Grid container spacing={12}>
      <Grid item xs={12} md={6} lg={3}>
        <DashboardCard
          title="INVOICES SENT"
          amount="$559.25k"
          change="+89.24"
          changeType="up"
          count="2,258"
          description="Invoices sent"
          icon={<Icon1 />}
          bgColor="white"
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DashboardCard
          title="PAID INVOICES"
          amount="$409.66k"
          change="+8.09"
          changeType="down"
          count="1,958"
          description="Paid by clients"
          icon={<Icon2 />}
          bgColor="white"
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DashboardCard
          title="UNPAID INVOICES"
          amount="$136.98k"
          change="+9.01"
          changeType="down"
          count="338"
          description="Unpaid by clients"
          icon={<Icon3 />}
          bgColor="#4caf50"  // green background
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DashboardCard
          title="CANCELLED INVOICES"
          amount="$84.20k"
          change="+7.55"
          changeType="up"
          count="502"
          description="Cancelled by clients"
          icon={<Icon4 />}
          bgColor="white"
        />
      </Grid>
    </Grid>

    <Row>
        <Col xl="12">
          <div className="table-custom-container">
            <MUIDataTable
              className="table-custom"
              data={customers}
              columns={columns}
              options={{
                selectableRows: "multiple",
                print: false,
                filterType: 'checkbox',
                download: false,
                sort: false,
                filter: false,
                viewColumns: false,
                searchAlwaysOpen: true,
                customSearchRender: (searchText, handleSearch) => {
                  return (
                    <CustomSearchRender
                      searchText={searchText}
                      onSearch={handleSearch}
                    />
                  )
                },
                customToolbar: () => (
                  <CustomToolbar
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                ),
              }}
              onRowClick={(rowData, rowMeta) => {
                const clickedCustomerId = rowData[0]
                const clickedCustomer = customers.find(
                  customer => customer.id === clickedCustomerId,
                )
                setExpandedCustomer(clickedCustomerId)
              }}
            />
          </div>
        </Col>
       
      </Row>
    </div>
  );
};

export default Invoice;
