import React from "react"
import { Card, CardContent, Typography, Box } from "@mui/material"
import "./Invoice.scss"

const DashboardCard = ({
  title,
  amount,
  change,
  changeType,
  count,
  description,
  icon,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="invoice-card-wrap">
          <Card className="card">
            <CardContent>
              <Typography variant="h5" component="div" className="amount">
                {amount}
                <Box
                  component="span"
                  className="icon icon-1"
                  sx={{ color: changeType === "up" ? "green" : "#049d8f", fontSize: '3rem'}}
                >
                  {icon}
                </Box>
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {title}
              </Typography>
              <Typography sx={{ mb: 1.5, color:'#f44336' }} color="text.secondary">
                {changeType === "up" ? "↑" : "↓"} {change} %
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    p: 1,
                    backgroundColor: "#049d8f",
                    color:"#fff",
                    borderRadius: "4px",
                    mr: 1,
                    
                  }}
                >
                  {count}
                </Box>
                <Typography variant="body2">{description}</Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default DashboardCard
