
import React from 'react';
import { Box, Rating, Typography } from '@mui/material';
import SkillsChart from './SkillsChart';
import './Content.css';

const Content = ({ analysis, data }) => {
  return (
    <div className="content">
      <div className="profile-and-chart">
        <div className="profile-synopsis">
          <h5>Profile Synopsis</h5>
          <p>{analysis?.analysis?.summary || analysis?.summary}</p>
        </div>

        <SkillsChart data={data} />
      </div>

      <div className="recommended-qa">
        <h5>Sqano recommended Q & A</h5>
        {(analysis.questionnaire ?? analysis?.analysis?.questionnaire)?.map((item, index) => (
          <React.Fragment key={index}>
            <h6 style={{color: "black"}}>Q : {item.Q}</h6>
            <h6 style={{color: "#808080"}}>Ans :{item.ANS}</h6>
            <Box display="flex" justifyContent="flex-end">
              <Rating
                name={`rating-${index}`}
                value={item.rating}
                size="small"
                className="rating"
              />
            </Box>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Content;


