// import React from "react"
// import { Navigate } from "react-router-dom"

// const Authmiddleware = (props) => {
//   if (!localStorage.getItem("authUser")) {
//     return (
//       <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
//     );
//   }
//   return (<React.Fragment>
//    {props.children}
//   </React.Fragment>);
// };

// export default Authmiddleware;

import React from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

const Authmiddleware = props => {
  const location = useLocation()
  const user = useSelector(state => state.Login?.user)

  // if (loading) {
  //   return <div>Loading...</div>
  // }

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  // if (role && !role.includes(user.role)) {
  //   return <Navigate to="/unauthorized" replace />
  // }
  return <React.Fragment> {props.children}</React.Fragment>
}

export default Authmiddleware
