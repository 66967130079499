import React, { useState } from "react"
import { Row, Col, Card } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import "pages/Sqano Dashboard/dashboard.css"

const MonthlyEarnings = () => {
  const [chartOptions] = useState({
    options: {
      colors: ["#660032", "#9e3165"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "70%",
        },
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
          2016,
        ],
        labels: {
          formatter: function (val) {
            return val
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
    series: [
      {
        name: "Series A",
        data: [45, 75, 100, 75, 100, 75, 50, 75, 50, 75, 100, 80],
      },
      {
        name: "Series B",
        data: [180, 65, 90, 65, 90, 65, 40, 65, 40, 65, 90, 65],
      },
    ],
  })

  return (
    <React.Fragment>
      <Card className="dashboard-graph-card" style={{ height: "550px" }}>
        <h4 className="dashboard-graph-title">GROWTH GRAPH</h4>

        <Row className="text-center dashboard-graph-info">
          <Col xs="6">
            <h5 className="dashboard-graph-value ">89425</h5>
            <p className="dashboard-graph-text text-muted">INDIA</p>
          </Col>
          <Col xs="6">
            <h5 className="dashboard-graph-value ">8974</h5>
            <p className="dashboard-graph-text text-muted">GLOBAL</p>
          </Col>
        </Row>
        <div className="dashboard-graph-chart-container">
          <ReactApexChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="bar"
            height="290"
          />
        </div>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarnings
