import React, { useState } from "react"
//import React, { Component } from 'react';
import { Row, Col, Card } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import "pages/Sqano Dashboard/dashboard.css"

const GlobalBusiness = () => {
  const [chartData] = useState({
    options: {
      colors: ["#58db83", "#9e3165", "#9e3165"],
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 0.1,
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["2011", "2012", "2013", "2014", "2015", "2016", "2017"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
    },
    series: [
      { name: "Series A", data: [0, 150, 60, 180, 90, 75, 30] },
      { name: "Series B", data: [0, 45, 150, 36, 60, 240, 30] },
      { name: "Series C", data: [0, 15, 195, 21, 360, 120, 30] },
    ],
  })

  return (
    <React.Fragment>
      <Card className="dashboard-graph-card" style={{ height: "550px" }}>
        <h4 className="dashboard-graph-title">GLOBAL BUSINESS</h4>

        <Row className="text-center dashboard-graph-info">
          <Col xs="4">
            <h5 className="dashboard-graph-value">89425</h5>
            <p className="dashboard-graph-text text-muted">INDIA</p>
          </Col>
          <Col xs="4">
            <h5 className="dashboard-graph-value">56210</h5>
            <p className="dashboard-graph-text text-muted">USA</p>
          </Col>
          <Col xs="4">
            <h5 className="dashboard-graph-value">8974</h5>
            <p className="dashboard-graph-text text-muted">EUROPE/ROW</p>
          </Col>
        </Row>
        <div className="dashboard-graph-chart-container">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height="300"
          />
        </div>
      </Card>
    </React.Fragment>
  )
}

export default GlobalBusiness
