import React from 'react';
import { Card, CardContent, Typography, Link, Grid, CardHeader } from '@mui/material';
function ResultCard({ results }) {
    return (
        <>
            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'stretch' }}>
                {results?.map((job, index) => (
                    <Grid item lg={6} xs={12} key={index} style={{ display: 'flex' }}>
                        <Card variant="outlined" style={{ width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: "#f0f0f0" }}>
                            <CardHeader
                                title={job.favicon_text}
                                titleTypographyProps={{ variant: 'h6' }}
                                style={{ textAlign: 'right', backgroundColor:"#660032", color:"#ffffff" }}
                            />
                            <CardContent style={{ flexGrow: 1 }}>
                                <Typography variant="h6" component="div">
                                    {job.title}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {job.desc}
                                </Typography>
                                <Link href={job.url} target="_blank" rel="noopener" sx={{ mt: 1 }}>
                                    View All
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
export default ResultCard;