import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mdi/react';
import { mdiTune, mdiChevronRight } from '@mdi/js';
import Divider from '@mui/material/Divider'; // Make sure you import Divider from Material UI
import "./Download.scss";

function FilterButton() {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const filterOptions = ["Name", "Phone", "Email", "Job Title", "Skills", "Fitment"];

  return (
    <Box className="filter-button-container">
      <Button
        className="filter-toggle-button"
        onClick={toggleFilters}
        aria-label="filter"
      >
        <Icon path={mdiTune} size={0.8} color="#660032" />
        <span style={{ color: "#660032", fontSize: "14px", marginLeft: "5px" }}>Filter</span>
        <Icon path={mdiChevronRight} size={0.8} color="#660032" />
      </Button>
      {showFilters && (
        <Box className="filter-button-options">
          {filterOptions.map((option, index) => (
            <React.Fragment key={option}>
              <Button variant="outlined" className='Filter-option'>{option}</Button>
              {index < filterOptions.length - 1 && (
                <Divider orientation="vertical" flexItem style={{ borderColor: "#660032" }} />
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default FilterButton;
