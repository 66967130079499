import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import ResultCard from './ResultCard';
import { Box, Grid, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import ProductTabs from '../ProductTabs';
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";

function WebIt() {
    const [jobTitle, setJobTitle] = useState('');
    const [location, setLocation] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const handleSearch = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post('http://localhost:9001/api/v1/scrape-jobs', {
                jobTitle, location
            });
            console.log("response", response)
            setResults(response.data.results[0].content.results.organic);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data. Please try again.');
            console.error(err);
            setLoading(false);
        }
    };

    const breadcrumbItems = [
        { label: "Home", href: "/dashboard" },
        { label: "WebIT", href: "/web-it" },
    ];

    return (
        <>
        <CustomBreadcrumb items={breadcrumbItems} />
        <ProductTabs tab={4}/>
            <Grid container>
                <Grid item lg={7}>
                    <TextField
                        label="Job Description"
                        variant="filled"
                        value={jobTitle}
                        onChange={e => setJobTitle(e.target.value)}
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item lg={5}>
                    <TextField
                        label="Location"
                        variant="filled"
                        value={location}
                        onChange={e => setLocation(e.target.value)}
                        fullWidth
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <div style={{ width: '100%', marginTop: '20px', position: 'relative' }}>
                {loading ? 
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                        <CircularProgress />
                    </div> :
                    <ResultCard results={results} />
                }
            </div>
        </>
    );
}
export default WebIt;