import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPDATE_COLLECTED_INFO,
  FILTER_CANDIDATES_REQUEST,
  FILTER_CANDIDATES_SUCCESS,
  FILTER_CANDIDATES_FAILURE,
  CREATE_SEARCH_REQUEST, 
  CREATE_SEARCH_SUCCESS, 
  CREATE_SEARCH_FAILURE,
  FETCH_SEARCHES_REQUEST,
  FETCH_SEARCHES_SUCCESS,
  FETCH_SEARCHES_FAILURE,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAILURE,
  CLEAR_CANDIDATE_DATA
} from './actionTypes';

export const uploadFileRequest = (files) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: files,
});

export const uploadFileSuccess = (data) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: data,
});

export const uploadFileFailure = (failedProfiles) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: failedProfiles,
});

export const filterCandidatesRequest = (filters) => ({
  type: FILTER_CANDIDATES_REQUEST,
  payload: filters
});

export const filterCandidatesSuccess = (candidates) => ({
  type: FILTER_CANDIDATES_SUCCESS,
  payload: candidates
});

export const filterCandidatesFailure = (error) => ({
  type: FILTER_CANDIDATES_FAILURE,
  payload: error
});

export const createSearchRequest = (searchData) => ({
    type: CREATE_SEARCH_REQUEST,
    payload: searchData
});

export const createSearchSuccess = (response) => ({
    type: CREATE_SEARCH_SUCCESS,
    payload: response
});

export const createSearchFailure = (error) => ({
    type: CREATE_SEARCH_FAILURE,
    payload: error
});

export const updateCollectedInfo = (info) => ({
  type: UPDATE_COLLECTED_INFO,
  payload: info
});

export const fetchSearchesRequest = (data) => ({
  type: FETCH_SEARCHES_REQUEST,
  payload: data
});

export const fetchSearchesSuccess = (searches) => ({
  type: FETCH_SEARCHES_SUCCESS,
  payload: searches
});

export const fetchSearchesFailure = (error) => ({
  type: FETCH_SEARCHES_FAILURE,
  payload: error
});

export const deleteProfileRequest = (profiles) => ({
  type: DELETE_PROFILE_REQUEST,
  payload: profiles,
});

export const deleteProfileSuccess = (profiles) => ({
  type: DELETE_PROFILE_SUCCESS,
  payload: profiles
});

export const deleteProfileFailure = (result) => ({
  type: DELETE_PROFILE_FAILURE,
  payload: result
});

export const clearCandidateData = () => ({
  type: CLEAR_CANDIDATE_DATA,
});



