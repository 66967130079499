import { IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import react, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchRolesStart } from "store/actions";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';


const GetAllRoles = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDetails } = useSelector((state) => state.Login?.user);
    const { data } = useSelector((state) => state.roleReducer.roles)
    console.log("userDetails", userDetails)

    

    const columns = [
        {
            name: "name",
            label: "Role Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "createdAt",
            label: "Created At",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return new Date(value).toLocaleDateString();
                }
            }
        },
        {
            name: "updatedAt",
            label: "Updated At",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return new Date(value).toLocaleDateString();
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <Tooltip title="Edit">
                      <IconButton aria-label="Edit">
                        <Edit style={{ color: "#660032" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                );
              },
              setCellProps: () => ({ style: { maxWidth: '100px' } }),
              setCellHeaderProps: () => ({ style: { maxWidth: '100px' } })
            },
          },
    ];

    const handleNavigate = () => {
        navigate('/create-role');
    };

    const options = {
        selectableRows: "none",
        responsive: "standard",
        textLabels: {
          body: {
            noMatch: "No roles found.",
          },
        },
        customToolbar: () => {
          return (
            <Tooltip title="Create New Role">
              <IconButton onClick={handleNavigate}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          );
        },
      };


    useEffect(() => {
        dispatch(fetchRolesStart(userDetails?.entityId));
    }, [])

    return (
        <div>
            <MUIDataTable
                title={"Roles"}
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
};

export default GetAllRoles;