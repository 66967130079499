export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const FILTER_CANDIDATES_REQUEST = 'FILTER_CANDIDATES_REQUEST';
export const FILTER_CANDIDATES_SUCCESS = 'FILTER_CANDIDATES_SUCCESS';
export const FILTER_CANDIDATES_FAILURE = 'FILTER_CANDIDATES_FAILURE';

export const UPDATE_COLLECTED_INFO = 'UPDATE_COLLECTED_INFO';

export const CREATE_SEARCH_REQUEST = 'CREATE_SEARCH_REQUEST';
export const CREATE_SEARCH_SUCCESS = 'CREATE_SEARCH_SUCCESS';
export const CREATE_SEARCH_FAILURE = 'CREATE_SEARCH_FAILURE';

export const FETCH_SEARCHES_REQUEST = 'FETCH_SEARCHES_REQUEST';
export const FETCH_SEARCHES_SUCCESS = 'FETCH_SEARCHES_SUCCESS';
export const FETCH_SEARCHES_FAILURE = 'FETCH_SEARCHES_FAILURE';

export const DELETE_PROFILE_REQUEST = "DELETE_PROFILE_REQUEST";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_FAILURE = "DELETE_PROFILE_FAILURE";

export const CLEAR_CANDIDATE_DATA = 'CLEAR_CANDIDATE_DATA';


