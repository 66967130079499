import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

const API_KEY = "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";

function ChatBot() {
  const [messages, setMessages] = useState([
    { id: 0, text: "Hello, I'm ChatGPT! Ask me anything!", sender: "ChatGPT" }
  ]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async () => {
    if (!input.trim()) return;

    const newMessages = [...messages, { id: messages.length, text: input, sender: "User" }];
    setMessages(newMessages);
    setInput('');
    setIsTyping(true);

    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map(msg => ({
      role: msg.sender === "ChatGPT" ? "assistant" : "user",
      content: msg.text
    }));

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: "Explain like I'm a software professional with 2 years of experience." },
        ...apiMessages
      ]
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });
    const data = await response.json();

    setMessages(prev => [...prev, { id: prev.length, text: data.choices[0].message.content, sender: "ChatGPT" }]);
    setIsTyping(false);
  }

  return (
    <Box sx={{ width: 700, mx: "auto", my: 4 }}>
      <Box sx={{ height: 400, overflowY: 'auto', border: '1px solid', borderColor: 'grey.300', p: 2 }}>
        {messages.map(msg => (
          <Typography key={msg.id} sx={{ my: 1, textAlign: msg.sender === "User" ? 'right' : 'left' }}>
            {msg.text}
          </Typography>
        ))}
        {isTyping && <Typography sx={{ my: 1 }}>ChatGPT is typing...</Typography>}
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message here..."
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' ? handleSend() : null}
        sx={{ mt: 2 }}
      />
      <Button onClick={handleSend} variant="contained" sx={{ mt: 1 }}>Send</Button>
    </Box>
  );
}

export default ChatBot;
