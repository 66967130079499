import React, { useEffect } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

const IconDripicons = props => {

  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "Icons", link: "#" },
    { title: "Dripicons", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Dripicons", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Examples</h4>
              <p className="card-title-desc">
                Use <code>&lt;i className="dripicons-alarm"&gt;&lt;/i&gt;</code>
                .
              </p>

              <Row className="icon-demo-content">
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-alarm" /> dripicons-alarm
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-align-center" />{" "}
                  dripicons-align-center
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-align-justify" />{" "}
                  dripicons-align-justify
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-align-left" /> dripicons-align-left
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-align-right" /> dripicons-align-right
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-anchor" /> dripicons-anchor
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-archive" /> dripicons-archive
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-down" /> dripicons-arrow-down
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-left" /> dripicons-arrow-left
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-right" /> dripicons-arrow-right
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-thin-down" />{" "}
                  dripicons-arrow-thin-down
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-thin-left" />{" "}
                  dripicons-arrow-thin-left
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-thin-right" />{" "}
                  dripicons-arrow-thin-right
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-thin-up" />{" "}
                  dripicons-arrow-thin-up
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-arrow-up" /> dripicons-arrow-up
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className=" dripicons-article" /> dripicons-article
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-backspace" /> dripicons-backspace
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-basket" /> dripicons-basket
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-basketball" /> dripicons-basketball
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-battery-empty" />{" "}
                  dripicons-battery-empty
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-battery-full" />{" "}
                  dripicons-battery-full
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-battery-low" /> dripicons-battery-low
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-battery-medium" />{" "}
                  dripicons-battery-medium
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-bell" /> dripicons-bell
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-blog" /> dripicons-blog
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-bluetooth" /> dripicons-bluetooth
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-bold" /> dripicons-bold
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-bookmark" /> dripicons-bookmark
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-bookmarks" /> dripicons-bookmarks
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-box" /> dripicons-box
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-briefcase" /> dripicons-briefcase
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-brightness-low" />{" "}
                  dripicons-brightness-low
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-brightness-max" />{" "}
                  dripicons-brightness-max
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-brightness-medium" />{" "}
                  dripicons-brightness-medium
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-broadcast" /> dripicons-broadcast
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-browser" /> dripicons-browser
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-browser-upload" />{" "}
                  dripicons-browser-upload
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-brush" /> dripicons-brush
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-calendar" /> dripicons-calendar
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-camcorder" /> dripicons-camcorder
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-camera" /> dripicons-camera
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-card" /> dripicons-card
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-cart" /> dripicons-cart
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-checklist" /> dripicons-checklist
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-checkmark" /> dripicons-checkmark
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-chevron-down" />{" "}
                  dripicons-chevron-down
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-chevron-left" />{" "}
                  dripicons-chevron-left
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-chevron-right" />{" "}
                  dripicons-chevron-right
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-chevron-up" /> dripicons-chevron-up
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-clipboard" /> dripicons-clipboard
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-clock" /> dripicons-clock
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-clockwise" /> dripicons-clockwise
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-cloud" /> dripicons-cloud
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-cloud-download" />{" "}
                  dripicons-cloud-download
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-cloud-upload" />{" "}
                  dripicons-cloud-upload
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-code" /> dripicons-code
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-contract" /> dripicons-contract
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-contract-2" /> dripicons-contract-2
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-conversation" />{" "}
                  dripicons-conversation
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-copy" /> dripicons-copy
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-crop" /> dripicons-crop
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-cross" /> dripicons-cross
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-crosshair" /> dripicons-crosshair
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-cutlery" /> dripicons-cutlery
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-device-desktop" />{" "}
                  dripicons-device-desktop
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-device-mobile" />{" "}
                  dripicons-device-mobile
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-device-tablet" />{" "}
                  dripicons-device-tablet
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-direction" /> dripicons-direction
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-disc" /> dripicons-disc
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-document" /> dripicons-document
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-document-delete" />{" "}
                  dripicons-document-delete
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-document-edit" />{" "}
                  dripicons-document-edit
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-document-new" />{" "}
                  dripicons-document-new
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-document-remove" />{" "}
                  dripicons-document-remove
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-dot" /> dripicons-dot
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-dots-2" /> dripicons-dots-2
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-dots-3" /> dripicons-dots-3
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-download" /> dripicons-download
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-duplicate" /> dripicons-duplicate
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-enter" /> dripicons-enter
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-exit" /> dripicons-exit
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-expand" /> dripicons-expand
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-expand-2" /> dripicons-expand-2
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-experiment" /> dripicons-experiment
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-export" /> dripicons-export
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-feed" /> dripicons-feed
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-flag" /> dripicons-flag
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-flashlight" /> dripicons-flashlight
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-folder" /> dripicons-folder
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-folder-open" /> dripicons-folder-open
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-forward" /> dripicons-forward
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-gaming" /> dripicons-gaming
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-gear" /> dripicons-gear
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-graduation" /> dripicons-graduation
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-graph-bar" /> dripicons-graph-bar
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-graph-line" /> dripicons-graph-line
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-graph-pie" /> dripicons-graph-pie
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-headset" /> dripicons-headset
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-heart" /> dripicons-heart
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-help" /> dripicons-help
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-home" /> dripicons-home
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-hourglass" /> dripicons-hourglass
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-inbox" /> dripicons-inbox
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-information" /> dripicons-information
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-italic" /> dripicons-italic
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-jewel" /> dripicons-jewel
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-lifting" /> dripicons-lifting
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-lightbulb" /> dripicons-lightbulb
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-link" /> dripicons-link
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-link-broken" /> dripicons-link-broken
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-list" /> dripicons-list
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-loading" /> dripicons-loading
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-location" /> dripicons-location
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-lock" /> dripicons-lock
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-lock-open" /> dripicons-lock-open
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-mail" /> dripicons-mail
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-map" /> dripicons-map
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-loop" /> dripicons-media-loop
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-next" /> dripicons-media-next
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-pause" /> dripicons-media-pause
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-play" /> dripicons-media-play
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-previous" />{" "}
                  dripicons-media-previous
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-record" />{" "}
                  dripicons-media-record
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-shuffle" />{" "}
                  dripicons-media-shuffle
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-media-stop" /> dripicons-media-stop
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-medical" /> dripicons-medical
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-menu" /> dripicons-menu
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-message" /> dripicons-message
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-meter" /> dripicons-meter
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-microphone" /> dripicons-microphone
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-minus" /> dripicons-minus
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-monitor" /> dripicons-monitor
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-move" /> dripicons-move
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-music" /> dripicons-music
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-network-1" /> dripicons-network-1
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-network-2" /> dripicons-network-2
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-network-3" /> dripicons-network-3
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-network-4" /> dripicons-network-4
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-network-5" /> dripicons-network-5
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-pamphlet" /> dripicons-pamphlet
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-paperclip" /> dripicons-paperclip
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-pencil" /> dripicons-pencil
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-phone" /> dripicons-phone
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-photo" /> dripicons-photo
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-photo-group" /> dripicons-photo-group
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-pill" /> dripicons-pill
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-pin" /> dripicons-pin
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-plus" /> dripicons-plus
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-power" /> dripicons-power
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-preview" /> dripicons-preview
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-print" /> dripicons-print
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-pulse" /> dripicons-pulse
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-question" /> dripicons-question
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-reply" /> dripicons-reply
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-reply-all" /> dripicons-reply-all
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-return" /> dripicons-return
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-retweet" /> dripicons-retweet
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-rocket" /> dripicons-rocket
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-scale" /> dripicons-scale
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-search" /> dripicons-search
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-shopping-bag" />{" "}
                  dripicons-shopping-bag
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-skip" /> dripicons-skip
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-stack" /> dripicons-stack
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-star" /> dripicons-star
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-stopwatch" /> dripicons-stopwatch
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-store" /> dripicons-store
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-suitcase" /> dripicons-suitcase
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-swap" /> dripicons-swap
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-tag" /> dripicons-tag
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-tag-delete" /> dripicons-tag-delete
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-tags" /> dripicons-tags
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-thumbs-down" /> dripicons-thumbs-down
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-thumbs-up" /> dripicons-thumbs-up
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-ticket" /> dripicons-ticket
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-time-reverse" />{" "}
                  dripicons-time-reverse
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-to-do" /> dripicons-to-do
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-toggles" /> dripicons-toggles
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-trash" /> dripicons-trash
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-trophy" /> dripicons-trophy
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-upload" /> dripicons-upload
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-user" /> dripicons-user
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-user-group" /> dripicons-user-group
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-user-id" /> dripicons-user-id
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-vibrate" /> dripicons-vibrate
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-view-apps" /> dripicons-view-apps
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-view-list" /> dripicons-view-list
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-view-list-large" />{" "}
                  dripicons-view-list-large
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-view-thumb" /> dripicons-view-thumb
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-volume-full" /> dripicons-volume-full
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-volume-low" /> dripicons-volume-low
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-volume-medium" />{" "}
                  dripicons-volume-medium
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-volume-off" /> dripicons-volume-off
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-wallet" /> dripicons-wallet
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-warning" /> dripicons-warning
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-web" /> dripicons-web
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-weight" /> dripicons-weight
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-wifi" /> dripicons-wifi
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-wrong" /> dripicons-wrong
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-zoom-in" /> dripicons-zoom-in
                </Col>
                <Col xl="3" lg="4" sm="6">
                  <i className="dripicons-zoom-out" /> dripicons-zoom-out
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(IconDripicons)
