import React from "react"
import {
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
  Skeleton, // Import Skeleton
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import ProgressBar from "react-bootstrap/ProgressBar"
import { color } from "echarts"


const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  height: '40px',
  overflow:"hidden", // Adjust the height as needed
  display: 'flex',
  fontSize:"12px",
  justifyContent:"end",
  color:"black",
  alignItems: 'center',
  '& .MuiTablePagination-actions': {
    alignItems: 'center',
  },
  '& .MuiTablePagination-toolbar': {
    alignItems: 'baseline',
  },
  '& .MuiSelect-root': {
    marginTop: '0px',
    marginBottom: '0px',
  },
  '& .MuiTablePagination-spacer': {
    display: 'none', // Hide the spacer to bring elements closer
  },

  
    
    

}));

const useStyles = makeStyles({
  cellWithEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  thinScrollbar: {
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  tableContainer: {
    borderRadius: "4px",
  },
  paper: {
    border: "solid 1px #660032",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    fontSize:"12px"
  },
  tableRow: {
    lineHeight: 1.5,
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
  },
  headerCell: {
    fontSize:"12px",
    fontWeight: "bold",
    padding:"5px 0px"
  },
  headerCellTypography: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  bodyCellTypography: {
    fontSize: "12px",
    color: "#333",
    textAlign: "left",
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
})

export default function CustomTable({ rows, columns, showProfileSection }) {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  if (!rows || rows.length === 0 || !columns || columns.length === 0) {
    return (
      <ThemeProvider theme={theme}>
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className={classes.paper}
        >
          <div style={{ padding: "20px", textAlign: "center" }}>
            No data found
          </div>
        </Paper>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{ width: "100%" }} className={classes.paper}>
        <TableContainer
          sx={{ maxHeight: 385 }}
          className={`${classes.tableContainer} ${classes.thinScrollbar}`}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    className={classes.headerCell}
                    sx={{
                      backgroundColor: "#660032",
                      color: "white",
                      maxWidth: column.width,
                      minWidth: column.width,
                      textAlign: "center",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row =>
                  row.isLoading ? (
                    <TableRow key={row.id} >
                      {columns.map((column, index) => (
                        <TableCell key={index}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={20}
                            style={{padding:"0px",margin:"0px"}}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      className={classes.tableRow}
                    >
                      {columns.map(column => {
                        const value = row[column.id]
                        let id = row["ProfileId"]
                        return (
                          <TableCell
                            key={column.id}
                            className={classes.cellWithEllipsis}
                            sx={{
                              maxWidth: column.width,
                              minWidth: column.width,
                              padding: "0px 6px",
                              textAlign: "center",
                            }}
                          >
                            {column.id === "Action" ? (
                              <span className={classes.bodyCellTypography}>
                                {value}
                              </span>
                            ) : column.id === "DataFulfillment" ? (
                              <ProgressBar
                                variant={value > 60 ? "success" : "danger"}
                                now={value}
                                label={
                                  <span
                                    style={{ color: "black" }}
                                  >{`${value}%`}</span>
                                }
                                style={{
                                  border:
                                    value > 60
                                      ? "1px solid #22bb33"
                                      : "1px solid #bb2124",
                                  margin: 5,
                                }}
                              />
                            ) : (
                              <Tooltip title={value}>
                                <span className={classes.bodyCellTypography}>
                                  {value}
                                </span>
                              </Tooltip>
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            toolbar: 'custom-pagination-toolbar',
          }}
        />
      </Paper>
    </ThemeProvider>
  )
}

// import React, { useState } from "react";
// import { Paper, Skeleton } from "@mui/material";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import Tooltip from "@mui/material/Tooltip";
// import { makeStyles } from "@mui/styles";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import ProgressBar from "react-bootstrap/ProgressBar";

// const useStyles = makeStyles({
//   cellWithEllipsis: {
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//   },
//   thinScrollbar: {
//     "&::-webkit-scrollbar": {
//       width: "5px",
//       height: "5px",
//     },
//     "&::-webkit-scrollbar-track": {
//       backgroundColor: "#f1f1f1",
//     },
//     "&::-webkit-scrollbar-thumb": {
//       backgroundColor: "#888",
//     },
//     "&::-webkit-scrollbar-thumb:hover": {
//       backgroundColor: "#555",
//     },
//   },
//   tableContainer: {
//     borderRadius: "4px",
//   },
//   paper: {
//     border: "solid 1px #660032",
//     borderRadius: "8px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//   },
//   tableRow: {
//     lineHeight: 1,
//     "&:nth-of-type(odd)": {
//       backgroundColor: "#f9f9f9",
//     },
//   },
//   headerCell: {
//     fontWeight: "bold",
//     lineHeight: 1,
//   },
//   headerCellTypography: {
//     fontWeight: "bold",
//     fontSize: "1rem",
//   },
//   bodyCellTypography: {
//     fontSize: "0.875rem",
//     color: "#333",
//     textAlign: "left",
//   },
// });

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#1976d2",
//     },
//   },
//   typography: {
//     fontFamily: "Arial, sans-serif",
//   },
// });

// export default function CustomTable({
//   rows = [],
//   columns = [],
//   loadingFiles = [],
//   isUploading,
// }) {
//   const classes = useStyles();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(15);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const renderRow = (file, index) => {
//     if (file.parsed) {
//       return (
//         <TableRow
//           hover
//           role="checkbox"
//           tabIndex={-1}
//           key={file.data.ProfileId}
//           className={classes.tableRow}
//         >
//           {columns.map((column) => {
//             const value = file.data[column.id];
//             return (
//               <TableCell
//                 key={column.id}
//                 className={classes.cellWithEllipsis}
//                 sx={{
//                   maxWidth: column.width,
//                   minWidth: column.width,
//                   padding: "0px 6px",
//                   textAlign: "center",
//                 }}
//               >
//                 {column.id === "Action" ? (
//                   <span className={classes.bodyCellTypography}>{value}</span>
//                 ) : column.id === "DataFulfillment" ? (
//                   <ProgressBar
//                     variant={value > 60 ? "success" : "danger"}
//                     now={value}
//                     label={<span style={{ color: "black" }}>{`${value}%`}</span>}
//                     style={{
//                       border:
//                         value > 60 ? "1px solid #22bb33" : "1px solid #bb2124",
//                       margin: 5,
//                     }}
//                   />
//                 ) : (
//                   <Tooltip title={value}>
//                     <span className={classes.bodyCellTypography}>{value}</span>
//                   </Tooltip>
//                 )}
//               </TableCell>
//             );
//           })}
//         </TableRow>
//       );
//     } else {
//       return (
//         <TableRow key={`skeleton-${index}`} className={classes.tableRow}>
//           {columns.map((column) => (
//             <TableCell
//               key={`${column.id}-skeleton`}
//               sx={{ textAlign: "center" }}
//             >
//               <Skeleton variant="rectangular" height={40} />
//             </TableCell>
//           ))}
//         </TableRow>
//       );
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Paper sx={{ width: "100%" }} className={classes.paper}>
//         <TableContainer
//           sx={{ maxHeight: 385 }}
//           className={`${classes.tableContainer} ${classes.thinScrollbar}`}
//         >
//           <Table stickyHeader aria-label="sticky table">
//             <TableHead>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.id}
//                     className={classes.headerCell}
//                     sx={{
//                       backgroundColor: "#660032",
//                       color: "white",
//                       maxWidth: column.width,
//                       minWidth: column.width,
//                       textAlign: "center",
//                     }}
//                   >
//                     {column.label}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {isUploading
//                 ? loadingFiles.map((file, index) => renderRow(file, index))
//                 : rows
//                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                     .map((row, index) =>
//                       renderRow({ parsed: true, data: row }, index)
//                     )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[15, 30, 50]}
//           component="div"
//           count={rows.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Paper>
//     </ThemeProvider>
//   );
// }
