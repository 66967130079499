export const INSERT_WORKFLOWS_REQUEST = 'INSERT_WORKFLOWS_REQUEST';
export const INSERT_WORKFLOWS_SUCCESS = 'INSERT_WORKFLOWS_SUCCESS';
export const INSERT_WORKFLOWS_FAILURE = 'INSERT_WORKFLOWS_FAILURE';


export const FETCH_WORKFLOWS_REQUEST = 'FETCH_WORKFLOWS_REQUEST';
export const FETCH_WORKFLOWS_SUCCESS = 'FETCH_WORKFLOWS_SUCCESS';
export const FETCH_WORKFLOWS_FAILURE = 'FETCH_WORKFLOWS_FAILURE';


export const UPDATE_WORKFLOW_REQUEST = 'UPDATE_WORKFLOW_REQUEST';
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS';
export const UPDATE_WORKFLOW_FAILURE = 'UPDATE_WORKFLOW_FAILURE';

export const FETCH_WORKFLOWS_BY_ID_REQUEST = 'FETCH_WORKFLOWS_BY_ID_REQUEST';
export const FETCH_WORKFLOWS_BY_ID_SUCCESS = 'FETCH_WORKFLOWS_BY_ID_SUCCESS';
export const FETCH_WORKFLOWS_BY_ID_FAILURE = 'FETCH_WORKFLOWS_BY_ID_FAILURE';

