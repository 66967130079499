// // src\store\index.js
// import { createStore, applyMiddleware, compose } from "redux"
// import { createBrowserHistory } from "history"
// import { routerMiddleware } from "connected-react-router"
// import createSagaMiddleware from "redux-saga"
// import rootReducer from "./reducers"
// import rootSaga from "./sagas"

// // Create a history object
// export const history = createBrowserHistory()
// const sagaMiddleware = createSagaMiddleware()
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store = createStore(
//   // rootReducer,
//   // rootReducer(history), // Pass history to rootReducer
//   rootReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware)),
//   // composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
// )
// sagaMiddleware.run(rootSaga)

// export default store

import { createStore, applyMiddleware, compose } from "redux"
import { createBrowserHistory } from "history"
import { routerMiddleware, connectRouter } from "connected-react-router"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer from "./reducers"
import rootSaga from "./sagas"

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Login"],
}

const persistedReducer = persistReducer(
  persistConfig,
  connectRouter(history)(rootReducer),
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
