import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { List } from "react-bootstrap-icons"

import logo from "assets/images/civixo-logo.png"
import usericon from "assets/images/civixo-logo.png"
import "./register-header.css"
import Drawer from "../Drawer/Drawer"

const menuList = [
  {
    id: 1,
    path: "https://www.sqano.com/",
    name: "Home",
    dropDown: [
      {
        id: 1,
        path: "https://www.sqano.com/#about",
        section: "#about",
        name: "About",
      },
    ],
  },
  {
    id: 2,
    section: "",
    name: "Products",
    dropDown: [
      {
        id: 1,
        path: "https://www.sqano.com/sqanit",
        name: "SQANIT",
      },
      {
        id: 2,
        path: "https://www.sqano.com/parseit",
        name: "PARSEIT",
      },
      {
        id: 3,
        path: "https://www.sqano.com/civix",
        name: "CIVIX",
      },
      {
        id: 4,
        path: "https://www.sqano.com/TOD",
        name: "TOD",
      },
    ],
  },
  {
    id: 3,
    path: "https://www.sqano.com/PriceSubscription",
    name: "PRICING",
  },
  {
    id: 4,
    path: "https://www.sqano.com/ComingSoon",
    name: "CAREER",
  },
  {
    id: 5,
    path: "https://www.sqano.com/all-blog",
    name: "Blog",
  },
  {
    id: 6,
    path: "https://www.sqano.com/contact",
    name: "CONTACT",
  },
]

const RegisterHeader = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
  const [dropDownId, setDropDownId] = useState(null)

  useEffect(() => {
    window.addEventListener("scroll", isSticky)
    return () => {
      window.removeEventListener("scroll", isSticky)
    }
  }, [])

  const isSticky = e => {
    const header = document.querySelector(".header-section")
    const scrollTop = window.scrollY

    scrollTop >= 35
      ? header.classList.add("menu-fixed", "animated", "fadeInDown")
      : header.classList.remove("menu-fixed")
  }

  const handleHeaderToggle = () => {
    setMenuActive(!menuActive)
  }

  const handleSubMenu = id => {
    setDropDownId(dropDownId === id ? null : id)
  }

  return (
    <header className="header-section">
      <div className="header-wrapper">
        <div className="main__logo">
          <Link to={"/home"} >
            <img src={logo} alt="logo" className="header-logo-image"/>
          </Link>
        </div>

        <div className="menu-container">
          <ul className={`main-menu ${menuActive ? "main-menu_active" : ""}`}>
            {menuList.map(({ id, name, path, dropDown, section }) => {
              return (
                <li key={id} onClick={() => handleSubMenu(id)}>
                  <HashLink smooth to={`${path}${section ? section : ""}`}>
                    {name}
                  </HashLink>
                  {dropDown?.length && (
                    <ul
                      className={`sub-menu ${
                        dropDownId === id ? "sub-menu_active" : ""
                      }`}
                    >
                      {dropDown.map(({ id, name, path }) => {
                        return (
                          <li key={id}>
                            <HashLink smooth to={path}>
                              {name}
                            </HashLink>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
          <div className="menu__components d-flex align-items-center">
            <div
              onClick={handleHeaderToggle}
              className={`header-bar d-lg-none ${menuActive ? "active" : ""}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              onClick={() => setIsSidebarActive(true)}
              className="header-drawer-toggle"
            >
              <List className="icon" />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        isSidebarActive={isSidebarActive}
        setIsSidebarActive={setIsSidebarActive}
      />
    </header>
  )
}

export default RegisterHeader
