import React, { useState, useEffect } from "react";
import C3Chart from "react-c3js"
import "c3/c3.css"

const DonutChart = () => {
  const [chartData, setChartData] = useState(null);  

  useEffect(() => {
    // Simulating data fetching or initialization
    const fetchData = () => {
      return {
        columns: [
          ["PARSEIT", 33],
          ["CIVIX", 33],
          ["SQANIT", 34],
        ],
        type: "donut",
      };
    };

    const data = fetchData();
    if (data && data.columns) {
      console.log("data fetched");
      setChartData(data);
    } else {
      console.error('Error: Data or data.columns is null');
    }
  }, []);

  const donut = {
    title: "Profiles",
    width: 30,
    label: { show: !1 },
  }

  const color = {
    pattern: ["#660032","#b84d81","#85194e"],
  }

  const size = {
    height: 300,
  }

  if (!chartData) {
    console.warn("no chart data");
    return <div>Loading...</div>;
  }

  return (
    <>
      <C3Chart data={chartData} donut={donut} color={color} size={size} dir="ltr" />
    </>
  )
}

export default DonutChart
