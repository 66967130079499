import {
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILURE,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  GET_VENDOR_BY_ID_REQUEST,
  GET_VENDOR_BY_ID_SUCCESS,
  GET_VENDOR_BY_ID_FAILURE,
  GET_SUPERADMIN_BY_ID_REQUEST,
  GET_SUPERADMIN_BY_ID_SUCCESS,
  GET_SUPERADMIN_BY_ID_FAILURE,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAILURE,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAILURE,
  UPDATE_SUPERADMIN_REQUEST,
  UPDATE_SUPERADMIN_SUCCESS,
  UPDATE_SUPERADMIN_FAILURE,
} from "./actionTypes"

export const createVendorRequest = vendorData => ({
  type: CREATE_VENDOR_REQUEST,
  payload: vendorData,
})

export const createVendorSuccess = vendor => ({
  type: CREATE_VENDOR_SUCCESS,
  payload: vendor,
})

export const createVendorFailure = error => ({
  type: CREATE_VENDOR_FAILURE,
  payload: error,
})

export const getVendorsRequest = () => ({
  type: GET_VENDORS_REQUEST,
})

export const getVendorsSuccess = vendors => ({
  type: GET_VENDORS_SUCCESS,
  payload: vendors,
})

export const getVendorsFailure = error => ({
  type: GET_VENDORS_FAILURE,
  payload: error,
})

export const getVendorByIdRequest = id => ({
  type: GET_VENDOR_BY_ID_REQUEST,
  payload: id,
})

export const getVendorByIdSuccess = vendor => ({
  type: GET_VENDOR_BY_ID_SUCCESS,
  payload: vendor,
})

export const getVendorByIdFailure = error => ({
  type: GET_VENDOR_BY_ID_FAILURE,
  payload: error,
})

export const getSuperAdminByIdRequest = id => ({
  type: GET_SUPERADMIN_BY_ID_REQUEST,
  payload: id,
})

export const getSuperAdminByIdSuccess = vendor => ({
  type: GET_SUPERADMIN_BY_ID_SUCCESS,
  payload: vendor,
})

export const getSuperAdminByIdFailure = error => ({
  type: GET_SUPERADMIN_BY_ID_FAILURE,
  payload: error,
})

export const updateVendorRequest = (id, data) => ({
  type: UPDATE_VENDOR_REQUEST,
  payload: { id, data },
})

export const updateVendorSuccess = vendor => ({
  type: UPDATE_VENDOR_SUCCESS,
  payload: vendor,
})

export const updateVendorFailure = error => ({
  type: UPDATE_VENDOR_FAILURE,
  payload: error,
})

export const updateSuperAdminRequest = ({id, formData}) => ({
  type: UPDATE_SUPERADMIN_REQUEST,
  payload: { id, formData },
})

export const updateSuperAdminSuccess = vendor => ({
  type: UPDATE_SUPERADMIN_SUCCESS,
  payload: vendor,
})

export const updateSuperAdminFailure = error => ({
  type: UPDATE_SUPERADMIN_FAILURE,
  payload: error,
})

export const deleteVendorRequest = id => ({
  type: DELETE_VENDOR_REQUEST,
  payload: id,
})

export const deleteVendorSuccess = id => ({
  type: DELETE_VENDOR_SUCCESS,
  payload: id,
})

export const deleteVendorFailure = error => ({
  type: DELETE_VENDOR_FAILURE,
  payload: error,
})
