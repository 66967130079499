import React from "react"
import "./Help.scss"
import faq from "../../../assets/images/faq-img.png"

const Help = () => {
  return (
    <div className="info-cards">
      <div className="help-cards-image text-center">
        <img src={faq} alt="Header" />
      </div>
      <div className="help-card">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="info-card">
              <div className="info-card-icon">
                <div className="question-icon">?</div>
              </div>
              <div className="info-card-content">
                <h3>What is Sqano</h3>
                <p>New common language will be more simple and regular than the existing European languages.</p>
                <a href="#">Read More &rarr;</a>
              </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="info-card">
              <div className="info-card-icon">
                <div className="question-icon">?</div>
              </div>
              <div className="info-card-content">
                <h3>Where does it come from?</h3>
                <p>New common language will be more simple and regular than the existing European languages.</p>
                <a href="#">Read More &rarr;</a>
              </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="info-card">
              <div className="info-card-icon">
                <div className="question-icon">?</div>
              </div>
              <div className="info-card-content">
                <h3>Where can I get some?</h3>
                <p>New common language will be more simple and regular than the existing European languages.</p>
                <a href="#">Read More &rarr;</a>
              </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="info-card">
              <div className="info-card-icon">
                <div className="question-icon">?</div>
              </div>
              <div className="info-card-content">
                <h3>Why do we use it?</h3>
                <p>New common language will be more simple and regular than the existing European languages.</p>
                <a href="#">Read More &rarr;</a>
              </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="info-card">
              <div className="info-card-icon">
                <div className="question-icon">?</div>
              </div>
              <div className="info-card-content">
                <h3>Where can I get some?</h3>
                <p>New common language will be more simple and regular than the existing European languages.</p>
                <a href="#">Read More &rarr;</a>
              </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="info-card">
              <div className="info-card-icon">
                <div className="question-icon">?</div>
              </div>
              <div className="info-card-content">
                <h3>What is Lorem Ipsum?</h3>
                <p>New common language will be more simple and regular than the existing European languages.</p>
                <a href="#">Read More &rarr;</a>
              </div>
            </div>
        </div>
        
      </div>
      </div>
    </div>
  )
}

export default Help
