import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import Icon from '@mdi/react';
import "../TopbarDropdown/menudropdown.scss"
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import WorkIcon from '@mui/icons-material/Work';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddCardIcon from '@mui/icons-material/AddCard';
import CallIcon from '@mui/icons-material/Call';


//i18n
import { withTranslation } from "react-i18next"
import { width } from "@mui/system"
import { useSelector } from "react-redux"

const MenuListDropdown = (props) => {
  const userRole = useSelector(state => state.Login?.user?.userDetails?.roleAndFeatures?.name)
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item header-item-inner noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <ViewCompactIcon />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu custom-dropdown dropdown-menu-lg dropdown-menu-end p-0">
          <SimpleBar style={{ height: "45px" }}>
            <div className="menu-header">
              <nav className="navbar navbar-expand-lg text-center">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse dropdown-wrap"
                    id="navbarSupportedContent"
                  >
                    {/* Centered unordered list */}
                    <div className="menu-list-center">
                      <ul className="navbar-nav mb-2 me-auto mb-lg-0">
                        {
                          userRole !== "Recruiter" && (
                            <li className="nav-item dropdown">
                              <Link className="nav-link dropdown-toggle" to="/tod" >
                                <WorkIcon style={{ fontSize: "15px", marginRight: "5px" }} />
                                Post A Job
                              </Link>
                            </li>
                          )
                        }
                        {/* <li className="nav-item dropdown">
                          <Link className="nav-link dropdown-toggle" to="/pages-blank">
                            <InsertDriveFileIcon style={{ fontSize: "15px", marginRight: "5px" }} />
                            New Requirement
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link className="nav-link" to="/comming-soon">
                            <AddCardIcon style={{ fontSize: "15px", marginRight: "5px" }} />
                            Subscription
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/comming-soon">
                            <i className="mdi mdi-cog" style={{ marginRight: "5px" }}></i>
                            Settings & Privacy
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="https://www.sqano.com/contact"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CallIcon style={{ fontSize: "15px", marginRight: "5px" }} />
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
export default withTranslation()(MenuListDropdown);

MenuListDropdown.propTypes = {
  t: PropTypes.any,
};
