// import {
//   SEND_OTP_REQUEST,
//   SEND_OTP_SUCCESS,
//   SEND_OTP_FAILURE,
//   VERIFY_OTP_REQUEST,
//   VERIFY_OTP_SUCCESS,
//   VERIFY_OTP_FAILURE,
//   REGISTER_REQUEST,
//   REGISTER_SUCCESS,
//   REGISTER_FAILURE,
// } from "./actionTypes"

// export const sendOtpRequest = email => ({
//   type: SEND_OTP_REQUEST,
//   payload: email,
// })

// export const sendOtpSuccess = () => ({
//   type: SEND_OTP_SUCCESS,
// })

// export const sendOtpFailure = error => ({
//   type: SEND_OTP_FAILURE,
//   payload: error,
// })

// export const verifyOtpRequest = ({ email, otp }) => ({
//   type: VERIFY_OTP_REQUEST,
//   payload: { email, otp },
// })

// export const verifyOtpSuccess = () => ({
//   type: VERIFY_OTP_SUCCESS,
// })

// export const verifyOtpFailure = error => ({
//   type: VERIFY_OTP_FAILURE,
//   payload: error,
// })

// export const registerRequest = user => ({
//   type: REGISTER_REQUEST,
//   payload: user,
// })

// export const registerSuccess = user => ({
//   type: REGISTER_SUCCESS,
//   payload: user,
// })

// export const registerFailure = error => ({
//   type: REGISTER_FAILURE,
//   payload: error,
// })

import {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SEND_OTP_SMS_REQUEST,
  SEND_OTP_SMS_SUCCESS,
  SEND_OTP_SMS_FAILURE,
  VERIFY_OTP_SMS_REQUEST,
  VERIFY_OTP_SMS_SUCCESS,
  VERIFY_OTP_SMS_FAILURE,
  RESET_REGISTRATION_STATE,
} from "./actionTypes"

// Email OTP Actions
export const sendOtpRequest = email => ({
  type: SEND_OTP_REQUEST,
  payload: email,
})

export const sendOtpSuccess = () => ({
  type: SEND_OTP_SUCCESS,
})

export const sendOtpFailure = error => ({
  type: SEND_OTP_FAILURE,
  payload: error,
})

export const verifyOtpRequest = ({ email, emailOtp }) => ({
  type: VERIFY_OTP_REQUEST,
  payload: { email, emailOtp },
})

export const verifyOtpSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
})

export const verifyOtpFailure = error => ({
  type: VERIFY_OTP_FAILURE,
  payload: error,
})

// SMS OTP Actions
export const sendOtpSmsRequest = contactNo => ({
  type: SEND_OTP_SMS_REQUEST,
  payload: contactNo,
})

export const sendOtpSmsSuccess = () => ({
  type: SEND_OTP_SMS_SUCCESS,
})

export const sendOtpSmsFailure = error => ({
  type: SEND_OTP_SMS_FAILURE,
  payload: error,
})

export const verifyOtpSmsRequest = ({ contactNo, smsOtp }) => ({
  type: VERIFY_OTP_SMS_REQUEST,
  payload: { contactNo, smsOtp },
})

export const verifyOtpSmsSuccess = () => ({
  type: VERIFY_OTP_SMS_SUCCESS,
})

export const verifyOtpSmsFailure = error => ({
  type: VERIFY_OTP_SMS_FAILURE,
  payload: error,
})

// User Registration Actions
export const registerRequest = user => ({
  type: REGISTER_REQUEST,
  payload: user,
})

export const registerSuccess = user => ({
  type: REGISTER_SUCCESS,
  payload: user,
})

export const registerFailure = error => ({
  type: REGISTER_FAILURE,
  payload: error,
})

export const resetRegistrationState = () => ({
  type: RESET_REGISTRATION_STATE,
});
