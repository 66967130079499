import { CREATE_ROLE_FAILURE, CREATE_ROLE_REQUEST, CREATE_ROLE_SUCCESS, FETCH_ROLES_FAILURE, FETCH_ROLES_REQUEST, FETCH_ROLES_SUCCESS } from "./actionTypes";


const initialState = {
    roles: [],
    loading: false,
    error: null,
    role: null
};

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROLES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload
            };
        case FETCH_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_ROLE_REQUEST:
            return { ...state, loading: true, error: null };
        case CREATE_ROLE_SUCCESS:
            return { ...state, loading: false, role: action.payload, error: null };
        case CREATE_ROLE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default roleReducer;
