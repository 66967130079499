import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              <p style={{ color: "#fff" }}>
              All Rights Reserved By SQANO IT Solution Limited
                
              </p>
            </Col>
            
            <Col sm={3}>
              <p>
                <a
                  href="https://www.sqano.com/TermsCondition" target="blank"
                  className="base"
                  style={{ color: "#fff" }}
                >
                  Terms & Condition
                </a>
              </p>
            </Col>
            <Col sm={3}>
              <p>
                <a
                  href="https://www.sqano.com/PrivacyPolicy" target="blank"
                  className="base"
                  style={{ color: "#fff" }}
                >
                  Privacy Policy
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
