import { takeLatest, call, put, fork } from "redux-saga/effects"
import {
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILURE,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  GET_VENDOR_BY_ID_REQUEST,
  GET_VENDOR_BY_ID_SUCCESS,
  GET_VENDOR_BY_ID_FAILURE,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAILURE,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAILURE,
  GET_SUPERADMIN_BY_ID_REQUEST,
  UPDATE_SUPERADMIN_REQUEST,
} from "./actionTypes"
import {
  createVendorSuccess,
  createVendorFailure,
  getVendorsSuccess,
  getVendorsFailure,
  getVendorByIdSuccess,
  getVendorByIdFailure,
  updateVendorSuccess,
  updateVendorFailure,
  deleteVendorSuccess,
  deleteVendorFailure,
  getSuperAdminByIdSuccess,
  updateSuperAdminSuccess,
  updateSuperAdminFailure,
} from "./actions"

import {
  createVendorWithSuperAdmins,
  getAllVendors,
  getVendorById,
  updateVendor,
  deleteVendor,
  createVendorProfile,
  getSuperAdminById,
  updateSuperAdmin,
} from "../../api"
import { all } from "axios"

function* createVendorSaga(action) {
  try {
    console.log("i am here",action.payload);
    const response = yield call(createVendorProfile, action.payload);
    
    yield put(createVendorSuccess(response.data));
  } catch (error) {
    yield put(createVendorFailure(error.message))
  }
}

function* getVendorsSaga() {
  try {
    const response = yield call(getAllVendors)
    yield put(getVendorsSuccess(response.data))
  } catch (error) {
    yield put(getVendorsFailure(error.message))
  }
}

function* getVendorByIdSaga(action) {
  try {
    const response = yield call(getVendorById, action.payload)
    yield put(getVendorByIdSuccess(response.data))
  } catch (error) {
    yield put(getVendorByIdFailure(error.message))
  }
}

// function* getSuperAdminByIdSaga(action) {
//   try {

//     console.log("In saga",action.payload);
//     const response = yield call(getSuperAdminById, action.payload)
//     console.log("response-",response);
//     yield put(getSuperAdminByIdSuccess(response.data))
//   } catch (error) {
//     yield put(getVendorByIdFailure(error.message))
//   }
// }

function* updateVendorSaga(action) {
  try {
    const response = yield call(
      updateVendor,
      action.payload.id,
      action.payload.data,
    )
    yield put(updateSuperAdminSuccess(response.data))
  } catch (error) {
    yield put(updateSuperAdminFailure(error.message))
  }
}

function* updateSuperAdminSaga(action) {
  try {
    console.log("res",action.payload);
    
    const response = yield call(
      updateSuperAdmin,
      action.payload.id,
      action.payload.formData,
    )
    console.log("respo",response.data);
    yield put(updateSuperAdminSuccess(response.data))
  } catch (error) {
    yield put(updateSuperAdminFailure(error.message))
  }
}

function* deleteVendorSaga(action) {
  try {
    yield call(deleteVendor, action.payload)
    yield put(deleteVendorSuccess(action.payload))
  } catch (error) {
    yield put(deleteVendorFailure(error.message))
  }
}

export default function* watchVendorActions() {
  yield takeLatest(CREATE_VENDOR_REQUEST, createVendorSaga)
  yield takeLatest(GET_VENDORS_REQUEST, getVendorsSaga)
  yield takeLatest(GET_VENDOR_BY_ID_REQUEST, getVendorByIdSaga)
  // yield takeLatest(GET_SUPERADMIN_BY_ID_REQUEST, getSuperAdminByIdSaga)
  yield takeLatest(UPDATE_VENDOR_REQUEST, updateVendorSaga)
  yield takeLatest(UPDATE_SUPERADMIN_REQUEST, updateSuperAdminSaga)
  yield takeLatest(DELETE_VENDOR_REQUEST, deleteVendorSaga)
}

// Watcher Sagas
// function* watchCreateVendorSaga() {
//   yield takeLatest(CREATE_VENDOR_REQUEST, createVendorSaga)
// }

// function* watchGetVendorsSaga() {
//   yield takeLatest(GET_VENDORS_REQUEST, getVendorsSaga)
// }

// function* watchGetVendorByIdSaga() {
//   yield takeLatest(GET_VENDOR_BY_ID_REQUEST, getVendorByIdSaga)
// }

// function* watchUpdateVendorSaga() {
//   yield takeLatest(UPDATE_VENDOR_REQUEST, updateVendorSaga)
// }

// function* watchDeleteVendorSaga() {
//   yield takeLatest(DELETE_VENDOR_REQUEST, deleteVendorSaga)
// }

// // Root Saga
// export default function* vendorSagas() {
//   yield all([
//     fork(watchCreateVendorSaga),
//     fork(watchGetVendorsSaga),
//     fork(watchGetVendorByIdSaga),
//     fork(watchUpdateVendorSaga),
//     fork(watchDeleteVendorSaga),
//   ])
// }
