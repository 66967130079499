import React, { useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import "./CvScanning.scss";
import Icon from "@mdi/react";

import {
  mdiMagnifyScan,
  mdiTextBox,
  mdiFileAccount,
  mdiBriefcaseAccount,
  mdiSearchWeb,
  mdiChartLine,
  mdiClipboardText,
  mdiFileFind,
} from "@mdi/js";

const tabConfigs = [
  { label: "SQANIT", path: "/scan-it", icon: mdiMagnifyScan },
  { label: "PARSEIT", path: "/parse-it", icon: mdiTextBox },
  { label: "CIVIX", path: "/civix", icon: mdiFileAccount },
  { label: "TOD", path: "/tod", icon: mdiBriefcaseAccount },
  { label: "WEBIT", path: "/web-it", icon: mdiSearchWeb },
  { label: "SQALEUP", path: "/sqale-up", icon: mdiChartLine },
  { label: "MOCK TEST", path: "/mocktest", icon: mdiClipboardText },
  { label: "CV TEMPLATE", path: "/cvtemplate", icon: mdiFileFind },
];

const ProductTabs = ({ tab = 0 }) => {
  const [value, setValue] = useState(tab);
  const navigate = useNavigate();

  const handleChange = (index, path) => {
    setValue(index);
    navigate(path);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="product-tabs">
            {tabConfigs.map((tab, index) => (
              <React.Fragment key={index}>
                <button
                  onClick={() => handleChange(index, tab.path)}
                  className={`product-tabs-buttons ${value === index ? "active" : ""}`}
                >
                  <Icon
                    path={tab.icon}
                    size={0.5}
                    className={`product-tab-icon ${value === index ? "active" : ""}`}
                  />
                  {tab.label}
                </button>
                {index < tabConfigs.length - 1 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    className="tab-divider"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductTabs;
