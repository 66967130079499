import React from "react"
import { Card, Row, Col } from "reactstrap"
import "pages/Sqano Dashboard/dashboard.css"

const Miniwidget = props => {
  return (
    <React.Fragment>
      <Row>
        {props.reports.map((report, key) => (
          <Col
            xl={3}
            lg={4}
            md={6}
            sm={6}
            xs={12}
            key={key}
            className="miniwidget-col"
          >
            <Card className="miniwidget-card">
              <i
                className={"mdi mdi-" + report.iconClass + " miniwidget-icon"}
              ></i>
              <h6 className="miniwidget-title">{report.title}</h6>
              <h2 className="miniwidget-total">{report.total}</h2>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default Miniwidget
