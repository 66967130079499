import React, { useState } from "react"
import { Select, MenuItem, FormControl } from "@mui/material"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./table.css" // Ensure this import line matches the path of your CSS file

const CustomToolbar = ({
  sortValue,
  setSortValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false)

  const handleSortChange = event => {
    const value = event.target.value
    setSortValue(value)
    if (value === "date") {
      setShowDatePicker(true)
    } else {
      setShowDatePicker(false)
    }
  }

  const commonFontStyle = {
    fontFamily: "Arial, sans-serif", // Ensure this matches the desired font family
  }

  return (
    <div className="custom-toolbar-container">
      <div className="custom-toolbar-sort-label">SORT</div>
      <FormControl variant="outlined" className="custom-toolbar-form-control">
        <Select
          value={sortValue}
          onChange={handleSortChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className="custom-toolbar-select"
          sx={{ ...commonFontStyle, width: "100%" }}
        >
          <MenuItem
            value=""
            className="custom-toolbar-menu-item"
            sx={{ fontSize: "12px", ...commonFontStyle }}
          >
            All
          </MenuItem>
          <MenuItem
            value="date"
            className="custom-toolbar-menu-item"
            sx={{ fontSize: "12px", ...commonFontStyle }}
          >
            Date
          </MenuItem>
          <MenuItem
            value="paid"
            className="custom-toolbar-menu-item"
            sx={{ fontSize: "12px", ...commonFontStyle }}
          >
            Paid Subscriber
          </MenuItem>
          <MenuItem
            value="unpaid"
            className="custom-toolbar-menu-item"
            sx={{ fontSize: "12px", ...commonFontStyle }}
          >
            Unpaid Subscriber
          </MenuItem>
          <MenuItem
            value="old"
            className="custom-toolbar-menu-item"
            sx={{ fontSize: "12px", ...commonFontStyle }}
          >
            Old Subscriber
          </MenuItem>
          <MenuItem
            value="new"
            className="custom-toolbar-menu-item"
            sx={{ fontSize: "12px", ...commonFontStyle }}
          >
            New Subscriber
          </MenuItem>
        </Select>
      </FormControl>
      {showDatePicker && (
        <div className="custom-toolbar-date-picker-container">
          <div className="custom-toolbar-date-picker-wrapper">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="custom-toolbar-date-picker-input"
            />
          </div>
          <div className="custom-toolbar-date-picker-wrapper">
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
              className="custom-toolbar-date-picker-input"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomToolbar
