import React from "react"
import { Card } from "reactstrap"
import "./FeatureToggle.css" // Adjust the import path as necessary

const EditDropdown = ({
  features,
  handleFeatureToggle,
  handleClose,
  isPaidSubscriber,
}) => {
  return (
    <Card className="mt-3 edit-dropdown-card">
      <span className="dropdown-close-btn" onClick={handleClose}>
        &times;
      </span>
      <div className="dropdown-header">
        <h6>Edit Features</h6>
      </div>
      <div className="feature-item header">
        <span className="feature-name">Features</span>
        <span className="feature-limit">Limit</span>
        <span className="feature-toggle">Toggle</span>
      </div>
      {features.map(feature => (
        <div key={feature.id} className="feature-item">
          <span className="feature-name">{feature.name}</span>
          <span className="feature-limit">
            {isPaidSubscriber ? "Unlimited" : "Limited"}
          </span>
          <label className="toggle_switch">
            <input
              type="checkbox"
              checked={feature.enabled}
              onChange={() => handleFeatureToggle(feature.id)}
            />
            <span className="switch_slider"></span>
          </label>
        </div>
      ))}
    </Card>
  )
}

export default EditDropdown
