import { fetchWorkflowsByIdRequest, fetchWorkflowsRequest, updateWorkflowRequest } from "store/actions";
import MUIDataTable from 'mui-datatables';
import {
    Box,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Button,
    Typography,
    Drawer,
    IconButton,
    Rating,
    Chip,
    Modal
} from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { TextField } from "@mui/material";
import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import GeneratePdf from 'components/Common/GeneratePdf/GeneratePdf';
import PulseLoader from "pages/PulseLoader/PulseLoader";
import CustomSearchRender from "pages/Sqano Dashboard/CustomSearchRender";
import FilterBtn from "pages/SuperAdmin/FilterBtn";
import FilterButton from "./FilterButton";
import "./scanithistory.css";
import CloseIcon from '@mui/icons-material/Close';
import CustomWarn from "components/CustomComponents/CustomWarn";
import { Formik, Form } from 'formik';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CloseOutlined } from '@mui/icons-material';





const ScanItHistory = () => {
    const dispatch = useDispatch();
    let { workflows, loading } = useSelector((state) => state.workflowReducer);
    let { id } = useSelector((state) => state.Login?.user?.userDetails);
    const reversedWorkflows = workflows.slice().reverse();
    console.log("reversedWorkflows", reversedWorkflows)
    const [activeStep, setActiveStep] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [ratings, setRatings] = useState({});
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState();
    const [filterValues, setFilterValues] = useState();
    const [filterModalView, setFilterModalView] = useState(false);


    const [statusData, setStatusData] = useState({
        progressStatus: "",
        remark: "",
    });

    const [formData, setFormData] = useState({
        noticePeriod: "",
        availabilityDate: "",
        screeningDate: "",
        skillsAndRating: [{ skill: "", rating: 0 }],
        currentCTC: "",
        expectedCTC: 0,
        relevantExperience: 0,
        overallExperience: 0,
    });
    const [open, setOpen] = useState(false)
    const [analysis, setAnalysis] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [status, setStatus] = useState("");


    const steps = [
        "Personal Information",
        "Sqano recommended Q & A",
        "Job Application",
        "PDF Generation"
    ];

    const formatContactNumber = (value) => {
        let countryCode = '';
        let phoneNumber = value;
      
        // Check if the number starts with a '+' (international format)
        if (value.startsWith('+')) {
          // Extract country code (assuming it starts with '+')
          countryCode = value.match(/^\+(\d+)/)?.[0] || '';
          // Extract the rest of the number
          phoneNumber = value.replace(countryCode, '').trim();
        } else {
          // Handle case where there is no '+' (assumes the first 2 digits are country code, e.g., "91")
          countryCode = `+${value.slice(0, 2)}`; // Assuming the country code is the first two digits
          phoneNumber = value.slice(2).trim(); // Extract rest of the number
        }
      
        // Return the formatted number with only a space between country code and the number
        return `${countryCode} ${phoneNumber}`;
      };

    const customBodyRender = (value) => (
        <div>
            {value?.length > 14 ? (
                <Tooltip title={value} arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#660032',
                                color: '#ffffff',
                                '& .MuiTooltip-arrow': {
                                    color: '#660032',
                                }
                            }
                        }
                    }}
                >
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100px',
                            display: 'inline-block',  // Changed from block to inline-block
                            width: 'fit-content',     // Added to make width fit content
                        }}>{value}</span>
                </Tooltip>
            ) : (
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100px',
                        display: 'inline-block',
                        width: 'fit-content',
                    }}>{value}</span>
            )}
        </div>
    );

    const columns = [
        {
            name: "_id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
                download: false,
                print: false,
                viewColumns: false,
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
            },
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return formatContactNumber(value);
                },
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "jobTitle",
            label: "Job Title",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "skills",
            label: "Skills",
            options: {
                filter: true,
                filterType: "multiselect",
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //   console.log("value", value);

                //   // Split the value string into an array if it's a string
                //   const skillsArray = typeof value === 'string' ? value.split(', ') : [];

                //   return (
                //     <div
                //       aria-owns={open ? "mouse-over-popover" : undefined}
                //       aria-haspopup="true"
                //       onMouseEnter={(e) => handlePopoverOpen(e, skillsArray)}
                //       onMouseLeave={handlePopoverClose}
                //     >
                //       {/* Map only the first two skills to display as chips */}
                //       {skillsArray.slice(0, 2).map((skill, index) => (
                //         <Chip key={index} label={skill} size="small" sx={{ m: 0.5 }} />
                //       ))}

                //       {/* Display a chip showing how many more skills are there if there are more than two */}
                //       {skillsArray.length > 2 && (
                //         <Tooltip
                //           title={<Stack spacing={1}>{skillsArray.slice(2).join(", ")}</Stack>}
                //           placement="top"
                //           arrow
                //         >
                //           <Chip
                //             label={`+${skillsArray.length - 2}`}
                //             size="small"
                //             sx={{ m: 0.5 }}
                //           />
                //         </Tooltip>
                //       )}
                //     </div>
                //   );
                // }
            },
        },

        {
            name: "experience",
            label: "Experience ",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender,
            },
        },
        {
            name: "fitmentPercentage",
            label: "Fitment",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{
                        color: "#660032", paddingLeft: "14px", whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                    }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    // console.log("value", value)
                    let color = 'default';
                    let label = value;

                    if (value < "65%") {
                        color = 'error';
                    }  else {
                        color = 'success';
                    }

                    return (
                        <Chip sx={{ fontSize: "9px" }} size="small" label={label} color={color} />
                    );
                },
            },
        },
        {
            name: "analysis",
            label: "Action",

            options: {
                filter: true,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender: function viewCandidateSynopsisAndQuestionnaire(
                    value,
                    tableMeta
                ) {
                    const rowData = tableMeta.rowData;
                    const rowIndex = tableMeta.rowIndex;
                    const candidate = reversedWorkflows[rowIndex];

                    // Check if name or phone is missing
                    const isNameOrPhoneMissing = !(candidate?.name) || !(candidate?.phone);
                    return (
                        <>
                            {parseInt(rowData[7]) < 65 || isNameOrPhoneMissing ?  (
                                <Tooltip title="Fitment percentage is below 65%" arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#660032',
                                                color: '#ffffff',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#660032',
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <span>
                                        <IconButton disabled>
                                            <VisibilitySharpIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Synopsis Questionnaire" arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#660032',
                                                color: '#ffffff',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#660032',
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <IconButton
                                        onClick={(e) => handleViewIconClick(rowData, rowIndex)}
                                        disabled={isNameOrPhoneMissing}
                                    >
                                        <ArrowCircleRightIcon sx={{ color: "#660032" }} />
                                    </IconButton>
                                </Tooltip>
                            )
                            }
                        </>
                    );
                },
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customHeadRender: ({ index, ...columnMeta }) => (
                    <th key={index} style={{ color: "#660032", paddingLeft: "14px" }}>
                        {columnMeta.label}
                    </th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Get the row index and reversedWorkflows data
                    const rowIndex = tableMeta.rowIndex;
                    const candidate = reversedWorkflows[rowIndex];
        
                    // Display the progressStatus (selected status) instead of the percentage
                    let statusLabel = candidate?.progressStatus || "Pending"; // Default to Pending if no status
                    const capitalizeWords = (label) => {
                        return label
                          .split('_') // Handle words separated by underscores
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                          .join(' '); // Join back into a single string
                      };
                
                      const capitalizedStatus = capitalizeWords(statusLabel);
                
        
                    // Return the status label as a Chip without color
                    return (
                        <Chip
                            size="small"
                            sx={{ fontSize: "12px",fontFamily: 'Roboto' }}
                            label={capitalizedStatus}
                        />
                    );
                },
            },
        }
        
    ];

    const options = {
        // filterType: "textField",
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        search: false,
        selectableRows: "none",
        sort: false,
        setTableProps: () => ({
            size: "small",

        }),
        rowsPerPageOptions: [5, 10, 15, 20],
        rowsPerPage: 5,
    };

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        setStatusData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log("Status Updated: ", value);
    };

    const handleRatingChange = (index, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [index]: value,
        }));
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, "0");
        const day = now.getDate().toString().padStart(2, "0");
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleChange = (e, newValue, index) => {
        const { name, value } = e.target;

        if (name === "skill" || name === "rating") {
            let updatedSkills = [...formData.skillsAndRating];
            let updatedSkill = { ...updatedSkills[index], [name]: newValue };
            updatedSkills[index] = updatedSkill;
            setFormData({ ...formData, skillsAndRating: updatedSkills });
        } else {
            const updatedFormData = { ...formData, [name]: value };
            setFormData(updatedFormData);
        }
    };

    const transformSkillsToSkillsAndRating = (skillsString) => {
        // const skillsString = skillsArray[0];
        const skills = skillsString.split(",");
        return skills.map((skill) => ({
            skill,
            rating: 0,
        }));
    };

    function formatToDateTimeLocal(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const handleViewIconClick = (rowData, rowIndex) => {
        if (reversedWorkflows[rowIndex].status === "0%") {
            setActiveStep(0)
        } else if (reversedWorkflows[rowIndex].status === "25%") {
            setActiveStep(1)
        } else if (reversedWorkflows[rowIndex].status === "50%") {
            setActiveStep(2)
        } else if (reversedWorkflows[rowIndex].status === "75%") {
            setActiveStep(3)
        } else {
            setActiveStep(3)
        }
        setIsDrawerOpen(!isDrawerOpen);
        setAnalysis(rowData);
        setSelectedIndex(rowIndex)
        const skillsAndRating = transformSkillsToSkillsAndRating(
            reversedWorkflows[rowIndex]?.skills
        );
        setFormData({
            noticePeriod: reversedWorkflows[rowIndex]?.reviewDetails?.noticePeriod || "",
            availabilityDate: reversedWorkflows[rowIndex]?.reviewDetails?.availabilityDate
                ? formatToDateTimeLocal(reversedWorkflows[rowIndex]?.reviewDetails?.availabilityDate)
                : "",
            screeningDate: reversedWorkflows[rowIndex]?.reviewDetails?.screeningDate || "",
            skillsAndRating:
                reversedWorkflows[rowIndex]?.reviewDetails?.skillsAndRating?.length > 0
                    ? reversedWorkflows[rowIndex]?.reviewDetails?.skillsAndRating
                    : skillsAndRating,
            currentCTC: reversedWorkflows[rowIndex]?.reviewDetails?.currentCTC || "",
            expectedCTC: reversedWorkflows[rowIndex]?.reviewDetails?.expectedCTC || "",
            relevantExperience: reversedWorkflows[rowIndex]?.reviewDetails?.relevantExperience || "",
            overallExperience: reversedWorkflows[rowIndex]?.reviewDetails?.overallExperience || "",
        });
        const initialRatings = reversedWorkflows[rowIndex]?.analysis?.questionnaire.reduce((acc, item, index) => {
            acc[index] = item.rating || 0;
            return acc;
        }, {});

        setRatings(initialRatings);

        setStatusData({
            progressStatus: reversedWorkflows[rowIndex]?.progressStatus || "",  // Initialize progress status
            remark: reversedWorkflows[rowIndex]?.remark || "",  // Initialize remark
        });

        setStatus(reversedWorkflows[rowIndex]?.status);
        console.log("sachin")
    };

    const handleDrawerClick = () => {
        setIsDrawerOpen(!isDrawerOpen);
    }

    const isFormIncomplete = () => {
        const areBasicFieldsIncomplete =
            formData.availabilityDate === "" ||
            formData.noticePeriod <= 0 ||
            formData.currentCTC === "" ||
            formData.expectedCTC <= 0 ||
            formData.overallExperience <= 0 || // Corrected condition
            formData.relevantExperience <= 0;  // Corrected condition

        const areSkillsIncomplete = formData.skillsAndRating?.some((skill) => {
            return skill.skill === "" || skill.rating <= 0;
        });

        return areBasicFieldsIncomplete || areSkillsIncomplete;
    };

    const handleNext = () => {
        console.log("Current status:", statusData.progressStatus);
        if (!statusData.progressStatus || statusData.progressStatus === "Pending") {
            alert('Please select a status other than "Pending" before proceeding.');
            return;
        }
        if (activeStep === 0) {
            const copyCollectedInfo = reversedWorkflows[selectedIndex];
            copyCollectedInfo.status = "25%"
            copyCollectedInfo.progressStatus = statusData.progressStatus;  // Update progress status
            copyCollectedInfo.remark = statusData.remark;
        } else if (activeStep === 1) {
            const copyCollectedInfo = reversedWorkflows[selectedIndex];
            copyCollectedInfo.analysis.questionnaire = copyCollectedInfo.analysis.questionnaire.map((item, index) => ({
                ...item,
                rating: ratings[index] || 0,
            }));
            copyCollectedInfo.status = "50%"
            copyCollectedInfo.progressStatus = statusData.progressStatus;  // Update progress status
            copyCollectedInfo.remark = statusData.remark;
            console.log("updated", copyCollectedInfo)
        } else if (activeStep === 2) {
            let copyCollectedInfo = reversedWorkflows[selectedIndex];
            copyCollectedInfo.reviewDetails = formData
            console.log("copyCollected", copyCollectedInfo)
            console.log("formData", formData)
            copyCollectedInfo.status = "75%"
            copyCollectedInfo.progressStatus = statusData.progressStatus;  // Update progress status
            copyCollectedInfo.remark = statusData.remark;
        } else {
            const copyCollectedInfo = reversedWorkflows[selectedIndex];
            copyCollectedInfo.status = "100%"
            copyCollectedInfo.progressStatus = statusData.progressStatus;  // Update progress status
            copyCollectedInfo.remark = statusData.remark;
        }

        setStatusData({
            progressStatus: "", // Reset progress status for next step to ensure placeholder shows
            remark: ""
        });

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = async () => {
        dispatch(updateWorkflowRequest(analysis[0], reversedWorkflows[selectedIndex]));
        setIsDrawerOpen(!isDrawerOpen);
    };

    const getStatusOptions = (step) => {
        switch (step) {
            case 0:
                return [
                    { value: 'call_not_received', label: 'Call Not Received' },
                    { value: 'not_interested', label: 'Candidate Not Interested' },
                    { value: 'interested', label: 'Candidate Interested' }
                ];
            case 1:
                return [
                    { value: 'skills_not_relevant', label: 'Skills Not Relevant' },
                    { value: 'skills_relevant', label: 'Skills Relevant' },
                    { value: 'skills_review_in_progress', label: 'Skills Review In Progress' },
                    { value: 'skills_review_completed', label: 'Skills Review Completed' },
                ];
            case 2:
                return [
                    { value: 'experience_incomplete', label: 'Experience Incomplete' },
                    { value: 'ctc_discussion_in_progress', label: 'CTC Discussion In Progress' },
                    { value: 'interview_scheduled', label: 'Interview Scheduled' },
                    { value: 'interview_completed', label: 'Interview Completed' },
                ];
            case 3:
                return [
                    { value: 'offer_extended', label: 'Offer Extended' },
                    { value: 'no_response', label: 'No Response' },
                    { value: 'candidate_reject', label: 'Candidate Reject' },
                    { value: 'in_progress', label: 'In Progress' },
                    { value: 'hired', label: 'Hired' },
                ];
            default:
                return [];
        }
    };


    function getStepContent(step, analysis) {
        switch (step) {
            case 0:
                return (
                    <Grid container className="history-step-content-grid">
                        <Grid item lg={4} xs={12} sm={5} sx={{ display: "flex", flexDirection: "column" }}>
                            <span className="info-label">Name:</span>
                            <span className="info-label">Email:</span>
                            <span className="info-label">Contact:</span>
                        </Grid>
                        <Grid item lg={8} xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                            <span className="info-value"> {analysis[1] || "NA"} </span>
                            <span className="info-value"> {analysis[3] || "NA"}</span>
                            <span className="info-value">{analysis[2] || "NA"}</span>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: "16px" }}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel shrink className="history-step-content-label"> Status</InputLabel>
                                <Select
                                    value={statusData.progressStatus || ""}
                                    onChange={handleStatusChange}
                                    displayEmpty
                                    inputProps={{
                                        name: 'progressStatus',
                                    }}
                                    className="history-step-content-input-select"
                                >
                                    <MenuItem value="" disabled className="menu-item">
                                        Select Status
                                    </MenuItem>
                                    <MenuItem value="Pending" className="menu-item">Pending</MenuItem>
                                    {getStatusOptions(0).map(option => (
                                        <MenuItem key={option.value} value={option.value} className="menu-item">
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "16px" }}>
                            <TextField
                                fullWidth
                                label="Remark"
                                name="remark"
                                variant="outlined"
                                multiline
                                rows={3}
                                className="history-step-content-textfield"
                                value={statusData.remark}
                                onChange={handleStatusChange}
                            />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <div className="history-step-content-grid">
                        {analysis[8]?.questionnaire?.map((item, index) => (
                            <React.Fragment key={index}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <h6 style={{ fontWeight: "bold" }}>{item.Q}</h6>
                                        <p>{item.ANS}</p>
                                    </div>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" width="150px">
                                        {/* <Typography component="legend" style={{ fontSize: '12px', marginRight: '8px' }}>Rate:</Typography> */}
                                        <Rating
                                            name={`rating-${index}`}
                                            value={ratings[index] || 0}
                                            onChange={(event, newValue) => handleRatingChange(index, newValue)}
                                            size="small"
                                            sx={{
                                                color: '#660032',
                                            }}
                                        />
                                    </Box>
                                </div>

                            </React.Fragment>
                        ))}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel shrink className="history-step-content-label"> Status</InputLabel>
                                <Select
                                    inputProps={{
                                        name: 'progressStatus',
                                    }}
                                    className="history-step-content-input-select"
                                    value={statusData.progressStatus || ""}
                                    onChange={handleStatusChange}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled className="menu-item">
                                        Select Status
                                    </MenuItem>
                                    <MenuItem value="Pending" className="menu-item">Pending</MenuItem>
                                    {getStatusOptions(1).map(option => (
                                        <MenuItem key={option.value} value={option.value} className="menu-item">{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "16px" }}>
                            <TextField
                                fullWidth
                                label="Remark"
                                name="remark"
                                variant="outlined"
                                multiline
                                rows={3}
                                className="history-step-content-textfield"
                                value={statusData.remark}
                                onChange={handleStatusChange}
                            />
                        </Grid>
                    </div>
                );
            case 2:
                return (
                    <div className="history-step-content-grid">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Availability Slot"
                                    name="availabilityDate"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: '12px', fontFamily: 'Roboto' }, // Set font size for the label
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: getCurrentDateTime(),
                                            style: { fontSize: '12px', fontFamily: 'Roboto' },
                                        },
                                        placeholder: 'Select date and time'
                                    }}
                                    value={formData.availabilityDate || ""}
                                    onChange={(e) => handleChange(e)}
                                    className="history-custom-textfield"
                                // disabled={status === "Completed"}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel shrink className="history-step-content-label">Notice Period (Days)</InputLabel>
                                    <Select
                                        value={formData.noticePeriod || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{
                                            name: 'noticePeriod',
                                        }}
                                        className="history-step-content-input-select"
                                    // disabled={status === "Completed"}
                                    >
                                        <MenuItem value="" disabled className="menu-item">
                                            Notice Period
                                        </MenuItem>
                                        <MenuItem value={7} style={{ fontSize: '12px' }}>7 Days</MenuItem>
                                        <MenuItem value={15} style={{ fontSize: '12px' }}>15 Days</MenuItem>
                                        <MenuItem value={30} style={{ fontSize: '12px' }}>30 Days</MenuItem>
                                        <MenuItem value={60} style={{ fontSize: '12px' }}>60 Days</MenuItem>
                                        <MenuItem value={90} style={{ fontSize: '12px' }}>90 Days</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Current CTC (LPA)"
                                    name="currentCTC"
                                    placeholder="INR"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: '12px', fontFamily: 'Roboto' }, // Set font size and family for the label
                                    }}
                                    inputProps={{
                                        style: {
                                            fontSize: '12px',
                                            fontFamily: 'Roboto',
                                            color: 'black'
                                        },
                                    }}
                                    value={formData.currentCTC || ""}
                                    onChange={(e) => handleChange(e)}
                                    disabled={status === "Completed"}
                                    className="history-custom-textfield"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Expected CTC (LPA)"
                                    name="expectedCTC"
                                    placeholder="INR"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: '12px', fontFamily: 'Roboto' }, // Set font size and family for the label
                                    }}

                                    value={formData.expectedCTC || ""}
                                    onChange={(e) => handleChange(e)}
                                    // disabled={status === "Completed"}
                                    inputProps={{
                                        style: {
                                            fontSize: '12px',
                                            fontFamily: 'Roboto',
                                            color: 'black'
                                        },
                                    }}
                                    className="history-custom-textfield"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel shrink className="history-step-content-label"> Relevant Experience (Years)</InputLabel>
                                    <Select
                                        value={formData.relevantExperience || ""}
                                        onChange={(e) => handleChange(e)}
                                        displayEmpty
                                        inputProps={{
                                            name: 'relevantExperience',
                                        }}
                                        className="history-step-content-input-select"
                                    >
                                        <MenuItem value="" disabled className="menu-item">
                                            Select Relevant Experience
                                        </MenuItem>
                                        {[...Array(21).keys()].map((year) => (
                                            <MenuItem key={year} value={year} className="menu-item">
                                                {year} {year === 1 ? "Year" : "Years"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel shrink className="history-step-content-label">  Overall Experience (Years) </InputLabel>
                                    <Select
                                        value={formData.overallExperience || ""}
                                        onChange={(e) => handleChange(e)}
                                        //  name= 'overallExperience'
                                        displayEmpty
                                        inputProps={{
                                            name: 'overallExperience',
                                        }}
                                        className="history-step-content-input-select"
                                    >
                                        <MenuItem value="" disabled className="menu-item">
                                            Select Overall Experience
                                        </MenuItem>
                                        {[...Array(21).keys()].map((year) => (
                                            <MenuItem key={year} value={year} className="menu-item">
                                                {year} {year === 1 ? "Year" : "Years"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {formData.skillsAndRating.map((skill, index) => (
                                <React.Fragment key={index}>
                                    <Grid
                                        item
                                        xs={5}
                                        lg={6}
                                        sm={3}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <span>{skill.skill}</span>
                                        <Slider
                                            size="small"
                                            aria-label="Always visible"
                                            min={1}
                                            max={5}
                                            step={1}
                                            // disabled={status === "Completed"}
                                            sx={{
                                                "& .MuiSlider-thumb": {
                                                    color: "#660032",
                                                },
                                                "& .MuiSlider-track": {
                                                    color: "#660032",
                                                },
                                                "& .MuiSlider-rail": {
                                                    color: "#660032",
                                                },
                                            }}
                                            name="rating"
                                            value={skill.rating}
                                            marks={[
                                                { value: 1, label: "1" },
                                                { value: 2, label: "2" },
                                                { value: 3, label: "3" },
                                                { value: 4, label: "4" },
                                                { value: 5, label: "5" },
                                            ]}
                                            onChange={(event, newValue) => {
                                                handleChange(event, newValue, index);
                                            }}
                                        />
                                    </Grid>

                                </React.Fragment>
                            ))}
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="standard" style={{ marginTop: "16px" }}>
                                    <InputLabel shrink className="history-step-content-label">Status</InputLabel>
                                    <Select
                                        inputProps={{
                                            name: 'progressStatus',
                                        }}
                                        className="history-step-content-input-select"
                                        value={statusData.progressStatus || ""}
                                        onChange={handleStatusChange}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled className="menu-item">
                                            Select Status
                                        </MenuItem>
                                        <MenuItem value="Pending" className="menu-item">Pending</MenuItem>
                                        {getStatusOptions(2).map(option => (
                                            <MenuItem key={option.value} value={option.value} className="menu-item">{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    label="Remark"
                                    name="remark"
                                    variant="outlined"
                                    placeholder='Remark'
                                    multiline
                                    rows={3}
                                    className="history-step-content-textfield"
                                    value={statusData.remark}
                                    onChange={handleStatusChange}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
            case 3:
                return (
                    <>
                        <GeneratePdf analysis={analysis[8]} data={formData.skillsAndRating} />
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="standard" style={{ marginTop: "16px" }}>
                                <InputLabel shrink className="history-step-content-label">Status</InputLabel>
                                <Select
                                    inputProps={{
                                        name: 'progressStatus',
                                    }}
                                    className="history-step-content-input-select"
                                    value={statusData.progressStatus || ""}
                                    onChange={handleStatusChange}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled className="menu-item">
                                        Select Status
                                    </MenuItem>
                                    <MenuItem value="Pending" className="menu-item">Pending</MenuItem>
                                    {getStatusOptions(3).map(option => (
                                        <MenuItem key={option.value} value={option.value} className="menu-item">{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Remark Field */}
                        <Grid item xs={12} style={{ marginTop: "16px" }}>
                            <TextField
                                fullWidth
                                label="Remark (optional)"
                                name="remark"
                                variant="outlined"
                                value={statusData.remark}
                                onChange={handleStatusChange}
                                multiline
                                rows={3}
                                className="history-step-content-textfield"
                            />
                        </Grid>
                    </>
                )

            default:
                return "Unknown step";
        }
    }

    const handleSearch = (value) => {
        setSearchText(value);
        const filteredData = reversedWorkflows.filter(candidate =>
            candidate.name?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.email?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.phone?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.jobTitle?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.skills?.toLowerCase().includes(value.toLowerCase()) ||
            candidate.fitmentPercentage?.toString().toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filteredData);
    };

    const handleFilter = (values) => {
        setFilterValues(values);
        const filteredData = reversedWorkflows.filter(candidate => {
            const isExperienceInRange = (candidateExp, range) => {
                const [min, max] = range.split('-').map(Number);
                const exp = parseFloat(candidateExp);
                return exp >= min && (max ? exp <= max : true);
            };
            return (
                (values.jobTitle ? candidate.jobTitle?.toLowerCase().includes(values.jobTitle.toLowerCase()) : true) &&
                (values.experience ? 
                    (values.experience === '10+' ? 
                        parseFloat(candidate.experience) >= 10 :
                        isExperienceInRange(candidate.experience, values.experience)
                    ) : true
                )  &&
                (values.skills ? candidate.skills?.toLowerCase().includes(values.skills.toLowerCase()) : true)
            );
        });
        setFilteredData(filteredData);
    }

    useEffect(() => {
        // dispatch(fetchWorkflowsRequest());
        dispatch(fetchWorkflowsByIdRequest(id))
    }, [])

    return (
        <div>
            <div className="history-scan-it-table-topbar">
                <div>
                    <span ><b>{reversedWorkflows?.length}</b> Candidates recommended for you</span>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {filterValues && Object.keys(filterValues).map((key) => (
                        filterValues[key] && (
                            <Chip
                                key={key}
                                label={key === 'experience' ? `${filterValues[key]} years` : filterValues[key]}
                                onDelete={() => {
                                    const newFilterValues = { ...filterValues };
                                    delete newFilterValues[key];
                                    setFilterValues(newFilterValues);
                                    handleFilter(newFilterValues);
                                }}
                                color="primary"
                            />
                        )
                    ))}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FilterListIcon style={{ marginRight: '10px',cursor:'pointer' }} onClick={() => setFilterModalView(true)} />
                    <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
                </div>
            </div>
            {loading ? (
                <PulseLoader />
            ) : (
                <div style={{ marginBottom: "10px" }}>
                    <MUIDataTable data={filteredData || reversedWorkflows} columns={columns} options={options} />
                </div>
            )}
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                // onClose={handleDrawerClick}
                PaperProps={{
                    className: 'history-scan-it-drawer', // Apply external CSS class
                }}
            >
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <CloseIcon onClick={handleDrawerClick} className="history-scan-it-close-icon" />
                    <Grid item lg={12}>
                        <Paper className="history-scan-it-drawer-paper">
                            {analysis &&
                                <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                    className="history-scan-it-stepper"
                                >
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                            <StepContent>
                                                {getStepContent(index, analysis)}
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            activeStep === 0 ? "flex-end" : "space-between",
                                                        mt: 2,
                                                    }}
                                                >
                                                    {activeStep > 0 && (
                                                        <Button
                                                            size="small"
                                                            className="history-scan-it-button-back"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </Button>
                                                    )}
                                                    {activeStep < steps.length - 1 && (
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            className="history-scan-it-button-next"
                                                            onClick={handleNext}
                                                            disabled={!statusData.progressStatus || statusData.progressStatus === "Pending"} 
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                                    {activeStep === steps.length - 1 && (
                                                        <>
                                                            {/* <Button
                            size="small"
                            variant="contained"
                            sx={{
                            backgroundColor: "#660032",
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#660032",
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#c1c1c1",
                                color: "#660032",
                            },
                            }}
                            onClick={handleSaveJobApplication}
                            disabled={status === "Completed"}
                        >
                            Save
                        </Button> */}
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                className="scan-it-button-finish"
                                                                onClick={handleFinish}
                                                            // disabled={
                                                            //     isFormIncomplete() || status === "Completed"
                                                            // }
                                                            >
                                                                Finish
                                                            </Button>
                                                        </>
                                                    )}
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    ))}

                                    {/* {activeStep > 0 && (
                    <Button sx={{ color: "#fff" }} onClick={handleBack}>
                        Back
                    </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                    <div
                        style={{ position: "relative", display: "inline-block" }}
                    >
                        <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSaveJobApplication}
                        disabled={status === "Completed"}
                        sx={{
                            "&.Mui-disabled": {
                            backgroundColor: "#c1c1c1",
                            color: "#660032",
                            },
                        }}
                        >
                        Save
                        </Button>
                    </div>
                    )}
                    {activeStep === steps.length - 1 ? (
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                        "&.Mui-disabled": {
                            backgroundColor: "#c1c1c1",
                            color: "#660032",
                        },
                        }}
                        onClick={handleFinish}
                        disabled={
                        (activeStep === steps.length - 1 &&
                            isFormIncomplete()) ||
                        status === "Completed"
                        }
                    >
                        Finish
                    </Button>
                    ) : (
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                        "&.Mui-disabled": {
                            backgroundColor: "#fff",
                            color: "#660032",
                        },
                        }}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                    )} */}
                                </Stepper>}
                        </Paper>
                    </Grid>
                </Grid>
            </Drawer>

            <Modal
                open={filterModalView}
                onClose={() => setFilterModalView(false)}
                className="filter-modal"
            >
                <div className="filter-modal-container">
                    <div className="filter-modal-title">
                        <h5>FILTER</h5>
                        <CloseOutlined onClick={() => setFilterModalView(false)}/>
                    </div>

                    <Formik
                        initialValues={{ jobTitle: '' || filterValues?.jobTitle, skills: '' || filterValues?.skills, experience: '' || filterValues?.experience }}
                        onSubmit={(values) => {
                            handleFilter(values);
                            setFilterModalView(false);
                        }}
                    >
                        {({ isSubmitting, handleChange, handleBlur, values }) => (
                            <Form>
                                <Grid container spacing={2} className="filter-modal-content">
                                    <Grid item xs={12} sm={6} md={4} >
                                        <div>
                                            <label htmlFor="jobTitle" style={{ display: 'block', marginBottom: '5px' }}>Job Title</label>
                                            <input
                                                type="text"
                                                id="jobTitle"
                                                name="jobTitle"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter Job Title"
                                                value={values.jobTitle}
                                                className="filter-modal-input"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <div>
                                            <label htmlFor="skills" style={{ display: 'block', marginBottom: '5px' }}>Skills</label>
                                            <input
                                                type="text"
                                                id="skills"
                                                name="skills"
                                                placeholder="Enter Skills"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.skills}
                                                className="filter-modal-input"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <div>
                                            <label htmlFor="experience" style={{ display: 'block', marginBottom: '5px' }}>Experience</label>
                                            <select
                                                id="experience"
                                                name="experience"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.experience}
                                                className="filter-modal-select"
                                            >
                                                <option value="">Select Experience</option>
                                                <option value="0-2">0 - 2</option>
                                                <option value="3-5">3 - 5</option>
                                                <option value="6-10">6 - 10</option>
                                                <option value="10+">10+</option>
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'right',}}>
                                        <button type="submit" disabled={isSubmitting} className="filter-modal-button">APPLY</button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>

                </div>
            </Modal>
        </div>
    )
}

export default ScanItHistory;