import React from "react"
import { Card, Row } from "reactstrap"
import DonutChart from "../AllCharts/DonutChart"
import "pages/Sqano Dashboard/dashboard.css"
import { useSelector } from "react-redux"
import DonutChart2 from "./DonutChart2"

const ProfilesSources = () => {
  let allCounts=useSelector(state=>state.usersReducer?.allCounts);  

  return (
    <React.Fragment>
      <Card className="dashboard-graph-card" style={{ height: "550px" }}>
        <h4 className="dashboard-graph-title mb-4 ">PRODUCTS DATA</h4>

        <Row className="text-center dashboard-graph-info">
          <div className="profiles-sources-col col-12" style={{display:"flex"}}>   
            <h6 className="dashboard-graph-text">PARSEIT: </h6>
            <h6 className="dashboard-graph-value">{allCounts?.parsedCount}</h6>
          </div>
          <div className="profiles-sources-col col-12">
            <h6 className="dashboard-graph-text">CIVIX: </h6>
            <h6 className="dashboard-graph-value">{allCounts?.civixCount}</h6>
          </div>
          <div className="profiles-sources-col col-12">   
            <h6 className="dashboard-graph-text">SQANIT: </h6>
            <h6 className="dashboard-graph-value">{allCounts?.scannedCount}</h6>
          </div>
        </Row>
        <div dir="ltr">
          {/* <DonutChart /> */}
          <DonutChart2 />
        </div>
      </Card>
    </React.Fragment>
  )
}

export default ProfilesSources
