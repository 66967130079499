import React from 'react';
import { Typography, Box } from '@mui/material';

function EvaluationPrompt() {
  return (
    <Box
      display="flex"
      flexDirection="column"
    //   alignItems="center"
      justifyContent="center"
      sx={{
        mt: 4,
        p: 3,
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        margin: 'auto',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" align="center" sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}>
       Please proceed with the candidate evaluation process by using the action button
      </Typography>
    </Box>
  );
}

export default EvaluationPrompt;
