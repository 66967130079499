const DashboardData = [
  {
    id: 1,
    title: "TOTAL REVENUE",
    value: "$65,841",
    detail: "Jan - Apr 2019",
    icon: "fa-dollar-sign",
    color: "#660032",
  },
  {
    id: 2,
    title: "TOTAL USERS",
    value: "26,521",
    detail: "Jan - Apr 2019",
    icon: "fa-users",
    color: "#660032",
  },
  {
    id: 3,
    title: "REQUIREMENTS",
    value: "7,842",
    detail: "Jan - Apr 2019",
    icon: "fa-user-times",
    color: "#660032",
  },
  {
    id: 4,
    title: "POSITIONS CLOSED",
    value: "2.07%",
    detail: "Jan - Apr 2019",
    icon: "fa-chart-line",
    color: "#660032",
  },
  {
    id: 5,
    title: "TOTAL CLIENTS",
    value: "2,562",
    detail: "Product Sold",
    icon: "fa-search",
    color: "#660032",
  },
  {
    id: 6,
    title: "PREMIUM CLIENTS",
    value: "8,542",
    detail: "User This Month",
    icon: "fa-code",
    color: "#660032",
  },
  {
    id: 7,
    title: "NEW CLIENTS",
    value: "6,254",
    detail: "Request Per Minute",
    icon: "fa-lock-open",
    color: "#660032",
  },
  {
    id: 8,
    title: "USER MANAGEMENT",
    value: "7,524",
    detail: "Total Users",
    icon: "fa-user-friends",
    color: "#660032",
  },
  {
    id: 9,
    title: "USER CREATION",
    value: "6521",
    detail: "New Downloads",
    icon: "fa-industry",
    color: "#660032",
  },
  {
    id: 10,
    title: "PAYMENTS AND SUBSCRIPTION",
    value: "7854",
    detail: "Statistics",
    icon: "fa-credit-card",
    color: "#660032",
  },
  {
    id: 11,
    title: "TASKS",
    value: "3652",
    detail: "User Today",
    icon: "fa-file-invoice-dollar",
    color: "#660032",
  },
  {
    id: 12,
    title: "TO DO LIST",
    value: "1250",
    detail: "User This Month",
    icon: "fa-ticket-alt",
    color: "#660032",
  },
]

export default DashboardData
