import React from "react"
import { Card, Row, CardTitle } from "reactstrap"
import DonutChart from "../AllCharts/DonutChart"
import "pages/Sqano Dashboard/dashboard.css"

const ProfilesSources = () => {
  return (
    <React.Fragment>
      <Card className="dashboard-graph-card" style={{ height: "550px" }}>
        <h4 className="dashboard-graph-title mb-4 ">PRODUCTS DATA</h4>

        <Row className="text-center dashboard-graph-info">
          <div className="profiles-sources-col col-12" style={{display:"flex"}}>   
            <h2 className="dashboard-graph-text">PARSEIT:</h2>
            <h5 className="dashboard-graph-value">$56241</h5>
          </div>
          <div className="profiles-sources-col col-12">
            <h2 className="dashboard-graph-text">CIVIX:</h2>
            <h5 className="dashboard-graph-value">$23651</h5>
          </div>
          <div className="profiles-sources-col col-12">   
            <h2 className="dashboard-graph-text">SQANIT:</h2>
            <h5 className="dashboard-graph-value">$651</h5>
          </div>
          <div className="profiles-sources-col col-12">
            <h2 className="dashboard-graph-text">TOD:</h2>
            <h5 className="dashboard-graph-value">UNLIMITED</h5>
          </div>
        </Row>
        <div dir="ltr">
          <DonutChart />
        </div>
      </Card>
    </React.Fragment>
  )
}

export default ProfilesSources
