import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, TextField, Box } from '@mui/material';
import { Grid, Card, CardMedia, CardContent } from '@mui/material';
import { Avatar, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ProductTabs from "../ProductTabs"
import CustomBreadcrumb from "../../../components/CustomComponents/CustomBreadCrumb";
import resume_1 from "assets/images/resume_img_1.webp"
import resume_2 from "assets/images/Resume_img_2.jpg"
import resume_3 from "assets/images/Resume_img_3.png"

const steps = [
  'Choose Template',
  'Add Personal Info',
  'Add Experience',
  'Add Education',
  'Add Skills',
  'Add Summary',
  'Add Other Fields',
];

const templates = [
  {
    id: 1,
    name: 'Modern Template',
    image: resume_1,
  },
  {
    id: 2,
    name: 'Classic Template',
    image: resume_2,
  },
  {
    id: 3,
    name: 'Professional Template',
    image: resume_3,
  },
];

const CreateNewResume = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    city: '',
    country: '',
    pincode: '',
    phone: '',
    email: '',
    photo: '',
    experience: '',
    education: '',
    skills: '',
    summary: '',
    otherFields: '',
  });

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
    // alert(`Template ${templateId} selected!`);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const Resume_view = (image,name) => {
    return <img src={image} alt={name} style={{ width: '100%', height: 'auto' }} />
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div style={{ padding: '20px' }}>
            <Grid container spacing={4} justifyContent="center">
              {templates.map((template) => (
                <Grid item xs={12} sm={6} md={4} key={template.id}>
                  <Card
                    style={{
                      border: selectedTemplate === template.id ? '2px solid #660032' : '1px solid #ccc',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleTemplateSelect(template.id)}
                  >
                    <CardMedia
                      component="img"
                      style={{ height: "300px", width: "100%", objectPosition: "top" }}
                      image={template.image}
                      alt={template.name}
                    />
                    <CardContent>
                      <Typography variant="h6" color="#660032" style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '10px' }}>{template.name}</Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleTemplateSelect(template.id)}
                        style={{ display: 'block', marginRight: "auto", marginLeft: "auto", color: 'white', backgroundColor: '#660032', width: 'fit-content', textAlign: 'center', fontSize: '12px', padding: "5px 10px" }}
                      >
                        {selectedTemplate === template.id ? 'Selected' : 'Choose Template'}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>)

      case 1:
        const handleInputChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
        };

        const handlePhotoUpload = (e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setFormData({
                ...formData,
                photo: reader.result,
              });
            };
            reader.readAsDataURL(file);
          }
        };

        const handleSubmit = (e) => {
          e.preventDefault();
          alert('Personal information submitted successfully!');
          console.log(formData);
        };

        return (
          <div style={{ padding: '20px' }}>
            <Typography variant="h5" align="center" gutterBottom>
              Personal Information
            </Typography>
            <form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                <div style={{ flexBasis: '50%', padding: '20px', backgroundColor: "white", borderRadius: "5px" }}>
                  <Grid container spacing={2} justifyContent="center" >
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Avatar
                        src={formData.photo}
                        alt="User Photo"
                        style={{ width: '100px', height: '100px', margin: 'auto' }}
                      />
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="upload-photo"
                        type="file"
                        onChange={handlePhotoUpload}
                      />
                      <label htmlFor="upload-photo">
                        <IconButton color="primary" component="span">
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Surname"
                        name="surname"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formData.surname}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="City"
                        name="city"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        label="Country"
                        name="country"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formData.country}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        label="Pincode"
                        name="pincode"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formData.pincode}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone"
                        name="phone"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button variant="contained" size="small" style={{ backgroundColor: '#660032', color: 'white' }} type="submit">
                        Submit
                      </Button>
                    </Grid> */}
                  </Grid>
                </div>
                <div style={{ flexBasis: '50%' }}>
                  {Resume_view(resume_1, "Template")}
                </div>
              </div>

            </form>
          </div>
        );
      case 2:
        return (
          <Box>
            <Typography>Add your work experience. AI will suggest improvements based on your job title.</Typography>
            <TextField
              label="Experience"
              name="experience"
              fullWidth
              variant="outlined"
              value={formData.experience}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Box>
        );
      case 3:
        return (
          <TextField
            label="Education"
            name="education"
            fullWidth
            variant="outlined"
            value={formData.education}
            onChange={handleChange}
          />
        );
      case 4:
        return (
          <Box>
            <Typography>AI will recommend skills based on your experience.</Typography>
            <TextField
              label="Skills"
              name="skills"
              fullWidth
              variant="outlined"
              value={formData.skills}
              onChange={handleChange}
            />
          </Box>
        );
      case 5:
        return (
          <Box>
            <Typography>AI will generate a summary based on your experience and skills.</Typography>
            <TextField
              label="Summary"
              name="summary"
              fullWidth
              variant="outlined"
              value={formData.summary}
              onChange={handleChange}
              multiline
              rows={3}
            />
          </Box>
        );
      case 6:
        return (
          <TextField
            label="Other Fields (Achievements, Interests, Certifications, Languages)"
            name="otherFields"
            fullWidth
            variant="outlined"
            value={formData.otherFields}
            onChange={handleChange}
            multiline
            rows={4}
          />
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "CV Template", href: "/cvtemplate" },
    { label: "Resume Builder", href: "/resumebuilder" },
    { label: "Create New Resume", href: "/create-new-resume" },
  ];


  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <ProductTabs tab={7} />
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: "20px", backgroundColor: "white", borderRadius: "5px", padding: "10px" }}>
          <Typography variant="h5" align="center" style={{ color: '#660032', fontWeight: 'bold', fontSize: "16px", backgroundColor: "White", padding: "5px", borderRadius: "5px", outline: "2px solid #660032", outlineOffset: "2px", width: "fit-content", margin: "10px auto" }}>
            CREATE NEW RESUME
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel style={{ paddingTop: "10px" }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <Typography style={index === activeStep || index < activeStep ? { fontWeight: 'bold', color: '#660032' } : {}}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div style={{ margin: '20px 0' }}>
          {renderStepContent(activeStep)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            style={{ backgroundColor: '#660032', color: 'white', fontSize: '14px', padding: "0px 5px" }}
          >
            Back
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            style={{ backgroundColor: '#660032', color: 'white', fontSize: '14px', padding: "0px 5px" }}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateNewResume;
