import { call, put, takeLatest, all, fork } from "redux-saga/effects"
import {
  JOB_CREATION,
  GET_ALL_JOB_REQUIREMENTS,
  FETCH_JOB_REQUIREMENTS_REQUEST,
  GET_ALL_USERS_BY_ENTITY_ID,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID,
  FETCH_JOB_REQUIREMENT_REQUEST,
  POST_JOB_REQUEST,
  UPDATE_JOB_REQUIREMENT
} from "./actionTypes"
import {
  jobCreationSuccess,
  jobCreationFailure,
  getAllJobRequirementsSuccess,
  getAllJobRequirementsFailure,
  fetchJobRequirementsSuccess,
  fetchJobRequirementsFailure,
  getAllUsersByEntityIdSuccess,
  getAllUsersByEntityIdFailure,
  getAllJobRequirementsByEntityIdSuccess,
  getAllJobRequirementsByEntityIdFailure,
  fetchJobRequirementSuccess,
  fetchJobRequirementFailure,
  postJobSuccess,
  postJobFailure,
  updateJobRequirementSuccess,
  updateJobRequirementFailure
} from "./actions"
import {
  jobCreation,
  getAllJobRequirements,
  getAssignedJobsByUserId,
  getAllUsersByEntityId,
  getUsersByEntityId,
  getAllJobRequirementsByEntityId,
  getJobRequirementsById,
  postJobRequirement,
  updateJobRequirement
} from "../../../../api"

function* jobCreationSaga(action) {
  try {
    const response = yield call(jobCreation, action.payload)
    yield put(jobCreationSuccess(action.payload))
  } catch (error) {
    yield put(jobCreationFailure(error))
  }
}

function* updateJobRequirementSaga(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateJobRequirement, id, data);
    yield put(updateJobRequirementSuccess(response.data));
  } catch (error) {
    yield put(updateJobRequirementFailure( error.message));
  }
}

function* getAllJobRequirementsSaga() {
  try {
    const response = yield call(getAllJobRequirements)

    yield put(getAllJobRequirementsSuccess(response.data))
  } catch (error) {
    yield put(getAllJobRequirementsFailure(error.message))
  }
}

function* getAllJobRequirementsByEntityIdSaga(action){
  try{
    console.warn("insaga,",action.payload);
    const response=yield call(getAllJobRequirementsByEntityId, action.payload)
    yield put(getAllJobRequirementsByEntityIdSuccess(response.data.data))

  } catch(error){
    yield put(getAllJobRequirementsByEntityIdFailure(error.message))
  }
}

function* fetchJobRequirements(action) {
  try {
    const response = yield call(getAssignedJobsByUserId, action.payload)
    // yield put({ type: FETCH_JOB_REQUIREMENTS_SUCCESS, payload: response.data.jobs });
    yield put(fetchJobRequirementsSuccess(response.data.jobs))
  } catch (error) {
    // yield put({ type: FETCH_JOB_REQUIREMENTS_FAILURE, payload: error.message });
    yield put(fetchJobRequirementsFailure(error.message))
  }
}

function* getAllUsersByEntityIdSaga(action) {
  try {
    const response = yield call(getUsersByEntityId,action.payload)
    yield put(getAllUsersByEntityIdSuccess(response.data))
  } catch (error) {
    yield put(getAllUsersByEntityIdFailure(error.message))
  }
}

function* fetchJobRequirementById(action) {
  try {
    const response = yield call(getJobRequirementsById, action.payload);
    yield put(fetchJobRequirementSuccess(response.data));
  } catch (error) {
    yield put(fetchJobRequirementFailure(error.message));
  }
}

function* postJobSaga(action) {
  try {
    const { jobId, userId } = action.payload;
    const response = yield call(postJobRequirement, { jobId, userId });
    yield put(postJobSuccess(response.data));
  } catch (error) {
    yield put(postJobFailure(error.response.data.message));
  }
}

function* FetchJobRequirements() {
  yield takeLatest(FETCH_JOB_REQUIREMENTS_REQUEST, fetchJobRequirements)
}
function* JobCreationsSaga() {
  yield takeLatest(JOB_CREATION, jobCreationSaga)
}

function* UpdateJobRequirementSaga() {
  yield takeLatest(UPDATE_JOB_REQUIREMENT, updateJobRequirementSaga);
}

function* GetAllJobRequirementsByEntityIdSaga(){
  yield takeLatest(GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID, getAllJobRequirementsByEntityIdSaga)
}

function* GetAllJobRequirementSaga() {
  yield takeLatest(GET_ALL_JOB_REQUIREMENTS, getAllJobRequirementsSaga)
}

function* GetAllUsersByEntityIdSaga() {
  yield takeLatest(GET_ALL_USERS_BY_ENTITY_ID, getAllUsersByEntityIdSaga)
}

function* fetchJobRequirementByIdSaga() {
  yield takeLatest(FETCH_JOB_REQUIREMENT_REQUEST, fetchJobRequirementById);
}

function* watchPostJob() {
  yield takeLatest(POST_JOB_REQUEST, postJobSaga);
}

export default function* jobRequirementSaga() {
  yield all([
    fork(JobCreationsSaga),
    fork(GetAllJobRequirementSaga),
    fork(GetAllJobRequirementsByEntityIdSaga),
    fork(FetchJobRequirements),
    fork(GetAllUsersByEntityIdSaga),
    fork(fetchJobRequirementByIdSaga),
    fork(watchPostJob),
    fork(UpdateJobRequirementSaga)
  ])
}
