import { call, put, takeLatest, all, fork } from "redux-saga/effects"
import { forgotPasswordApi, resetPasswordApi } from "../../api"
import { FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST } from "./actionTypes"
import {
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
} from "./actions"

function* forgotPasswordSaga(action) {
  try {
    const response = yield call(forgotPasswordApi, action.payload)
    yield put(forgotPasswordSuccess(response.data.message))
  } catch (error) {
    yield put(forgotPasswordFailure(error.response.data.message))
  }
}

// Reset Password Saga
function* resetPasswordSaga(action) {
  try {
    const response = yield call(resetPasswordApi, action.payload)
    yield put(resetPasswordSuccess(response.data.message))
  } catch (error) {
    yield put(resetPasswordFailure(error.response.data.message))
  }
}

export function* ForgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga)
}

export function* ResetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga)
}

export default function* ForgtSaga() {
  yield all([fork(ForgotPasswordSaga), fork(ResetPasswordSaga)])
}
