import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Grid, Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCvAccessCandidatesRequest,
  filterCvAccessCandidatesRequest,
} from "../../store/client/product/Civix/actions";
import CandidateFilterForm from "./CvAccess/CandidateFilterForm";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CandidateDetailsDialog from "./CvAccess/CandidateDetailsDialog";
import CandidateCard from "./CvAccess/CandidateCard";
import PulseLoader from "pages/PulseLoader/PulseLoader";
import NoCandidatesAvailable from "./CvAccess/NoCandidatesAvailable";
import "./CvAccess.css";
import ProductTabs from "./ProductTabs";
import Icon from '@mdi/react';
import { mdiAccountBoxOutline } from '@mdi/js';
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";

const CvAccess = () => {
  const dispatch = useDispatch();
  const candidates = useSelector((state) => state.cvAccessCandidatesReducer.candidates || []);
  const filteredCandidates = useSelector((state) => state.cvAccessCandidatesReducer.filteredCandidates || []);
  const loading = useSelector((state) => state.cvAccessCandidatesReducer.loading);
  const error = useSelector((state) => state.cvAccessCandidatesReducer.error);
  const entityData = useSelector(state => state?.Vendor?.vendor || null)
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedSource, setSelectedSource] = useState("vendor");
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noCandidates, setNoCandidates] = useState(false);

  useEffect(() => {
    if (!filtersApplied) {
      dispatch(fetchCvAccessCandidatesRequest());
    }
  }, [dispatch, filtersApplied]);

  useEffect(() => {
    if (candidates.length > 0 && !selectedCandidate) {
      setSelectedCandidate(candidates[0]);
    }
  }, [candidates, selectedCandidate]);

  const handleFilterSubmit = (filters) => {
    const { job_title, experienceRange, location, skills, match } = filters;

    if (job_title || experienceRange || location || skills) {
      dispatch(filterCvAccessCandidatesRequest(filters));
      setFiltersApplied(true);
      setNoCandidates(false);
    } else {
      handleClearAll();
    }
  };

  const handleClearAll = () => {
    setFiltersApplied(false);
    setNoCandidates(false);
  };

  const handlePreviewClick = (candidate) => {
    setSelectedCandidate(candidate);
    setDialogOpen(true);
  };

  const sortedCandidates = useMemo(() => {
    const candidatesToSort = filtersApplied ? filteredCandidates : candidates;
    if (filtersApplied && candidatesToSort.length === 0) {
      setNoCandidates(true);
    } else {
      setNoCandidates(false);
    }
    return candidatesToSort.slice().sort((a, b) => new Date(b.parsedDate) - new Date(a.parsedDate));
  }, [candidates, filteredCandidates, filtersApplied]);

  const handleSourceToggle = (source) => {
    setSelectedSource(source);
  };

  const vendorDataButtonText = loading
    ? "Loading..."
    : sortedCandidates.length > 0
      ? `${sortedCandidates.length} Candidates`
      : "No candidates available";

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "CIVIX", href: "/civix" },
  ];

  return (
    <React.Fragment>
      <CustomBreadcrumb items={breadcrumbItems} />
        <ProductTabs tab={2} />
          <Box sx={{ flexGrow: 1 }} style={{marginBottom: "10px",height: "400px"}}>
            <Grid container style={{marginBottom: "10px",gap: "10px"}}>
            <Grid container item xs={2.5} direction="column" className="sticky-left-grid" >
              <Grid item>
                <div className="button-group-container">
                  <Tooltip
                    title={
                      entityData?.companyName
                        ? `${entityData?.companyName}: ${sortedCandidates.length} Candidates`
                        : ""
                    }
                    arrow
                  >
                    <Button
                      onClick={() => handleSourceToggle("vendor")}
                      className={`source-button ${selectedSource === "vendor" ? "selected" : ""}`}
                      variant = "outlined"
                      size="small"
                    >
                      <div className="button-content">
                        <span className="button-text">
                          {entityData?.companyName?.length > 5
                            ? `${entityData.companyName.substring(0, 5)}...`
                            : entityData?.companyName}
                          {/* <span>[{sortedCandidates.length}]</span> */}
                        </span>
                      </div>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Civixo Data" arrow>
                    <Button
                      onClick={() => handleSourceToggle("sqano")}
                      className={`source-button ${selectedSource === "sqano" ? "selected" : ""}`}
                      variant = "outlined"
                      size="small"
                    >
                      <div className="button-content">
                        Civix Data
                      </div>
                    </Button>
                  </Tooltip>
                </div>
              </Grid>

              <Grid item>
                <CandidateFilterForm onSubmit={handleFilterSubmit} onClearAll={handleClearAll} />
              </Grid>

              <Grid item>
                <div className="apply-filter">
                  <button className="apply-filter-btn">
                    Apply Filter's
                  </button>
                </div>
              </Grid>  
              <Grid>
                <div className="filter-footer">
                  {/* Footer content here */}
                  {/* <span>Footer Information</span> */}
                </div> 
              </Grid>      
            </Grid>

              <Grid item xs={9.3} className="candidates-list" direction="column">
                <Grid item style={{marginBottom: "10px"}}>
                  <div className="count-candidate">
                  <Icon path={mdiAccountBoxOutline} size={1} style={{color: "#660032"}}/>
                  <span style={{color: "#660032"}}>Total {sortedCandidates.length} Candidate's</span>
                  </div>
                </Grid>
                {/* <div display="flex" alignItems="center" mb={2}>
                  <HowToRegIcon style={{ marginRight: 8 }} />
                  <Typography variant="h6">
                    {loading
                      ? "Loading candidates..."
                      : sortedCandidates.length > 0
                      ? `${filtersApplied ? "Filtered" : "Total"} ${sortedCandidates.length} Candidates`
                      : "No candidates available"}
                  </Typography>
                </div> */}
                {loading && <PulseLoader />}
                {!loading && (error || noCandidates) ? (
                  <NoCandidatesAvailable error={error} />
                ) : (
                  !loading && sortedCandidates.length > 0 && (
                    <div className="candidate-list-container">
                    <Grid container spacing={2}>
                      {sortedCandidates.map((candidate) => (
                        <CandidateCard
                          key={candidate._id}
                          candidate={candidate}
                          onPreviewClick={handlePreviewClick}
                        />
                      ))}
                    </Grid>
                  </div>
                  )
                )}

              </Grid>
              <CandidateDetailsDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                candidate={selectedCandidate}
              />
            </Grid>
          </Box>
    </React.Fragment>
  );
};

export default CvAccess;
