import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import ChecklistIcon from "@mui/icons-material/Checklist"
import TextSnippetIcon from "@mui/icons-material/TextSnippet"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PendingActionsIcon from "@mui/icons-material/PendingActions"
import HelpCenterIcon from "@mui/icons-material/HelpCenter"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import SettingsIcon from "@mui/icons-material/Settings"
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
// API
import {  getVendorByIdRequest, logoutRequest, resetParseDataRequest } from "../../../store/actions"

import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/ample.png"
import { useSelector } from "react-redux"
import { Avatar, Tooltip, Typography } from "@mui/material"

const ProfileMenu = props => {
  const loggedUser = useSelector(state => state.Login?.user?.userDetails)
  const entityId = loggedUser?.entityId || null;

  const entityData=useSelector(state=>state?.Vendor?.vendor || null)
  const companyLogo=entityData?.companyLogo;
  const companyName=entityData?.companyName;

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  const handleLogout = async () => {

    dispatch(logoutRequest())
    dispatch(resetParseDataRequest())
    // navigate("/logoutPage")
  }

  // useEffect(()=>{
  //   dispatch(getVendorByIdRequest(entityId))
  // },[])
  useEffect(() => {
      const fetchVendorData = async () => {
        if (entityId) {
          setLoading(true);  // Set loading to true when fetching starts
          await dispatch(getVendorByIdRequest(entityId));
          setLoading(false); // Comment this out to simulate a defect
        }
      };

      fetchVendorData();
    }, [dispatch, entityId]);
  

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={24} color="inherit" />
            </Box>
          ) : (
            <img
            className="rounded-circle header-profile-user"
            style={{ marginRight: "5px", color: "#ffffff" }}
            src={companyLogo}
            alt="Header Avatar"
          />
          )}
          <Tooltip title={companyName} arrow>
            <span sx={{color:"#ffffff"}}>{companyName?.length > 5 ? `${companyName.substring(0, 5)}...` : companyName}</span>
          </Tooltip>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to="/profile" style={{ display: 'flex', alignItems: 'center',color:"white",backgroundColor:"#660032" }}>
            <Avatar
              sx={{ width: 20, height: 20, borderRadius: '50%', marginRight: 1 }}
              src={loggedUser?.profilePicture}
            />
            {props.t(loggedUser?.name)}
          </DropdownItem>
          <DropdownItem tag={Link} to="/history">
            <ManageHistoryIcon className="menu-icon" />
            {props.t("History")}
          </DropdownItem>
          <DropdownItem tag={Link} to="/comming-soon">
            <PendingActionsIcon className="menu-icon" />
            {props.t("Tasks")}
          </DropdownItem>
          <DropdownItem tag={Link} to="/comming-soon">
            <SettingsIcon className="menu-icon" />
            {props.t("Setting & Privacy")}
          </DropdownItem>
          <DropdownItem tag={Link} to="/comming-soon">
            <HelpCenterIcon className="menu-icon" />
            {props.t("Help")}
          </DropdownItem>
          <DropdownItem tag={Link} to="/comming-soon">
            <AccountBalanceIcon className="menu-icon" />
            {props.t("My Account")}
          </DropdownItem>
          <DropdownItem tag={Link} to="/comming-soon">
            <AssignmentIndIcon className="menu-icon" />
            {props.t("User Agreement")}
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to="/logout"
            className="dropdown-item"
            onClick={handleLogout}
            style={{ color: "#660032" }}
          >
            <i
              className="mdi mdi-power text-muted align-middle me-1"
              style={{ color: "#660032" }}
            />
            {props.t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
)
