import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { Checkbox, Tooltip, Skeleton } from '@mui/material';
import ReplayIcon from "@mui/icons-material/Replay"
import no_data_found from "../../assets/images/no_data_found.png"


export default function CheckboxList({ skeletonRows, parsedData, failedData, activeButton, onSelectionChange, removeSelectedData, reuploadAllFiles }) {
    const [checked, setChecked] = useState([]);
    let [selectedProfiles, setSelectedProfiles] = React.useState([]);

    useEffect(() => {
        removeSelectedData && setChecked([])
        removeSelectedData && setSelectedProfiles([])
    }, [removeSelectedData])


    const handleToggle = (value) => () => {
        const currentIndex = checked?.indexOf(value.ProfileId);
        const newChecked = [...checked];
        let newSelectedProfiles = [...selectedProfiles];

        if (currentIndex === -1) {
            // Check if the ProfileId is already in newChecked before pushing
            if (!newChecked.includes(value.ProfileId)) {
                newChecked.push(value.ProfileId);
                newSelectedProfiles.push(value);
            }
        } else {
            newChecked.splice(currentIndex, 1);
            newSelectedProfiles = newSelectedProfiles.filter(profile => profile.ProfileId !== value.ProfileId);
        }

        setChecked(newChecked);
        setSelectedProfiles(newSelectedProfiles);
        onSelectionChange(newChecked, newSelectedProfiles);
    };

    const handleSelectAll = (allData) => {
        const allProfileIds = allData.map(profile => profile.ProfileId);

        const allSelected = allProfileIds.every(profileId => checked.includes(profileId));

        if (allSelected) {
            setChecked([]);
            setSelectedProfiles([]);
            onSelectionChange([], []);
        } else {
            const newChecked = [...allProfileIds];
            const newSelectedProfiles = [...allData];

            setChecked(newChecked);
            setSelectedProfiles(newSelectedProfiles);
            onSelectionChange(newChecked, newSelectedProfiles);
        }
    };



    const renderWithTooltip = (text) => (
        <Tooltip title={text}>
            <p className='listText'>
                {text}
            </p>
        </Tooltip>
    );

    return (
        <>

            {
                activeButton === "parsed" ?
                    (parsedData?.length === 0 && skeletonRows === null) ?
                        <>
                            <List className='listView'>
                                <ListItem>
                                    <img src={no_data_found} alt="No data found" style={{ display: 'block', margin: '0 auto', width: '100%', height: 'auto' }} />
                                </ListItem>
                            </List>
                        </>
                        :
                        (

                            <List className='listView' style={{ padding: "0px" }}>
                                <ListItem secondaryAction={"ACTION"} sx={{ fontSize: "12px", backgroundColor: "#660032", color: "white", padding: "5px 0px" }}>

                                    <ListItemIcon style={{ padding: "0px 5px" }}>
                                        <Checkbox
                                            edge="start"
                                            onClick={() => handleSelectAll(parsedData)}
                                            tabIndex={-1}
                                            disableRipple
                                            style={{ padding: "1px", paddingLeft: "15px", color: "white" }}
                                        />
                                    </ListItemIcon>
                                    <div style={{ width: "75%", display: "flex" }}>
                                        <div style={{ width: "30%", paddingRight: "15px" }}>NAME</div>
                                        <div style={{ width: "30%", paddingRight: "15px" }}>LOCATION</div>
                                        <div style={{ width: "40%", paddingRight: "15px" }}>SKILLS</div>
                                    </div>

                                </ListItem>
                                {parsedData?.map((value) => {
                                    const labelId = `checkbox-list-label-${value.ProfileId}`;

                                    return (<>
                                        {
                                            <ListItem key={value.ProfileId} secondaryAction={value.Action} className='listItem' sx={{ padding: 0, fontSize: "12px" }}>

                                                <ListItemIcon style={{ padding: "0px 5px" }}>
                                                    <Checkbox
                                                        edge="start"
                                                        onClick={handleToggle(value)}
                                                        checked={checked.indexOf(value.ProfileId) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        style={{ padding: "1px", paddingLeft: "15px" }}
                                                    />
                                                </ListItemIcon>
                                                <div style={{ width: "75%", display: "flex" }}>
                                                    <div style={{ width: "30%", paddingRight: "15px" }}>{renderWithTooltip(value.CandidateName)}</div>
                                                    <div style={{ width: "30%", paddingRight: "15px" }}>{renderWithTooltip(value.Location)}</div>
                                                    <div style={{ width: "40%", paddingRight: "15px" }}>{renderWithTooltip(value.Skills)}</div>
                                                </div>

                                            </ListItem>
                                        }
                                    </>

                                    );
                                })}
                                {
                                    skeletonRows?.map((value, index) => {
                                        return (<>
                                            {
                                                <ListItem key={index} secondaryAction={<Skeleton variant="text" width={10} />} className='listItem' sx={{ padding: 0, fontSize: "12px" }}>

                                                    <ListItemIcon style={{ padding: "0px 5px" }}>
                                                        <Skeleton variant="text" width={10} style={{ padding: "1px", paddingLeft: "15px" }} />
                                                    </ListItemIcon>
                                                    <div style={{ width: "75%", display: "flex" }}>
                                                        <div style={{ width: "30%", paddingRight: "15px" }}><Skeleton variant="text" width={50} /></div>
                                                        <div style={{ width: "30%", paddingRight: "15px" }}><Skeleton variant="text" width={50} /></div>
                                                        <div style={{ width: "40%", paddingRight: "15px" }}><Skeleton variant="text" width={50} /></div>
                                                    </div>

                                                </ListItem>
                                            }
                                        </>)
                                    })
                                }
                            </List>)
                    :
                    failedData.length === 0 ?
                        <List className='listView'>
                            <ListItem>
                                <img src={no_data_found} alt="No data found" style={{ display: 'block', margin: '0 auto', width: '100%', height: 'auto' }} />
                            </ListItem>
                        </List>
                        :
                        (
                            <List className="listView" style={{ padding: "0px" }}>
                                <ListItem
                                    secondaryAction={<div onClick={() => reuploadAllFiles()} style={{ cursor: "pointer" }} ><Tooltip title={"Reupload All Files"}><ReplayIcon sx={{ marginRight: "5px" }} /></Tooltip></div>}
                                    sx={{
                                        backgroundColor: "#660032",
                                        color: "white",
                                        padding: "5px 0px",
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <ListItemIcon style={{ padding: "0px 5px" }}>
                                        <Checkbox
                                            edge="start"
                                            onClick={() => handleSelectAll(failedData)}
                                            tabIndex={-1}
                                            disableRipple
                                            style={{ padding: "1px", paddingLeft: "15px", color: "white" }}
                                        />
                                    </ListItemIcon>
                                    <div style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ paddingLeft: "15px" }}>Name</div>
                                    </div>
                                </ListItem>
                                {failedData?.map((value) => {
                                    const labelId = `checkbox-list-label-${value.ProfileId}`;

                                    return (
                                        <ListItem
                                            key={value.ProfileId}
                                            // secondaryAction={value.Action}
                                            className="listItem"
                                            sx={{ padding: 0, fontSize: "12px" }}
                                        >
                                            <ListItemIcon style={{ padding: "0px 5px" }}>
                                                <Checkbox
                                                    edge="start"
                                                    onClick={handleToggle(value)}
                                                    checked={checked.indexOf(value.ProfileId) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    style={{ padding: "1px", paddingLeft: "15px" }}
                                                />
                                            </ListItemIcon>
                                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ paddingLeft: "15px", width: "65%" }}>{renderWithTooltip(value.fileName)}</div>
                                            </div>
                                        </ListItem>
                                    );
                                })}
                            </List>)
            }
        </>

    );
}
