import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

const SkillsChart = ({ data }) => {
  
    const skillColors = data.reduce((acc, skill) => {
        acc[skill.skill] = getRandomColor();
        return acc;
      }, {});

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    console.log("Data passed to SkillsChart:", data);
    Chart.register(ChartDataLabels);

    if (!chartInstance.current && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: data.map((d) => d.skill),
          datasets: [
            {
              data: data.map((d) => d.rating),
              backgroundColor: data.map((d) => skillColors[d.skill]),
              hoverOffset: 4,
              datalabels: {
                color: "white",
                textAlign: "center",
                font: {
                  size: 10,
                },
                formatter: function (value, context) {
                  return (
                    context.chart.data.labels[context.dataIndex] +
                    "\n" +
                    value +
                    "/5"
                  );
                },
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: true,
            },
          },
        },
      });
    } else if (chartInstance.current && chartRef.current) {
      const chart = chartInstance.current;
      chart.data.labels = data.map((d) => d.skill);
      chart.data.datasets[0].data = data.map((d) => d.rating);
      chart.data.datasets[0].backgroundColor = data.map(
        (d) => skillColors[d.skill]
      );
      chart.update();
    }
  }, [data]);

  return (
    <div style={{ display: "flex", justifyContent:"flex-end" }}>
      <div style={{ width: "200px", height: "200px" }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default SkillsChart;
