import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import AddIcon from "@mui/icons-material/Add";
import XIcon from '@mui/icons-material/X';
import axios from 'axios'; // Import Axios

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import MenuListDropdown from "../CommonForBoth/TopbarDropdown/MenuListDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Import images
import logo from "assets/images/civixo-icon.png";
import logoLightPng from "../../assets/images/civixo-logo.png";
import logoDark from "../../assets/images/civixo-logo.png";
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

// i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
// import "./SidebarContent.scss"

import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Button } from "@mui/material";

const Header = props => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [createmenu, setCreateMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [results, setResults] = useState([]);
  console.log("res", results)

  const { user } = useSelector(state => state.Login);
  const {
    name,
    DOJ,
    contactNumber,
    email,
    twitter,
    instagram,
    facebook,
    linkedin,
    profilePicture,
  } = user?.userDetails || {};
  const role = user?.userDetails?.roleAndFeatures?.name;

  useEffect(() => {
    const body = document.body;
    body.classList.add("vertical-collpsed", "sidebar-enable");
  }, []);

  const tToggle = () => {
    const body = document.body;
    body.classList.toggle("sidebar-enable");
    body.classList.toggle("vertical-collpsed");
  };

  // Function to fetch results based on search input
  const fetchResults = async (query) => {
    if (query) {
      try {
        const response = await axios.get(`https://tod-sqano-backend-gamma.vercel.app/api/v1/search-query?query=${query}`); // Replace with your API endpoint
        setResults(response.data.routes); 
      } catch (error) {
        console.error("Error fetching results:", error); 
        setResults([]); 
      }
    } else {
      setResults([]);
    }
  };

  // Effect to fetch results when search input changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchResults(search);
    }, 300); // Debounce for 300ms

    return () => clearTimeout(delayDebounceFn); // Cleanup on unmount
  }, [search]);

  // Initialize navigate

  const handleResultClick = (result, event) => {
    event.preventDefault(); // Prevent default button behavior
    navigate(result.path);
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                className="logo logo-dark"
                type="button"
                id="vertical-menu-btn"
                onClick={tToggle}
              >
                <span className="logo-sm">
                  <img src={logo} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="40" />
                </span>
              </Link>

              <Link className="logo logo-light" onClick={tToggle}>
                <span className="logo-sm">
                  <img src={logo} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="40" />
                </span>
              </Link>
            </div>

            {/* {(role !== "Recruiter") && (
              <Link to="/super-admin">
                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-24 header-item waves-effect-btn"
                  id="vertical-menu-btn"
                >
                  <AddIcon />
                </button>
              </Link>
            )} */}

            <div>
              <MenuListDropdown />
            </div>
          </div>

          <div className="d-flex">
            <form className="app-search d-none d-lg-inline-block">
              <div className="position-relative" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search} 
                  onChange={(e) => setSearch(e.target.value)} 
                  style={{
                    paddingRight: '30px',
                    width: '100%',
                    backgroundColor: 'white', 
                    borderRadius: '10px', 
                    border: '1px solid #ced4da', 
                    fontWeight: "bold",
                    fontSize:"14px"
                  }} 
                />
                <Icon
                  path={mdiMagnify}
                  size={1} 
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '60%', // Center vertically
                    transform: 'translateY(-50%)',
                    color: '#847B7B', 
                  }}
                />
                {results.length > 0 && (
                  <div className="autocomplete-results" style={{
                    position: 'absolute',
                    top: '100%', // Position below the input field
                    left: 0, // Align to the left
                    backgroundColor: 'white', // Set background color for results
                    borderRadius: '4px', // Set border radius for results
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Add shadow for better visibility
                    zIndex: 1000, // Ensure it appears above other elements
                    width: '100%', // Match width of input
                  }}>
                    {results.map(result => 
                      <Button 
                        key={result.id} 
                        onClick={(e) => handleResultClick(result, e)} 
                        style={{
                          width: '100%', // Full width for button
                          textAlign: 'left', // Add padding for better spacing
                          borderRadius: '0', // Remove border radius for buttons
                          backgroundColor: 'transparent', // Use flexbox for alignment
                          textTransform: 'none',
                          display:"flex",
                          justifyContent:"flex-start",
                          color: "#660032", // Capitalize button text
                          fontSize: '10px', // Set font size to 10px for smaller text
                          fontFamily: 'Roboto', // Set font family to Roboto
                          '&:hover': {
                            backgroundColor: '#f1f1f1', // Change background on hover
                          },
                        }}
                      >
                        {result.name}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </form>

            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a className="nav-link" href={linkedin} target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon className="header-icon" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={facebook} target="_blank" rel="noopener noreferrer">
                        <FacebookIcon className="header-icon" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={instagram} target="_blank" rel="noopener noreferrer">
                        <InstagramIcon className="header-icon" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={twitter} target="_blank" rel="noopener noreferrer">
                        <XIcon className="header-icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));