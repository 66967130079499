import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import DraftsIcon from "@mui/icons-material/Drafts"
import ContactMailIcon from "@mui/icons-material/ContactMail"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import ProductsIcon from '../../assets/images/icons/product.png';
import Cvsqanit from '../../assets/images/icons/Cvsqanit.png'
import Cvparseit from '../../assets/images/icons/Cvparseit.png'
import SubscriptionIcon from '../../assets/images/icons/subscription.png';
import ClientUserIcon from '../../assets/images/icons/client.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UserProfileIcon from '../../assets/images/icons/profile-creation.png';
import usermgt from '../../assets/images/icons/usermgt.png'
import { SuperAdminData, SuperAdminDetails } from "./StaticData"
import "./SidebarContent.css";

const SqanoSidebarContent = props => {
    const ref = useRef()
    const navigate = useNavigate()
    const userDetails = useSelector(state => state.Login?.user.userDetails || {})
    const userFeatures = useSelector(state => state.Login?.user?.userDetails?.roleAndFeatures?.features) 
    const [data, setData] = useState([]);
    const [productSubFeatures, setProductSubFeatures] = useState();

    const [dropdownVisible, setProductDropdownVisible] = useState(false);
    const location = useLocation();
    const [activeLink,setActiveLink] =useState(location.pathname)
    
    const toggleDropdown = (dropdown,path) => {
        setProductDropdownVisible(dropdownVisible === dropdown ? null : dropdown);
        setActiveLink(path);
    };

    useEffect(() => {
        new MetisMenu("#side-menu")
        ref.current?.recalculate()
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])


    const hasFeature = featureName => {
        return data.some(features => features.feature.name === featureName);
    };

    const hasSubFeatureCall = () => {
        const productData = data.find(features => features.feature.name === "PRODUCT");
        if (productData) {
            setProductSubFeatures(productData.subFeatures);
        }
    };

    const hasSubFeature = subFeatureName => {
        return productSubFeatures.some(subFeature => subFeature.feature.name === subFeatureName);
    };

    useEffect(() => {
        if (hasFeature("PRODUCT")) {
            hasSubFeatureCall();
        }
    }, [data]);


    useEffect(() => {
        function extractNames(userFeatures) {

            const names = [];
            function getFeatureNames(userFeatures) {
                userFeatures?.forEach(features => {
                    // Add feature name
                    names.push(features);
                });
            }
            // Start extraction from the root features
            getFeatureNames(userFeatures);

            return names;
        }
        const data = extractNames(userFeatures)

        setData(data)
    }, [])

    // const hasFeature = featureName => {
    //     return (
    //         userDetails.permissions?.some(
    //             p => p.featureName === featureName && p.permissions.canView,
    //         ) || false
    //     )
    // }


    const handleChangeMenu = (e, path) => {
        e.preventDefault()
        navigate(path)
        setActiveLink(path)
    }

    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const activeMenu = useCallback(() => {
        const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [props.router.location.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        activeMenu();
    }, [activeMenu]);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }


    return (
        <React.Fragment>
            <SimpleBar ref={ref}>
                <div id="sidebar-menu">
                  <ul className="metismenu list-unstyled" id="side-menu">
                    {hasFeature("HOME") && (
                        <li>
                            <Link
                                to="/dashboard"
                                onClick={(e) => handleChangeMenu(e, "/dashboard")}
                                className={`waves-effect ${activeLink === '/dashboard' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-home"></i>
                                <span>{props.t("HOME")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("CLIENT MANAGEMENT") && (
                        <li>
                            <Link
                                to="/client-management"
                                onClick={(e) => handleChangeMenu(e, "/client-management")}
                                className={`waves-effect ${activeLink === '/client-management' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-account"></i>
                                <span>{props.t("USER MANAGEMENT")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("USER MANAGEMENT") && (
                        <li>
                            <Link
                                to="/user-management"
                                onClick={(e) => handleChangeMenu(e, "/user-management")}
                                className={`waves-effect ${activeLink === '/user-management' ? 'active' : ''}`}
                            >
                                <img src={usermgt} alt="User Management" />
                                <span>{props.t("USER MANAGEMENT")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("REQUIREMENT LIST") && (
                        <li>
                            <Link
                                to="/requirement-list"
                                onClick={(e) => handleChangeMenu(e, "/requirement-list")}
                                className={`waves-effect ${activeLink === '/requirement-list' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-checkbox-multiple-marked-outline"></i>
                                <span>REQUIREMENT LIST</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("SQANO USERS") && (
                        <li className="product-main-item">
                            <Link
                                to="#"
                                onClick={toggleDropdown}
                                className={`has-arrow waves-effect product-main-link ${activeLink.startsWith('/sqano-users') ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-receipt"></i>
                                <span>{props.t("SQANO USERS")}</span>
                            </Link>
                            <ul className="sub-dropdown">
                                <li>
                                    <Link to="#" className={`waves-effect ${activeLink === '/sqano-super-admin' ? 'active' : ''}`}>
                                        <i className="mdi mdi-format-list-bulleted"></i>
                                        <span>{props.t("SQANO SUPER ADMIN")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className={`waves-effect ${activeLink === '/sqano-admin' ? 'active' : ''}`}>
                                        <i className="mdi mdi-plus-box"></i>
                                        <span>{props.t("SQANO ADMIN")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className={`waves-effect ${activeLink === '/sqano-users' ? 'active' : ''}`}>
                                        <i className="mdi mdi-pencil-box"></i>
                                        <span>{props.t("SQANO USERS")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}

                    {hasFeature("CLIENT USERS") && (
                        <li className="product-main-item">
                            <Link
                                to="#"
                                onClick={toggleDropdown}
                                className={`has-arrow waves-effect product-main-link ${activeLink.startsWith('/client-users') ? 'active' : ''}`}
                            >
                                <img src={ClientUserIcon} alt="Client Users" />
                                <span>{props.t("CLIENT USERS")}</span>
                            </Link>
                            <ul className="sub-dropdown">
                                <li>
                                    <Link to="#" className={`waves-effect ${activeLink === '/client-super-admin' ? 'active' : ''}`}>
                                        <AccountCircleIcon style={{ fontSize: '19px', marginRight: '5px' }} />
                                        <span>{props.t("SUPER ADMIN")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className={`waves-effect ${activeLink === '/client-admin' ? 'active' : ''}`}>
                                        <PersonAddIcon style={{ fontSize: '19px', marginRight: '5px' }} />
                                        <span>{props.t("ADMIN")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/UserProfileCreation" className={`waves-effect ${activeLink === '/UserProfileCreation' ? 'active' : ''}`}>
                                        <img src={UserProfileIcon} alt="User Profile" />
                                        <span>{props.t("USER PROFILE CREATION")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}

                    {hasFeature("PRODUCT") && (
                        <li>
                            <Link
                                to="javascript:void(0)"
                                onClick={(e) => toggleDropdown('product','/product')}
                                className={`waves-effect${dropdownVisible === 'product' ? 'active' : ''}`}
                            >
                                    {/* {/* <img src={ProductsIcon} alt="Products" />  */}
                                <i className="mdi mdi-package-variant"></i>
                                <span>{props.t("PRODUCT")}</span>
                                <i
                                    className={`arrow-icon mdi mdi-chevron-right ${
                                        dropdownVisible === 'product' ? 'rotate-down' : ''
                                    }`}
                                ></i>
                            </Link>
                            {dropdownVisible === 'product' && (
                                <ul className="sub-dropdown">
                                    {hasSubFeature("SQANIT") && (
                                        <li>
                                            <Link to="/scan-it" onClick={(e) => handleChangeMenu(e, "/scan-it")} className={`${activeLink === '/scan-it' ? 'active' : ''}`}>
                                                <i className="mdi mdi-magnify-scan"></i>
                                                <span>{props.t("SQANIT")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("PARSEIT") && (
                                        <li>
                                            <Link to="/parsing" onClick={(e) => handleChangeMenu(e, "/parse-it")} className={`${activeLink === '/parsing' ? 'active' : ''}`}>
                                                <i className="mdi mdi-text-box"></i>
                                                <span>{props.t("PARSEIT")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("CIVIX") && (
                                        <li>
                                            <Link to="/access" onClick={(e) => handleChangeMenu(e, "/civix")} className={`${activeLink === '/access' ? 'active' : ''}`}>
                                                <i className="mdi mdi-file-account"></i>
                                                <span className="menu-text">{props.t("CIVIX")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("TOD") && (
                                        <li>
                                            <Link to="/todlist" onClick={(e) => handleChangeMenu(e, "/tod")} className={`${activeLink === '/tod' ? 'active' : ''}`}>
                                                <i className="mdi mdi-briefcase-account"></i>
                                                <span className="menu-text">{props.t("TOD")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("WEBIT") && (
                                        <li>
                                            <Link to="/Webscrap" onClick={(e) => handleChangeMenu(e, "/web-it")} className={`${activeLink === '/web-it' ? 'active' : ''}`}>
                                                <i className="mdi mdi-search-web"></i>
                                                <span className="menu-text">{props.t("WEBIT")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("SQALEUP") && (
                                        <li>
                                            <Link to="/salescrmp" onClick={(e) => handleChangeMenu(e, "/sqale-up")} className={`${activeLink === '/sqale-up' ? 'active' : ''}`}>
                                                <i className="mdi mdi-chart-line"></i>
                                                <span className="menu-text">{props.t("SQALEUP")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("MOCK TEST") && (
                                        <li>
                                            <Link to="/mocktestp" onClick={(e) => handleChangeMenu(e, "/mocktest")} className={`${activeLink === '/mocktest' ? 'active' : ''}`}>
                                                <i className="mdi mdi-clipboard-text"></i>
                                                <span className="menu-text">{props.t("MOCK TEST")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {hasSubFeature("CV TEMPLATES") && (
                                        <li>
                                            <Link to="/cvtemplate" onClick={(e) => handleChangeMenu(e, "/cvtemplate")} className={`${activeLink === '/cvtemplate' ? 'active' : ''}`}>
                                                <i className="mdi mdi-file-find"></i>
                                                <span className="menu-text">{props.t("CV TEMPLATES")}</span>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </li>
                    )}

                    {hasFeature("PAID/UNPAID SUBSCRIBERS") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-account-multiple"></i>
                                <span>{props.t("PAID/UNPAID SUBSCRIBERS")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("SUBSCRIPTION") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <img src={SubscriptionIcon} alt="Subscription" />
                                <span>{props.t("SUBSCRIPTION")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("INVOICE") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-receipt"></i>
                                <span>{props.t("INVOICE MANAGEMENT")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("TO DO LIST") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-checkbox-marked-outline"></i>
                                <span>{props.t("TO DO LIST")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("EMAIL") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-message-text"></i>
                                <span>{props.t("EMAIL")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("ALERTS") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-alert-circle"></i>
                                <span>{props.t("ALERTS")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("Form Creation") && (
                        <li className="product-main-item">
                            <Link
                                to="#"
                                onClick={toggleDropdown}
                                className={`has-arrow waves-effect product-main-link ${activeLink.startsWith('/form-creation') ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-clipboard-outline"></i>
                                <span>{props.t("Form Creation")}</span>
                            </Link>
                            <ul className="sub-dropdown">
                                <li>
                                    <Link
                                        to="/client-creation-form"
                                        className={`waves-effect ${activeLink === '/client-creation-form' ? 'active' : ''}`}
                                    >
                                        <i className="mdi mdi-account"></i>
                                        {props.t("Client Creation")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/vendor-form"
                                        className={`waves-effect ${activeLink === '/vendor-form' ? 'active' : ''}`}
                                    >
                                        <i className="mdi mdi-account-plus"></i>
                                        {props.t("Vendor Form")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/user-creation"
                                        className={`waves-effect ${activeLink === '/user-creation' ? 'active' : ''}`}
                                    >
                                        <i className="mdi mdi-account-multiple-plus"></i>
                                        {props.t("User Creation")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/Create-job-post"
                                        className={`waves-effect ${activeLink === '/Create-job-post' ? 'active' : ''}`}
                                    >
                                        <i className="mdi mdi-briefcase-plus"></i>
                                        {props.t("New Job Post")}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}

                    {hasFeature("ACCOUNT SETTING") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-cog"></i>
                                <span>{props.t("ACCOUNT SETTING")}</span>
                            </Link>
                        </li>
                    )}

                    {hasFeature("PAYMENT SETTING") && (
                        <li>
                            <Link
                                to="/comming-soon"
                                onClick={(e) => handleChangeMenu(e, "/comming-soon")}
                                className={`waves-effect ${activeLink === '/comming-soon' ? 'active' : ''}`}
                            >
                                <i className="mdi mdi-cash"></i>
                                <span>{props.t("PAYMENT SETTING")}</span>
                            </Link>
                        </li>
                    )}
                  </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SqanoSidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(SqanoSidebarContent))
