// reducer.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "./actionTypes"

const initialState = {
  user: {},
  loading: false,
  error: null,
  allCounts: null
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOGIN_SUCCESS:
      console.log("action======", action.payload)
      return {
        ...state,
        loading: false,
        user: action.payload,
      }

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        allCounts: null
      }
    default:
      return state
  }
}

export default loginReducer
