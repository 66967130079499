import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { insertWorkflowsSuccess, insertWorkflowsFailure, fetchWorkflowsSuccess, fetchWorkflowsFailure, updateWorkflowSuccess, updateWorkflowFailure, fetchWorkflowsByIdSuccess, fetchWorkflowsByIdFailure } from './actions';
import { getAllWorkFlows, insertWorkFlow, updateWorkFlow,getAllWorkFlowsById } from 'store/api';
import { FETCH_WORKFLOWS_REQUEST, INSERT_WORKFLOWS_REQUEST, UPDATE_WORKFLOW_REQUEST, FETCH_WORKFLOWS_BY_ID_REQUEST } from './actionTypes';

function* insertWorkflowsSaga(action) {
    try {
        const response = yield call(insertWorkFlow, action.payload);
        yield put(insertWorkflowsSuccess(response.data.data));
    } catch (error) {
        yield put(insertWorkflowsFailure(error.response.data));
    }
}


function* fetchWorkflowsSaga() {
    try {
        const response = yield call(getAllWorkFlows);
        yield put(fetchWorkflowsSuccess(response.data.data));
    } catch (error) {
        yield put(fetchWorkflowsFailure(error.message));
    }
}

function* fetchWorkflowsByIdSaga(action) {
    try {
        const response = yield call(getAllWorkFlowsById, action.payload);
        yield put(fetchWorkflowsByIdSuccess(response.data.data));
    } catch (error) {
        yield put(fetchWorkflowsByIdFailure(error.message));
    }
}


function* updateWorkflowSaga(action) {
    try {
        console.log("dhbsfhbdshf", action.payload)
        const { id, details } = action.payload;
        const response = yield call(updateWorkFlow, id, details);
        yield put(updateWorkflowSuccess(response.data));
    } catch (error) {
        yield put(updateWorkflowFailure(error.response ? error.response.data.error : "Unknown error"));
    }
}

export function* watchInsertWorkflows() {
    yield takeLatest(INSERT_WORKFLOWS_REQUEST, insertWorkflowsSaga);
}

export function* watchFetchWorkflows() {
    yield takeLatest(FETCH_WORKFLOWS_REQUEST, fetchWorkflowsSaga);
}

export function* watchFetchWorkflowsById() {
    yield takeLatest(FETCH_WORKFLOWS_BY_ID_REQUEST, fetchWorkflowsByIdSaga);
}

export function* watchUpdateWorkflow() {
    yield takeLatest(UPDATE_WORKFLOW_REQUEST, updateWorkflowSaga);
}
  
  export default function* workFlowSaga() {
    yield all([fork(watchInsertWorkflows), fork(watchFetchWorkflows), fork(watchUpdateWorkflow), fork(watchFetchWorkflowsById)]);
  }

