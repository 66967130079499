// src/store/client/product/Civix/reducer.js
import {
  FETCH_CV_ACCESS_CANDIDATES_REQUEST,
  FETCH_CV_ACCESS_CANDIDATES_SUCCESS,
  FETCH_CV_ACCESS_CANDIDATES_FAILURE,
  FILTER_CV_ACCESS_CANDIDATES_REQUEST,
  FILTER_CV_ACCESS_CANDIDATES_SUCCESS,
  FILTER_CV_ACCESS_CANDIDATES_FAILURE,
} from "./actionTypes"

const initialState = {
  candidates: [],
  filteredCandidates: [],
  loading: false,
  error: null,
}

const cvAccessCandidatesReducer = (state = initialState, action) => {
  // console.log("Reducer called with action:", action)
  switch (action.type) {
    case FETCH_CV_ACCESS_CANDIDATES_REQUEST:
    case FILTER_CV_ACCESS_CANDIDATES_REQUEST:
      // console.log("FETCH or FILTER CANDIDATES REQUEST case")
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_CV_ACCESS_CANDIDATES_SUCCESS:
      // console.log("FETCH or FILTER CANDIDATES REQUEST case")
      return {
        ...state,
        loading: false,
        candidates: action.payload,
      }
    case FILTER_CV_ACCESS_CANDIDATES_SUCCESS:
      // console.log("FETCH or FILTER CANDIDATES SUCCESS case", action.payload)
      return {
        ...state,
        loading: false,
        filteredCandidates: action.payload,
      }
    case FETCH_CV_ACCESS_CANDIDATES_FAILURE:
    case FILTER_CV_ACCESS_CANDIDATES_FAILURE:
      // console.log("FETCH or FILTER CANDIDATES FAILURE case")
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default cvAccessCandidatesReducer
