// src/GlobalBusiness.js
import React, { useState } from "react"
import { Row, Col, Card } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import "pages/Sqano Dashboard/dashboard.css"
import { HTML5_FMT } from "moment/moment"

const GlobalBusiness = () => {
  const [chartData] = useState({
    options: {
      colors: ["#58db83", "#9e3165", "#9e3165"],
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 0.1,
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["2011", "2012", "2013", "2014", "2015", "2016", "2017"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
    },
    series: [
      { name: "Series A", data: [0, 0, 0, 0, 0, 0, 0] },
      { name: "Series B", data: [0, 0, 0, 0, 0, 0, 0] },
      { name: "Series C", data: [0, 0, 0, 0, 0, 0, 0] },
    ],
  })

  return (
    <React.Fragment>
      <Card className="dashboard-graph-card" style={{ height: "550px" }}>
        <h5 className="dashboard-graph-title">GLOBAL BUSINESS</h5>

        <Row className="text-center dashboard-graph-info">
          <Col xs="4">
            <h6 className="dashboard-graph-value">0</h6>
            <p className="dashboard-graph-text text-muted">INDIA</p>
          </Col>
          <Col xs="4">
            <h6 className="dashboard-graph-value">0</h6>
            <p className="dashboard-graph-text text-muted">USA</p>
          </Col>
          <Col xs="4">
            <h6 className="dashboard-graph-value font-size-20">0</h6>
            <p className="dashboard-graph-text text-muted">EUROPE/ROW</p>
          </Col>
        </Row>
        <div className="dashboard-graph-chart-container">
          <ReactApexChart
            options={{ ...chartData.options, colors: ["#660032"] }}
            series={chartData.series}
            type="area"
            height="300"
          />
        </div>
      </Card>
    </React.Fragment>
  )
}

export default GlobalBusiness
