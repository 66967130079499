import React, { useState } from "react";
import { Box, Grid, Button, Divider } from "@mui/material";
import "./Download.scss";
import { mdiMagnifyScan, mdiTextBox, mdiBriefcaseAccount } from "@mdi/js";
import ScanItHistory from "./ScanItHistory";
import ParseItHistory from "./ParseItHistory";
import PulseLoader from "pages/PulseLoader/PulseLoader";
import Icon from "@mdi/react";
import CommingSoon from "pages/Extra Pages/pages-blank";
import PagesBlank from "pages/Extra Pages/pages-blank";
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import Tod from "pages/ProductList/Tod";

const tabConfigs = [
  { id: "scanit", label: "SQANIT HISTORY", icon: mdiMagnifyScan, component: <ScanItHistory /> },
  { id: "parseit", label: "PARSEIT HISTORY", icon: mdiTextBox, component: <ParseItHistory /> },
  { id: "account", label: "REQUIREMENT HISTORY", icon: mdiBriefcaseAccount, component: <CommingSoon /> },
  { id: "jobpost", label: "JOB POST HISTORY", icon: mdiBriefcaseAccount, component: <CommingSoon /> },
];


const DownloadPage = () => {
  const [activeTab, setActiveTab] = useState("scanit");
  const [loading, setloading] = useState(false);

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "History", href: "/download" },
  ];

  return (
    <Box>
      <CustomBreadcrumb items={breadcrumbItems} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className="tabs">
            {tabConfigs.map((tab, index) => (
              <React.Fragment key={tab.id}>
                <Button
                  className={`tabs-buttons ${activeTab === tab.id ? "active" : ""}`}
                  variant="text"
                  onClick={() => setActiveTab(tab.id)}
                >
                  <Icon
                    path={tab.icon}
                    size={0.5}
                    className={`tab-icon ${activeTab === tab.id ? "active" : ""}`}
                  />
                  {tab.label}
                </Button>
                {index < tabConfigs.length - 1 && (
                  <Divider orientation="vertical" flexItem style={{ borderColor: "#660032" }} />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {loading && <PulseLoader />}
          {!loading && tabConfigs.find(tab => tab.id === activeTab)?.component}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadPage;
