import React, { useState } from "react"
import PropTypes from "prop-types"
import { Tabs, Tab, Box, Typography, TextField, Button } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useDropzone } from "react-dropzone"
import img1 from "../../assets/images/Picture1.png"
import img2 from "../../assets/images/Picture2.png"
import img3 from "../../assets/images/Picture3.png"
import img4 from "../../assets/images/Picture4.png"
import img5 from "../../assets/images/Picture5.png"
import img6 from "../../assets/images/Picture6.png"
import img7 from "../../assets/images/Picture7.png"
import img8 from "../../assets/images/Picture8.png"
import img9 from "../../assets/images/Picture9.png"
import img10 from "../../assets/images/Picture10.png"
import img11 from "../../assets/images/Picture11.png"
import img12 from "../../assets/images/Picture12.png"
import "./CvScanning.scss"
import Search from './Search';
import ProductHome from "./ProductHome";
import ProductTabs from "./ProductTabs"
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";




function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const MockTest = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".pdf", ".doc", ".docx"],
  })
  const [query, setQuery] = useState('');

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  const handleSearch = () => {
    // Implement your search logic here
    console.log('Searching for:', query);
    // For demo purposes, we'll just log the query
    // You can update the searchResults state based on your search logic
  };
  
  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "MOCKTEST", href: "/mocktest" },
  ];



  return (
    <div>      
        <CustomBreadcrumb items={breadcrumbItems} />
        <ProductTabs tab={6}/>
        <div className="row">
          <div className="text-center" >
           <h2 style={{fontSize:"40px", color:"#660032", fontWeight:"bold", marginTop:"60px"}}>Coming Soon...</h2>
           </div>
          </div>     
    </div>
  )
}

export default MockTest
