import {
  ADD_USER,
  ADD_USER_FAILURE,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  GET_ROLE_BY_ID,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAILURE,
  GET_USER_BY_ENTITY_ID,
  GET_USER_BY_ENTITY_ID_SUCCESS,
  GET_USER_BY_ID,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  GET_ALL_ROLES,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  GET_ALL_FEATURES,
  GET_ALL_FEATURES_SUCCESS,
  GET_ALL_FEATURES_FAILURE,
  GET_USER_BY_ENTITY_ID_FAILURE,
  GET_ALL_COUNTS,
  GET_ALL_COUNTS_SUCCESS,
  GET_ALL_COUNTS_FAILURE,
  GET_ALL_ROLES_REQUEST,
  GET_USER_BY_CREATED_BY_SUCCESS,
  GET_USER_BY_CREATED_BY_REQUEST,
  GET_USER_BY_CREATED_BY_FAILURE,
} from "./actionTypes"

// Actions for CRUD operations
export const addUserRequest = user => ({ type: ADD_USER, payload: user })
export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})
export const addUserFailure = error => ({
  type: ADD_USER_FAILURE,
  payload: error,
})

export const getUsers = () => ({ type: GET_USERS })
export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})
export const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: error,
})

export const getUserById = id => ({ type: GET_USER_BY_ID, payload: id })

export const getUserByIdSuccess = user => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
})
export const getUserByIdFailure = error => ({
  type: GET_USER_BY_ID_FAILURE,
  payload: error,
})

export const getUserByEntityId = id => ({ type: GET_USER_BY_ENTITY_ID, payload: id })

export const getUserByEntityIdSuccess = users => ({
  type: GET_USER_BY_ENTITY_ID_SUCCESS,
  payload: users,
})
export const getUserByEntityIdFailure = error => ({
  type: GET_USER_BY_ENTITY_ID_FAILURE,
  payload: error,
})

export const getUserByCreatedByRequest = id => ({ type: GET_USER_BY_CREATED_BY_REQUEST, payload: id })

export const getUserByCreatedBySuccess = users => ({
  type: GET_USER_BY_CREATED_BY_SUCCESS,
  payload: users,
})
export const getUserByCreatedByFailure = error => ({
  type: GET_USER_BY_CREATED_BY_FAILURE,
  payload: error,
})

export const updateUser = ({id, userData}) => ({
  type: UPDATE_USER,
  payload: { id, userData },
})
export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})
export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
})

export const deleteUser = id => ({ type: DELETE_USER, payload: id })
export const deleteUserSuccess = id => ({
  type: DELETE_USER_SUCCESS,
  payload: id,
})
export const deleteUserFailure = error => ({
  type: DELETE_USER_FAILURE,
  payload: error,
})

export const getRoleByID = id => ({ type: GET_ROLE_BY_ID, payload: id })

export const getRoleByIDSuccess = role => ({
  type: GET_ROLE_BY_ID_SUCCESS,
  payload: role,
})
export const getRoleByIDFailure = error => ({
  type: GET_ROLE_BY_ID_FAILURE,
  payload: error,
})

export const getAllRolesRequest = () => ({
  type: GET_ALL_ROLES_REQUEST
});

export const getAllRolesSuccess = (roles) => ({
  type: GET_ALL_ROLES_SUCCESS,
  payload: roles
});

export const getAllRolesFailure = (error) => ({
  type: GET_ALL_ROLES_FAILURE,
  payload: error
});

export const getAllFeatures = () => ({ type: GET_ALL_FEATURES })

export const getAllFeaturesSuccess = features => ({
  type: GET_ALL_FEATURES_SUCCESS,
  payload: features,
})
export const getAllFeaturesFailure = error => ({
  type: GET_ALL_FEATURES_FAILURE,
  payload: error,
})

export const getAllCounts = (data) => ({ type: GET_ALL_COUNTS,payload: data })

export const getAllCountsSuccess = counts => ({
  type: GET_ALL_COUNTS_SUCCESS,
  payload: counts,
})
export const getAllCountsFailure = error => ({
  type: GET_ALL_COUNTS_FAILURE,
  payload: error,
})
