import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { Country, State, City } from "country-state-city"
import "./FormWizardSteps/vendor.css"

const JobPostingForm = props => {

  const [requirementReceivedDateTime, setRequirementReceivedDateTime] =
    useState(new Date())
  const [requirementAllocationDateTime, setRequirementAllocationDateTime] =
    useState(new Date())

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [cities, setCities] = useState([])

  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "Forms", link: "#" },
    { title: "Job Posting Form", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Job Posting Form", breadcrumbItems)
  }, [props])

  const handleSubmit = event => {
    event.preventDefault()
    // Add your form submission logic here
  }

  const handleCountryChange = country => {
    setSelectedCountry(country)
    setSelectedState(null) // Reset state when country changes
    setCities([]) // Reset cities when country changes
  }

  const handleStateChange = state => {
    setSelectedState(state)
    // Fetch cities for the selected state
    const stateCities = City.getCitiesOfState(selectedCountry, state)
    setCities(stateCities)
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <h4 className="card-title text-center mb-4">Create Job Post</h4>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={6}>
                    <Label for="requirementReceivedDateTime">
                      Requirement Received Date & Time
                    </Label>
                    <Input
                      type="datetime-local"
                      id="requirementReceivedDateTime"
                      className="form-control"
                      value={requirementReceivedDateTime}
                      onChange={e =>
                        setRequirementReceivedDateTime(e.target.value)
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <Label for="requirementAllocationDateTime">
                      Requirement Allocation Date & Time
                    </Label>
                    <Input
                      type="datetime-local"
                      id="requirementAllocationDateTime"
                      className="form-control"
                      value={requirementAllocationDateTime}
                      onChange={e =>
                        setRequirementAllocationDateTime(e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="clientName">Client Name</Label>
                    <Input
                      type="text"
                      id="clientName"
                      placeholder="Enter client name"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="jobTitle">Job Title</Label>
                    <Input
                      type="text"
                      id="jobTitle"
                      placeholder="Enter job title"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="numberOfPositions">Number of Positions</Label>
                    <Input
                      type="number"
                      id="numberOfPositions"
                      placeholder="Enter number of positions"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="yearsOfExperience">Years of Experience</Label>
                    <Input
                      type="text"
                      id="yearsOfExperience"
                      placeholder="Enter years of experience"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="jobType">Job Type</Label>
                    <Input type="select" id="jobType" className="form-control">
                      <option>FTE</option>
                      <option>Contract</option>
                      <option>Freelance</option>
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="country">Country</Label>
                    <Input
                      type="select"
                      id="country"
                      className="form-control"
                      value={selectedCountry}
                      onChange={e => handleCountryChange(e.target.value)}
                    >
                      <option value="">Select Country</option>
                      {Country.getAllCountries().map(country => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="state">State</Label>
                    <Input
                      type="select"
                      id="state"
                      className="form-control"
                      value={selectedState}
                      onChange={e => handleStateChange(e.target.value)}
                      disabled={!selectedCountry}
                    >
                      <option value="">Select State</option>
                      {selectedCountry &&
                        State.getStatesOfCountry(selectedCountry).map(state => (
                          <option key={state.isoCode} value={state.isoCode}>
                            {state.name}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="city">City</Label>
                    <Input
                      type="select"
                      id="city"
                      className="form-control"
                      value={null} // Set value if needed
                      onChange={e => {
                        // Handle city change
                      }}
                      disabled={!selectedState}
                    >
                      <option value="">Select City</option>
                      {cities.map(city => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="jobDescription">Job Description</Label>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="mustHaveSkills">Must have skills</Label>
                    <Input
                      type="text"
                      id="mustHaveSkills"
                      placeholder="Enter must have skills"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="goodToHaveSkills">Good to have skills</Label>
                    <Input
                      type="text"
                      id="goodToHaveSkills"
                      placeholder="Enter good to have skills"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="industryType">Industry Type</Label>
                    <Input
                      type="select"
                      id="industryType"
                      className="form-control"
                    >
                      <option>Select Industry Type</option>
                      <option>Technology, Information and Media</option>
                      <option>Manufacturing</option>
                      <option>Professional Services</option>
                      <option>Financial Services</option>
                      {/* Add more industry options here */}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="roleCategory">Role Category</Label>
                    <Input
                      type="select"
                      id="roleCategory"
                      className="form-control"
                    >
                      <option>Select Role Category</option>
                      <option>Software Development</option>
                      <option>Product Management</option>
                      <option>Quality Assurance</option>
                      <option>Data Science</option>
                      {/* Add more role category options here */}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="certifications">
                      Certifications (optional)
                    </Label>
                    <Input
                      type="text"
                      id="certifications"
                      placeholder="List any required certifications, separated by commas"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="noticePeriod">Notice Period</Label>
                    <Input
                      type="select"
                      id="noticePeriod"
                      className="form-control"
                    >
                      <option>Immediate</option>
                      <option>1 Month</option>
                      <option>2 Months</option>
                      <option>3 Months</option>
                      {/* Add more notice period options here */}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="minimumBudget">Minimum Budget</Label>
                    <Input
                      type="text"
                      id="minimumBudget"
                      placeholder="Enter minimum budget"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="maximumBudget">Maximum Budget</Label>
                    <Input
                      type="text"
                      id="maximumBudget"
                      placeholder="Enter maximum budget"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="workMode">Work Mode</Label>
                    <Input type="select" id="workMode" className="form-control">
                      <option>Select Work Mode</option>
                      <option>Onsite</option>
                      <option>Remote</option>
                      <option>Hybrid</option>
                      {/* Add more work mode options here */}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Label for="assignToRecruiters">Assign to recruiters</Label>
                    <Input
                      type="select"
                      id="assignToRecruiters"
                      className="form-control"
                    >
                      <option>Select Recruiter</option>
                      {/* Add recruiter options here */}
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} className="text-center">
                    <Button type="submit" className="custom-button">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(JobPostingForm)
