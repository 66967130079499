  import React from 'react';
  import './PulseLoader.css';
  import SqanoIcon from './../../assets/images/civixo-logo.png';

  const PulseLoader = () => {
    return (
      <div className="loader-container">
        <img src={SqanoIcon} alt="Loader" className="loader-image" />
      </div>
    );
  };

  export default PulseLoader;
