import { combineReducers } from "redux"

import { connectRouter } from "connected-react-router"

import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer"

import Login from "./auth/login/reducer"
import Register from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import chat from "./chat/reducer"

import calendar from "./calendar/reducer"

import tasks from "./tasks/reducer"

import JobCreation from "./client/product/tod/jobRequirements/reducer"
import profileScanReducer from "./client/product/profileScan/reducer"

import GetAllUsers from "./client/users/reducer"

import JobRequirement from "./client/product/tod/jobRequirements/reducer"

import usersReducer from "./client/users/reducer"

import cvAccessCandidatesReducer from "./client/product/Civix/reducer"
import parseProfilesReducer from "./client/product/profileParse/reducer"
import workflowReducer from "./client/product/workFlow/reducer"
import Vendor from "./client/vendorForm/reducer"
import roleReducer from "./client/product/Roles/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Breadcrumb,
  tasks,
  Login,
  Register,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  //TOD
  JobCreation,

  GetAllUsers,
  JobRequirement,
  usersReducer,

  //Parse it
  parseProfilesReducer,

  // scan it
  profileScanReducer,
  // GetAllUsers,
  // JobRequirement,
  // usersReducer,
  cvAccessCandidatesReducer,
  workflowReducer,
  Vendor,
  usersReducer,
  roleReducer
})

export default rootReducer
