import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Slide, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const closeButtonStyle = {
    position: "absolute",
    top: 0,
    right: 0,
  };

const CustomModal = ({ open, onClose, onUpdate, title, content}) => {
    return (
        <>
            
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}
                PaperProps={{
                    sx: { width: '40%', height: '100%', maxHeight:"100%",maxWidth:"100%", position: 'absolute', right: 0, margin: 0, transitionDuration: '1s',backgroundColor: '#f2f2f2'},
                }}
            >
                <IconButton sx={closeButtonStyle} onClick={onClose}>
                <CloseIcon />
                </IconButton>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{content}</Typography>
                </DialogContent>
            </Dialog>
        </>


    );
};

export default CustomModal;
