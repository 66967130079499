import React from "react"
import { Card, CardBody } from "reactstrap"
import "pages/Sqano Dashboard/dashboard.css"

const SubscriptionValues = ({
  subScriptionValue,
  badge,
  clientImage,
  expiryDate,
}) => {
  return (
    <Card className="subscription-container">
      <CardBody>
        <h4 className="card-title mb-4">SUBSCRIPTION VALUE</h4>
        <div className="d-flex flex-column">
          <img
            src={clientImage}
            alt="Client"
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              marginBottom: "10px",
            }}
          />
          <h3>{subScriptionValue}</h3>
          <div className="text-muted">{expiryDate}</div>

          {/* Display badge */}
          {badge && <span className="badge bg-danger mt-2">{badge}</span>}
        </div>
      </CardBody>
    </Card>
  )
}

export default SubscriptionValues

