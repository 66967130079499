import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
import { connect } from "react-redux"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { setBreadcrumbItems } from "../../store/actions"
import "./FormWizardSteps/vendor.css"

const ClientCreation = props => {

  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "Forms", link: "#" },
    { title: "Client Creation Form", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Client Creation Form", breadcrumbItems)
  }, [props, breadcrumbItems])

  const [companySizeDropdownOpen, setCompanySizeDropdownOpen] = useState(false)
  const [industryDropdownOpen, setIndustryDropdownOpen] = useState(false)
  const [selectedCompanySize, setSelectedCompanySize] = useState(
    "Select company size",
  )
  const [selectedIndustry, setSelectedIndustry] = useState("Select industry")
  const [ownerContact, setOwnerContact] = useState("")

  const toggleCompanySizeDropdown = () =>
    setCompanySizeDropdownOpen(prevState => !prevState)
  const toggleIndustryDropdown = () =>
    setIndustryDropdownOpen(prevState => !prevState)

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <CardBody>
              <h4 className="card-title">Client Creation Form</h4>
              <Form>
                <Row className="mb-3">
                  <Label
                    htmlFor="owner-name"
                    className="col-md-4 col-form-label"
                  >
                    Owner / Founder Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="owner-name"
                      className="form-control"
                      placeholder="Enter owner/founder name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="owner-contact"
                    className="col-md-4 col-form-label"
                  >
                    Owner Contact Number
                  </Label>
                  <Col md={8}>
                    <PhoneInput
                      country={"in"}
                      value={ownerContact}
                      enableSearch={true}
                      onChange={phone => setOwnerContact(phone)}
                      inputProps={{
                        name: "ownerContact",
                        required: true,
                        className: "form-control",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="owner-email"
                    className="col-md-4 col-form-label"
                  >
                    Owner Email ID
                  </Label>
                  <Col md={8}>
                    <Input
                      type="email"
                      id="owner-email"
                      className="form-control"
                      placeholder="Enter email ID"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="company-name"
                    className="col-md-4 col-form-label"
                  >
                    Entity Name / Company Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="company-name"
                      className="form-control"
                      placeholder="Enter company name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="company-type"
                    className="col-md-4 col-form-label"
                  >
                    Company Type
                  </Label>
                  <Col md={8}>
                    <Input
                      type="select"
                      id="company-type"
                      className="form-control"
                    >
                      <option>Private Ltd.</option>
                      <option>LLP</option>
                      <option>LLC</option>
                      <option>INC.</option>
                      <option>Public Limited</option>
                      <option>Others</option>
                    </Input>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="incorporation-number"
                    className="col-md-4 col-form-label"
                  >
                    Incorporation Number
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="incorporation-number"
                      className="form-control"
                      placeholder="Enter incorporation number"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="incorporation-certificate"
                    className="col-md-4 col-form-label"
                  >
                    Upload Incorporation Certificate
                  </Label>
                  <Col md={8}>
                    <Input
                      type="file"
                      id="incorporation-certificate"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="gst-vat-number"
                    className="col-md-4 col-form-label"
                  >
                    GST/VAT Number
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="gst-vat-number"
                      className="form-control"
                      placeholder="Enter GST/VAT number"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="gst-vat-certificate"
                    className="col-md-4 col-form-label"
                  >
                    Upload GST/VAT Certificate
                  </Label>
                  <Col md={8}>
                    <Input
                      type="file"
                      id="gst-vat-certificate"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="company-size"
                    className="col-md-4 col-form-label"
                  >
                    Company Size
                  </Label>
                  <Col md={8}>
                    <Dropdown
                      isOpen={companySizeDropdownOpen}
                      toggle={toggleCompanySizeDropdown}
                    >
                      <DropdownToggle className="custom-button" caret>
                        {selectedCompanySize}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("1-10 employees")
                          }
                        >
                          1-10 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("11-50 employees")
                          }
                        >
                          11-50 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("51-200 employees")
                          }
                        >
                          51-200 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("201-500 employees")
                          }
                        >
                          201-500 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("501-1000 employees")
                          }
                        >
                          501-1000 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("1001-5000 employees")
                          }
                        >
                          1001-5000 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("5001-10000 employees")
                          }
                        >
                          5001-10000 employees
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedCompanySize("10001+ employees")
                          }
                        >
                          10001+ employees
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label htmlFor="industry" className="col-md-4 col-form-label">
                    Industry
                  </Label>
                  <Col md={8}>
                    <Dropdown
                      isOpen={industryDropdownOpen}
                      toggle={toggleIndustryDropdown}
                    >
                      <DropdownToggle className="custom-button" caret>
                        {selectedIndustry}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            setSelectedIndustry(
                              "Technology, Information and Media",
                            )
                          }
                        >
                          Technology, Information and Media
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setSelectedIndustry("Manufacturing")}
                        >
                          Manufacturing
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedIndustry("Professional Services")
                          }
                        >
                          Professional Services
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedIndustry(
                              "Technology, Information and Internet",
                            )
                          }
                        >
                          Technology, Information and Internet
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setSelectedIndustry("Financial Services")
                          }
                        >
                          Financial Services
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>

                <h5 className="mt-4">Super Admin 1 Information</h5>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-1-first-name"
                    className="col-md-4 col-form-label"
                  >
                    First Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-1-first-name"
                      className="form-control"
                      placeholder="Enter first name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-1-middle-name"
                    className="col-md-4 col-form-label"
                  >
                    Middle Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-1-middle-name"
                      className="form-control"
                      placeholder="Enter middle name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-1-last-name"
                    className="col-md-4 col-form-label"
                  >
                    Last Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-1-last-name"
                      className="form-control"
                      placeholder="Enter last name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-1-contact"
                    className="col-md-4 col-form-label"
                  >
                    Contact Number
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-1-contact"
                      className="form-control"
                      placeholder="Enter contact number"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-1-email"
                    className="col-md-4 col-form-label"
                  >
                    Email ID
                  </Label>
                  <Col md={8}>
                    <Input
                      type="email"
                      id="super-admin-1-email"
                      className="form-control"
                      placeholder="Enter email ID"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-1-designation"
                    className="col-md-4 col-form-label"
                  >
                    Designation
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-1-designation"
                      className="form-control"
                      placeholder="Enter designation"
                    />
                  </Col>
                </Row>
                <h5 className="mt-4">Super Admin 2 Information</h5>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-2-first-name"
                    className="col-md-4 col-form-label"
                  >
                    First Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-2-first-name"
                      className="form-control"
                      placeholder="Enter first name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-2-middle-name"
                    className="col-md-4 col-form-label"
                  >
                    Middle Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-2-middle-name"
                      className="form-control"
                      placeholder="Enter middle name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-2-last-name"
                    className="col-md-4 col-form-label"
                  >
                    Last Name
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-2-last-name"
                      className="form-control"
                      placeholder="Enter last name"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-2-contact"
                    className="col-md-4 col-form-label"
                  >
                    Contact Number
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-2-contact"
                      className="form-control"
                      placeholder="Enter contact number"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-2-email"
                    className="col-md-4 col-form-label"
                  >
                    Email ID
                  </Label>
                  <Col md={8}>
                    <Input
                      type="email"
                      id="super-admin-2-email"
                      className="form-control"
                      placeholder="Enter email ID"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label
                    htmlFor="super-admin-2-designation"
                    className="col-md-4 col-form-label"
                  >
                    Designation
                  </Label>
                  <Col md={8}>
                    <Input
                      type="text"
                      id="super-admin-2-designation"
                      className="form-control"
                      placeholder="Enter designation"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={{ size: 8, offset: 4 }}>
                    <Button className="custom-button">Submit</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ClientCreation)
