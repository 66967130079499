import axios from "axios"

// const baseurl = "http://localhost:9001/api/v1" 
const baseurl = "https://tod-sqano-backend-gamma.vercel.app/api/v1"

// for demo
// const baseurl = "https://tod-sqano-backend-sachinsuryawanshi-sqano-3fbf1b51.vercel.app/api/v1"

const API = axios.create({
  baseURL: baseurl,
  withCredentials: true,
})

export const sendOtp = async email => await API.post("/send-otp", { email })

export const verifyOtp = async ({ email, emailOtp }) =>
  await API.post("/verify-otp", { email, emailOtp })

export const sendOtpSms = async ({ email, contactNo }) =>
  await API.post("/send-sms-otp", { email, contactNo })

export const verifyOtpSms = async ({ contactNo, smsOtp }) =>
  await API.post("/verify-sms-otp", { contactNo, smsOtp })

export const registerUser = async data => await API.post("/register", data)

export const loginApi = async credentials =>
  await API.post("/login", credentials)

export const logoutApi = async () => await API.post("/logout")

export const forgotPasswordApi = async email =>
  await API.post("/forgot-password", { email })

// Reset Password API
export const resetPasswordApi = async data =>
  await API.post("/reset-password", data)
//tod
export const jobCreation = formdata =>API.post("/createJobRequirement", formdata)
export const updateJobRequirement = (id,data) =>API.put(`/updateJobRequirement/${id}`,data)
export const getAllJobRequirements = () => API.get("/getAllJobRequirement")
export const getAllJobRequirementsByEntityId = async id => API.get(`/getAllJobRequirementByEntityId/${id}`)

export const getJobRequirementsById = async id => API.get(`/getJobRequirementById/${id}`)

export const postJobRequirement = ({jobId, userId}) => API.post(`/postJobRequirement/${jobId}`, {userId});

export const fetchCandidates = params =>
  API.get("/filterCandidates", { params })

export const uploadProfile = formData =>
  axios.post(
    "https://dz1cq183e4.execute-api.ap-south-1.amazonaws.com/dev/upload/extract/Profile",
    formData,
  )
export const scanAIResponse = data =>
  axios.post(
    "https://dz1cq183e4.execute-api.ap-south-1.amazonaws.com/dev/scan/ai/response",
    data,
  )
export const parseAIResponse = data =>
  axios.post(
    "https://dz1cq183e4.execute-api.ap-south-1.amazonaws.com/dev/open/ai/response",
    data,
  )
export const saveParsedData = data => API.post("/parseProfile", data)
export const getParsedProfileDataByUserId= id=>API.get(`getParsedProfileData/${id}`)
export const updateCandidateData = ({id,updatedData}) =>API.put(`/updateCandidateData/${id}`, updatedData)

export const shareProfiles = data => API.post("/shareProfiles", data)

export const createSearchApi = data => API.post("/insert-search", data)

export const getAllSearchRequirement = ({id, entityId, role}) => API.get(`/get-all-searches?id=${id}&entityId=${entityId}&role=${role}`)

export const getAssignedJobsByUserId = async id =>
  API.get(`/getAssignedJobsByUserId/${id}`)

//users
export const getAllUsers = () => API.get("/getAllUsers")
export const getUsersByEntityId = id => API.get(`/getUserByEntityId/${id}`)

//
// export const fetchCandidatesList = async filters => {
//   const response = await API.get("/getCandidateList", { params: filters })
//   return response
// }

// Candidates
export const fetchCandidatesList = async filters => {
  const response = await API.get("/getCandidateList", { params: filters })
  return response
}

export const filterCandidateData = async filterCriteria => {
  const response = await API.get("/filterCandidates", {
    params: filterCriteria,
  })
  return response
}

export const insertWorkFlow = async data =>
  API.post("/workflows/insertMany", data)

export const getAllWorkFlows = async => API.get("/getAllWorkFlows")
export const getAllWorkFlowsById = async id => API.get(`/getAllWorkFlowsById/${id}`)

// export const updateWorkFlowWithStatus = async (id, data) =>
//   API.put(`/updateWorkFlowWithStatus/${id}`, data)

export const updateWorkFlow = async (id, data) =>
  API.put(`/updateWorkFlow/${id}`, data)

export const createVendorWithSuperAdmins = async vendorData =>
  API.post("/vendors/createVendor", vendorData) 

export const createVendorProfile = async formData => {
  console.log("formdata", formData)
  const res = await API.post("/vendors/createVendorProfile", formData)
  console.log("apicall", res)
}

export const getAllVendors = async () => API.get("/vendors/getAllVendors")

export const getVendorById = async id => API.get(`/vendors/getVendorById/${id}`)

export const fetchRoles = async entityId => API.get(`/getAllRoles/${entityId}`)

export const createRole = async roleData => await API.post("/createRole", roleData);

export const getSuperAdminById = async id =>
  API.get(`/vendors/getSuperAdminById/${id}`) 

export const updateVendor = async (id, vendorData) =>
  API.put(`/vendors/updateVendor/${id}`, vendorData)
export const updateSuperAdmin = async (id, formData) => {
  API.put(`/vendors/updateSuperAdmin/${id}`, formData)
}

export const deleteVendor = async id =>
  API.delete(`/vendors/deleteVendor/${id}`)

// Vendor User APIs
export const createVendorUser = async data => 
  API.post("/createVendorUser", data)


export const updateVendorUser = async ({id,userData}) => API.put(`/updateVendorUser/${id}`, userData)
export const getVendorUsers = async () => API.get("/getVendorUsers")
export const getVendorUserById = async id => API.get(`/getVendorUserById/${id}`)
export const getVendorUsersByEntityId = async id => API.get(`/getVendorUserByEntityId/${id}`)
export const getUsersByCreatedBy = async id => API.get(`/getUserByCreatedBy/${id}`)
export const deleteVendorUserById = async id =>
  API.delete(`/deleteVendorUserById/${id}`)
export const getRoleById = async id => API.get(`/getRoleById/${id}`)
export const getAllRoles = async () => API.get("/getAllRoles")
export const getAllFeatures = async () => API.get("/getAllFeatures")
export const getAllCounts = async data=> API.get(`/getAllCounts/${data.id}/${data.entityId}`)

