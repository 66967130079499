import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Checkbox, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from 'components/CustomComponents/CustomBreadCrumb';
import { createRoleRequest } from 'store/actions';


const CreateRole = () => {
  const dispatch = useDispatch()
  const { userDetails } = useSelector((state) => state.Login?.user);
  const [roleName, setRoleName] = useState('');
  const [features, setFeatures] = useState([]);
  console.log("features", features)

  const generatePayload = (features) => {
    return {
      name: roleName,
      entityId: userDetails?.entityId,
      features: features.filter(f => f.isChecked).map(f => ({
        feature: f._id,
        permissions: f.permissions.filter(p => p.isChecked).map(p => p.id._id), 
        subFeatures: f.subFeatures.filter(sf => sf.isChecked).map(sf => ({
          feature: sf._id,
          permissions: sf.permissions.filter(p => p.isChecked).map(p => p.id._id) 
        }))
      })),
      createdBy: userDetails?.id
    };
  };

  // const generatePayload = (features) => {
  //   return {
  //     name,
  //     features: features.filter(f => f.isChecked).map(f => ({
  //       feature: f._id,
  //       // Check if the feature is 'PRODUCT' and include all permissions if it is checked
  //       permissions: f.name === "PRODUCT" ? 
  //                    f.permissions.map(perm => perm.id) :  // Include all permissions if 'PRODUCT'
  //                    f.permissions.filter(p => p.isChecked).map(p => p.id), // Only checked permissions otherwise
  //       subFeatures: f.subFeatures.filter(sf => sf.isChecked).map(sf => ({
  //         feature: sf._id,
  //         permissions: sf.permissions.filter(p => p.isChecked).map(p => p.id)
  //       }))
  //     })),
  //     createdBy: "66a287291a6de794e03ac9b5"  // Assuming this is your user ID
  //   };
  // };
  
  
  

  
  // const generatePayload = (features) => {
  //   return features.filter(feature => feature.isChecked).map(feature => ({
  //     ...feature,
  //     permissions: feature.permissions.filter(perm => perm.isChecked).map(perm => perm.id),
  //     subFeatures: feature.subFeatures.map(subFeature => ({
  //       ...subFeature,
  //       permissions: subFeature.permissions.filter(perm => perm.isChecked).map(perm => perm.id)
  //     })).filter(subFeature => subFeature.isChecked)
  //   }));
  // };
  
  useEffect(() => {
        axios.get('https://tod-sqano-backend-gamma.vercel.app/api/v1/getAllFeatures')
          .then(response => {
            // Filter and create a manual structure for "PRODUCT" with specific sub-features
            const productSubFeatures = ["SQANIT", "PARSEIT", "CIVIX", "TOD", "WEBIT", "SQALEUP", "MOCK TEST", "CV TEMPLATES"];
            const accessSubFeatures = ["ROLES"];
            const subFeaturesData = response.data.filter(item => productSubFeatures.includes(item.name));
            const subAccessFeaturesData = response.data.filter(item => accessSubFeatures.includes(item.name));
            const featuresWithChecks = response.data.map(feature => {
              if (feature.name === "PRODUCT") {
                return {
                  ...feature,
                  isChecked: false,
                  permissions: feature.permissions.map(perm => ({ id: perm, isChecked: false })),
                  subFeatures: subFeaturesData.map(sub => ({
                    ...sub,
                    isChecked: false,
                    permissions: sub.permissions.map(perm => ({ id: perm, isChecked: false }))
                  }))
                };
              } else if (feature.name === "ACCESS"){
                return {
                  ...feature,
                  isChecked: false,
                  permissions: feature.permissions.map(perm => ({ id: perm, isChecked: false })),
                  subFeatures: subAccessFeaturesData.map(sub => ({
                    ...sub,
                    isChecked: false,
                    permissions: sub.permissions.map(perm => ({ id: perm, isChecked: false }))
                  }))
                };
              } else if (!productSubFeatures.includes(feature.name) && !accessSubFeatures.includes(feature.name)) {  // Exclude sub-features from main list
                return {
                  ...feature,
                  isChecked: false,
                  permissions: feature.permissions.map(perm => ({ id: perm, isChecked: false })),
                  subFeatures: []
                };
              }
              return null;  // Filter out individual entries for "ScanIT", "ParseIT", "Civix", "Tod"
            }).filter(feature => feature !== null); // Remove null entries from the array
    
            setFeatures(featuresWithChecks);
          })
          .catch(error => console.error('Failed to fetch features:', error));
      }, []);
  

  // Recursive function to set isChecked to false for all nested elements
  const uncheckChildren = (feature) => {
    const updatedPermissions = feature.permissions.map(perm => ({ ...perm, isChecked: false }));
    const updatedSubFeatures = feature.subFeatures?.map(subFeature => {
      return { ...subFeature, isChecked: false, permissions: subFeature.permissions.map(perm => ({ ...perm, isChecked: false })) };
    });
    return { ...feature, permissions: updatedPermissions, subFeatures: updatedSubFeatures };
  };

  const handleFeatureCheck = (id, type, parentId) => {
    setFeatures(prevFeatures => prevFeatures.map(feature => {
      if (type === 'feature' && feature._id === id) {
        const updatedFeature = { ...feature, isChecked: !feature.isChecked };
        return feature.isChecked ? uncheckChildren(updatedFeature) : updatedFeature;
      } else if (type === 'subFeature' && feature._id === parentId) {
        const updatedSubFeatures = feature.subFeatures.map(sub => {
          if (sub._id === id) {
            const updatedSubFeature = { ...sub, isChecked: !sub.isChecked };
            return sub.isChecked ? uncheckChildren(updatedSubFeature) : updatedSubFeature;
          }
          return sub;
        });
        return { ...feature, subFeatures: updatedSubFeatures };
      }
      return feature;
    }));
  };
  

  const handlePermissionCheck = (permId, parentId, isSubFeature = false) => {
    setFeatures(prevFeatures => prevFeatures.map(feature => {
      if (!isSubFeature && feature._id === parentId) {
        return {
          ...feature,
          permissions: feature.permissions.map(perm => perm.id === permId ? {...perm, isChecked: !perm.isChecked} : perm)
        };
      } else if (isSubFeature) {
        const updatedSubFeatures = feature.subFeatures.map(sub => {
          if (sub._id === parentId) {
            return {
              ...sub,
              permissions: sub.permissions.map(perm => perm.id === permId ? {...perm, isChecked: !perm.isChecked} : perm)
            };
          }
          return sub;
        });
        return { ...feature, subFeatures: updatedSubFeatures };
      }
      return feature;
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Generate the payload from checked features
    const payload = generatePayload(features);
    console.log(payload); // For demonstration purposes
    dispatch(createRoleRequest(payload))
  };

  const breadcrumbItems = [
    { label: "Roles", href: "/roles" },
    { label: "Create Role", href: "/create-role" },
  ];

  return (
    <>
       <CustomBreadcrumb items={breadcrumbItems} />
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" component="div" sx={{ mb: 2 }}>
        Enter Role Name
      </Typography>
      <TextField
      sx={{ 
        width: '50%', 
        mb: 2,
      }}
        label="Role Name" 
        variant="outlined"
        value={roleName}
        onChange={(e) => setRoleName(e.target.value)}
        required
        size='small'
      />
      {features.map(feature => (
        <FeatureItem feature={feature} handleFeatureCheck={handleFeatureCheck} handlePermissionCheck={handlePermissionCheck} />
      ))}
      <Box sx={{ display: 'flex', width: '50%', justifyContent: 'flex-end', mb: 2 }}>
        <Button variant="outlined" type="submit" sx={{backgroundColor:"#660032", color:"#ffffff"}}>Create Role</Button>
      </Box>
    </form>
    </>
  );
};

export default CreateRole;


const FeatureItem = ({ feature, handleFeatureCheck, handlePermissionCheck }) => {
  return (
    <div key={feature._id}>
      <label>
        <Checkbox
          checked={feature.isChecked}
          onChange={() => handleFeatureCheck(feature._id, 'feature')}
        />
        {feature.name}
      </label>
      {feature.isChecked && (
        <div style={{ marginLeft: '20px' }}>
          {feature.subFeatures && feature.subFeatures.length > 0 ? (
            feature.subFeatures.map(subFeature => (
              <div key={subFeature._id}>
                <label>
                  <Checkbox
                    checked={subFeature.isChecked}
                    onChange={() => handleFeatureCheck(subFeature._id, 'subFeature', feature._id)}
                  />
                  {subFeature.name}
                </label>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                  {subFeature.isChecked && subFeature.permissions.map(perm => (
                    <div key={perm.id} style={{ flex: '0 0 25%', padding: '5px', boxSizing: 'border-box', marginLeft: '20px' }}>
                      <label>
                        <Checkbox
                          checked={perm.isChecked}
                          onChange={() => handlePermissionCheck(perm.id, subFeature._id, true)}
                        />
                        {perm.id.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
              {feature.permissions.map(perm => (
                <div key={perm.id} style={{ flex: '0 0 25%', padding: '5px', boxSizing: 'border-box' }}>
                  <label>
                    <Checkbox
                      checked={perm.isChecked}
                      onChange={() => handlePermissionCheck(perm.id, feature._id, false)}
                    />
                    {perm.id.name}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const CreateRole = () => {
//   const [name, setName] = useState('');
//   const [features, setFeatures] = useState([]);
//   console.log("fae", features)

//   useEffect(() => {
//     axios.get('http://localhost:9001/api/v1/getAllFeatures')
//       .then(response => {
//         // Filter and create a manual structure for "PRODUCT" with specific sub-features
//         const productSubFeatures = ["SQANIT", "PARSEIT", "CIVIX", "TOD", "WEBIT", "SQALEUP", "MOCK TEST", "CV TEMPLATES"];
//         const subFeaturesData = response.data.filter(item => productSubFeatures.includes(item.name));
//         const featuresWithChecks = response.data.map(feature => {
//           if (feature.name === "PRODUCT") {
//             return {
//               ...feature,
//               isChecked: false,
//               permissions: feature.permissions.map(perm => ({ id: perm, isChecked: false })),
//               subFeatures: subFeaturesData.map(sub => ({
//                 ...sub,
//                 isChecked: false,
//                 permissions: sub.permissions.map(perm => ({ id: perm, isChecked: false }))
//               }))
//             };
//           } else if (!productSubFeatures.includes(feature.name)) {  // Exclude sub-features from main list
//             return {
//               ...feature,
//               isChecked: false,
//               permissions: feature.permissions.map(perm => ({ id: perm, isChecked: false })),
//               subFeatures: []
//             };
//           }
//           return null;  // Filter out individual entries for "ScanIT", "ParseIT", "Civix", "Tod"
//         }).filter(feature => feature !== null); // Remove null entries from the array

//         setFeatures(featuresWithChecks);
//       })
//       .catch(error => console.error('Failed to fetch features:', error));
//   }, []);


//   const handleFeatureCheck = (id, type, parentId) => {
//     setFeatures(prevFeatures => prevFeatures.map(feature => {
//       if (type === 'feature' && feature._id === id) {
//         return { ...feature, isChecked: !feature.isChecked };
//       } else if (type === 'subFeature' && feature._id === parentId) {
//         const updatedSubFeatures = feature.subFeatures.map(sub => {
//           if (sub._id === id) {
//             return { ...sub, isChecked: !sub.isChecked };
//           }
//           return sub;
//         });
//         return { ...feature, subFeatures: updatedSubFeatures };
//       }
//       return feature;
//     }));
//   };

//   const handlePermissionCheck = (permId, parentId, isSubFeature = false) => {
//     setFeatures(prevFeatures => prevFeatures.map(feature => {
//       if (!isSubFeature && feature._id === parentId) {
//         // Handle permission toggling for main features
//         return {
//           ...feature,
//           permissions: feature.permissions.map(perm => perm.id === permId ? {...perm, isChecked: !perm.isChecked} : perm)
//         };
//       } else if (isSubFeature && feature.subFeatures) {
//         // Handle permission toggling for sub-features
//         return {
//           ...feature,
//           subFeatures: feature.subFeatures.map(sub => {
//             if (sub._id === parentId) {
//               return {
//                 ...sub,
//                 permissions: sub.permissions.map(perm => perm.id === permId ? {...perm, isChecked: !perm.isChecked} : perm)
//               };
//             }
//             return sub;
//           })
//         };
//       }
//       return feature;
//     }));
// };

  

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Prepare payload filtering out unchecked items
//     const payload = features.filter(f => f.isChecked).map(f => ({
//       ...f,
//       permissions: f.permissions.filter(p => p.isChecked).map(p => p.id),
//       subFeatures: f.subFeatures.map(sf => ({
//         ...sf,
//         permissions: sf.permissions.filter(p => p.isChecked).map(p => p.id)
//       }))
//     }));
//     console.log(payload); // Send this payload to your API
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Role Name:
//         <input type="text" value={name} onChange={e => setName(e.target.value)} />
//       </label>
//       {features.map(feature => (
//         <div key={feature._id}>
//           <label>
//             <input
//               type="checkbox"
//               checked={feature.isChecked}
//               onChange={() => handleFeatureCheck(feature._id, 'feature')}
//             />
//             {feature.name}
//           </label>
//           {feature.isChecked && (
//             <div style={{ marginLeft: '20px' }}>
//               {feature.subFeatures && feature.subFeatures.length > 0 ? (
//                 feature.subFeatures.map(subFeature => (
//                   <div key={subFeature._id}>
//                     <label>
//                       <input
//                         type="checkbox"
//                         checked={subFeature.isChecked}
//                         onChange={() => handleFeatureCheck(subFeature._id, 'subFeature', feature._id)}
//                       />
//                       {subFeature.name}
//                     </label>
//                     <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
//                     {subFeature.isChecked && subFeature.permissions.map(perm => (
//                       <div key={perm.id} style={{ flex: '0 0 25%', padding: '5px', boxSizing: 'border-box', marginLeft: '20px' }}>
//                         <label>
//                           <input
//                             type="checkbox"
//                             checked={perm.isChecked}
//                             onChange={() => handlePermissionCheck(perm.id, subFeature._id, true)}
//                           />
//                           {perm.id.name}
//                         </label>
//                       </div>
//                     ))}
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
//                 {feature.permissions.map(perm => (
//                   <div key={perm.id} style={{ flex: '0 0 25%', padding: '5px', boxSizing: 'border-box' }}>
//                     <label>
//                       <input
//                         type="checkbox"
//                         checked={perm.isChecked}
//                         onChange={() => handlePermissionCheck(perm.id, feature._id, false)}
//                       />
//                       {perm.id.name}
//                     </label>
//                   </div>
//                 ) )}</div>
//               )}
//             </div>
//           )}
//         </div>
//       ))}
//       <button type="submit">Create Role</button>
//     </form>
//   );
  
// };

// export default CreateRole;
