// src\store\client\product\Civix\actions.js
import {
  FETCH_CV_ACCESS_CANDIDATES_REQUEST,
  FETCH_CV_ACCESS_CANDIDATES_SUCCESS,
  FETCH_CV_ACCESS_CANDIDATES_FAILURE,
  FILTER_CV_ACCESS_CANDIDATES_REQUEST,
  FILTER_CV_ACCESS_CANDIDATES_SUCCESS,
  FILTER_CV_ACCESS_CANDIDATES_FAILURE,
} from "./actionTypes"

export const fetchCvAccessCandidatesRequest = () => {
  // console.log("fetchCandidatesRequest action------------")
  return {
    type: FETCH_CV_ACCESS_CANDIDATES_REQUEST,
  }
}

export const fetchCvAccessCandidatesSuccess = candidates => {
  // console.log(
  //   "fetchCandidatesSuccess action called with candidates:",
  //   candidates,
  // )
  return {
    type: FETCH_CV_ACCESS_CANDIDATES_SUCCESS,
    payload: candidates.data,
  }
}

export const fetchCvAccessCandidatesFailure = error => {
  // console.log("fetchCandidatesFailure action called with error:", error)
  return {
    type: FETCH_CV_ACCESS_CANDIDATES_FAILURE,
    payload: error,
  }
}

export const filterCvAccessCandidatesRequest = filterCriteria => {
  // console.log(
  //   "filterCandidatesRequest action called with criteria:",
  //   filterCriteria,
  // )
  return {
    type: FILTER_CV_ACCESS_CANDIDATES_REQUEST,
    payload: filterCriteria,
  }
}

export const filterCvAccessCandidatesSuccess = candidates => {
  // console.log(
  //   "filterCandidatesSuccess action called with candidates:",
  //   candidates,
  // )
  return {
    type: FILTER_CV_ACCESS_CANDIDATES_SUCCESS,
    payload: candidates.data,
  }
}

export const filterCvAccessCandidatesFailure = error => {
  // console.log("filterCandidatesFailure action called with error:", error)
  return {
    type: FILTER_CV_ACCESS_CANDIDATES_FAILURE,
    payload: error,
  }
}
