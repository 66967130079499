import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
  },
};

const closeButtonStyle = {
  position: "absolute",
  top: 0,
  right: 0,
};

const CustomModal = ({ open, onClose, modalTitle, modalContent }) => {
  const [modalDimensions, setModalDimensions] = useState({
    width: "90%",
    height: "100%",
  });

  const modalContentRef = useRef(null);

  useEffect(() => {
    // Calculate and set the dimensions of the modal after rendering
    if (modalContentRef.current) {
      const { scrollWidth, scrollHeight } = modalContentRef.current;
      const maxWidth = window.innerWidth * 0.9; // 90% of the viewport width
      const maxHeight = window.innerHeight * 0.9; // 90% of the viewport height
      const width = Math.min(maxWidth, scrollWidth);
      const height = Math.min(maxHeight, scrollHeight);
      setModalDimensions({ width, height });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ width: modalDimensions.width, height: modalDimensions.height }}>
        <IconButton sx={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <h3>{modalTitle}</h3>
        <div className="modalBody" ref={modalContentRef}>
          {modalContent}
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
