import React from 'react';
import './GeneratePdfFooter.css'

const GeneratePdfFooter = () =>{
    return (
        <div className="generatepdf-footer">
        <p>FALQON Systems Limited. | All rights reserved.</p>
      </div>
    )
}
export default GeneratePdfFooter