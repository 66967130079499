import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import "./superAdmin.scss"

function FilterBtn() {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Box className="filter-container">
      <Button 
        variant="contained" 
        className="filter-button" 
        onClick={toggleFilters}
      >
        Add Filters
      </Button>
      {showFilters && (
        <Box className="filter-options">
          <TextField 
            label="Client ID" 
            variant="outlined" 
            size="small" 
            className="text-field" 
            InputProps={{ classes: { input: 'text-field' } }}
            InputLabelProps={{ classes: { root: 'text-field' } }}
          />
          <TextField 
            label="Client Name" 
            variant="outlined" 
            size="small" 
            className="text-field" 
            InputProps={{ classes: { input: 'text-field' } }}
            InputLabelProps={{ classes: { root: 'text-field' } }}
          />
          <TextField 
            label="Email ID" 
            variant="outlined" 
            size="small" 
            className="text-field" 
            InputProps={{ classes: { input: 'text-field' } }}
            InputLabelProps={{ classes: { root: 'text-field' } }}
          />
          <TextField 
            label="Client No." 
            variant="outlined" 
            size="small" 
            className="text-field" 
            InputProps={{ classes: { input: 'text-field' } }}
            InputLabelProps={{ classes: { root: 'text-field' } }}
          />
          <TextField 
            label="Location." 
            variant="outlined" 
            size="small" 
            className="text-field" 
            InputProps={{ classes: { input: 'text-field' } }}
            InputLabelProps={{ classes: { root: 'text-field' } }}
          />
        </Box>
      )}
      
    </Box>
  );
}

export default FilterBtn;
