import React, { useState } from "react"
import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Paper,
  Select,
  FormControl,
  InputLabel,
  Dialog, DialogTitle, DialogContent, DialogActions,IconButton,
  Checkbox,
  FormControlLabel,
  Link as MuiLink,
  Button as MuiButton
} from "@mui/material"
import {
  FormFeedback,
  Input,
  Label,
  FormGroup,
  Button as ReactstrapButton,
} from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import "./Setting.scss"
import lprofile from "../../../assets/images/K-linkdlin.png"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import verificationimg1 from "../../../assets/images/padlock.png"
import verificationimg2 from "../../../assets/images/pc-tower.png"

function Setting() {
  const [activeTab, setActiveTab] = useState("accountpref")
  const [selectedItem, setSelectedItem] = useState(null)
  const [gender, setGender] = useState("")
  const [disability, setDisability] = useState("")
  const [language, setLanguage] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');

  const handleClickOpen = (type) => {
    setDialogType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetupClick = () => {
    setStep(2);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!code) {
      setError(true);
    } else {
      // Handle code submission logic here
      console.log('Verification code submitted:', code);
      setError(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      requireSignIn: false
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Current password is required'),
      newPassword: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .required('New password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: values => {
      console.log('Form data', values);
      // Handle form submission
    }
  });

  const handleLanguageChange = event => {
    setLanguage(event.target.value)
  }
  const handleCountryChange = event => {
    setCountry(event.target.value)
  }

  const handleCityChange = event => {
    setCity(event.target.value)
  }

  const handleGenderChange = event => {
    setGender(event.target.value)
  }

  const handleDisabilityChange = event => {
    setDisability(event.target.value)
  }

  const profileItems = [
    "Name, location, and industry",
    "Personal demographic information",
    "Linkdlin Profile",
  ]

  const generalPreferencesItems = [
    "Language",
    "Showing profile photos",
    "Feed preferences",
  ]

  const subscriptionsPaymentsItems = [
    "Upgrade for Free",
    "View purchase history",
  ]

  const partnersServicesItems = ["Microsoft"]

  const accountManagementItems = ["Hibernate account", "Close account"]

  const accountAccessItems = [
    "Email addresses",
    "Phone numbers",
    "Change password",
    "Passkeys",
    "Where you're signed in",
    "Two-step verification",
  ]

  const getProfileContent = item => {
    switch (item) {
      case "Name, location, and industry":
        return (
          <div className="setting-box">
            <Box className="edit-intro-container setting-form-heading">
              <h3 style={{ marginBottom: "20px" }}> Edit intro</h3>

              <form>
                <TextField
                  required
                  label="First name"
                  defaultValue="Enter Your Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  required
                  label="Last name"
                  defaultValue="Enter Your Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Location
                </Typography>

                <TextField
                  required
                  label="Country/Region"
                  defaultValue="India"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={country}
                  onChange={handleCountryChange}
                />

                <TextField
                  required
                  label="City"
                  defaultValue="Pune, Maharashtra"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={city}
                  onChange={handleCityChange}
                />

                <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Contact info{" "}
                </Typography>

                <MuiLink
                  href="#"
                  style={{ display: "block", marginBottom: "20px" }}
                >
                  Edit contact info{" "}
                </MuiLink>

                <MuiButton
                  variant="contained"
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#660032",
                    color: "#fff",
                  }}
                >
                  Save{" "}
                </MuiButton>
              </form>
            </Box>
          </div>
        )
      case "Personal demographic information":
        return (
          <div>
            <Box className="setting-form-heading mt-4">
              <h3>Demographic info</h3>
              <Typography variant="body2" style={{ marginBottom: "10px" }}>
                Here’s the information you’ve provided about yourself. This will
                not be displayed on your profile.
                <br />
                You can always <MuiLink href="#">remove</MuiLink> all personal demographic
                data submitted in these categories.
              </Typography>

              <FormControl fullWidth margin="normal">
                <InputLabel>Gender</InputLabel>
                <Select
                  value={gender}
                  onChange={handleGenderChange}
                  label="Gender"
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>

              <p className="mb-2">
                Do you have a disability that substantially limits a major life
                activity, or a history of a disability?
              </p>
              <FormControl fullWidth margin="normal">
                <InputLabel>Disability</InputLabel>
                <Select
                  value={disability}
                  onChange={handleDisabilityChange}
                  label="Disability"
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>

              <Paper variant="outlined" className="personal-info-box">
                <h4 variant="body2">How LinkedIn uses this data</h4>
                <Typography variant="body2">
                  Your demographic data will not be shown on your profile. It
                  will be used to provide aggregated workforce and salary
                  insights to you and others, to feature news, ads and other
                  content personalized for you, and to provide tools and
                  insights to help employers reach a diverse talent pool.{" "}
                  <MuiLink href="#">Learn more</MuiLink>
                </Typography>
              </Paper>

              <MuiButton
                variant="contained"
                className="personal-info-button"
                style={{
                  marginTop: "20px",
                  backgroundColor: "#660032",
                  color: "#fff",
                }}
              >
                Agree and save
              </MuiButton>
            </Box>
          </div>
        )
      case "Linkdlin Profile":
        return (
          <div>
            <img src={lprofile} alt="profile" />
          </div>
        )
      default:
        return <div>Select an item to see details</div>
    }
  }

  const getGeneralPreferencesContent = item => {
    switch (item) {
      case "Language":
        return (
          <div>
            <Box className="language-selection-container setting-form-heading">
              <h3>Language</h3>
              <Typography variant="body2" style={{ marginBottom: "10px" }}>
                Select the language you use on LinkedIn
              </Typography>

              <FormControl fullWidth margin="normal">
                <InputLabel>Language</InputLabel>
                <Select
                  value={language}
                  onChange={handleLanguageChange}
                  label="Language"
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value="English">English (English)</MenuItem>
                  <MenuItem value="Spanish">Spanish (Español)</MenuItem>
                  <MenuItem value="French">French (Français)</MenuItem>
                  {/* Add more languages as needed */}
                </Select>
              </FormControl>

              <Typography variant="body2" style={{ marginTop: "20px" }}>
                Let us know which language you’re most comfortable using on
                LinkedIn. You can change it back at any time.{" "}
                <MuiLink href="#">Learn more</MuiLink>
              </Typography>
            </Box>
          </div>
        )
      case "Showing profile photos":
        return <div>Content for Showing profile photos</div>
      case "Feed preferences":
        return <div>Content for Feed preferences</div>
      case "People you unfollowed":
        return <div>Content for People you unfollowed</div>
      default:
        return <div>Select an item to see details</div>
    }
  }

  const getSubscriptionsPaymentsContent = item => {
    switch (item) {
      case "Upgrade for Free":
        return <div>Content for Upgrade for Free</div>
      case "View purchase history":
        return <div>Content for View purchase history</div>
      default:
        return <div>Select an item to see details</div>
    }
  }

  const getPartnersServicesContent = item => {
    switch (item) {
      case "Microsoft":
        return <div>Content for Microsoft</div>
      default:
        return <div>Select an item to see details</div>
    }
  }

  const getAccountManagementContent = item => {
    switch (item) {
      case "Hibernate account":
        return <div>Content for Hibernate account</div>
      case "Close account":
        return <div>Content for Close account</div>
      default:
        return <div>Select an item to see details</div>
    }
  }

  const getAccountAccessContent = item => {
    switch (item) {
      case "Email addresses":
        return (
          <div className="setting-box">
            <p>Emails you've added</p>
            <div>
              <h5>
                <strong>Primary email</strong>
              </h5>
              <p>komal@ample.com</p>
            </div>
            <div>
              <strong>Secondary email</strong>
              <div className="manage-email">
                <p>komalpaygude97@gmail.com</p>
                <div>
                  <MuiButton className="border-btn">Make primary</MuiButton>
                  <MuiButton className="border-btn">Remove</MuiButton>
                </div>
              </div>
            </div>
            <div>
              <MuiButton className="common-btn">Add email address</MuiButton>
            </div>
          </div>
        )
      case "Phone numbers":
        return (
          <div className="setting-box">
            
            <p>Phone numbers you've added</p>
            <p>These won't be displayed on your profile.</p>

            <div className="manage-email">
              <p> IN +91 8208250626</p>
              <div>
                <MuiButton className="border-btn" onClick={() => handleClickOpen('primary')}>Make primary</MuiButton>
                <MuiButton className="border-btn" onClick={() => handleClickOpen('remove')}>Remove</MuiButton>
              </div>
            </div>
            <h6>Use for resetting password</h6>
            <p>
              If selected, you'll be able to use this number to reset your
              password
            </p>
            <p>
              Your phone number helps us keep your account secure by adding an
              additional layer of verification. It also helps others, who
              already have your phone number, discover and connect with you. You
              can always decide how you want your phone number used. Learn more
            </p>
              <div className="btn btn-setup" onClick={() => handleClickOpen('add-phone')} >Add Phone Number</div>
              
              <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
        {dialogType === 'add-phone' ? 'We sent a code to your email' : 'Enter Password'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
        {dialogType === 'primary' && <p>Enter your password to make this the primary number</p>}
          {dialogType === 'remove' && <p>Enter your password to remove this number</p>}
          {dialogType === 'add-phone' && (
            <>
          <p>Enter the 6-digit verification code sent to komal@tunoes.com</p>
              <TextField
                autoFocus
                margin="dense"
                id="code"
                label="6-digit code"
                type="text"
                fullWidth
                variant="outlined"
                required
              />
            </>
          )}
         {dialogType !== 'add-phone' && (
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              required
            />
          )}
        </DialogContent>
        <DialogActions>
          {dialogType === 'primary' && (
            <MuiButton  className="btn-setup" variant="contained" disabled>
              Make primary
            </MuiButton>
          )}
          {dialogType === 'remove' && (
            <MuiButton className="btn-setup" variant="contained" disabled>
              Remove
            </MuiButton>
          )}
          {dialogType === 'add-phone' && (
            <MuiButton className="btn-setup" variant="contained" onClick={handleClose}>
              Submit
            </MuiButton>
          )}
         {dialogType !== 'add-phone' && (
            <MuiButton className="border-btn" variant="text" onClick={handleClose}>
              Forgot password
            </MuiButton>
          )}
        </DialogActions>
        {dialogType === 'add-phone' && (
          <p>If you don't see the email in your inbox, check your spam folder.</p>
        )}
      </Dialog>
          </div>
        )
      case "Change password":
        return (
          <div> 
                  <div className="setting-box">
                    {/* <ReactstrapButton className="mb-3" color="link">&lt; Back</ReactstrapButton> */}
                   
                    <p>Create a new password that is at least 8 characters long.</p>
                    <form onSubmit={formik.handleSubmit}>
                      <FormGroup>
                        <Label for="currentPassword">Type your current password*</Label>
                        <Input
                          type="password"
                          id="currentPassword"
                          name="currentPassword"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.currentPassword}
                          invalid={formik.touched.currentPassword && formik.errors.currentPassword ? true : false}
                        />
                        <FormFeedback>{formik.errors.currentPassword}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="newPassword">Type your new password*</Label>
                        <Input
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.newPassword}
                          invalid={formik.touched.newPassword && formik.errors.newPassword ? true : false}
                        />
                        <FormFeedback>{formik.errors.newPassword}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="confirmPassword">Retype your new password*</Label>
                        <Input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                          invalid={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                        />
                        <FormFeedback>{formik.errors.confirmPassword}</FormFeedback>
                      </FormGroup>

                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            id="requireSignIn"
                            name="requireSignIn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.requireSignIn}
                          />{' '}
                          Require all devices to sign in with new password
                        </Label>
                      </FormGroup>

                      <ReactstrapButton type="submit" style={{backgroundColor:"#660032",
                       color:"#ffffff", border:"none"}} 
                       className="w-100 btn-color-style" disabled={!formik.isValid || formik.isSubmitting}>
                        Save password
                      </ReactstrapButton>
                    </form>
                    <div className="mt-3 text-center">
                      <ReactstrapButton color="#660032" className="p-0">Forgot password</ReactstrapButton>
                    </div>
                  </div>
          </div>
        )
      case "Passkeys":
        return (
          <div className="setting-box">
            <p>Passkeys</p>
            <p>
              With passkeys you don’t need to remember complex passwords. We
              recommend using your device’s unlock methods like fingerprint and
              Face ID for added security.
            </p>
            <p>To know more about adding and removing passkeys, click here.</p>

            <div>
              <MuiButton className="common-btn" onClick={handleClickOpen}>Create a passkey</MuiButton>
            </div>
            <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Enter Password
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <p>Enter your password to add a new passkey</p>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            required
          />
        </DialogContent>
        <DialogActions>
          <div className="btn btn-color-style" variant="contained" disabled>
            Create a passkey
          </div>
          <div className="btn btn-color-style" variant="outlined" onClick={handleClose}>
            Forgot password
          </div>
        </DialogActions>
      </Dialog>
          </div>
        )
      case "Where you're signed in":
        return (
          <div className="setting-box">
            <div className="sign-wrap">
              <p>Active sessions</p>
              <p>
                The locations listed below are an estimate of where the IP
                address may be located within your country, region and city. The
                accuracy of the look-up varies by providers and the location of
                the device. This should only be used as a rough guideline.
              </p>

              <h6>You're signed in to 2 sessions</h6>
              <h6 className="text-size">Current Session</h6>
              <h6 className="text-size">Details</h6>
              <p>Gurgaon, Haryana, India (Approximate location)</p>
              <div className="sign-wrap-inner">
                <h6>
                  IP Address:
                  <br />
                  2401:4900:57bb:7ee6:553d:d0fc:3c39:107a
                  <br />
                  IP Address Owner:
                  <br />
                  Bharti Airtel Limited
                </h6>
              </div>
            </div>

            <div className="sign-wrap">
              <h6 className="text-size">Other active sessions</h6>
              <h6 className="text-size">Last accessed</h6>
              <p>Gurgaon, Haryana, India (Approximate location)</p>
              <div className="sign-wrap-inner">
                <h6>
                  IP Address:
                  <br />
                  2401:4900:57bb:7ee6:553d:d0fc:3c39:107a
                  <br />
                  IP Address Owner:
                  <br />
                  Bharti Airtel Limited
                </h6>
              </div>
            </div>

            <div>
              <MuiButton className="common-btn">Add Phone Number</MuiButton>
            </div>
          </div>
        )
      // case "Devices that remember your password":
      //   return <div>Devices that remember your password</div>
      case "Two-step verification":
        return (
        <div className="setting-box">
           {step === 1 && (
            <div style={{ padding: '10px' }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Two-step verification
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Secure your account with two-step verification.
                </Typography>
                <Box display="flex" alignItems="center" marginBottom="20px">
                  <img src={verificationimg1} alt="icon" style={{ marginRight: '10px', width:'40px' }} />
                  <Typography variant="body2">
                    Two step verification gives you additional security by requiring a verification code whenever you sign in on a new device. <a href="#">Learn more</a>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginBottom="20px">
                  <img src={verificationimg2} alt="icon" style={{ marginRight: '10px', width:'40px'}} />
                  <Typography variant="body2">
                    Your phone number or Authenticator App helps us keep your account secure by adding an additional layer of verification. Your phone number also helps others, who already have your phone number,
                    discover and connect with you. You can always decide how you want your phone number used.
                      <a href="#">Learn more</a>
                  </Typography>
                </Box>
                <div className="btn btn-setup" onClick={handleSetupClick}>Set up</div>
                {/* <Button variant="contained" color="primary" fullWidth>Set up</Button> */}
                <Typography variant="caption" display="block" style={{ marginTop: '20px', color: 'gray' }}>
                  Note: Turning this feature on will sign you out anywhere you’re currently signed in and remove all your remembered devices.
                </Typography>
            
            </div>
            )}

{step === 2 && (
        <div style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
              We sent a code to your email
            </Typography>
            <Typography variant="body2" gutterBottom>
              Enter the 6-digit verification code sent to <strong>komal@tunoes.com</strong>
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="6-digit code"
                variant="outlined"
                fullWidth
                margin="normal"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                error={error}
                helperText={error ? 'Please fill in this field.' : ''}
              />
             
              <div className="btn btn-setup">Submit</div>
            </form>
            <Typography variant="body2" type="submit" fullWidth style={{ marginTop: '20px' }}>
              If you don't see the email in your inbox, check your spam folder.
            </Typography>
            
        </div>
      )}

        </div>
        )
      default:
        return <div>Select an item to see details</div>
    }
  }

  const accountPrefrences = () => (
    <React.Fragment>
      <div className="profile-container">
        <div className="profile-card">
          <h3>Profile information</h3>
          <div className="profile-card-items">
            {profileItems.map((item, index) => (
              <div
                className="profile-card-item"
                key={index}
                onClick={() => setSelectedItem(item)}
              >
                <span>{item}</span>
                <span className="arrow">&rarr;</span>
              </div>
            ))}
          </div>
        </div>

        <div className="profile-card">
          <h3>General preferences</h3>
          <div className="profile-card-items">
            {generalPreferencesItems.map((item, index) => (
              <div
                className="profile-card-item"
                key={index}
                onClick={() => setSelectedItem(item)}
              >
                <span>{item}</span>
                <span className="arrow">&rarr;</span>
              </div>
            ))}
          </div>
        </div>

        <div className="profile-card">
          <h3>Subscriptions & payments</h3>
          <div className="profile-card-items">
            {subscriptionsPaymentsItems.map((item, index) => (
              <div
                className="profile-card-item"
                key={index}
                onClick={() => setSelectedItem(item)}
              >
                <span>{item}</span>
                <span className="arrow">&rarr;</span>
              </div>
            ))}
          </div>
        </div>
        <div className="profile-card">
          <h3>Partners & services</h3>
          <div className="profile-card-items">
            {partnersServicesItems.map((item, index) => (
              <div
                className="profile-card-item"
                key={index}
                onClick={() => setSelectedItem(item)}
              >
                <span>{item}</span>
                <span className="arrow">&rarr;</span>
              </div>
            ))}
          </div>
        </div>
        <div className="profile-card">
          <h3>Account management</h3>
          <div className="profile-card-items">
            {accountManagementItems.map((item, index) => (
              <div
                className="profile-card-item"
                key={index}
                onClick={() => setSelectedItem(item)}
              >
                <span>{item}</span>
                <span className="arrow">&rarr;</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  const signinDetails = () => (
    <React.Fragment>
      <div className="profile-container">
        <div className="profile-card">
          <h3>Account management</h3>
          <div className="profile-card-items">
            {accountAccessItems.map((item, index) => (
              <div
                className="profile-card-item"
                key={index}
                onClick={() => setSelectedItem(item)}
              >
                <span>{item}</span>
                <span className="arrow">&rarr;</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <div>
      <Box className="account-page">
        <Typography variant="h6" component="h1" className="header">
          Setting
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className="tabs">
              <MuiButton
                className={activeTab === "accountpref" ? "active" : ""}
                variant="text"
                onClick={() => setActiveTab("accountpref")}
              >
                Account Preferences
              </MuiButton>
              <MuiButton
                className={activeTab === "signin" ? "active" : ""}
                variant="text"
                onClick={() => setActiveTab("signin")}
              >
                Sign In & Security
              </MuiButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {activeTab === "accountpref" && accountPrefrences()}
            {activeTab === "signin" && signinDetails()}
          </Grid>
          <Grid item xs={12} md={8}>
            <Box className="content-box">
              <Typography variant="h5">{selectedItem}</Typography>
              {profileItems.includes(selectedItem) &&
                getProfileContent(selectedItem)}
              {generalPreferencesItems.includes(selectedItem) &&
                getGeneralPreferencesContent(selectedItem)}
              {subscriptionsPaymentsItems.includes(selectedItem) &&
                getSubscriptionsPaymentsContent(selectedItem)}
              {partnersServicesItems.includes(selectedItem) &&
                getPartnersServicesContent(selectedItem)}
              {accountManagementItems.includes(selectedItem) &&
                getAccountManagementContent(selectedItem)}
              {accountAccessItems.includes(selectedItem) &&
                getAccountAccessContent(selectedItem)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Setting
