import React from 'react'
import ProdcutHome from "../../../pages/ProductList/ProductHome";

function WebScrap() {
  return (
    <div>
        <ProdcutHome initialTab={4}/>
    </div>
  )
}

export default WebScrap