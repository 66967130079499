// import PropTypes from "prop-types"
// import React from "react"
// import { connect } from "react-redux"
// import withRouter from "components/Common/withRouter"

// //i18n
// import { withTranslation } from "react-i18next"

// import SqanoSidebarContent from "./SqanoSidebarContent"
// // import ClientSidebarContent from "./ClientSidebarContent"
// // import UserSidebarContent from "./UserSidebarContent"

// const Sidebar = props => {
//   return (
//     <React.Fragment>
//       <div className="vertical-menu">
//         <div data-simplebar className="h-100">
//           {props.type !== "condensed" ? (
//             <SqanoSidebarContent />
//           ) : (
//             <SqanoSidebarContent />
//           )}
//         </div>
//       </div>
//     </React.Fragment>
//   )
// }

// Sidebar.propTypes = {
//   type: PropTypes.string,
// }

// const mapStatetoProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }
// export default connect(
//   mapStatetoProps,
//   {},
// )(withRouter(withTranslation()(Sidebar)))

// import PropTypes from "prop-types"
// import React, { useEffect, useState } from "react"
// import { connect } from "react-redux"
// import withRouter from "components/Common/withRouter"
// import { withTranslation } from "react-i18next"

// // Sidebar content components
// import SqanoSidebarContent from "./SqanoSidebarContent"
// import ClientSidebarContent from "./ClientSidebarContent"
// import UserSidebarContent from "./UserSidebarContent"
// import GuestUserSidebarContent from "./GuestUserSidebarContent"
// import { useSelector } from "react-redux"

// const Sidebar = props => {
//   const { type, userRole } = props
//   const userDetails = useSelector(state => state.Login?.user.userDetails || {})
//   console.log("userRole", userRole)

//   const [Role, setRole] = useState(userRole)

//   useEffect(() => {
//     setRole(userRole)
//   }, [userRole])

//   let SidebarContent
//   switch (Role) {
//     case "Super Admin":
//       SidebarContent = SqanoSidebarContent
//       break
//     case "Admin":
//       SidebarContent = ClientSidebarContent
//       break
//     case "Recruiter":
//       SidebarContent = UserSidebarContent
//       break
//     case "Guest User":
//       SidebarContent = GuestUserSidebarContent
//       break
//     default:
//       SidebarContent = GuestUserSidebarContent
//   }

//   return (
//     <React.Fragment>
//       <div className="vertical-menu">
//         <div data-simplebar className="h-100">
//           {type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
//         </div>
//       </div>
//     </React.Fragment>
//   )
// }

// Sidebar.propTypes = {
//   type: PropTypes.string,
//   userRole: PropTypes.string.isRequired,
// }

// const mapStateToProps = state => {
//   return {
//     type: state.Layout.type,
//     // userRole: state.Login?.user?.userDetails?.role || "Guest User",
//     userRole: state.Login?.user?.userDetails?.role,
//   }
// }

// export default connect(
//   mapStateToProps,
//   {},
// )(withRouter(withTranslation()(Sidebar)))

import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

// Sidebar content components
import SqanoSidebarContent from "./SqanoSidebarContent"
import ClientSidebarContent from "./ClientSidebarContent"
import UserSidebarContent from "./UserSidebarContent"
import GuestUserSidebarContent from "./GuestUserSidebarContent"
import SideBarComponent from "./SideBarComponent"

const Sidebar = ({ type }) => {
  // Use useSelector to access Redux state
  const userRole = useSelector(state => state.Login?.user?.userDetails?.role)

  // useEffect(() => {
  //   // Example: Log or perform an action when user role changes
  //   console.log(`Role has changed to: ${userRole}`)
  //   // Potential actions could include fetching role-specific data, updating UI, etc.
  // }, [userRole]) // Dependency array includes userRole to react to its changes

  // const getSidebarContent = () => {
  //   switch (userRole) {
  //     case "Sqano Superadmin":
  //       return <SqanoSidebarContent />
  //     case "Sqano Superadmin":
  //       return <SqanoSidebarContent />
  //     case "Superadmin":
  //       return <ClientSidebarContent />
  //     case "Admin":
  //       return <ClientSidebarContent />
  //     case "Recruiter":
  //       return <UserSidebarContent />
  //     case "Guest User":
  //       return <GuestUserSidebarContent />
  //     default:
  //       return <div>No Access</div> // Handling for undefined or unauthorized roles
  //   }
  // }

  return (
    <React.Fragment>
      <div className="vertical-menu">

          {/* {getSidebarContent()} */}
          <SideBarComponent />
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

export default withRouter(withTranslation()(Sidebar))
