import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  InputGroupText,
  InputGroup,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../Authentication/Register.css";
import "./Forgetpassword.scss";
import { forgotPasswordRequest } from "../../store/actions";
import RegisterHeader from "../Authentication/Header/RegisterHeader";
import Footer from "../Authentication/Footer/Footer";
import { FaEnvelope } from "react-icons/fa";

const ForgetPasswordPage = (props) => {
  

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Invalid email address format"
        )
        .required("Please enter your email"),
    }),
    onSubmit: (values) => {
      dispatch(forgotPasswordRequest(values.email));
    },
  });

  const selectForgotPasswordState = (state) => state.ForgetPassword;
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  );

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  );

  useEffect(() => {
    if (forgetError) {
      toast.error(forgetError);
    }
  }, [forgetError]);

  useEffect(() => {
    if (forgetSuccessMsg) {
      toast.success(forgetSuccessMsg);
    }
  }, [forgetSuccessMsg]);

  return (
    <div className="forgot-page">
      <div className="forgot-background">
        <RegisterHeader />
        <div className="forgot-box">
          <div className="forgot-container">
            <Row>
              <Col>
                <Card>
                  <CardBody className="forgot-card">
                    <Form
                      className=""
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="text-center ">
                        <h4 className="text-muted text-center">
                          Forgot Password
                        </h4>
                        <p className="text-center">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="forgot-form-group" style={{ width: '50%' }}>
                          <Label htmlFor="useremail">Email</Label>
                          <InputGroup
                            className={`forgot-input-group ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                          <Input
                            name="email"
                            placeholder="Enter email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email || ""}
                            invalid={
                              formik.touched.email && formik.errors.email
                                ? true
                                : false
                            }
                            className="forgot-input"
                          />
                          <InputGroupText
                            className="email-icon"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              position: "absolute",
                              right: "14px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: "10", // Ensures the icon stays above the input
                              pointerEvents: "none", // Makes the icon unclickable to avoid input focus loss
                            }}
                          >
                            <FaEnvelope style={{ color: "#660032" }} />
                          </InputGroupText>
                        </InputGroup>
                        {formik.touched.email && formik.errors.email ? (
                          <FormFeedback type="invalid">
                            {formik.errors.email}
                          </FormFeedback>
                        ) : null}
                       </div>
                       </div>
                      <Row className="mb-3">
                        <div className="text-center">
                          <Button
                            className="w-50 register-btn-custom"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
