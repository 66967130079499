import React from 'react'
import ProdcutHome from "../../../pages/ProductList/ProductHome";

function ViewRequirement() {
  return (
    <div>

        <ProdcutHome initialTab={10}/> 

    </div>
  )
}

export default ViewRequirement;