import { call, put, takeLatest, all, fork } from "redux-saga/effects"
import {
  sendOtp,
  verifyOtp,
  registerUser,
  sendOtpSms,
  verifyOtpSms,
} from "../../api"
import {
  SEND_OTP_REQUEST,
  VERIFY_OTP_REQUEST,
  REGISTER_REQUEST,
  SEND_OTP_SMS_REQUEST,
  VERIFY_OTP_SMS_REQUEST,
} from "./actionTypes"

import {
  sendOtpSuccess,
  sendOtpFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
  registerSuccess,
  registerFailure,
  sendOtpSmsSuccess,
  sendOtpSmsFailure,
  verifyOtpSmsSuccess,
  verifyOtpSmsFailure,
} from "./actions"
import { push } from "connected-react-router"

// Send OTP Saga
function* sendOtpSaga(action) {
  try {
    const response = yield call(sendOtp, action.payload)
    yield put(sendOtpSuccess(response.data))
  } catch (error) {
    yield put(sendOtpFailure(error.response.data))
  }
}

// Verify OTP Saga
function* verifyOtpSaga(action) {
  try {
    const response = yield call(verifyOtp, action.payload)
    yield put(verifyOtpSuccess(response.data))
  } catch (error) {
    yield put(verifyOtpFailure(error.message))
  }
}

// Send OTP SMS Saga
function* sendOtpSmsSaga(action) {
  try {
    // console.log("OTP SMS SAGA")
    const response = yield call(sendOtpSms, action.payload)
    yield put(sendOtpSmsSuccess(response.data))
  } catch (error) {
    yield put(sendOtpSmsFailure(error.message))
  }
}

// Verify OTP SMS Saga
function* verifyOtpSmsSaga(action) {
  try {
    const res = yield call(verifyOtpSms, action.payload)
    yield put(verifyOtpSmsSuccess(res.data))
  } catch (error) {
    yield put(verifyOtpSmsFailure(error.message))
  }
}

// Register User Saga
function* registerSaga(action) {
  try {
    const response = yield call(registerUser, action.payload)
    yield put(registerSuccess(response.data));

  } catch (error) {
    yield put(registerFailure(error.response.data.message));
  }
}

// Watcher Sagas
function* watchSendOtp() {
  yield takeLatest(SEND_OTP_REQUEST, sendOtpSaga)
}

function* watchVerifyOtp() {
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOtpSaga)
}

function* watchSendOtpSms() {
  yield takeLatest(SEND_OTP_SMS_REQUEST, sendOtpSmsSaga)
}

function* watchVerifyOtpSms() {
  yield takeLatest(VERIFY_OTP_SMS_REQUEST, verifyOtpSmsSaga)
}

function* watchRegister() {
  yield takeLatest(REGISTER_REQUEST, registerSaga)
}

// Root Saga
export default function* registerSagas() {
  yield all([
    fork(watchSendOtp),
    fork(watchVerifyOtp),
    fork(watchSendOtpSms),
    fork(watchVerifyOtpSms),
    fork(watchRegister),
  ])
}
