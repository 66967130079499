// Action types for CRUD operations on users
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const GET_USER_BY_ENTITY_ID = "GET_USER_BY_ENTITY_ID";
export const GET_USER_BY_ENTITY_ID_SUCCESS = "GET_USER_BY_ENTITY_ID_SUCCESS";
export const GET_USER_BY_ENTITY_ID_FAILURE = "GET_USER_BY_ENTITY_ID_FAILURE";

export const GET_USER_BY_CREATED_BY_REQUEST = "GET_USER_BY_CREATED_BY_REQUEST";
export const GET_USER_BY_CREATED_BY_SUCCESS = "GET_USER_BY_CREATED_BY_SUCCESS";
export const GET_USER_BY_CREATED_BY_FAILURE = "GET_USER_BY_CREATED_BY_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";
export const GET_ROLE_BY_ID_FAILURE = "GET_ROLE_BY_ID_FAILURE";

export const GET_ALL_ROLES_REQUEST = "GET_ALL_ROLES_REQUEST";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const GET_ALL_FEATURES = "GET_ALL_FEATURES";
export const GET_ALL_FEATURES_SUCCESS = "GET_ALL_FEATURES_SUCCESS";
export const GET_ALL_FEATURES_FAILURE = "GET_ALL_FEATURES_FAILURE";

export const GET_ALL_COUNTS = "GET_ALL_COUNTS";
export const GET_ALL_COUNTS_SUCCESS = "GET_ALL_COUNTS_SUCCESS";
export const GET_ALL_COUNTS_FAILURE = "GET_ALL_COUNTS_FAILURE";
