// Action Types
export const CREATE_VENDOR_REQUEST = "CREATE_VENDOR_REQUEST"
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS"
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE"

export const GET_VENDORS_REQUEST = "GET_VENDORS_REQUEST"
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS"
export const GET_VENDORS_FAILURE = "GET_VENDORS_FAILURE"

export const GET_VENDOR_BY_ID_REQUEST = "GET_VENDOR_BY_ID_REQUEST"
export const GET_VENDOR_BY_ID_SUCCESS = "GET_VENDOR_BY_ID_SUCCESS"
export const GET_VENDOR_BY_ID_FAILURE = "GET_VENDOR_BY_ID_FAILURE"

export const GET_SUPERADMIN_BY_ID_REQUEST = "GET_SUPERADMIN_BY_ID_REQUEST"
export const GET_SUPERADMIN_BY_ID_SUCCESS = "GET_SUPERADMIN_BY_ID_SUCCESS"
export const GET_SUPERADMIN_BY_ID_FAILURE = "GET_SUPERADMIN_BY_ID_FAILURE"


export const UPDATE_VENDOR_REQUEST = "UPDATE_VENDOR_REQUEST"
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS"
export const UPDATE_VENDOR_FAILURE = "UPDATE_VENDOR_FAILURE"

export const UPDATE_SUPERADMIN_REQUEST = "UPDATE_SUPERADMIN_REQUEST"
export const UPDATE_SUPERADMIN_SUCCESS = "UPDATE_SUPERADMIN_SUCCESS"
export const UPDATE_SUPERADMIN_FAILURE = "UPDATE_SUPERADMIN_FAILURE"

export const DELETE_VENDOR_REQUEST = "DELETE_VENDOR_REQUEST"
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS"
export const DELETE_VENDOR_FAILURE = "DELETE_VENDOR_FAILURE"
