import {
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILURE,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  GET_VENDOR_BY_ID_REQUEST,
  GET_VENDOR_BY_ID_SUCCESS,
  GET_VENDOR_BY_ID_FAILURE,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAILURE,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAILURE,
  GET_SUPERADMIN_BY_ID_REQUEST,
  GET_SUPERADMIN_BY_ID_SUCCESS,
  GET_SUPERADMIN_BY_ID_FAILURE,
  UPDATE_SUPERADMIN_REQUEST,
  UPDATE_SUPERADMIN_SUCCESS,
  UPDATE_SUPERADMIN_FAILURE
} from "./actionTypes"

const initialState = {
  vendor: null,
  superAdmin:null,
  vendors: [],
  loading: false,
  error: null,
}

const Vendor = (state = initialState, action) => {
  switch (action.type) {
    // CREATE VENDOR
    case CREATE_VENDOR_REQUEST:
    case GET_VENDORS_REQUEST:
    case GET_VENDOR_BY_ID_REQUEST:
    case GET_SUPERADMIN_BY_ID_REQUEST:
    case UPDATE_VENDOR_REQUEST:
    case DELETE_VENDOR_REQUEST:
      console.log("action",action.payload)
      return {
        ...state,
        loading: true,
        error: null,
      }
    case CREATE_VENDOR_SUCCESS:
      return {
        ...state,
        vendor: action.payload,
        loading: false,
      }
    case CREATE_VENDOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // GET VENDORS
    case GET_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload,
        loading: false,
      }
    case GET_SUPERADMIN_BY_ID_SUCCESS:
      return{
        ...state,
        superAdmin: action.payload,
        loading: false,
      }
    case GET_VENDORS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // GET VENDOR BY ID
    case GET_VENDOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_VENDOR_BY_ID_SUCCESS:
      return {
        ...state,
        vendor: action.payload,
        loading: false,
      }
    case GET_VENDOR_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // UPDATE VENDOR
    case UPDATE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        vendor: action.payload,
        loading: false,
      }
    case UPDATE_VENDOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

      // UPDATE SUPER ADMIN
    case UPDATE_SUPERADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case UPDATE_SUPERADMIN_SUCCESS:
      return {
        ...state,
        superAdmin: action.payload,
        loading: false,
      }
    case UPDATE_SUPERADMIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // DELETE VENDOR
    case DELETE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        vendors: state.vendors.filter(vendor => vendor._id !== action.payload),
        loading: false,
      }
    case DELETE_VENDOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Vendor
