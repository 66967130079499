// Header.js
import React from 'react';
import civixoIcon from '../../../assets/images/civixo-logo.png';
import './GeneratePdfHeader.css';

const GeneratePdfHeader = () => {
  return (
    <div className="generatepdf-header">
      <img src={civixoIcon} alt="Civixo Logo" className="header-logo" />
    </div>
  );
};

export default GeneratePdfHeader;
