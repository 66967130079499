import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
  InputGroupText,
  InputGroup,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FaEnvelope } from 'react-icons/fa';
// import PulseLoader from "pages/PulseLoader/PulseLoader"

// Redux actions from your saga
import {
  sendOtpRequest,
  sendOtpSuccess,
  sendOtpFailure,
  verifyOtpRequest,
  verifyOtpSuccess,
  verifyOtpFailure,
  sendOtpSmsRequest,
  sendOtpSmsSuccess,
  sendOtpSmsFailure,
  verifyOtpSmsRequest,
  verifyOtpSmsSuccess,
  verifyOtpSmsFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  resetRegistrationState,
} from "../../store/auth/register/actions"
import "../Authentication/Header/RegisterHeader"
import "./Register.css"
import RegisterHeader from "../Authentication/Header/RegisterHeader"
import Footer from "./Footer/Footer"
import PhoneInput from "react-phone-input-2"
import PulseLoader from "pages/PulseLoader/PulseLoader"
import logoLight from "./../../assets/images/logo-light.png";
import { List } from "react-bootstrap-icons"
import Drawer from "./Drawer/Drawer"
import loginimg from "../../assets/images/Login-img.png"


const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [isSidebarActive, setIsSidebarActive] = useState(false)
  const [topDrawerOpen, setTopDrawerOpen] = useState(false);

  const toastOptions = {
    autoClose: 500,
    progressClassName: "toast-progress-bar",
    icon: <CheckCircleIcon style={{ color: "#660032" }} />,
  };

  const {
    emailOtpSent,
    emailOtpVerified,
    smsOtpSent,
    smsOtpVerified,
    sendOtpLoading,
    verifyOtpLoading,
    registerLoading,
    message,
    sendOtpError,
    verifyOtpError,
    registerError,
    sendOtpSmsLoading,
    verifyOtpSmsLoading,
    sendOtpSmsError,
    verifyOtpSmsError,
    registrationMessage
  } = useSelector(state => state.Register)

  const formik = useFormik({
    initialValues: {
      companyName: "",
      email: "",
      password: "",
      otp: Array(6).fill(""),
      phone: "",
      phoneOtp: Array(6).fill(""),
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Invalid email address format",
        )
        .required("Please enter your email"),

      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&]).{8,20}$/,
          "Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character.",
        )
        .required("Please enter your password"),
    //   phone: Yup.string()
    //     .matches(
    //       /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,6}[-\s\.]?[0-9]{1,10}$/,
    //       "Invalid phone number format",
    //     )
    //     .required("Please enter your phone number"),
    }),
    onSubmit: values => {
      console.log("submit")
      dispatch(
        registerRequest({
          companyName: values.companyName,
          email: values.email,
          phone: values.phone,
          password: values.password,
        }),
      )



      // if (registerSuccess) {
      //   toast.success("Register successful!.", toastOptions)
      //   formik.resetForm()

      //   navigate("/login")
      // }
    },
  })

  const handleSendEmailOTP = e => {
    e.preventDefault()
    dispatch(sendOtpRequest(formik.values.email))
  }

  const handleVerifyEmailOTP = e => {
    e.preventDefault()
    const otp = formik.values.otp.join("")
    dispatch(verifyOtpRequest({ email: formik.values.email, emailOtp: otp }))
  }

  const handleSendSmsOTP = e => {
    e.preventDefault()
    dispatch(
      sendOtpSmsRequest({
        email: formik.values.email,
        contactNo: formik.values.phone,
      }),
    )
  }

  const handleVerifySmsOTP = e => {
    e.preventDefault()
    const otp = formik.values.phoneOtp.join("")
    dispatch(
      verifyOtpSmsRequest({ contactNo: formik.values.phone, smsOtp: otp }),
    )
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === "otp" || name === "phoneOtp") {
      const otpArray = value.split("").slice(0, 6)
      formik.setFieldValue(name, otpArray)
      formik.setFieldTouched(name, true, false)
    } else {
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    }
  }

  useEffect(()=>{
    if (registrationMessage) {
      toast.success(registrationMessage)
      navigate("/login")
    }

  },[registrationMessage])

  useEffect(() => {
    dispatch(resetRegistrationState())

    return () => {
      dispatch(resetRegistrationState())
    }
  }, [dispatch])

  useEffect(() => {
    if (sendOtpError) toast.error(sendOtpError)
  }, [sendOtpError])

  useEffect(() => {
    if (verifyOtpError) toast.error(verifyOtpError)
  }, [verifyOtpError])

  useEffect(() => {
    if (registerError) toast.error(registerError,toastOptions)
  }, [registerError])

  useEffect(() => {
    if (sendOtpSmsError) toast.error(sendOtpSmsError)
  }, [sendOtpSmsError])

  useEffect(() => {
    if (verifyOtpSmsError) toast.error(verifyOtpSmsError)
  }, [verifyOtpSmsError])

  useEffect(() => {
    if (emailOtpSent) toast.success(emailOtpSent, toastOptions)
  }, [emailOtpSent])

  useEffect(() => {
    if (emailOtpVerified) toast.success(emailOtpVerified, toastOptions)
  }, [emailOtpVerified])

  useEffect(() => {
    if (smsOtpSent) toast.success(smsOtpSent, toastOptions)
  }, [smsOtpSent])

  useEffect(() => {
    if (smsOtpVerified) toast.success(smsOtpVerified, toastOptions)
  }, [smsOtpVerified])

  useEffect(() => {
    if (message) toast.success(message, toastOptions)
  }, [message])

  const isEmailValid = formik.values.email && !formik.errors.email
  const isPhoneValid = formik.values.phone && !formik.errors.phone

  const toggleDrawer = () => {
    setIsSidebarActive(!isSidebarActive)
  }

  const toggleTopDrawer = (open) => () => {
    setTopDrawerOpen(open);
  };

  return (
    <div className="register-page">

      {registerLoading && <PulseLoader />}

      <div className="register-background">
        <RegisterHeader />

        <div className="register-box">
          <div className="register-container">
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}
                className="d-flex flex-column justify-content-center align-items-center text-center login-wrap"
              >
                <img className="register-image" src={loginimg} />
                <p className="login-p">
                  If you already have an account, you can{" "}
                  <Link className="register-login" to="/login">
                    login here
                  </Link>
                  .
                </p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Card>
                  <CardBody className="register-card">
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="text-center">
                        <h4>Register</h4>
                      </div>

                      <div className="register-form-group">
                        <Label htmlFor="companyName">Company Name</Label>
                        <Input
                          type="text"
                          name="companyName"
                          className={`register-input ${formik.touched.companyName && formik.errors.companyName ? "is-invalid" : ""}`}
                          placeholder="Company Name"
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName}
                          required
                          autoFocus
                        />
                        {formik.touched.companyName &&
                          formik.errors.companyName && (
                            <FormFeedback className="invalid-feedback">
                              {formik.errors.companyName}
                            </FormFeedback>
                          )}
                      </div>

                      <div className="register-form-group">
                      <Label htmlFor="email">Email</Label>
                      <InputGroup className={`register-input-group ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Enter Your Email"
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          disabled={emailOtpSent}
                          required
                          style={{
                            paddingRight: '2.5rem', // Ensure space for the icon
                            fontSize: "12px",
                            fontFamily: "Roboto"
                          }}
                        />
                         <InputGroupText
                                  className="email-icon"
                                  style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer', // Optional, for a pointer cursor on hover
                                    zIndex: '10', // Ensures the icon stays above the input
                                    pointerEvents: 'none', // Makes the icon unclickable to avoid input focus loss
                                  }}
                                >
                                  <FaEnvelope style={{ color: '#660032' }} />
                                </InputGroupText>
                              </InputGroup>
                        
                        {formik.touched.email && formik.errors.email && (
                          <FormFeedback className="invalid-feedback">
                            {formik.errors.email}
                          </FormFeedback>
                        )}
                      </div>
                      {isEmailValid && (
                        <>
                          <Button
                            onClick={handleSendEmailOTP}
                            disabled={!formik.values.email || sendOtpLoading}
                            className="mt-2 w-100 register-btn-custom"
                            style={{
                              display: emailOtpSent ? "none" : "block",
                            }}
                          >
                            {sendOtpLoading ? "Processing..." : "Send OTP"}
                          </Button>

                          {emailOtpSent && !emailOtpVerified && (
                            <>
                              <div className="register-form-group">
                                <Label htmlFor="otp">Enter Your OTP</Label>
                                <Input
                                  type="text"
                                  name="otp"
                                  className={`register-input ${formik.touched.otp && formik.errors.otp ? "is-invalid" : ""}`}
                                  placeholder="Enter Your OTP "
                                  maxLength="6"
                                  onChange={handleChange}
                                  value={formik.values.otp.join("")}
                                  required
                                />
                                {formik.touched.otp && formik.errors.otp && (
                                  <FormFeedback className="invalid-feedback">
                                    {formik.errors.otp}
                                  </FormFeedback>
                                )}
                                <Button
                                  onClick={handleVerifyEmailOTP}
                                  disabled={
                                    formik.values.otp.join("").length !== 6 ||
                                    verifyOtpLoading
                                  }
                                  className="w-100 register-btn-custom"
                                // style={{
                                //   display: emailOtpVerified
                                //     ? "none"
                                //     : "block",
                                // }}
                                >
                                  {verifyOtpLoading
                                    ? "Processing..."
                                    : "Verify OTP"}
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {/* <div className="register-form-group">
                        <Label htmlFor="phone">Phone Number</Label>
                        <PhoneInput
                          country={"in"}
                          className={`register-input ${formik.touched.phone && formik.errors.phone ? "is-invalid" : ""}`}
                          enableSearch={true}
                          placeholder="+91 12345678"
                          value={formik.values.phone}
                          onChange={phone =>
                            formik.setFieldValue("phone", phone)
                          }
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            borderBottom: "none",
                            background: "none",
                            borderRadius: "0",
                            outline: "none",
                            fontSize: "14px",
                            padding: "10px 0px 8px 50px",
                            fontFamily: "Arial, sans-serif",
                          }}
                          buttonStyle={{
                            border: "none",
                            background: "none",
                          }}
                          containerStyle={{
                            width: "100%",
                          }}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <FormFeedback className="invalid-feedback">
                            {formik.errors.phone}
                          </FormFeedback>
                        )}
                      </div> */}
                      {/* {isPhoneValid && (
                        <>
                          <Button
                            onClick={handleSendSmsOTP}
                            disabled={!formik.values.phone || sendOtpSmsLoading}
                            className="mt-2 w-100 register-btn-custom"
                            style={{
                              display: smsOtpSent ? "none" : "block",
                            }}
                          >
                            {sendOtpSmsLoading ? "Processing..." : "Send OTP"}
                          </Button>

                          {smsOtpSent && !smsOtpVerified && (
                            <>
                              <div className="register-form-group">
                              <Label htmlFor="phoneOtp">Enter Your OTP</Label>
                                <Input
                                  type="text"
                                  name="phoneOtp"
                                  className={`register-input ${formik.touched.phoneOtp && formik.errors.phoneOtp ? "is-invalid" : ""}`}
                                  placeholder="Enter Your OTP "
                                  maxLength="6"
                                  onChange={handleChange}
                                  value={formik.values.phoneOtp.join("")}
                                  required
                                />
                                {formik.touched.phoneOtp &&
                                  formik.errors.phoneOtp && (
                                    <FormFeedback className="invalid-feedback">
                                      {formik.errors.phoneOtp}
                                    </FormFeedback>
                                  )}
                              </div>
                              <Button
                                onClick={handleVerifySmsOTP}
                                disabled={
                                  formik.values.phoneOtp.join("").length !==
                                    6 || verifyOtpSmsLoading
                                }
                                className="w-100 register-btn-custom"
                                // style={{
                                //   display: smsOtpVerified ? "none" : "block",
                                // }}
                              >
                                {verifyOtpSmsLoading
                                  ? "Processing..."
                                  : "Verify OTP"}
                              </Button>
                            </>
                          )}
                        </>
                      )} */}

                      <div className="register-form-group register-password-group">
                        <Label htmlFor="password">Password</Label>
                        <div className="register-input-icon-group">
                          <Input
                            type={visible ? "text" : "password"}
                            name="password"
                            className={`register-password-input ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                            placeholder="Enter Password"
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            required
                          />
                          <span
                            onClick={() => setVisible(!visible)}
                            className="register-eye-icon"
                          >
                            {visible ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>

                        {/* {formik.touched.password && formik.errors.password && (
                          <FormFeedback className="invalid-feedback">
                            {formik.errors.password}
                          </FormFeedback>
                        )} */}
                        {formik.touched.password && formik.errors.password && (
                          <div className="register-password-requirements">
                            Password must be 8-20 characters long and include at
                            least one uppercase letter, one lowercase letter, a
                            number, and a special character.
                          </div>
                        )}
                      </div>
                      <Button
                        type="submit"
                        // disabled={registerLoading}
                        className="w-100 register-btn-custom"
                      >
                        {registerLoading ? "Processing..." : "Register"}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Register
