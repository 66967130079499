// src\store\client\product\Civix\actionTypes.js
export const FETCH_CV_ACCESS_CANDIDATES_REQUEST =
  "FETCH_CV_ACCESS_CANDIDATES_REQUEST"
export const FETCH_CV_ACCESS_CANDIDATES_SUCCESS =
  "FETCH_CV_ACCESS_CANDIDATES_SUCCESS"
export const FETCH_CV_ACCESS_CANDIDATES_FAILURE =
  "FETCH_CV_ACCESS_CANDIDATES_FAILURE"
export const FILTER_CV_ACCESS_CANDIDATES_REQUEST =
  "FILTER_CV_ACCESS_CANDIDATES_REQUEST"
export const FILTER_CV_ACCESS_CANDIDATES_SUCCESS =
  "FILTER_CV_ACCESS_CANDIDATES_SUCCESS"
export const FILTER_CV_ACCESS_CANDIDATES_FAILURE =
  "FILTER_CV_ACCESS_CANDIDATES_FAILURE"
