export const PARSE_PROFILE_REQUEST = 'PARSE_PROFILE_REQUEST';
export const PARSE_PROFILE_SUCCESS = 'PARSE_PROFILE_SUCCESS';
export const PARSE_PROFILE_FAILURE = 'PARSE_PROFILE_FAILURE';

export const UPDATE_CANDIDATE_REQUEST = 'UPDATE_CANDIDATE_REQUEST';
export const UPDATE_CANDIDATE_SUCCESS = 'UPDATE_CANDIDATE_SUCCESS';
export const UPDATE_CANDIDATE_FAILURE = 'UPDATE_CANDIDATE_FAILURE';

export const SEND_TO_SQANIT_REQUEST = 'SEND_TO_SQANIT_REQUEST';
export const SEND_TO_SQANIT_SUCCESS = 'SEND_TO_SQANIT_SUCCESS';
export const SEND_TO_SQANIT_FAILURE = 'SEND_TO_SQANIT_FAILURE';

export const SHARE_PROFILE_REQUEST = 'SHARE_PROFILE_REQUEST';
export const SHARE_PROFILE_SUCCESS = 'SHARE_PROFILE_SUCCESS';
export const SHARE_PROFILE_FAILURE = 'SHARE_PROFILE_FAILURE';

export const DELETE_FAILED_PROFILE_REQUEST = "DELETE_FAILED_PROFILE_REQUEST";
export const DELETE_FAILED_PROFILE_SUCCESS = "DELETE_FAILED_PROFILE_SUCCESS";
export const DELETE_FAILED_PROFILE_FAILURE = "DELETE_FAILED_PROFILE_FAILURE";

export const RESET_PARSE_DATA_REQUEST = "RESET_PARSE_DATA_REQUEST";

export const GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST="GET_PARSED_PROFILE_DATA_BY_USER_ID_REQUEST"
export const GET_PARSED_PROFILE_DATA_BY_USER_ID_SUCCESS="GET_PARSED_PROFILE_DATA_BY_USER_ID_SUCCESS"
export const GET_PARSED_PROFILE_DATA_BY_USER_ID_FAILURE="GET_PARSED_PROFILE_DATA_BY_USER_ID_FAILURE"
