import { 
    INSERT_WORKFLOWS_REQUEST, 
    INSERT_WORKFLOWS_SUCCESS, 
    INSERT_WORKFLOWS_FAILURE, 
    FETCH_WORKFLOWS_REQUEST, 
    FETCH_WORKFLOWS_SUCCESS, 
    FETCH_WORKFLOWS_FAILURE, 
    FETCH_WORKFLOWS_BY_ID_REQUEST, 
    FETCH_WORKFLOWS_BY_ID_SUCCESS, 
    FETCH_WORKFLOWS_BY_ID_FAILURE, 
    UPDATE_WORKFLOW_REQUEST, 
    UPDATE_WORKFLOW_SUCCESS, 
    UPDATE_WORKFLOW_FAILURE 
} from './actionTypes';

const initialState = {
    loading: false,
    workflows: [],
    updateWorkflow: {},
    error: null
};

const workflowReducer = (state = initialState, action) => {
    switch (action.type) {
        case INSERT_WORKFLOWS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case INSERT_WORKFLOWS_SUCCESS:
            return {
                ...state,
                loading: false,
                workflows: action.payload,
                error: null
            };
        case INSERT_WORKFLOWS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_WORKFLOWS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_WORKFLOWS_SUCCESS:
            return {
                ...state,
                loading: false,
                workflows: action.payload,
                error: null
            };
        case FETCH_WORKFLOWS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_WORKFLOWS_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_WORKFLOWS_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                workflows: action.payload,
                error: null
            };
        case FETCH_WORKFLOWS_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case UPDATE_WORKFLOW_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                loading: false,
                updateWorkflow: action.payload,
                error: null
            };
        case UPDATE_WORKFLOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default workflowReducer;


