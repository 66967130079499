import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { connect, useSelector, useDispatch } from "react-redux"
import { addUserRequest, getAllRolesRequest, setBreadcrumbItems } from "../../store/actions"
import "./FormWizardSteps/vendor.css"
import PhoneInput from "react-phone-input-2"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { toast } from "react-toastify"
import { padStart } from "lodash"
import { Stepper, Step, StepLabel,StepContent,Box } from '@mui/material';

const ModalStepper = ({currentStep, steps,handleNextStep, handlePrevStep}) => (
  <Box sx={{width: '100%', marginBottom: 1, marginTop: 1 }}>
    <Stepper activeStep={currentStep - 1} orientation="vertical">
      {steps.map((step,index) => (
        <Step key={index}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            {step.content}
            <div className="step-navigation" style={{ textAlign: 'right', marginTop: '5px' }}>
              {index > 0 && (
                <Button
                  onClick={handlePrevStep}
                  disabled={currentStep === 1}
                  className="custom-button mt-2"
                  style={{ marginRight: '10px' }}
                  size="small"
                >
                  BACK
                </Button>
              )}
              {index < steps.length - 1 && (
                <Button
                  className="custom-button mt-2"
                  onClick={handleNextStep}
                  style={{ marginRight: '10px' }}
                  size="small"
                >
                  NEXT
                </Button>
              )}
              {index === steps.length - 1 && (
                <Button
                  type="submit"
                  className="custom-button mt-2"
                  size="small"
                >
                  SUBMIT
                </Button>
              )}
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  </Box>
)

const UserCreation = ({handleSubmit}) => {

  const breadcrumbItems = [
    { title: "SQANO", link: "#" },
    { title: "Forms", link: "#" },
    { title: "User Profile Creation Form", link: "#" },
  ]
  const dispatch = useDispatch()
  const {roles} = useSelector(state => state.usersReducer);

  const {userDetails} = useSelector(state => state.Login.user);
  console.log("userDetails", userDetails)
  const loggedUserRole = userDetails?.roleAndFeatures?.name
  // console.log("users", Users)
  const [formData, setFormData] = useState({
    entityId: "",
    // userName: "",
    firstName: "",
    lastName: "",
    gender: "",
    // doj: "",
    contactNumber: "",
    email: "",
    role: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    facebook: "",
    profilePicture: "",
    password: "",
    address: "",
    aboutUser: "",
  })

  const [errors, setErrors] = useState({})
  const [visible, setVisible] = useState(false)
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    dispatch(getAllRolesRequest())
  }, [])

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };
  console.log("formData", formData)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    validateField(name,value);
  }

  const handleFileChange = e => {
    setFormData({
      ...formData,
      // entityId: vendorId,
      entityId:userDetails.entityId,
      createdBy: userDetails.id,
      profilePicture: e.target.files[0],
    })
  }
  const handleRoleChange = e => {
    const role = roles.find(role => role._id === e.target.value)
    setFormData({ ...formData, role: role._id })
  }

  const validateForm = () => {
    let errors = {}

    // if (!formData.userName) errors.userName = "User Name is required"
    if (!formData.firstName) errors.firstName = "First Name is required"
    if (!formData.lastName) errors.lastName = "Last Name is required"
    if (!formData.gender) errors.gender = "Gender is required"
    // if (!formData.doj) errors.doj = "Date of Joining is required"
    if (!formData.contactNumber)
      errors.contactNumber = "Contact Number is required"
    if (!formData.email) errors.email = "Email is required"
    if (!formData.role) errors.role = "User Type is required"
    if (!formData.profilePicture)
      errors.profilePicture = "Profile Picture is required"
    if (!formData.password) errors.password = "Password is required"
    if (!formData.address) errors.address = "Address is required"
    if (!formData.aboutUser) errors.aboutUser = "About User is required"

    return errors
  }
  const validateField = (name, value) => {
    let error = "";
  
    const namePattern = /^[A-Za-z\s]+$/;
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&]).{8,20}$/;
    const phonePattern = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,6}[-\s\.]?[0-9]{1,10}$/;
    const facebookPattern = /^(https?:\/\/)?(www\.)?facebook.com\/[A-Za-z0-9._%+-]+\/?$/;
    const instagramPattern = /^(https?:\/\/)?(www\.)?instagram.com\/[A-Za-z0-9._%+-]+\/?$/;
    const twitterPattern = /^(https?:\/\/)?(www\.)?twitter.com\/[A-Za-z0-9._%+-]+\/?$/;
    const linkedinPattern = /^(https?:\/\/)?(www\.)?linkedin.com\/in\/[A-Za-z0-9._%+-]+\/?$/;

    if (["userName", "firstName", "lastName"].includes(name)) {
      if (!namePattern.test(value)) {
        error = "This field cannot contain numbers or special characters.";
      }
    }

    if (name === "email") {
      if (!emailPattern.test(value)) {
        error = "Invalid email address format";
      }
    }
  
    if (name === "password") {
      if (!passwordPattern.test(value)) {
        error = "Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character.";
      }
    }
  
    if (name === "contactNumber") {
      if (!phonePattern.test(value)) {
        error = "Invalid phone number format";
      }
    }
  

    if (name === "facebook" && !facebookPattern.test(value)) {
      error = "Invalid Facebook URL format";
    }
  
    if (name === "instagram" && !instagramPattern.test(value)) {
      error = "Invalid Instagram URL format";
    }
  
    if (name === "twitter" && !twitterPattern.test(value)) {
      error = "Invalid Twitter URL format";
    }
  
    if (name === "linkedin" && !linkedinPattern.test(value)) {
      error = "Invalid LinkedIn URL format";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  
  // const handleFileChange = e => {
  //   const file = e.target.files[0]
  //   const reader = new FileReader()
  //   reader.onloadend = () => {
  //     setFormData({ ...formData, profilePicture: reader.result })
  //   }
  //   reader.readAsDataURL(file)
  // }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   dispatch(addUser(formData)) // Dispatching the form data
  // }

  

  const roleHierarchy = {
    "Super Admin": 1,
    "Admin": 2,
    "Director": 3,
    "BMD": 3,
    "Lead Recruiter": 3,
    "Recruiter": 6
  };


  const visibleRoles = roles.filter(role => {
    const loggedUserRoleLevel = roleHierarchy[loggedUserRole];
    const currentRoleLevel = roleHierarchy[role.name];
    return currentRoleLevel > loggedUserRoleLevel;
  });

 const steps = [
  {
    label:'PERSONAL INFORMATION',
    content:(
      <div>
        <Row className="mb-3">
          {/* <Label
            htmlFor="user-name"
            className="col-md-2 col-form-label"
          >
            User Name<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="text"
              id="user-name"
              name="userName"
              className="form-control"
              placeholder="User Name"
              value={formData.userName}
              onChange={handleChange}
              invalid={!!errors.userName}
            />
            {errors.userName && (
              <span className="text-danger">{errors.userName}</span>
            )}
          </Col> */}
          <Label
            htmlFor="first-name"
            className="col-md-2 col-form-label"
          >
            First Name<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="text"
              id="first-name"
              name="firstName"
              className="form-control"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              invalid={!!errors.firstName}
            />
            {errors.firstName && (
              <span className="text-danger">{errors.firstName}</span>
            )}
          </Col>
          <Label
            htmlFor="last-name"
            className="col-md-2 col-form-label"
          >
            Last Name<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="text"
              id="last-name"
              name="lastName"
              className="form-control"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              invalid={!!errors.lastName}
            />
            {errors.lastName && (
              <span className="text-danger">{errors.lastName}</span>
            )}
          </Col>
        </Row>
        <Row className="mb-3">   
          <Label
            htmlFor="contact-number"
            className="col-md-2 col-form-label text-nowrap"
          >
            Contact<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
          
            <PhoneInput
              country={"in"}
              enableSearch={true}
              id="contact-number"
              inputProps={{
                name: "contactNumber",
                required: true,
                className: "form-control",
              }}
              containerStyle={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              value={formData.contactNumber}
              onChange={(value) => {
                setFormData({ ...formData, contactNumber: value });
                validateField("contactNumber", value);
              }}
            />
            
            {errors.contactNumber && <span className="text-danger">{errors.contactNumber}</span>}
          </Col>
          <Label htmlFor="gender" className="col-md-2 col-form-label">
            Gender<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="gender"
                  value="Male"
                  // checked={formData.gender === "Male"}
                  onChange={handleChange}
                  invalid={!!errors.gender}
                />{" "}
                Male
              </Label>            
              <Label check  style={{ marginLeft: "40px" }} className="ml-2">
                <Input
                  type="radio"
                  name="gender"
                  value="Female"
                  // checked={formData.gender === "Female"}
                  onChange={handleChange}
                  invalid={!!errors.gender}
                />{" "}
                Female
              </Label>
            </FormGroup>
            {errors.gender && (
              <span className="text-danger">{errors.gender}</span>
            )}
          </Col>
        </Row>       
        <Row className="mb-3">
          <Label htmlFor="email" className="col-md-2 col-form-label">
            Email<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              invalid={!!errors.email}
            />
            {errors.email && <span className="text-danger">{errors.email}</span>}
          </Col>
          <Label
            htmlFor="designation"
            className="col-md-2 col-form-label"
          >
            User Type<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="select"
              id="role"
              name="role"
              className="form-control"
              value={formData.role}
              onChange={handleRoleChange}
            style={{ color: formData.role ? 'initial' : '#6c757d' }}
            >
              <option value="">Select</option>
              {visibleRoles.map(role => (
                <option key={role._id} value={role._id} style={{color: 'initial'}}>
                  {role.name}
                </option>
              ))}
            </Input>
            {errors.role && <span className="text-danger">{errors.role}</span>}
          </Col>
        </Row>

         {/* <Row className="mb-3">
          { <Label htmlFor="doj" className="col-md-2 col-form-label">
            DOJ<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="date"
              id="doj"
              name="doj"
              className="form-control"
              value={formData.doj}
              max={getTodayDate()}
              onChange={handleChange}
              invalid={!!errors.doj}
              style={{ color: formData.doj ? 'initial' : '#6c757d' }}
            />
            {errors.doj && (
              <span className="text-danger">{errors.doj}</span>
            )}
          </Col> }        
        </Row> */}
      </div>
    )
  },

  {
    label:'CONTACT INFORMATION & SOCIAL PROFILES',
    content:(
      <div>
        <Row className="mb-3">
          <Label htmlFor="email" className="col-md-2 col-form-label">
            Twitter
          </Label>
          <Col md={4}>
            <Input
              type="url"
              id="twitter"
              name="twitter"
              className="form-control"
              placeholder="Twitter"
              value={formData.twitter}
              onChange={handleChange}
            />
            {errors.twitter && <span className="text-danger">{errors.twitter}</span>}
          </Col>
          <Label
            htmlFor="instagram"
            className="col-md-2 col-form-label"
          >
            Instagram
          </Label>
          <Col md={4}>
            <Input
              type="url"
              id="instagram"
              name="instagram"
              className="form-control"
              placeholder="Instagram"
              value={formData.instagram}
              onChange={handleChange}
            />
              {errors.instagram && <span className="text-danger">{errors.instagram}</span>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label htmlFor="linkedin" className="col-md-2 col-form-label">
            Linkedin
          </Label>
          <Col md={4}>
            <Input
              type="url"
              id="linkedin"
              name="linkedin"
              className="form-control"
              placeholder="Linkedin"
              value={formData.linkedin}
              onChange={handleChange}
            />
            {errors.linkedin && <span className="text-danger">{errors.linkedin}</span>}
          </Col>
          <Label
            htmlFor="social-media-profile"
            className="col-md-2 col-form-label"
          >
            Facebook
          </Label>
          <Col md={4}>
            <Input
              type="url"
              id="facebook"
              name="facebook"
              className="form-control"
              placeholder="Facebook"
              value={formData.facebook}
              onChange={handleChange}
            />
              {errors.facebook && <span className="text-danger">{errors.facebook}</span>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label
            htmlFor="profile-pic"
            className="col-md-2 col-form-label"
          >
            Profile<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="file"
              id="profilePicture"
              name="profilePicture"
              className="form-control"
              onChange={handleFileChange}
              invalid={!!errors.profilePicture}
            />
              {errors.profilePicture && <span className="text-danger">{errors.profilePicture}</span>}
          </Col>
          <Label htmlFor="password" className="col-md-2 col-form-label">
            Password<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <div className="password-input-container">
              <Input
                type={visible ? "text" : "password"}
                id="password"
                name="password"
                className="form-control"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                invalid={!!errors.password}
              />
              <span
                onClick={() => setVisible(!visible)}
                className="eye-icon"
              >
                {visible ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors.password && (
              <div className="password-requirements">
                Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character.
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label htmlFor="address" className="col-md-2 col-form-label">
            Address<span className="required-asterisk">*</span>
          </Label>
          <Col md={4}>
            <Input
              type="textarea"
              id="address"
              name="address"
              className="form-control"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
              invalid={!!errors.address}
            />
              {errors.address && <span className="text-danger">{errors.address}</span>}
          </Col>
          <Label htmlFor="address" className="col-md-2 col-form-label">
            About user
          </Label>
          <Col md={4}>
            <Input
              type="textarea"
              id="aboutUser"
              name="aboutUser"
              className="form-control"
              placeholder="About user"
              value={formData.aboutUser}
              onChange={handleChange}
              invalid={!!errors.aboutUser}
            />
              {errors.aboutUser && <span className="text-danger">{errors.aboutUser}</span>}
          </Col>
        </Row>
      </div>
    )
  }
 ]

 const handleNextStep = () => {
  if (currentStep < steps.length) setCurrentStep(currentStep + 1);
 };

 const handlePrevStep = () => {
  if (currentStep > 1) setCurrentStep(currentStep - 1);
 };

  return (
    <React.Fragment>
      <Row>
        <Col>
          {/* <Card> */}
          <CardBody>
              <Form onSubmit={(e) => handleSubmit(e, formData)}>
              <ModalStepper currentStep={currentStep} steps={steps}  handleNextStep={handleNextStep}
                  handlePrevStep={handlePrevStep} />
              </Form>
          </CardBody>
          {/* </Card> */}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default UserCreation;
