import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import DashboardCards from "./DashboardCards"
import "pages/Sqano Tile Box/SqanoTileDashboard.css"
import DashboardData from "./DashboardData"

const SqanoTileDashboard = props => {
  useEffect(() => {
    const breadcrumbItems = [
      { title: "SQANO", link: "#" },
      { title: "User Dashboard", link: "#" },
    ]
    props.setBreadcrumbItems("User Dashboard", breadcrumbItems)
  }, [props])

  return (
    <div className="sqano-Tile-dashboard-container">
      {DashboardData.map(card => (
        <DashboardCards key={card.id} {...card} />
      ))}
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(SqanoTileDashboard)
