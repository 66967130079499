import {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SEND_OTP_SMS_REQUEST,
  SEND_OTP_SMS_SUCCESS,
  SEND_OTP_SMS_FAILURE,
  VERIFY_OTP_SMS_REQUEST,
  VERIFY_OTP_SMS_SUCCESS,
  VERIFY_OTP_SMS_FAILURE,
  RESET_REGISTRATION_STATE,
} from "./actionTypes"

const initialState = {
  email: "",
  contactNo: "",
  emailOtpSent: false,
  emailOtpVerified: false,
  smsOtpSent: false,
  smsOtpVerified: false,
  registrationMessage: null,
  message: null,
  loading: false,
  user: null,
  error: null,
  sendOtpLoading: false,
  verifyOtpLoading: false,
  registerLoading: false,
  sendOtpError: null,
  verifyOtpError: null,
  registerError: null,
  sendOtpSmsLoading: false,
  verifyOtpSmsLoading: false,
  sendOtpSmsError: null,
  verifyOtpSmsError: null,
}

const register = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return {
        ...state,
        sendOtpLoading: true,
        message: null,
        sendOtpError: null,
      }
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        emailOtpSent: true,
        sendOtpLoading: false,
        message: "OTP sent to email!",
      }
    case SEND_OTP_FAILURE:
      return {
        ...state,
        sendOtpLoading: false,
        sendOtpError: action.payload.message,
      }
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        verifyOtpLoading: true,
        message: null,
        verifyOtpError: null,
      }
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        emailOtpVerified: true,
        verifyOtpLoading: false,
        message: "OTP verified!",
      }
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpError: action.payload,
      }
    case SEND_OTP_SMS_REQUEST:
      return {
        ...state,
        sendOtpSmsLoading: true,
        message: null,
        sendOtpSmsError: null,
      }
    case SEND_OTP_SMS_SUCCESS:
      return {
        ...state,
        smsOtpSent: true,
        sendOtpSmsLoading: false,
        message: "OTP sent to contact number!",
      }
    case SEND_OTP_SMS_FAILURE:
      return {
        ...state,
        sendOtpSmsLoading: false,
        sendOtpSmsError: action.payload,
      }
    case VERIFY_OTP_SMS_REQUEST:
      return {
        ...state,
        verifyOtpSmsLoading: true,
        message: null,
        verifyOtpSmsError: null,
      }
    case VERIFY_OTP_SMS_SUCCESS:
      return {
        ...state,
        smsOtpVerified: true,
        verifyOtpSmsLoading: false,
        message: "OTP verified!",
      }
    case VERIFY_OTP_SMS_FAILURE:
      return {
        ...state,
        verifyOtpSmsLoading: false,
        verifyOtpSmsError: action.payload,
      }
    case REGISTER_REQUEST:
      console.log("action", action.payload)
      return {
        ...state,
        registerLoading: true,
        message: null,
        registrationError: null,
        registrationMessage:null,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        registerLoading: false,
        registrationMessage: action.payload.message,
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        registerLoading: false,
        registerError: action.payload,
      }
    case RESET_REGISTRATION_STATE:
      return initialState

    default:
      return state
  }
}

export default register
