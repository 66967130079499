import React from 'react'
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import LinearProgress from "@mui/material/LinearProgress";

function CustomProgressBar({prog}) {
    const [progress,setProgress]=React.useState(0);

    React.useEffect(() => {
      setProgress(prog);
    }, [prog]);
  
    return (
      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%', display: 'flex',paddingX:"5px" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            border: "1px solid #660032",
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: `#660032`
            },
            flexGrow: 1
          }}
        />
        <FormLabel>{progress}%</FormLabel>
      </Stack>
    );
}

export default CustomProgressBar
