import React, { useEffect } from "react"

//Import Action to copy breadcrumb items from local state to redux state

const CommingSoon = props => {

  return <React.Fragment>
    <div>      
      <div className="row">
        <div className="text-center" >
        <h2 style={{fontSize:"40px", color:"#660032", fontWeight:"bold", marginTop:"60px"}}>Coming Soon...</h2>
        </div>
      </div>     
    </div>
  </React.Fragment>
}

export default CommingSoon;
