import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Row, Col } from 'react-bootstrap';

import * as Yup from 'yup';
import "./EditProfile.css"
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateUser } from 'store/actions';
import { FaUserEdit } from "react-icons/fa";
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import { Grid, Button, TextField } from '@mui/material';
import PulseLoader from 'pages/PulseLoader/PulseLoader';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = location?.state;
  const {loading} = useSelector(state => state.usersReducer)
  const [profilePicture, setProfilePicture] = useState(user?.profilePicture);
  const id = user?.id;
  const [editProfilePicture, setEditProfilePicture] = useState(false);

  const handleProfilePictureChange = (event) => {
    setProfilePicture(event.target.files[0])
  };

  const validationSchema = Yup.object({
    // userName: Yup.string().required('username is required'),
    firstName: Yup.string().required('Firstname is required'),
    lastName: Yup.string().required('Lastname is required'),
    contactNumber: Yup.string().required('Contact Number is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    designation: Yup.string().required('Designation is required'),
    location: Yup.string().required('Location is required'),
    aboutUser: Yup.string().required('About User is required'),
    linkedin: Yup.string().url('Invalid URL format'),
    twitter: Yup.string().url('Invalid URL format'),
    instagram: Yup.string().url('Invalid URL format'),
    facebook: Yup.string().url('Invalid URL format'),
  });

  const handleSubmit = async (values) => {

    values.profilePicture = profilePicture;
    const userData = new FormData()
    for (const key in values) {
      userData.append(key, values[key])
    }

    try {
      
      dispatch(updateUser({ id, userData }));
    } catch (error) {
      console.log("Error in updating user")
    } finally {
      if (user.source === "Profile"){
        navigate("/profile")
      toast.success('Profile updated successfully!', {
        // position: "top-center",
        autoClose: 500,
        progressClassName: 'toast-progress-bar',
        icon: <CheckCircleIcon style={{ color: '#660032' }} />,

      })
    }
      else{
        navigate("/super-admin")
        toast.success('Profile updated successfully!', {
          // position: "top-center",
          autoClose: 500,
          progressClassName: 'toast-progress-bar',
          icon: <CheckCircleIcon style={{ color: '#660032' }} />,
  
        })
      }
    }
  };

  // useEffect(() => {
  //   if (formData !== undefined) {
  //     dispatch(updateUser({ id, formData }));
  //   }
  // }, [formData, dispatch]);

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: user?.source === "Profile" ? "User Profile" : "User Management", href: user?.source === "Profile" ? "/profile" : "/super-admin" },
    { label: "Edit Profile", href: "/editProfile" },
  ];

  return (
    <>
      {
        loading && <PulseLoader /> 
      }
      <CustomBreadcrumb items={breadcrumbItems} />
      <div className="edit-profile">
        {/* <h1>EDIT PROFILE</h1> */}
        <div className="profile-picture mb-2">
          <img src={user?.profilePicture} alt="Profile" />
          {
            editProfilePicture ?
              <input type="file" onChange={handleProfilePictureChange} /> :
              <button className='mt-2'
                style=
                {{
                  backgroundColor: "#660032",
                  color: "#ffffff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={() => setEditProfilePicture(true)}>
                  EDIT PICTURE
              </button>
          }

        </div>
        <Formik
          initialValues={{
            // userName: user?.userName || '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            contactNumber: user?.contactNumber || '',
            email: user?.email || '',
            designation: user?.designation || '',
            location: user?.location || '',
            aboutUser: user?.aboutUser || '',
            profilePicture: user?.profilePicture || "",
            linkedin: user?.linkedin || '',
            twitter: user?.twitter || '',
            instagram: user?.instagram || '',
            facebook: user?.facebook || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >

          <Form>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6} md={4}>
                <div className="form-group">
                  <Field type="text" name="userName" placeholder="User Name" />
                  <ErrorMessage name="userName" component="div" className="error-message" />
                </div>
              </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0">First Name</label>
                <div className="form-group">
                  <Field type="text" name="firstName" placeholder="First Name" />
                  <ErrorMessage name="firstName" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0">Last Name</label>
                <div className="form-group">
                  <Field type="text" name="lastName" placeholder="Last Name" />
                  <ErrorMessage name="lastName" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0">Contact Number</label>
                <div className="form-group">
                  <Field type="text" name="contactNumber" placeholder="Contact Number" />
                  <ErrorMessage name="contactNumber" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >Email</label>
                <div className="form-group">
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >Designation</label>
                <div className="form-group">
                  <Field type="text" name="designation" placeholder="Designation" />
                  <ErrorMessage name="designation" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >Location</label>
                <div className="form-group">
                  <Field type="text" name="location" placeholder="Location" />
                  <ErrorMessage name="location" component="div" className="error-message" />
                </div>
              </Grid>
              
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >LinkedIn</label>
                <div className="form-group">
                  <Field type="url" name="linkedin" placeholder="LinkedIn" />
                  <ErrorMessage name="linkedin" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >Twitter</label>
                <div className="form-group">
                  <Field type="url" name="twitter" placeholder="Twitter" />
                  <ErrorMessage name="twitter" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >Instagram</label>
                <div className="form-group">
                  <Field type="url" name="instagram" placeholder="Instagram" />
                  <ErrorMessage name="instagram" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label className="p-0 m-0"  >Facebook</label>
                <div className="form-group">
                  <Field type="url" name="facebook" placeholder="Facebook" />
                  <ErrorMessage name="facebook" component="div" className="error-message" />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <label className="p-0 m-0"  style={{fontSize:"12px"}}>About User</label>
                <div className="form-group">
                  <Field type="text" name="aboutUser" placeholder="About User" />
                  <ErrorMessage name="aboutUser" component="div" className="error-message" />
                </div>
              </Grid>
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <button variant="contained"  type="submit" className="update-button" >
                SUBMIT
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>

  );
};

export default EditProfile;
