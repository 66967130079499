import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';

const PersonalInformationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  contact: Yup.string().required('Contact is required'),
  location: Yup.string().required('Location is required'),
  preferredLocation: Yup.string().required('Preferred location is required'),
  jobTitle: Yup.string().required('Job title is required'),
  totalExperience: Yup.number().required('Total experience is required').min(0, 'Experience must be a positive number'),
});

const PersonalInformationForm = ({ onSubmit, info }) => {
  const {
    name,email,contact,location,
    jobTitle,
    preferred_Location,
    experience,
  } = info
  return (
    <Formik
      initialValues={{
        name: name || "NA",
        email: email || "NA",
        contact: contact || "NA",
        location: location || "NA",
        preferredLocation: preferred_Location || "NA",
        jobTitle: jobTitle || "NA",
        totalExperience: experience || "NA",
      }}
      validationSchema={PersonalInformationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, handleChange, handleBlur }) => (
        <Form>
          <Box mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Name"
                  fullWidth
                  margin="normal"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  margin="normal"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contact"
                  label="Contact"
                  fullWidth
                  margin="normal"
                  value={values.contact}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.contact && !!errors.contact}
                  helperText={touched.contact && errors.contact}
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="location"
                  label="Location"
                  fullWidth
                  margin="normal"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.location && !!errors.location}
                  helperText={touched.location && errors.location}
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="preferredLocation"
                  label="Preferred Location"
                  fullWidth
                  margin="normal"
                  value={values.preferredLocation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.preferredLocation && !!errors.preferredLocation}
                  helperText={touched.preferredLocation && errors.preferredLocation}
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="jobTitle"
                  label="Job Title"
                  fullWidth
                  margin="normal"
                  value={values.jobTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.jobTitle && !!errors.jobTitle}
                  helperText={touched.jobTitle && errors.jobTitle}
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="totalExperience"
                  label="Total Experience (years)"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={values.totalExperience}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.totalExperience && !!errors.totalExperience}
                  helperText={touched.totalExperience && errors.totalExperience}
                  InputProps={{
                    style: {
                      paddingLeft: '10px', // Adjust the padding as needed
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalInformationForm;
