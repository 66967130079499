//create job requirement
export const JOB_CREATION = "JOB_CREATION"
export const JOB_CREATION_SUCCESS = "JOB_CREATION_SUCCESS"
export const JOB_CREATION_FAILURE = "JOB_CREATION_FAILURE"

export const UPDATE_JOB_REQUIREMENT = "UPDATE_JOB_REQUIREMENT"
export const UPDATE_JOB_REQUIREMENT_SUCCESS = "UPDATE_JOB_REQUIREMENT_SUCCESS"
export const UPDATE_JOB_REQUIREMENT_FAILURE = "UPDATE_JOB_REQUIREMENT_FAILURE"  

//GET all job requirements
export const GET_ALL_JOB_REQUIREMENTS = "GET_ALL_JOBS"
export const GET_ALL_JOB_REQUIREMENT_SUCCESSS ="GET_ALL_JOB_REQUIREMENT_SUCCESSS"
export const GET_ALL_JOB_REQUIREMENT_FAILURE = "GET_ALL_JOB_REQUIREMENT_FAILURE"

export const GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID = "GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID"
export const GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_SUCCESSS ="GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_SUCCESSS"
export const GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_FAILURE = "GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_FAILURE"

// actionTypes.js
export const FETCH_JOB_REQUIREMENTS_REQUEST = "FETCH_JOB_REQUIREMENTS_REQUEST"
export const FETCH_JOB_REQUIREMENTS_SUCCESS = "FETCH_JOB_REQUIREMENTS_SUCCESS"
export const FETCH_JOB_REQUIREMENTS_FAILURE = "FETCH_JOB_REQUIREMENTS_FAILURE"

export const GET_ALL_USERS_BY_ENTITY_ID = "GET_ALL_USERS_BY_ENTITY_ID";
export const GET_ALL_USERS_BY_ENTITY_ID_SUCCESS= "GET_ALL_USERS_BY_ENTITY_ID_SUCCESS";
export const GET_ALL_USERS_BY_ENTITY_ID_FAILURE="GET_ALL_USERS_BY_ENTITY_ID_FAILURE"

export const FETCH_JOB_REQUIREMENT_REQUEST = 'FETCH_JOB_REQUIREMENT_REQUEST';
export const FETCH_JOB_REQUIREMENT_SUCCESS = 'FETCH_JOB_REQUIREMENT_SUCCESS';
export const FETCH_JOB_REQUIREMENT_FAILURE = 'FETCH_JOB_REQUIREMENT_FAILURE';

export const POST_JOB_REQUEST = 'POST_JOB_REQUEST';
export const POST_JOB_SUCCESS = 'POST_JOB_SUCCESS';
export const POST_JOB_FAILURE = 'POST_JOB_FAILURE';

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
