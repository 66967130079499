import React, { useState, useEffect } from "react"
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  CardHeader,
  Avatar,
  Checkbox,
  CardContent,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  Skeleton,
  Tooltip,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Rating,
  Snackbar,
  Alert,
  ButtonGroup,
  Autocomplete,
  InputBase,
} from '@mui/material';
import { mdiFileSearch, mdiUploadBox, mdiImport, mdiMagnifyScan } from '@mdi/js';
import Icon from '@mdi/react';
import ReplayIcon from "@mui/icons-material/Replay"
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { createSearchRequest, deleteProfileRequest, fetchSearchesRequest, filterCandidatesRequest, insertWorkflowsRequest, uploadFileRequest, clearCandidateData } from 'store/actions';
import Drawer from '@mui/material/Drawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import GeneratePdf from 'components/Common/GeneratePdf/GeneratePdf';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import civixIcon from "../../assets/images/search-img.png";
import noDataFound from "../../assets/images/no_data_found.png"
import cvDetailsIcon from "../../assets/images/details-img.png";
import EvaluationPrompt from "./ScanItEvaluationPrompt";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useLocation, Link } from 'react-router-dom';
import ProductTabs from "./ProductTabs";
import "./ScanIT.css"
import CloseIcon from '@mui/icons-material/Close';
import CustomWarn from "components/CustomComponents/CustomWarn";
import CustomBreadcrumb from "components/CustomComponents/CustomBreadCrumb";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PulseLoader from "pages/PulseLoader/PulseLoader";


const ScanIT = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  let candidatesData = useSelector(state => state.profileScanReducer.filterCandidates)
  
  // console.log("Atharv count",scannedProfilesCount)
  let { error, loading } = useSelector(state => state.profileScanReducer);
  console.log("candidates", error)
  let { collectedInfo, failedProfiles, progress } = useSelector(
    state => state.profileScanReducer,
  )
  let searches = useSelector((state) => state.profileScanReducer.searches);
  const entityData = useSelector(state => state?.Vendor?.vendor || null)
  const { userDetails } = useSelector((state) => state.Login?.user);
  const dataFromParseit = useSelector(state => state.parseProfilesReducer?.sqanitData)
  const scannedProfilesCount = dataFromParseit?.length || 0;
  const [isAutoFilled, setIsAutoFilled] = useState(false);


  const { entityId, id, roleAndFeatures } = userDetails;
  const [jobRequirementInfo, setJobRequirementInfo] = useState({
    jobRequirementId: "",
    jobTitle: "",
    experience: "",
    mandatorySkills: "",
    location: "",
    jobDescription: "",
  })
  const [selectedButton, setSelectedButton] = useState("select");
  const [selectedCandidate, setSelectedCandidate] = useState([])
  const [showAllSkills, setShowAllSkills] = useState(null)
  const [showCandidateDetails, setShowCandidateDetails] = useState({})
  const [showButton, setShowButton] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [loadingFiles, setLoadingFiles] = useState([])
  const [analysis, setAnalysis] = useState({})
  console.log("analysis", analysis)
  const [modalOpen, setModalOpen] = useState(false)
  const [status, setStatus] = useState("")
  const [activeStep, setActiveStep] = useState(0)
  const [actionClicked, setActionClicked] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [dataSource, setDataSource] = useState('Internal');
  const [candidateCount, setCandidateCount] = useState(0);
  const [activeButton, setActiveButton] = useState("scanned")
  const [failedProfilesInfo, setFailedProfilesInfo] = useState([]);
  const [checked, setChecked] = useState([]);
  let [selectedProfiles, setSelectedProfiles] = React.useState([]);
  const [selectedJobRequirement, setSelectedJobRequirement] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const steps = [
    "Personal Information",
    "Sqano recommended Q & A",
    "Job Application",
    "PDF Generation",
  ]

  const [formData, setFormData] = useState({
    noticePeriod: "",
    availabilityDate: "",
    screeningDate: "",
    skillsAndRating: [{ skill: "", rating: 1 }],
    currentCTC: "",
    expectedCTC: 0,
    relevantExperience: 0,
    overallExperience: 0,

  })
  const [ratings, setRatings] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [skeletonRows, setSkeletonRows] = useState([])
  const [combinedRows, setCombinedRows] = useState([])

  const [stepStatusData, setStepStatusData] = useState([
    { progressStatus: "", remark: "" }, 
    { progressStatus: "", remark: "" }, 
    { progressStatus: "", remark: "" }, 
    { progressStatus: "", remark: "" }, 
  ]);

  console.log("stepStatusData", stepStatusData)

  useEffect(() => {
    const skeletonCount = loadingFiles.length - (collectedInfo?.length + failedProfilesInfo?.length);
    const myArray = Array.from({ length: skeletonCount }, (_, index) => index);
    setSkeletonRows(myArray);
  }, [loadingFiles, collectedInfo, failedProfilesInfo]);

  useEffect(() => {
    if (location.state && location.state.requirementData) {
      setJobRequirementInfo(location.state?.requirementData)
      setSelectedJobRequirement(location.state?.requirementData?.jobRequirementId)
    }
  }, [location]);

  useEffect(() => {
    const combinedRows = [...skeletonRows, ...collectedInfo]
    setCombinedRows(combinedRows)
  }, [collectedInfo, skeletonRows])

  const handleRatingChange = (index, value) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [index]: value,
    }))
  }

  const handleStatusChange = (e, stepIndex) => {
    const { name, value } = e.target;
    console.log("status", stepStatusData);
    
    // Ensure stepStatusData is an array
    const updatedStepStatusData = [...stepStatusData];
    console.log("updatedStepStatusData",updatedStepStatusData)
    // Update the status for the current step
    // console.log("step", stepStatusData)
    // const updatedStepStatusData = [...stepStatusData];
    updatedStepStatusData[stepIndex] = {
        ...updatedStepStatusData[stepIndex],
        [name]: value,
    };

    // Clear the status for the next step if moving to the next step
    if (stepIndex < stepStatusData.length - 1) {
        updatedStepStatusData[stepIndex + 1].progressStatus = "";
        updatedStepStatusData[stepIndex + 1].remark = ""; 
    }

    setStepStatusData(updatedStepStatusData);
  };

  const handleChange = (e, newValue, index) => {
    const { name, value } = e.target
    console.log("statusname", name, value)

    if (name === "skill" || name === "rating") {
      let updatedSkills = [...formData.skillsAndRating]
      let updatedSkill = { ...updatedSkills[index], [name]: newValue }
      updatedSkills[index] = updatedSkill
      setFormData({ ...formData, skillsAndRating: updatedSkills })
    } else {
      const updatedFormData = { ...formData, [name]: value }
      setFormData(updatedFormData)
    }
  }

  const jobRequirements = searches?.map((search, index) => ({
    index: index,
    label: `REQ NO - ${index} - ${search.jobTitle}`,
  }));

  const handleJobRequirementChange = (e, newValue) => {
    setIsAutoFilled(true);
    setSelectedJobRequirement(newValue?.jobRequirementId)
    setJobRequirementInfo({
      jobRequirementId: newValue?.jobRequirementId,
      jobTitle: newValue?.jobTitle,
      experience: newValue?.yearsOfExperience,
      mandatorySkills: newValue?.mandatorySkills.join(', '),
      location: newValue?.city,
      jobDescription: newValue?.jobDescription,
    });
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setJobRequirementInfo(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const isFormIncomplete = () => {
    const areBasicFieldsIncomplete =
      formData.availabilityDate === "" ||
      formData.noticePeriod <= 0 ||
      formData.currentCTC === "" ||
      formData.expectedCTC <= 0 ||
      formData.overallExperience <= 0 || // Corrected condition
      formData.relevantExperience <= 0;  // Corrected condition


    const areSkillsIncomplete = formData.skillsAndRating?.some(skill => {
      return skill.skill === "" || skill.rating <= 0
    })

    return areBasicFieldsIncomplete || areSkillsIncomplete
  }

  const handleNext = () => {
    const currentStepStatus = stepStatusData[activeStep].progressStatus;
    if (!currentStepStatus) {
        console.log("Please select a status before proceeding.");
        return; // Prevent moving to the next step if no status is selected
    }
    console.log("Form Data before moving to next step:", formData);

    if (activeStep === 0) {
      const copyCollectedInfo = collectedInfo[selectedIndex]
      copyCollectedInfo.status = "25%"
      copyCollectedInfo.progressStatus = currentStepStatus;  // Update progress status
      copyCollectedInfo.remark = stepStatusData[activeStep].remark;  // Update remark
    } else if (activeStep === 1) {
      const copyCollectedInfo = collectedInfo[selectedIndex]
      copyCollectedInfo.analysis.questionnaire =
        copyCollectedInfo.analysis.questionnaire.map((item, index) => ({
          ...item,
          rating: ratings[index] || 0,
        }))
      copyCollectedInfo.status = "50%"
      copyCollectedInfo.progressStatus = currentStepStatus;  // Update progress status
      copyCollectedInfo.remark = stepStatusData[activeStep].remark;  // Update remark
    } else if (activeStep === 2) {
      let copyCollectedInfo = collectedInfo[selectedIndex]
      copyCollectedInfo.reviewDetails = formData
      copyCollectedInfo.status = "75%"
      copyCollectedInfo.progressStatus = currentStepStatus;  // Update progress status
      copyCollectedInfo.remark = stepStatusData[activeStep].remark;  // Update remark
    } else {
      const copyCollectedInfo = collectedInfo[selectedIndex]
      copyCollectedInfo.status = "100%"
      copyCollectedInfo.progressStatus = currentStepStatus;  // Update progress status
      copyCollectedInfo.remark = stepStatusData[activeStep].remark;  // Update remark
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSubmit = () => {
    dispatch(createSearchRequest(jobRequirementInfo))
    dispatch(filterCandidatesRequest(jobRequirementInfo))
    setShowButton(true)
  }
  useEffect(() => {
    setCandidateCount(candidatesData.length);
  }, [candidatesData]);


  const handleConfirm = () => {
    dispatch(clearCandidateData())
    let copyCollectedInfo = [...collectedInfo]
    // let filteredCandidate = copyCollectedInfo.filter(candidate => {
    //   return parseInt(candidate.fitmentPercentage) >= 70
    // })
    dispatch(insertWorkflowsRequest(copyCollectedInfo))
    setJobRequirementInfo({
      jobRequirementId: "",
      jobTitle: "",
      experience: "",
      mandatorySkills: "",
      location: "",
      jobDescription: "",
    })
    setSelectedJobRequirement(null)
    setSelectedFiles([])
    setRatings({})
    setFormData({
      noticePeriod: "",
      availabilityDate: "",
      screeningDate: "",
      skillsAndRating: [{ skill: "", rating: 1 }],
      currentCTC: "",
      expectedCTC: 0,
      relevantExperience: 0,
      overallExperience: 0,
    })

    setStepStatusData({
      progressStatus: "",
      remark: "",
    })

    // setSelectedCandidate([]);
    setShowButton(false)
    setOpen(false);
    setIsDrawerOpen(!isDrawerOpen);
  }

  const message = "If you close this process now, all your actions will be automatically saved, and you will be able to view these details in your history. Do you wish to proceed?";


  const handleSaveJobApplication = async () => {
    console.log("save sachin")
  }

  const handleFinish = async () => {
    let updatedCollectedInfo = [...collectedInfo];
    if (currentIndex >= 0 && currentIndex < updatedCollectedInfo.length) {
      updatedCollectedInfo[currentIndex].status = "100%";
      // dispatch(insertWorkflowsRequest([updatedCollectedInfo[currentIndex]]));
    }

    const nextIndex = findNextEligibleCandidate(currentIndex);
    console.log("nextIndex", nextIndex)
    if (nextIndex !== null) {
      console.log("inner analysis", collectedInfo[nextIndex])
      setCurrentIndex(nextIndex);
      setSelectedIndex(nextIndex);
      setActiveStep(0);
      setAnalysis(collectedInfo[nextIndex]);
      setModalOpen(true);
      setStepStatusData([
        { progressStatus: "", remark: "" },
        { progressStatus: "", remark: "" },
        { progressStatus: "", remark: "" },
        { progressStatus: "", remark: "" },
      ]);
    } else {
      setModalOpen(false)
    }
    setRatings({});
    const skillsAndRating = transformSkillsToSkillsAndRating(
      collectedInfo[nextIndex]?.skills,
    )
    setFormData({
      noticePeriod: "",
      availabilityDate: "",
      screeningDate: "",
      currentCTC: "",
      expectedCTC: 0,
      skillsAndRating:
        collectedInfo[nextIndex]?.reviewDetails?.skillsAndRating?.length > 0
          ? collectedInfo[nextIndex]?.reviewDetails?.skillsAndRating
          : skillsAndRating,
      relevantExperience: 0,
      overallExperience: 0,
    });
  };


  const handleCheck = (e, index) => {
    e.stopPropagation()
    const candidate = candidatesData[index]
    const exists = selectedCandidate.some(item => item._id === candidate._id)

    if (exists) {
      setSelectedCandidate(
        selectedCandidate.filter(item => item._id !== candidate._id),
      )
    } else {
      setSelectedCandidate([...selectedCandidate, candidate])
    }
  }
  const toggleSkillsExpansion = (candidateId) => {
    setShowAllSkills((prevId) => (prevId === candidateId ? null : candidateId));
  };

  const formatSkills = (skills, all = false) => {
    if (!all && skills.length > 2) {
      return `${skills.slice(0, 2).join(", ")}, ...`
    }
    return skills.join(", ")
  }

  const handleCardClick = (e, candidate) => {
    setShowCandidateDetails(candidate)
  }

  const handleFileChange = event => {
    const files = Array.from(event.target.files)
    setSelectedFiles(files)
    // setLoadingFiles(files.map(() => ({ loading: true })));
  }

  const handleScanIt = () => {
    console.log("selectedFiles=>", selectedFiles)
    setActiveButton("scanned")
    setLoadingFiles(selectedFiles.map(() => ({ loading: true })))
    setIsDrawerOpen(!isDrawerOpen)
    dispatch(uploadFileRequest({ files: selectedFiles, jobRequirementInfo, parsedBy: id, reupload: false }))
  }


  const transformSkillsToSkillsAndRating = skillsInput => {
    if (Array.isArray(skillsInput)) {
      return skillsInput.map(skill => ({
        skill,
        rating: 1,
      }));
    } else if (typeof skillsInput === 'string') {
      const skillsArray = skillsInput.split(",");
      return skillsArray.map(skill => ({
        skill: skill.trim(),
        rating: 1,
      }));
    } else {
      return [];
    }
  }

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleDataSourceChange = (source) => {
    setDataSource(source);
    // Here you can add logic to fetch or set the data for 'sqano' or 'own'
  };

  const getStatusOptions = (step) => {
    switch (step) {
      case 0:
        return [
          { value: 'call_not_received', label: 'Call Not Received' },
          { value: 'not_interested', label: 'Candidate Not Interested' },
          { value: 'interested', label: 'Candidate Interested' }
        ];
      case 1:
        return [
          { value: 'skills_not_relevant', label: 'Skills Not Relevant' },
          { value: 'skills_relevant', label: 'Skills Relevant' },
          { value: 'skills_review_in_progress', label: 'Skills Review In Progress' },
          { value: 'skills_review_completed', label: 'Skills Review Completed' },
        ];
      case 2:
        return [
          { value: 'experience_incomplete', label: 'Experience Incomplete' },
          { value: 'ctc_discussion_in_progress', label: 'CTC Discussion In Progress' },
          { value: 'interview_scheduled', label: 'Interview Scheduled' },
          { value: 'interview_completed', label: 'Interview Completed' },
        ];
      case 3:
        return [
          { value: 'offer_extended', label: 'Offer Extended' },
          { value: 'hired', label: 'Hired' }, 
          { value: 'waiting_for_candidate', label: 'Waiting for Candidate' },
          { value: 'no_response', label: 'No Response' },
          { value: 'candidate_reject', label: 'Candidate Reject' },
          { value: 'in_progress', label: 'In Progress' },
        ];
      default:
        return [];
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Grid container className="step-content-grid">
            <Grid item lg={4} xs={12} sm={5} sx={{ display: "flex", flexDirection: "column" }}>
              <span className="info-label">Name:</span>
              <span className="info-label">Email:</span>
              <span className="info-label">Contact:</span>
            </Grid>

            <Grid item lg={8} xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
              <span className="info-value">{analysis?.name || "NA"}</span>
              <span className="info-value">{analysis?.email || "NA"}</span>
              <span className="info-value">{analysis?.phone || "NA"}</span>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "16px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink className="step-content-label">Status</InputLabel>
                <Select
                  value={stepStatusData[activeStep]?.progressStatus}
                  onChange={(e) => handleStatusChange(e, step)}
                  displayEmpty
                  inputProps={{
                    name: 'progressStatus',
                  }}
                  className="step-content-input-select"
                >
                  <MenuItem value="" disabled className="menu-item">
                    Select Status
                  </MenuItem>
                  {getStatusOptions(0).map(option => (
                    <MenuItem key={option.value} value={option.value} className="menu-item">
                      {option.label}
                    </MenuItem>
                  ))} 
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <TextField
                fullWidth
                label="Remark"
                name="remark"
                variant="outlined"
                multiline
                rows={3}
                className="step-content-textfield"
                value={stepStatusData[activeStep]?.remark}
                onChange={(e) => handleStatusChange(e, step)}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <div className="step-content-grid">
            {analysis?.analysis?.questionnaire?.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <h6 style={{ fontWeight: "bold", fontFamily: "Roboto", fontSize: "12px" }}>{item.Q}</h6>
                    <p style={{ fontFamily: "Roboto", fontSize: "12px" }}>{item.ANS}</p>
                  </div>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width="150px"
                  >
                    <Rating
                      name={`rating-${index}`}
                      value={ratings[index] || 0}
                      onChange={(event, newValue) => handleRatingChange(index, newValue)}
                      size="small"
                      sx={{ color: "#660032" }}
                    />
                  </Box>
                </div>
              </React.Fragment>
            ))}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink className="step-content-label"> Status</InputLabel>
                <Select
                  inputProps={{
                    name: 'progressStatus',
                  }}
                  value={stepStatusData[activeStep]?.progressStatus}
                  onChange={(e) => handleStatusChange(e, step)}
                  displayEmpty
                  className="step-content-input-select"
                >
                  <MenuItem value="" disabled className="menu-item">
                    Select Status
                  </MenuItem>
                  {getStatusOptions(1).map(option => (
                    <MenuItem key={option.value} value={option.value} className="menu-item">{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <TextField
                fullWidth
                label="Remark"
                name="remark"
                variant="outlined"
                multiline
                rows={3}
                className="step-content-textfield"
                value={stepStatusData[activeStep]?.remark}
                onChange={(e) => handleStatusChange(e, step)}
              />
            </Grid>
          </div>
        );
      case 2:
        return (
          <div className="step-content-grid">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Availability Slot"
                  name="availabilityDate"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '12px', fontFamily: 'Roboto' }, // Set font size for the label
                  }}
                  InputProps={{
                    inputProps: {
                      min: getCurrentDateTime(),
                      style: { fontSize: '12px', fontFamily: 'Roboto' },
                    },
                    placeholder: 'Select date and time'
                  }}
                  value={formData.availabilityDate || ""}
                  onChange={(e) => handleChange(e)}
                  disabled={status === "Completed"}
                  className="custom-textfield"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink className="step-content-label">Notice Period (Days)</InputLabel>
                  <Select
                    value={formData.noticePeriod || ""}
                    onChange={(e) => handleChange(e)}
                    displayEmpty
                    inputProps={{ name: 'noticePeriod' }}
                    className="step-content-input-select"
                    disabled={status === "Completed"}
                  >
                    <MenuItem value="" disabled className="menu-item">
                      Notice Period
                    </MenuItem>
                    <MenuItem value={7}>7 Days</MenuItem>
                    <MenuItem value={15}>15 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                    <MenuItem value={60}>60 Days</MenuItem>
                    <MenuItem value={90}>90 Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Current CTC (LPA)"
                  name="currentCTC"
                  placeholder="INR"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '12px', fontFamily: 'Roboto' }, // Set font size and family for the label
                  }}
                  value={formData.currentCTC || ""}
                  onChange={(e) => handleChange(e)}
                  disabled={status === "Completed"}
                  inputProps={{
                    style: {
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                      color: 'black'
                    },
                  }}
                  className="custom-textfield"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Expected CTC (LPA)"
                  name="expectedCTC"
                  placeholder="INR"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '12px', fontFamily: 'Roboto' }, // Set font size and family for the label
                  }}
                  value={formData.expectedCTC || ""}
                  onChange={(e) => handleChange(e)}
                  disabled={status === "Completed"}
                  inputProps={{
                    style: {
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                      color: 'Black'
                    },
                  }}
                  className="custom-textfield"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink className="step-content-label"> Relevant Experience (Years)</InputLabel>
                  <Select
                    value={formData.relevantExperience || ""}
                    onChange={(e) => handleChange(e)}
                    displayEmpty
                    disabled={status === "Completed"}
                    inputProps={{ name: 'relevantExperience' }}
                    className="step-content-input-select"
                  >
                    <MenuItem value="" disabled className="menu-item">
                      Relevant Experience
                    </MenuItem>
                    {[...Array(21).keys()].map((year) => (
                      <MenuItem key={year} value={year} className="menu-item">
                        {year} {year === 1 ? "Year" : "Years"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink className="step-content-label">  Overall Experience (Years) </InputLabel>
                  <Select
                    value={formData.overallExperience || ""}
                    onChange={(e) => handleChange(e)}
                    displayEmpty
                    disabled={status === "Completed"}
                    inputProps={{ name: 'overallExperience' }}
                    className="step-content-input-select"
                  >
                    <MenuItem value="" disabled className="menu-item">
                      Overall Experience
                    </MenuItem>
                    {[...Array(21).keys()].map((year) => (
                      <MenuItem key={year} value={year} className="menu-item">
                        {year} {year === 1 ? "Year" : "Years"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {formData.skillsAndRating.map((skill, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={5}
                    lg={6}
                    sm={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span>{skill.skill}</span>
                    <Slider
                      size="small"
                      aria-label="Always visible"
                      min={1}
                      max={5}
                      step={1}
                      disabled={status === "Completed"}
                      sx={{
                        "& .MuiSlider-thumb": { color: "#660032" },
                        "& .MuiSlider-track": { color: "#660032" },
                        "& .MuiSlider-rail": { color: "#660032" },
                      }}
                      name="rating"
                      value={skill.rating}
                      marks={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" },
                      ]}
                      onChange={(event, newValue) => handleChange(event, newValue, index)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard" style={{ marginTop: "16px" }}>
                  <InputLabel shrink className="step-content-label">Status</InputLabel>
                  <Select
                    inputProps={{
                      name: 'progressStatus',
                    }}
                    className="step-content-input-select"
                    value={stepStatusData[activeStep]?.progressStatus}
                    onChange={(e) => handleStatusChange(e, step)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled className="menu-item">
                      Select Status
                    </MenuItem>
                    {getStatusOptions(2).map(option => (
                      <MenuItem key={option.value} value={option.value} className="menu-item">{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Remark"
                  name="remark"
                  variant="outlined"
                  placeholder='Remark'
                  multiline
                  rows={3}
                  className="step-content-textfield"
                  value={stepStatusData[activeStep]?.remark}
                  onChange={(e) => handleStatusChange(e, step)}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return (
          <>
            <GeneratePdf analysis={analysis} data={formData.skillsAndRating} />
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard" style={{ marginTop: "16px" }}>
                <InputLabel shrink
                  className="step-content-label"
                >Status</InputLabel>
                <Select
                  inputProps={{
                    name: 'progressStatus',
                  }}
                  className="step-content-input-select"
                  value={stepStatusData[activeStep]?.progressStatus}
                  onChange={(e) => handleStatusChange(e, step)}
                  displayEmpty
                >
                  <MenuItem value="" disabled className="menu-item">
                    Select Status
                  </MenuItem>
                  {getStatusOptions(3).map(option => (
                    <MenuItem key={option.value} value={option.value} className="menu-item">{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Remark Field */}

            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <TextField
                fullWidth
                label="Remark (optional)"
                name="remark"
                variant="outlined"
                value={stepStatusData[activeStep]?.remark}
                onChange={(e) => handleStatusChange(e, step)}
                multiline
                rows={3}
                className="step-content-textfield"
              />
            </Grid>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  const handleViewIconClick = (rowData, rowIndex) => {

    const fitment = parseInt(collectedInfo[rowIndex]?.fitmentPercentage);
    if (fitment >= 65) {
      setActionClicked(true);
      setShowStepper(true);
      if (collectedInfo[rowIndex].status === "0%") {
        setActiveStep(0);
      } else if (collectedInfo[rowIndex].status === "25%") {
        setActiveStep(1);
      } else if (collectedInfo[rowIndex].status === "50%") {
        setActiveStep(2);
      } else if (collectedInfo[rowIndex].status === "75%") {
        setActiveStep(3);
      } else {
        setActiveStep(3);
      }
      setModalOpen(true);
      setAnalysis(collectedInfo[rowIndex]);
      setSelectedIndex(rowIndex);
      setCurrentIndex(rowIndex);
       // Set the status and remarks based on the selected candidate
    
      const skillsAndRating = transformSkillsToSkillsAndRating(
        collectedInfo[rowIndex]?.skills,
      )
      setFormData({
        noticePeriod: collectedInfo[rowIndex]?.reviewDetails?.noticePeriod || "",
        availabilityDate: collectedInfo[rowIndex]?.reviewDetails?.availabilityDate
          ? formatToDateTimeLocal(
            collectedInfo[rowIndex]?.reviewDetails?.availabilityDate,
          )
          : "",
        screeningDate:
          collectedInfo[rowIndex]?.reviewDetails?.screeningDate || "",
        skillsAndRating:
          collectedInfo[rowIndex]?.reviewDetails?.skillsAndRating?.length > 0
            ? collectedInfo[rowIndex]?.reviewDetails?.skillsAndRating
            : skillsAndRating,
        currentCTC: collectedInfo[rowIndex]?.reviewDetails?.currentCTC || "",
        expectedCTC: collectedInfo[rowIndex]?.reviewDetails?.expectedCTC || "",
        relevantExperience: collectedInfo[rowIndex]?.reviewDetails?.relevantExperience || "",
        overallExperience: collectedInfo[rowIndex]?.reviewDetails?.overallExperience || "",
      })
      const initialRatings = collectedInfo[
        rowIndex
      ]?.analysis?.questionnaire.reduce((acc, item, index) => {
        acc[index] = item.rating || 0
        return acc
      }, {})

      setRatings(initialRatings)


      setStepStatusData([
        { progressStatus: collectedInfo[rowIndex]?.progressStatus || "", remark: collectedInfo[rowIndex]?.remark || "" },
        { progressStatus: collectedInfo[rowIndex]?.progressStatus || "", remark: collectedInfo[rowIndex]?.remark || "" },
        { progressStatus: collectedInfo[rowIndex]?.progressStatus || "", remark: collectedInfo[rowIndex]?.remark || "" },
        { progressStatus: collectedInfo[rowIndex]?.progressStatus || "", remark: collectedInfo[rowIndex]?.remark || "" },
      ]);
      setStatus(collectedInfo[rowIndex]?.status)
    } else {

    }
  }

  function formatToDateTimeLocal(isoString) {
    const date = new Date(isoString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")
    const hours = date.getHours().toString().padStart(2, "0")
    const minutes = date.getMinutes().toString().padStart(2, "0")
    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            paddingTop: "15px",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            // padding: "0px 8px",
            fontSize: "0.875rem",
          },
        },
      },
    },
  })

  const columns = [
    {
      name: "_id",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
        print: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head-small">
            {columnMeta.label}
          </th>
        ),
        customBodyRender: value => {
          return (
            <Tooltip
              title={value}
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#660032",
                    color: "#ffffff",
                  },
                },
              }}
            >
              <div className="tooltip-text">
                {value}
              </div>
            </Tooltip>
          )
        },
        setCellProps: () => ({
          className: "cell-medium",
        }),
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        viewColumns: false,
        display: "excluded",
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
        setCellProps: () => ({
          className: "cell-medium",
        }),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        display: "excluded",
        viewColumns: false,
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "jobTitle",
      label: "Job Title",
      options: {
        display: "excluded",
        viewColumns: false,
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
        setCellProps: () => ({
          className: "cell-medium",
        }),
      },
    },
    {
      name: "skills",
      label: "Skills",
      options: {
        display: "excluded",
        viewColumns: false,
        filter: true,
        filterType: "multiselect",
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
        setCellProps: () => ({
          className: "cell-large",
        }),
      },
    },

    {
      name: "experience",
      label: "Experience",
      options: {
        display: "excluded",
        viewColumns: false,
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "fitmentPercentage",
      label: "Fitment",
      options: {
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
        setCellProps: () => ({
          className: "cell-fitment",
        }),
      },
    },
    {
      name: "progressStatus", // Change to the field that holds the status
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const candidateStatus = collectedInfo[rowIndex]?.progressStatus || "Pending"; // Replace with the actual field for the status
          const capitalizeWords = (candidateStatus) => {
            return candidateStatus
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join('');
          };
          
          const capitalizedStatus = capitalizeWords(candidateStatus);
  
          return (
            <Tooltip
              title={capitalizedStatus}
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#660032",
                    color: "#ffffff",
                  },
                },
              }}
            >
              <div className="tooltip-text">
                {capitalizedStatus}
              </div>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          className: "cell-status",
        }),
      },
    },
    {
      name: "analysis",
      label: "Action",

      options: {
        filter: true,
        customHeadRender: ({ index, ...columnMeta }) => (
          <th key={index} className="table-head">
            {columnMeta.label}
          </th>
        ),
        customBodyRender: function viewCandidateSynopsisAndQuestionnaire(
          value,
          tableMeta,
        ) {
          const rowData = tableMeta.rowData
          const rowIndex = tableMeta.rowIndex
          const candidate = collectedInfo[rowIndex];

          // Check if name or phone is missing
          const isNameOrPhoneMissing = !(candidate?.name) || !(candidate?.phone);

          // Console log the candidate data and the disable condition
          // console.log(`Candidate at index ${rowIndex}:`, candidate);
          // console.log(`Is name or phone missing: ${isNameOrPhoneMissing}`);
          
          return (
            <>
              {collectedInfo[rowIndex]
                ?.analysis ?
                parseInt(rowData[7]) < 65 || isNameOrPhoneMissing ? (
                  <Tooltip title="Fitment percentage is below 65%">
                    <span>
                      <IconButton disabled>
                        <ArrowCircleRightIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Synopsis Questionnaire">
                    <IconButton
                      onClick={e => handleViewIconClick(rowData, rowIndex)}
                      disabled={isNameOrPhoneMissing}
                    >
                      <ArrowCircleRightIcon sx={{ color: "#660032" }} />
                    </IconButton>
                  </Tooltip>
                ) : <Skeleton variant="text" />}
            </>
          )
        },
        setCellProps: () => ({
          className: "cell-status",
        }),
      },
    },
  ]

  const options = {
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20],
    rowsPerPage: 15,
    search: false,
  }

  const handleButtonClick = (button) => {
    setSelectedButton(button);
    dispatch(clearCandidateData())
    setJobRequirementInfo("");
    setSelectedFiles([])
    // if (button === "create") {
    //   navigate("/tod", { state: { openDrawer: true } })
    // }
  };

  const handleParsedClick = () => {
    // setSelectedData([]);
    // setSelectedProfiles([]);
    // setRemoveSelectedData(true);
    setActiveButton("scanned")
  }

  const handleFailedClick = () => {
    // setSelectedData([]);
    // setSelectedProfiles([]);
    // setRemoveSelectedData(true);
    setActiveButton("failed");
  }

  const renderWithTooltip = (text) => (
    <Tooltip title={text}>
      <p className='listText'>
        {text}
      </p>
    </Tooltip>
  );

  const reuploadAllFiles = () => {
    // setPreviousFilesCount(rows.length)
    // setReuploadFlag(true)
    setActiveButton("scanned")
    // setFailedRows([])
    let files = failedProfilesInfo?.map((file) => file.file.file)
    console.log("files=>", files)
    setFailedProfilesInfo([])


    dispatch(uploadFileRequest({ files: files, jobRequirementInfo, parsedBy: id, reupload: true }))
  }

  const handleToggle = (value) => () => {
    console.log("handleToggle", value)
    const currentIndex = checked?.indexOf(value.ProfileId);
    const newChecked = [...checked];
    let newSelectedProfiles = [...selectedProfiles];

    if (currentIndex === -1) {
      // Check if the ProfileId is already in newChecked before pushing
      if (!newChecked.includes(value.ProfileId)) {
        newChecked.push(value.ProfileId);
        newSelectedProfiles.push(value);
      }
    } else {
      newChecked.splice(currentIndex, 1);
      newSelectedProfiles = newSelectedProfiles.filter(profile => profile.ProfileId !== value.ProfileId);
    }

    setChecked(newChecked);
    setSelectedProfiles(newSelectedProfiles);
    console.log("selected11", newSelectedProfiles)
    // onSelectionChange(newChecked, newSelectedProfiles);
  };

  const handleSelectAll = (allData) => {
    const allProfileIds = allData.map(profile => profile.ProfileId);

    const allSelected = allProfileIds.every(profileId => checked.includes(profileId));

    if (allSelected) {
      setChecked([]);
      setSelectedProfiles([]);
      // onSelectionChange([], []);
    } else {
      const newChecked = [...allProfileIds];
      const newSelectedProfiles = [...allData];

      setChecked(newChecked);
      setSelectedProfiles(newSelectedProfiles);
      // onSelectionChange(newChecked, newSelectedProfiles);
    }
  };

  const handleDelete = () => {
    setChecked([]);
    setSelectedProfiles([]);
    setLoadingFiles([])

    const updatedFailedRows = [...failedProfilesInfo];
    selectedProfiles.forEach(selectedItem => {
      const index = updatedFailedRows.findIndex(row => row.ProfileId === selectedItem.ProfileId);
      if (index > -1) {
        dispatch(deleteProfileRequest(selectedItem))
        updatedFailedRows.splice(index, 1);
      }
    });
    setFailedProfilesInfo(updatedFailedRows);
    setSelectedProfiles(updatedFailedRows);
  };

  const handleImport = () => {
    setLoadingFiles(selectedCandidate.map(() => ({ loading: true })))
    setIsDrawerOpen(!isDrawerOpen)
    dispatch(uploadFileRequest({ files: selectedCandidate, jobRequirementInfo, reupload: false }))
  }

  const findNextEligibleCandidate = (startIndex) => {
    // Check forward from the current index
    for (let i = startIndex + 1; i < collectedInfo.length; i++) {
      if (parseInt(collectedInfo[i].fitmentPercentage) > 65 && collectedInfo[i].status !== "100%") {
        return i;
      }
    }

    // If no eligible candidate is found forward, check from the beginning to the current index
    for (let i = 0; i <= startIndex; i++) {
      if (parseInt(collectedInfo[i].fitmentPercentage) > 65 && collectedInfo[i].status !== "100%") {
        return i;
      }
    }

    return null;
  };

  useEffect(() => {
    const files = failedProfiles?.map((file, index) => {
      return {
        ProfileId: index + 1,
        file: file
      }
    })
    setFailedProfilesInfo(files)
  }, [failedProfiles])

  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "SQANIT", href: "/scan-it" },
  ];

  useEffect(() => {
    dispatch(fetchSearchesRequest({ entityId, id, role: roleAndFeatures?.name }));
  }, [])

  useEffect(() => {
    if (collectedInfo.length > 0 && currentIndex === -1) {
      const firstEligibleIndex = findNextEligibleCandidate(-1);
      if (firstEligibleIndex !== null) {
        setCurrentIndex(firstEligibleIndex);
        setSelectedIndex(firstEligibleIndex);
        setActiveStep(0);
        setAnalysis(collectedInfo[firstEligibleIndex]);
        setModalOpen(true);
      }
    }
  }, [collectedInfo]);

  return (
    <React.Fragment>
      <CustomWarn open={open} message={message} onConfirm={handleConfirm} onCancel={() => setOpen(false)} />
      <CustomBreadcrumb items={breadcrumbItems} />
      <ProductTabs tab={0} />
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: "20px" }}>
        <Grid container style={{ gap: "10px" }}>
          <Grid item xs={2} direction="column" className="scan-it-paper">

            <div className="sqan-it-button-group-container" style={{ marginTop: "15px" }}>
              <button
                onClick={() => handleButtonClick('select')}
                className={`sqan-it-source-button ${selectedButton === "select" ? "selected" : ""}`}
                size="medium"
              >
                SELECT REQ.ID
              </button>

              <button
                // onClick={() => handleButtonClick('create')}
                className={`sqan-it-source-button ${selectedButton === "create" ? "selected" : ""}`}
                size="medium"
              >
                <Link to="/tod" state={{ openDrawer: true }} style={{ textDecoration: 'none', justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                  CREATE NEW
                </Link>
              </button>
            </div>

            <div item style={{ padding: "5px" }}>
              {selectedButton === 'select' && (
                <>

                  <Autocomplete
                    options={searches}
                    getOptionLabel={(option) => option?.jobRequirementId || jobRequirementInfo?.jobRequirementId}
                    value={selectedJobRequirement || null}
                    onChange={(event, newValue) => {
                      handleJobRequirementChange(event, newValue);
                      if (!newValue) {
                        setShowButton(false)
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.index === value.index}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.label}
                          style={{ backgroundColor: "#fff", color: "#660032" }}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref} className="autocomplete-input-wrapper" style={{ position: 'relative' }}>
                        <input
                          {...params.inputProps}
                          placeholder="Select Requirement ID"
                          style={{
                            padding: "10px",
                            width: "100%",
                            boxSizing: "border-box",
                            /* padding-right: '30px'; */
                            fontSize: "12px",
                            height: "35px",
                            /* margin-bottom: 10px; */
                            borderRadius: "8px",
                            border: "none",
                            border: "1px solid #660032",
                          }}
                        />
                        <ArrowDropDownIcon style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none', color: '#660032' }} />
                      </div>
                    )}
                  />
                </>
              )}
              <input
                placeholder="Job Title"
                className="sqan-it-form-textfield"
                name="jobTitle"
                value={jobRequirementInfo?.jobTitle || ""}
                onChange={handleInputChange}
                disabled={isAutoFilled}
              />
              <input
                placeholder="Skills"
                variant="outlined"
                fullWidth
                className="sqan-it-form-textfield"
                name="mandatorySkills"
                value={jobRequirementInfo?.mandatorySkills || ""}
                onChange={handleInputChange}
                disabled={isAutoFilled}
              />

              <input
                placeholder="Experience (Years e.g 3-7)"
                variant="outlined"
                fullWidth
                className="sqan-it-form-textfield"
                name="experience"
                value={jobRequirementInfo?.experience || ""}
                onChange={handleInputChange}
                disabled={isAutoFilled}
              />

              <input
                placeholder="Location"
                variant="outlined"
                fullWidth
                className="sqan-it-form-textfield"
                name="location"
                value={jobRequirementInfo?.location || ""}
                onChange={handleInputChange}
                disabled={isAutoFilled}
              />
              <input
                placeholder="Job Description"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                className="sqan-it-form-textfield"
                style={{
                  height: "63px"
                }}
                name="jobDescription"
                value={jobRequirementInfo?.jobDescription || ""}
                onChange={handleInputChange}
                disabled={isAutoFilled}
              />
              {!selectedCandidate.length && selectedFiles?.length > 0 && (
                <div className="selected-files">
                  <Typography>
                    {selectedFiles?.length} file(s) selected
                  </Typography>
                </div>
              )}
              {scannedProfilesCount > 0 && (
              <div className="selected-files">
                <Typography>
                  {scannedProfilesCount} scanned profile(s)
                </Typography>
              </div>
            )}
            </div>
            

            <Box className="sqan-it-form-footer">
              {/* Search Button */}
              <Tooltip title="Search" arrow>
                <span>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    size="small"
                    startIcon={<Icon path={mdiFileSearch} size={0.6} />}
                    className={`sqan-it-form-button ${!jobRequirementInfo.jobTitle || !jobRequirementInfo.experience ? 'disabled-button' : ''}`}
                  >
                  </Button>
                </span>
              </Tooltip>
              {/* {selectedCandidate.length > 0 && ( */}
              {/* Import Button */}
              <Tooltip title="Import">
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    className={`sqan-it-form-button ${selectedCandidate.length === 0 ? 'disabled-button' : ''}`}
                    onClick={handleImport}
                    startIcon={<Icon path={mdiImport} size={0.6} />}
                    disabled={selectedCandidate.length === 0}
                  >
                  </Button>
                </span>
              </Tooltip>
              {/* )} */}

              {/* {!selectedCandidate.length && !selectedFiles.length && ( */}

              <input
                type="file"
                accept=".pdf,.doc,.docx"
                hidden
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-upload"
              />
              <label htmlFor="file-upload" style={{ display: 'contents' }}>
                {/* Upload Button */}
                <Tooltip title="Upload">
                  <span>
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      className={`sqan-it-form-button ${!jobRequirementInfo.jobTitle || !jobRequirementInfo.experience || !jobRequirementInfo.mandatorySkills || !jobRequirementInfo.location || !jobRequirementInfo.jobDescription ? 'disabled-button' : ''}`}
                      startIcon={<Icon path={mdiUploadBox} size={0.6} />}
                      disabled={
                        !jobRequirementInfo.jobTitle ||
                        !jobRequirementInfo.experience ||
                        !jobRequirementInfo.mandatorySkills ||
                        !jobRequirementInfo.location ||
                        !jobRequirementInfo.jobDescription
                      }
                    >
                    </Button>
                  </span>
                </Tooltip>
              </label>

              {/* )} */}
              {/* {!selectedCandidate.length && selectedFiles.length > 0 && ( */}
              {/* Sqan-it-button */}
              <Tooltip title="SQAN-IT">
                <span>
                  <Button
                    onClick={handleScanIt}
                    variant="contained"
                    size="small"
                    className={`sqan-it-form-button ${selectedFiles.length === 0 ? 'disabled-button' : ''}`}
                    startIcon={<Icon path={mdiMagnifyScan} size={0.6} />}
                    disabled={selectedFiles.length === 0}
                  >
                  </Button>
                </span>
              </Tooltip>
              {/* )} */}
            </Box>

          </Grid>
          <Grid item xs={5} direction="column" className="scan-it-paper-1">
            <div className="sqan-it-button-group-container-2">
              <Tooltip title={entityData?.companyName || ""} arrow>
                <Button
                  className={`sqan-it-source-button-2 ${dataSource === "Internal" ? "selected" : ""}`}
                  onClick={() => handleDataSourceChange('Internal')}
                  variant="outlined"
                >
                  {entityData?.companyName?.length > 15
                    ? `${entityData.companyName.substring(0, 15)}...`
                    : entityData?.companyName}
                  {candidateCount > 0 && (
                    <div className="count-div">
                      {candidateCount}
                    </div>
                  )}
                </Button>
              </Tooltip>
              <Button
                className={`sqan-it-source-button-2 ${dataSource === "External" ? "selected" : ""}`}
                onClick={() => handleDataSourceChange('External')}
                variant="outlined"
              >
                CIVIX DATA
              </Button>
            </div>
            <div className="sqan-it-card-container">
              {
                loading ?
                  <PulseLoader /> :
                  <>
                    {candidatesData?.length > 0 ? (
                      candidatesData?.map((candidate, index) => (
                        <div className="sqan-it-card" onClick={e => handleCardClick(e, candidate)}>
                          <div className="sqan-it-card-header">
                            <div className="sqan-it-avatar">
                              {candidate.personal_information && candidate.personal_information.name
                                ? candidate.personal_information.name[0]
                                : "N/A"}
                            </div>
                            <Typography className="candidate-name">
                              {candidate.personal_information.name}
                            </Typography>
                            <Checkbox
                              checked={selectedCandidate.some(
                                item => item._id === candidate._id,
                              )}
                              onClick={e => handleCheck(e, index)}
                              className="sqan-it-card-checkbox"
                            />
                          </div>
                          <div className="sqan-it-card-content">
                            <Grid container>
                              <Grid item xs={4} className="sqan-it-card-space">
                                <span className="sqan-it-card-info-label">Email:</span>
                              </Grid>
                              <Grid item xs={7} className="sqan-it-card-space">
                                <span className="sqan-it-value">{candidate.personal_information.email || "NA"}</span>
                              </Grid>
                              <Grid item xs={4} className="sqan-it-card-space">
                                <span className="sqan-it-card-info-label">Contact:</span>
                              </Grid>
                              <Grid item xs={7} className="sqan-it-card-space">
                                <span className="sqan-it-value">{candidate.personal_information.phone || "NA"}</span>
                              </Grid>

                              <Grid item xs={4} className="sqan-it-card-space">
                                <span className="sqan-it-card-info-label">Location:</span>
                              </Grid>
                              <Grid item xs={7} className="sqan-it-card-space">
                                <span className="sqan-it-value">{candidate.personal_information.city || "NA"}</span>
                              </Grid>

                              <Grid item xs={4} className="sqan-it-card-space">
                                <span className="sqan-it-card-info-label">Experience:</span>
                              </Grid>
                              <Grid item xs={7} className="sqan-it-card-space">
                                <span className="sqan-it-value">{`${candidate.total_experience} Years`}</span>
                              </Grid>

                              <Grid item xs={4} className="sqan-it-card-space">
                                <span className="sqan-it-card-info-label">Skills:</span>
                              </Grid>
                              <Grid item xs={7} className="sqan-it-card-space">
                                <span className="sqan-it-value">
                                  {showAllSkills === candidate._id
                                    ? candidate.inferred_skills.join(", ")
                                    : formatSkills(candidate.inferred_skills, false)}
                                </span>
                                {candidate.inferred_skills.length > 2 && (
                                  <Link
                                    className="sqan-it-see-more"
                                    onClick={() => toggleSkillsExpansion(candidate._id)}
                                  >
                                    {showAllSkills === candidate._id ? "See Less" : "See More"}
                                  </Link>
                                )}
                              </Grid>

                            </Grid>
                          </div>
                        </div>
                      ))
                    ) : (
                      <Box className="image-box">
                        {!showButton && candidatesData.length === 0 ?
                          <img src={civixIcon} className="sqan-it-image" />
                          :
                          // <Typography width="100%" align="center">No data found...</Typography>
                          <img src={noDataFound} className="sqan-it-image" />
                        }
                      </Box>
                    )}
                  </>
              }
            </div>
          </Grid>
          <Grid item xs={4.6} className="scan-it-paper-2">
            {Object.keys(showCandidateDetails).length > 0 ? (
              <Box container spacing={2} style={{ backgroundColor: "#f2f2f2", padding: "10px" }}>
                <h5 className="scan-it-title">
                  Personal Information
                </h5>
                <Box className="scan-it-profile-container" >
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} className="scan-it-profile-left-column">
                      <h5>{showCandidateDetails?.personal_information?.name}</h5>
                      {/* Personal Information */}
                      <ul className="profile-personal-item">
                        <li>
                          <div><strong>Email: </strong>{showCandidateDetails?.personal_information?.email || "NA"}</div>
                          <div><strong>Contact: </strong>{showCandidateDetails?.personal_information?.phone || "NA"}</div>
                          <div><strong>Location: </strong>{showCandidateDetails?.personal_information?.city || "NA"}</div>
                          <div><strong>Experience: </strong>{`${showCandidateDetails?.total_experience} Years`}</div>
                        </li>
                      </ul>
                      {/* Education Background */}

                      <h5>Educational Details :</h5>
                      <ul className="profile-education-item">
                        {showCandidateDetails?.education?.map((edu) => (
                          <li key={edu._id}>
                            <div><strong>{edu.degree}</strong></div>
                            {edu.field_of_study && (
                              <div><strong>Field: </strong>{edu.field_of_study}</div>
                            )}
                            {edu.graduation_date && (
                              <div><strong>Graduation: </strong>{edu.graduation_date}</div>
                            )}
                          </li>
                        ))}
                      </ul>
                      {/* Skills */}
                      <h5>Skills : </h5>
                      {showCandidateDetails?.inferred_skills.length > 0 && (
                        <div className="profile-skills-list">
                          {showCandidateDetails?.inferred_skills?.map((skill, index) => (
                            <div key={index} className="profile-skill-item">{skill}</div>
                          ))}
                        </div>

                      )}
                    </Grid>
                    {/* Professional Experience */}
                    <Grid item xs={6} className="scan-it-profile-right-column">
                      <h5>Experience:</h5>
                      {showCandidateDetails?.professional_experience?.map((exp) => (
                        <ul key={exp._id} className="profile-experience-item">
                          <li>
                            <div><strong>Designation: </strong> {exp.job_title}</div>
                            <div><strong>Company: </strong> {exp.company_name}</div>
                            <div><strong>Duration: </strong> {exp.employment_dates}</div>
                            {exp.experience_description && (
                              <div><strong>Description: </strong> {exp.experience_description}</div>
                            )}
                          </li>
                        </ul>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <div className="image-box">
                <img src={cvDetailsIcon} className="sqan-it-image" />
              </div>
            )}
          </Grid>

        </Grid>


        {/* { progress &&
      progress > 0 (
        <Snackbar
          open={!isDrawerOpen}
          // autoHideDuration={6000}
          // onClose={handleSnackbarClose}
          // onClick={handleSnackbarClick}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="info" sx={{ width: '100%' }}>
            Upload Progress: {progress}%
          </Alert>
        </Snackbar>
      )
    } */}
      </Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        PaperProps={{
          className: 'scan-it-drawer', // Apply external CSS class
        }}
      >
        <CloseIcon onClick={() => setOpen(true)} className="scan-it-close-icon" />
        <Grid container spacing={2} sx={{ padding: "15px" }}>

          <Grid item lg={5}>
            <Paper className="scan-it-drawer-paper">
              <div className="scan-it-button-container">
                <div className="scan-it-button-group">
                  <Button
                    onClick={handleParsedClick}
                    className={`scan-it-button ${activeButton === "scanned" ? "active" : ""}`}
                  >
                    Scanned [{collectedInfo ? collectedInfo.length : 0}]
                  </Button>
                  <Button
                    onClick={handleFailedClick}
                    className={`scan-it-button ${activeButton === "failed" ? "active" : ""}`}
                  >
                    Failed [{failedProfiles ? failedProfiles.length : 0}]
                  </Button>
                </div>
                <div className="scan-it-delete-button-container">
                  {activeButton === "failed" && (
                    <Button
                      onClick={handleDelete}
                      disabled={checked.length === 0 || !failedProfilesInfo}
                      className={`scan-it-delete-button ${checked.length > 0 ? "enabled" : "disabled"}`}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  )}
                </div>
              </div>
              {
                activeButton === "scanned" ?
                  (
                    <MUIDataTable
                      data={combinedRows.map((file, index) => ({
                        ...file,
                        ...collectedInfo[index],
                        name: collectedInfo[index]?.name || (
                          <Skeleton variant="text" width={50} />
                        ),
                        // phone: collectedInfo[index]?.phone || <Skeleton variant="text" width={100} />,
                        // email: collectedInfo[index]?.email || <Skeleton variant="text" width={200} />,
                        // jobTitle: collectedInfo[index]?.jobTitle || <Skeleton variant="text" width={150} />,
                        // skills: collectedInfo[index]?.skills || <Skeleton variant="text" width={300} />,
                        // experience: collectedInfo[index]?.experience || <Skeleton variant="text" width={150} />,
                        fitmentPercentage: collectedInfo[index]
                          ?.fitmentPercentage || (
                            <Skeleton variant="text" width={10} />
                          ),
                        status: collectedInfo[index]
                          ?.status || (
                            <Skeleton variant="text" width={10} />
                          ),
                      }))}
                      options={options}
                      columns={columns}
                    />

                  )
                  :
                  (
                    failedProfiles.length === 0 ?
                      <List className='listView'>
                        <ListItem>
                          <ListItemText sx={{ textAlign: "center" }}>No data found...</ListItemText>
                        </ListItem>
                      </List>
                      :
                      (
                        <List className="listView" style={{ padding: "0px" }}>
                          <ListItem
                            secondaryAction={<div
                              onClick={() => reuploadAllFiles()}
                              style={{ cursor: "pointer" }} ><Tooltip title={"Reupload All Files"}><ReplayIcon sx={{ marginRight: "5px" }} /></Tooltip></div>}
                            sx={{
                              backgroundColor: "#660032",
                              color: "white",
                              padding: "5px 0px",
                              fontSize: "12px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ListItemIcon style={{ padding: "0px 5px" }}>
                              <Checkbox
                                edge="start"
                                onClick={() => handleSelectAll(failedProfilesInfo)}
                                tabIndex={-1}
                                disableRipple
                                style={{ padding: "1px", paddingLeft: "15px", color: "white" }}
                              />
                            </ListItemIcon>
                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
                              <div style={{ paddingLeft: "15px" }}>Name</div>
                            </div>
                          </ListItem>
                          {failedProfilesInfo?.map((value) => {
                            const labelId = `checkbox-list-label-${value.ProfileId}`;

                            return (
                              <ListItem
                                key={value.ProfileId}
                                className="listItem"
                                sx={{ padding: 0, fontSize: "12px" }}
                              >
                                <ListItemIcon style={{ padding: "0px 5px" }}>
                                  <Checkbox
                                    edge="start"
                                    onClick={handleToggle(value)}
                                    checked={checked.indexOf(value.ProfileId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    style={{ padding: "1px", paddingLeft: "15px" }}
                                  />
                                </ListItemIcon>
                                <div style={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
                                  <div style={{ paddingLeft: "15px", width: "65%" }}>{renderWithTooltip(value.file.name)}</div>
                                </div>
                              </ListItem>
                            );
                          })}
                        </List>)
                  )
              }

            </Paper>
          </Grid>
          <Grid item lg={7}>
            <Paper className="scan-it-drawer-paper">
              {showStepper ? (
                analysis && (
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    className="scan-it-stepper">
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                          {getStepContent(index, analysis)}
                          <Box
                            className={`scan-it-stepper-buttons ${activeStep === 0
                              ? "scan-it-stepper-buttons-start"
                              : "scan-it-stepper-buttons-between"
                              }`}
                          >
                            {activeStep > 0 && (
                              <Button
                                size="small"
                                className="scan-it-button-back"
                                onClick={handleBack}
                              >
                                Back
                              </Button>
                            )}
                            {activeStep < steps.length - 1 && (
                              <Button
                                size="small"
                                variant="contained"
                                className="scan-it-button-next"
                                onClick={handleNext}
                                disabled={!stepStatusData[activeStep]?.progressStatus}
                              >
                                Next
                              </Button>
                            )}
                            {activeStep === steps.length - 1 && (
                              <>
                                {/* <Button
                              size="small"
                              variant="contained"
                              sx={{
                                backgroundColor: "#660032",
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#660032",
                                },
                                "&.Mui-disabled": {
                                  backgroundColor: "#c1c1c1",
                                  color: "#660032",
                                },
                              }}
                              onClick={handleSaveJobApplication}
                              disabled={status === "Completed"}
                            >
                              Save
                            </Button> */}
                                <Button
                                  size="small"
                                  variant="contained"
                                  className="scan-it-button-finish"
                                  onClick={handleFinish}
                                  disabled={
                                    isFormIncomplete() || status === "Completed"
                                  }
                                >
                                  Finish
                                </Button>
                              </>
                            )}
                          </Box>
                        </StepContent>
                      </Step>
                    ))}

                    {/* {activeStep > 0 && (
                        <Button sx={{ color: "#fff" }} onClick={handleBack}>
                          Back
                        </Button>
                      )}
                      {activeStep === steps.length - 1 && (
                        <div
                          style={{ position: "relative", display: "inline-block" }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleSaveJobApplication}
                            disabled={status === "Completed"}
                            sx={{
                              "&.Mui-disabled": {
                                backgroundColor: "#c1c1c1",
                                color: "#660032",
                              },
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                      {activeStep === steps.length - 1 ? (
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            "&.Mui-disabled": {
                              backgroundColor: "#c1c1c1",
                              color: "#660032",
                            },
                          }}
                          onClick={handleFinish}
                          disabled={
                            (activeStep === steps.length - 1 &&
                              isFormIncomplete()) ||
                            status === "Completed"
                          }
                        >
                          Finish
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            "&.Mui-disabled": {
                              backgroundColor: "#fff",
                              color: "#660032",
                            },
                          }}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      )} */}
                  </Stepper>
                )
              ) : (
                <EvaluationPrompt />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  )
}


export default ScanIT
