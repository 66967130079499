import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import './CustomComponent.css'

const CustomBreadcrumb = ({ items }) => {

  const breadcrumbs =

    items?.map((item, index) => {
      const isLast = index === items.length - 1;
      return isLast ? (
        <Link
          key={item.label}
          className='breadCrumb-active'
          to="#"
          onClick={(e) => e.preventDefault()}
        >
          {item.label}
        </Link>
      ) : (
        <Link
          key={index}
          to={item.href}
          className='breadCrumb-link'
        >
          {item.label}
        </Link>
      );
    })

  return (
    <Stack spacing={2} className='breadCrumb-container'>
      <Breadcrumbs
        separator={<KeyboardDoubleArrowRightIcon className='breadCrumb-separator' />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomBreadcrumb;