import {
  JOB_CREATION,
  JOB_CREATION_SUCCESS,
  JOB_CREATION_FAILURE,
  GET_ALL_JOB_REQUIREMENTS,
  GET_ALL_JOB_REQUIREMENT_SUCCESSS,
  GET_ALL_JOB_REQUIREMENT_FAILURE,
  FETCH_JOB_REQUIREMENTS_REQUEST,
  FETCH_JOB_REQUIREMENTS_SUCCESS,
  FETCH_JOB_REQUIREMENTS_FAILURE,
  GET_ALL_USERS_BY_ENTITY_ID,
  GET_ALL_USERS_BY_ENTITY_ID_SUCCESS,
  GET_ALL_USERS_BY_ENTITY_ID_FAILURE,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_FAILURE,
  GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_SUCCESSS,
  FETCH_JOB_REQUIREMENT_REQUEST,
  FETCH_JOB_REQUIREMENT_SUCCESS,
  FETCH_JOB_REQUIREMENT_FAILURE,
  POST_JOB_REQUEST,
  POST_JOB_SUCCESS,
  POST_JOB_FAILURE,
  UPDATE_JOB_REQUIREMENT,
  UPDATE_JOB_REQUIREMENT_SUCCESS,
  UPDATE_JOB_REQUIREMENT_FAILURE,
  CLEAR_MESSAGE
} from "./actionTypes"

const initialState = {
  submitting: false,
  success: false,
  error: null,
  message: null,
  formData: null,
  loading: false,
  allJobRequirements: null,
  jobs: [],
  allUsers: [],
  jobRequirement: {},
  job: null
}

const jobRequirementReducer = (state = initialState, action) => {
  switch (action.type) {
    //create job requirement
    case JOB_CREATION:
      return {
        ...state,
        submitting: true,
        success: false,
        error: null,
        loading: true,
        message: null,
      }

    case JOB_CREATION_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        message: "Job created Successfully..!",
        formData: action.payload,
        loading: false,
      }

    case JOB_CREATION_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        error: action.payload,
        loading: false,
      }

    case UPDATE_JOB_REQUIREMENT:
      return {
        ...state,
        submitting: true,
        success: false,
        error: null,
        message: null,
        loading: true,
      }

    case UPDATE_JOB_REQUIREMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        message: "Job updated Successfully..!",
        formData: action.payload,
        loading: false,
      }

    case UPDATE_JOB_REQUIREMENT_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        error: action.payload,
        loading: false,
      }
      
    // get all job requirements
    case GET_ALL_JOB_REQUIREMENTS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_JOB_REQUIREMENT_SUCCESSS:
      return {
        loading: false,
        allJobRequirements: action.payload,
        error: "",
      }
    case GET_ALL_JOB_REQUIREMENT_FAILURE:
      return {
        loading: false,
        allJobRequirements: [],
        error: action.payload,
      }

    case GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID:
      return { 
        ...state, 
        loading: true 
      }

    case GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_SUCCESSS:
      return { 
        ...state, 
        loading: false, 
        allJobRequirements: action.payload 
      }

    case GET_ALL_JOB_REQUIREMENTS_BY_ENTITY_ID_FAILURE:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      }

    case FETCH_JOB_REQUIREMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_JOB_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.payload,
        allJobRequirements: action.payload,
      }
    case FETCH_JOB_REQUIREMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_ALL_USERS_BY_ENTITY_ID:
      return {
        ...state,
        success: false,
        loading: true,
      }
    case GET_ALL_USERS_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        allUsers: action.payload,
        error: "",
      }
    case GET_ALL_USERS_BY_ENTITY_ID_FAILURE:
      return {
        loading: false,
        allUsers: [],
        error: action.payload,
      }
    case FETCH_JOB_REQUIREMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_JOB_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        jobRequirement: action.payload,
        error: null
      };
    case FETCH_JOB_REQUIREMENT_FAILURE:
      return {
        ...state,
        loading: false,
        jobRequirement: null,
        error: action.payload
      };
    case POST_JOB_REQUEST:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };
    case POST_JOB_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        job: action.payload.data, 
        message: action.payload.message,
        error: null 
      };
    case POST_JOB_FAILURE:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case CLEAR_MESSAGE:
      return { 
        ...state, 
        loading: false, 
        message: null 
      };
    default:
      return state
  }
}

export default jobRequirementReducer
